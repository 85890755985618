import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
//import { RootStateOrAny } from "react-redux";
import { IAbsenceReportExtended } from "interfaces/IAbsenceReportExtended";
import PrimaryButton from "components/PrimaryButton";
import Select, { ISelectValue } from "components/Select";
import SecondaryButton from "components/SecondaryButton";
import { SpinnerWithOverlay } from "components/Spinner";
import { MedHelpAbsenceReportingDomainTemplate } from "swagger/absencereportapi";
import { actions as absenceReportsActions, IAbsenceReportSlice, thunkActions, selectors } from "../../redux";
import AbsenceReportFieldWrapper from "../AbsenceReportFieldWrapper";
import * as UserSelectors from "store/userSelectors";
import { SingleDatepicker } from "@medhelp/ui";
import Timepicker from "components/Timepicker";
import { useSingleDatepickerProps } from "hooks";
import { getDateStringForwardOrBack, getDateFromStringOrDate, getStringFromDate } from "utils/date";
import { IUpdateSelectedAbsenceReportDates } from "../../redux/absenceReportsSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store";

interface IAbsenceReportEditFinished {
    id: string;
    preset: IAbsenceReportHistoryCardPresets;
    className?: string;
    absenceReport: IAbsenceReportExtended;
}

const absenceReportHistoryCardPresets = {
    background: "pageBg",
    red: "bg-red-500",
};

export type IAbsenceReportHistoryCardPresets = keyof typeof absenceReportHistoryCardPresets;

const AbsenceReportEditFinished = ({
    id,
    preset,
    className,
    absenceReport,
    children,
}: IAbsenceReportEditFinished & { children?: React.ReactNode }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [absenceTemplateTypes, setAbsenceTemplateTypes] = useState<ISelectValue[]>([]);
    const [enableSave, setEnableSave] = useState(false);
    const [absenceDegreeChanged, setAbsenceDegreeChanged] = useState(false);
    const [selectedAbsenceDegree, setSelectedAbsenceDegree] = useState(
        absenceReport?.degree ? absenceReport?.degree?.toString() : "",
    );
    const [timeStamp, setTimeStamp] = useState<string | undefined>(undefined);

    const userContext = useAppSelector(UserSelectors.getUserContext);
    const selectedAbsenceReport = useAppSelector(selectors.getSelectedAbsenceReport);
    const template = useAppSelector(selectors.getSelectedTemplate);
    const hasChanged = useAppSelector(selectors.getIsChanged(absenceReport));
    const [startTimeHour, setStartTimeHour] = useState<number>(0);
    const [startTimeMinute, setStartTimeMinute] = useState<number>(0);
    const [endTimeHour, setEndTimeHour] = useState<number>(0);
    const [endTimeMinute, setEndTimeMinute] = useState<number>(0);
    const { Templates, EditFinishedAbsenceReportOK } = useAppSelector(
        (state: RootState) => state.absenceReports as IAbsenceReportSlice,
    );

    const isShowReportHealthyTime = useAppSelector(selectors.getConfigurationByName("showReportHealthyTime"));

    // disable save button if the start date is after the end date
    useEffect(() => {
        if (!hasChanged) return;
        if (!selectedAbsenceReport?.start || !selectedAbsenceReport?.end) return;
        const sd = getDateFromStringOrDate(selectedAbsenceReport.start);
        const ed = getDateFromStringOrDate(selectedAbsenceReport.end);
        if (sd > ed) {
            setEnableSave(false);
            return;
        }
        setEnableSave(true);
    }, [hasChanged, selectedAbsenceReport?.start, selectedAbsenceReport?.end]);

    useEffect(() => {
        dispatch(absenceReportsActions.updateSelectedAbsenceReport(absenceReport));
    }, [absenceReport, dispatch]);

    useEffect(() => {
        if (Templates && Templates.length > 0) {
            setAbsenceTemplateTypes(
                Templates.map((at: MedHelpAbsenceReportingDomainTemplate) => {
                    return {
                        value: at.absenceReportType!,
                        description: t(_.lowerFirst(at.absenceReportType!)),
                    };
                }),
            );
        }
    }, [Templates, t]);

    useEffect(() => {
        if (EditFinishedAbsenceReportOK) {
            dispatch(absenceReportsActions.updateEditFinishedAbsenceReportOK(false));
            dispatch(
                absenceReportsActions.updateAbsenceReportIsEdit({
                    id: absenceReport.id,
                    isEdit: false,
                }),
            );
        }
    }, [EditFinishedAbsenceReportOK, absenceReport.id, dispatch]);

    // case to set the time to the selected absence report if isShowReportHealthyTime is true
    useEffect(() => {
        if (!isShowReportHealthyTime) return;

        const sd = getDateFromStringOrDate(selectedAbsenceReport?.start ?? "");
        const ed = getDateFromStringOrDate(selectedAbsenceReport?.end ?? "");

        setStartTimeHour(sd.getHours());
        setStartTimeMinute(sd.getMinutes());
        setEndTimeHour(ed.getHours());
        setEndTimeMinute(ed.getMinutes());
    }, [selectedAbsenceReport?.start, selectedAbsenceReport?.end, isShowReportHealthyTime]);

    // if the selected time is changed, we want to update the selectedAbsenceReport to the new time
    const handleTimeChange = useCallback(
        (newTime: number | undefined, field: "start" | "end", section: "hour" | "minute") => {
            if (newTime === undefined) return;
            let dates: IUpdateSelectedAbsenceReportDates = {
                startDate: selectedAbsenceReport?.start,
                endDate: selectedAbsenceReport?.end ?? undefined,
                backAtWork: selectedAbsenceReport?.backAtWork ?? undefined,
            };

            let sd = getDateFromStringOrDate(selectedAbsenceReport?.start ?? "");
            let ed = getDateFromStringOrDate(selectedAbsenceReport?.end ?? "");

            if (field === "start" && section === "hour") sd.setHours(newTime);
            if (field === "start" && section === "minute") sd.setMinutes(newTime);
            if (field === "end" && section === "hour") ed.setHours(newTime);
            if (field === "end" && section === "minute") ed.setMinutes(newTime);

            dates.startDate = getStringFromDate(sd);
            dates.endDate = getStringFromDate(ed);
            dispatch(absenceReportsActions.updateSelectedAbsenceReportDates(dates));
        },
        [dispatch, selectedAbsenceReport?.backAtWork, selectedAbsenceReport?.end, selectedAbsenceReport?.start],
    );

    // if the selected date is changed, we want to update the selectedAbsenceReport to the new date
    const handleDateChange = useCallback(
        (newDate: string | undefined, date: "start" | "end" | "baw") => {
            if (!newDate) return;

            // If the company has the setting showReportHealthyTime, we want to set the time to 00:00:00
            // If the date is the end date, we want to set the time to 00:00:01 to make sure to avoid collisions where start > end
            const nd = getDateFromStringOrDate(newDate);
            if (!isShowReportHealthyTime) nd.setHours(0, 0, 0, 0);
            if (!isShowReportHealthyTime && date === "end") nd.setHours(0, 0, 1, 0);
            newDate = getStringFromDate(nd);

            let dates: IUpdateSelectedAbsenceReportDates = {
                startDate: selectedAbsenceReport?.start,
                endDate: selectedAbsenceReport?.end ?? undefined,
                backAtWork: selectedAbsenceReport?.backAtWork ?? undefined,
            };
            if (date === "start") dates.startDate = newDate;
            else if (date === "end") {
                dates.endDate = newDate;
                dates.backAtWork = getDateStringForwardOrBack(1, "days", "forward", newDate);
            } else dates.backAtWork = newDate;
            dispatch(absenceReportsActions.updateSelectedAbsenceReportDates(dates));
        },
        [
            dispatch,
            selectedAbsenceReport?.backAtWork,
            selectedAbsenceReport?.end,
            selectedAbsenceReport?.start,
            isShowReportHealthyTime,
        ],
    );

    // if the selected degree is changed, we want to update the selectedAbsenceReport to the new degree and display the datepicker for the degree change
    const handleAbsenceDegreeChange = useCallback(
        (degree: string | undefined) => {
            if (degree) {
                dispatch(
                    absenceReportsActions.updateSelectedAbsenceReportDegrees({
                        value: degree,
                        timeStamp: timeStamp,
                    }),
                );
                setSelectedAbsenceDegree(degree);
                setAbsenceDegreeChanged(true);
            }
        },
        [dispatch, timeStamp],
    );

    // if the date for the degree change is changed, we want to update the selectedAbsenceReport degree to the new date
    const handleAbsenceDegreeDateChange = (timeStamp: string | undefined) => {
        if (absenceDegreeChanged && timeStamp && selectedAbsenceDegree) {
            dispatch(
                absenceReportsActions.updateSelectedAbsenceReportDegrees({
                    value: selectedAbsenceDegree,
                    timeStamp: timeStamp,
                }),
            );
        }
        setTimeStamp(timeStamp);
    };

    // when the user clicks on the cancel button, we want to reset the selectedAbsenceReport and set isEdit to false
    const handleCancelOnClick = () => {
        dispatch(
            absenceReportsActions.updateAbsenceReportIsEdit({
                id: absenceReport.id,
                isEdit: false,
            }),
        );
        dispatch(absenceReportsActions.resetSelectedAbsenceReport());
    };

    // when the user clicks on the save button, we want to dispatch the thunk to save the selectedAbsenceReport
    const handleSaveOnClick = () => {
        if (selectedAbsenceReport && template?.id) {
            dispatch(thunkActions.editHistoricalAbsenceReport(selectedAbsenceReport));
        }
    };

    const startDatepickerProps = useSingleDatepickerProps({
        onChangeDate: (d) => handleDateChange(d, "start"),
        givenSelectedDate: selectedAbsenceReport?.start,
        placeholder: t("addDate"),
    });
    const endDatepickerProps = useSingleDatepickerProps({
        onChangeDate: (d) => handleDateChange(d, "end"),
        givenSelectedDate: selectedAbsenceReport?.end ?? undefined,
        placeholder: t("addDate"),
    });
    const backAtWorkDatepickerProps = useSingleDatepickerProps({
        onChangeDate: (d) => handleDateChange(d, "baw"),
        givenSelectedDate: selectedAbsenceReport?.backAtWork ?? undefined,
        placeholder: t("addDate"),
    });
    const timeStampDatepickerProps = useSingleDatepickerProps({
        onChangeDate: handleAbsenceDegreeDateChange,
        givenSelectedDate: selectedAbsenceReport?.end ?? undefined,
        placeholder: t("selectDate"),
    });

    const disableDatesAfter = useCallback(
        (noOfTime: number, isDays?: boolean): string =>
            getDateStringForwardOrBack(noOfTime, isDays ? "days" : "months", "forward"),
        [],
    );

    const maxMonthsToRegisterHistoricalAbsence = userContext.HasCustomerSupport
        ? 36
        : userContext.HasAbsenceAdministration
          ? 12
          : 1;

    const disableDatesBefore = getDateStringForwardOrBack(maxMonthsToRegisterHistoricalAbsence, "months", "backwards");
    return (
        <div
            id={id}
            className={`relative p-6 shadow-custom xxs:p-8 xxs:px-10 xs:-mx-10 ${absenceReportHistoryCardPresets[preset]} ${className}`}
        >
            {selectedAbsenceReport?.isLoading && <SpinnerWithOverlay />}
            <div>
                <h4>{t("editCompletedAbsence")}</h4>
            </div>
            <p className="mt-4 text-grey-600 lg:mt-6 lg:w-3/5">
                {t("editHistoricalAbsenceMaxMonths", {
                    months: maxMonthsToRegisterHistoricalAbsence,
                })}
            </p>
            <div className="flex flex-wrap gap-4">
                <AbsenceReportFieldWrapper heading={`${t("kindOfAbsence")}`} isMandatory>
                    <Select
                        id="absenceTypeGroup"
                        placeholder={t("choseAbsenceType")}
                        hidePlaceHolderInList
                        disabled={true}
                        values={absenceTemplateTypes}
                        onChange={() => {}}
                        selectedValue={absenceReport.type!}
                        className="xs:w-80"
                    />
                </AbsenceReportFieldWrapper>

                <AbsenceReportFieldWrapper heading={t("startDate")} isMandatory>
                    <SingleDatepicker
                        {...startDatepickerProps}
                        disableDatesBefore={disableDatesBefore}
                        disableDatesAfter={selectedAbsenceReport?.end || undefined}
                        mobileHeading={t("startDate")}
                        isMandatory
                        className="flex grow"
                    />
                </AbsenceReportFieldWrapper>
                {isShowReportHealthyTime && (
                    // If the company has the setting showReportHealthyTime, we want to show the timepicker
                    <AbsenceReportFieldWrapper heading={t("startTime")} isMandatory>
                        <Timepicker
                            hourOnChange={(time) => handleTimeChange(time, "start", "hour")}
                            hourValue={startTimeHour}
                            minuteOnChange={(time) => handleTimeChange(time, "start", "minute")}
                            minuteValue={startTimeMinute}
                            className="xs:w-80"
                        />
                    </AbsenceReportFieldWrapper>
                )}

                <AbsenceReportFieldWrapper heading={t("endDate")} isMandatory>
                    <SingleDatepicker
                        {...endDatepickerProps}
                        disableDatesBefore={selectedAbsenceReport?.start}
                        disableDatesAfter={disableDatesAfter(1, true)}
                        mobileHeading={t("endDate")}
                        isMandatory
                        className="flex grow"
                    />
                </AbsenceReportFieldWrapper>
                {isShowReportHealthyTime && (
                    // If the company has the setting showReportHealthyTime, we want to show the timepicker
                    <AbsenceReportFieldWrapper heading={t("endTime")} isMandatory>
                        <Timepicker
                            hourOnChange={(time) => handleTimeChange(time, "end", "hour")}
                            hourValue={endTimeHour}
                            minuteOnChange={(time) => handleTimeChange(time, "end", "minute")}
                            minuteValue={endTimeMinute}
                            className="xs:w-80"
                        />
                    </AbsenceReportFieldWrapper>
                )}
                <AbsenceReportFieldWrapper heading={t("backAtWork")} isMandatory>
                    <SingleDatepicker
                        {...backAtWorkDatepickerProps}
                        disableDatesBefore={selectedAbsenceReport?.end ?? ""}
                        disabled={!selectedAbsenceReport?.end}
                        mobileHeading={t("backAtWork")}
                        isMandatory
                        className="flex grow"
                    />
                </AbsenceReportFieldWrapper>

                <AbsenceReportFieldWrapper
                    heading={t("absenceDegree")}
                    isMandatory
                    modalHeading={t("absenceDegreeInfoHistoricalModalHeader")}
                    modalTopContent={t("absenceDegreeInfoHistoricalModalContent")}
                >
                    <Select
                        id="absenceDegreeSelect"
                        placeholder={t("choseDegree")}
                        hidePlaceHolderInList
                        values={[
                            { value: "100", description: "100%" },
                            { value: "75", description: "75%" },
                            { value: "50", description: "50%" },
                            { value: "25", description: "25%" },
                        ]}
                        onChange={handleAbsenceDegreeChange}
                        selectedValue={selectedAbsenceDegree ?? ""}
                        className="xs:w-80"
                    />
                </AbsenceReportFieldWrapper>

                {selectedAbsenceReport && selectedAbsenceReport.degree && absenceDegreeChanged && (
                    <AbsenceReportFieldWrapper heading={t("degreeChangedFrom")} isMandatory>
                        <SingleDatepicker
                            {...timeStampDatepickerProps}
                            disableDatesBefore={selectedAbsenceReport?.start}
                            disableDatesAfter={selectedAbsenceReport.end ?? undefined}
                            mobileHeading={t("degreeChangedFrom")}
                            isMandatory
                            className="xs:w-80"
                        />
                    </AbsenceReportFieldWrapper>
                )}
            </div>
            <div className="mt-10 flex justify-between xxs:mr-10 xxs:mt-8 lg:absolute lg:right-0 lg:top-0">
                <SecondaryButton
                    id="registerHistArCancelBtn"
                    text={t("abort")}
                    onClick={handleCancelOnClick}
                    className="mr-1 flex w-full justify-center"
                />
                <PrimaryButton
                    id="registerHistArSaveBtn"
                    text={t("save")}
                    onClick={handleSaveOnClick}
                    className="ml-1 flex w-full justify-center"
                    disabled={!enableSave}
                />
            </div>
            {children}
        </div>
    );
};
export default AbsenceReportEditFinished;
