import { IDoubleDatepickerProps } from "@medhelp/ui/Datepicker/types";
import { useTranslation } from "react-i18next";
import Dropdown from "../Dropdown";
import { DateRangePicker } from "components/DatePicker/DateRangePicker";
import StatusMessage from "components/StatusMessage/StatusMessage";

interface IProps {
    certificateDatePickerProps: IDoubleDatepickerProps;
    setCurrentChild: (index: number) => void;
    heading: string;
    values: string[];
    child?: number;
}
const AddMedicalCertificateMetadata = ({
    certificateDatePickerProps,
    setCurrentChild,
    child,
    heading,
    values,
}: IProps) => {
    const { t } = useTranslation();
    return (
        <>
            <StatusMessage
                text={t("youCanUpload1PdfOr8Photos") + " " + t("infoMedicalCertificate")}
                icon="info"
                theme="info"
            />
            <DateRangePicker {...certificateDatePickerProps} />
            <div className="flex flex-col">
                <p className="mb-4 font-bold">
                    {t("absenceDegree")} <span className="text-red-500">*</span>
                </p>
                <Dropdown
                    onDropdownClick={setCurrentChild}
                    heading={heading}
                    activeChild={child}
                    data-testid="medical-certificate-degree-metadata"
                >
                    {values.map((value, index) => (
                        <p key={`dropdown-degree-${value}-${index}`} data-test-id={`dropdown-degree-${value}-${index}`}>
                            {value}
                        </p>
                    ))}
                </Dropdown>
            </div>
        </>
    );
};
export default AddMedicalCertificateMetadata;
