import { IDynamicData } from "@medhelp/ui/List/CheckboxList/types";
import { getServices } from "api/services";
import { TFunction } from "i18next";
import { get, flow, isEqual, isNull, isString, lowerCase, uniqBy, lowerFirst } from "lodash/fp";
import {
    MedHelpAbsenceFollowUpWebApiRequestSearchPeriods,
    MedHelpAbsenceFollowUpWebApiServicesFilterResponse,
} from "swagger/absencefollowup";
import { getDateDisplayValue, newDateString, getDateForwardOrBack, getStartOfDateString } from "utils/date";
import {
    ISearchFilterTags,
    isSearchNumber,
    ISortingFilter,
    isTag,
    ITags,
    isDate,
    INumber,
    IDate,
    IFilter,
    isMonth,
    checkIfNumberAndRunCallback,
    checkIfTagsAndRunCallback,
    ISearchFilter,
    checkIfDateAndRunCallback,
    IMonth,
} from "../types";
import { SearchFilterState, ISeachActionPayloadContent, EmployementGroup } from "../redux/followUpStateTypes";
import { TreeViewNode } from "swagger/employeeattendance";
import { createSearchRequestSchema, getTemplateIdsFilteredByCaseType } from "./searchSchemas";
import { getExtraRule, getPeriodsWithCorrectLabel, sortPeriod } from "./searchSortings";
import { getIdFromSelected, getReferenceKeyFromSelected, isAllSelected } from "domain/ogranization/dynamicData";
import { MedHelpHCMDomainTemplate } from "swagger/healthCaseManagement";
import { checkIfJestIsRunning } from "./checkIfJestIsRunning";

export const SEARCH_PAGE_SIZE = 50 as const;

export enum MonthEnum {
    Started,
    Created,
    Ended,
    Affected,
}

export const ALL_SEARCH_FILTERS = [
    "from",
    "to",
    "maxDays",
    "minDays",
    "occurrences",
    "month",
    "monthStatus",
    "absenceStatus",
    "absenceType",
    "workRelated",
    "employmentGroups",
    "sortingFilter",
    "periodStatus",
    "reimbursableType",
    "absenceImpact",
    "codes",
    "causes",
    "caseStatus",
    "caseType",
    "activityStatus",
    "daysActivityDelayed",
    "includeArchived",
] as const;
export type AllFiltersType = (typeof ALL_SEARCH_FILTERS)[number];

export type ExtractFilterTypes<T extends AllFiltersType, C extends AllFiltersType> = Extract<T, C>;

export type SearchStatus = "pending" | "ok" | "error" | "no-data" | "empty";

export const SearchTypesFilter: {
    [k in SearchTypes]: AllFiltersType[];
} = {
    absencePeriod: [
        "from",
        "to",
        "absenceType",
        "workRelated",
        "maxDays",
        "minDays",
        "occurrences",
        "employmentGroups",
        "absenceImpact",
        "includeArchived",
    ],
    ongoingAbsence: ["maxDays", "minDays", "absenceType"],
    longtermAbsence: ["from", "to", "absenceImpact", "codes", "employmentGroups"],
    monthlyReport: ["month", "absenceType", "absenceImpact"],
    recurringAbsence: ["from", "to", "employmentGroups", "absenceImpact"],
    medicalCertificate: ["from", "to"],
    reimbursable: ["absenceType", "reimbursableType", "from", "to", "employmentGroups"],
    cases: ["from", "to", "causes", "caseStatus", "caseType"],
    activities: ["from", "to", "caseType", "activityStatus", "daysActivityDelayed"],
};

export const FiltersInView: { [key in AllFiltersType]: boolean } = {
    month: false,
    from: false,
    to: false,
    maxDays: false,
    minDays: false,
    occurrences: false,
    absenceStatus: false,
    absenceType: false,
    workRelated: false,
    employmentGroups: false,
    sortingFilter: false,
    periodStatus: false,
    reimbursableType: false,
    codes: false,
    monthStatus: false,
    absenceImpact: false,
    causes: false,
    caseStatus: false,
    caseType: false,
    activityStatus: false,
    daysActivityDelayed: false,
    includeArchived: false,
} as const;

export type FiltersInViewType = typeof FiltersInView;

export const ReportSearch = [
    "absencePeriod",
    "ongoingAbsence",
    "longtermAbsence",
    "recurringAbsence",
    "monthlyReport",
    "medicalCertificate",
    "reimbursable",
    "cases",
    "activities",
] as const;

export const AbsenceSearch = [
    "absencePeriod",
    "ongoingAbsence",
    "longtermAbsence",
    "recurringAbsence",
    "monthlyReport",
    "medicalCertificate",
    "reimbursable",
] as const;

export const CaseSearch = ["cases", "activities"] as const;

export const EmployeeSearch = ["employee"] as const;

export type EmployeeSearchTypes = (typeof EmployeeSearch)[number];
export type SearchTypes = (typeof ReportSearch)[number] | (typeof CaseSearch)[number];

export type AllSearchTypes = EmployeeSearchTypes | SearchTypes;

export const getInitialPeriods = (): IDate[] => [
    {
        id: "from",
        date: getStartOfDateString("months", getDateForwardOrBack(1, "months", "backwards")),
        referenceKey: "from",
    },
    {
        id: "to",
        date: newDateString(),
        referenceKey: "to",
    },
];
export const getCompanyIdsAsNumberArray = (companies: IDynamicData[]) => getIdFromSelected(companies).map(Number);

export const requestEmployementGroups = (companyIds: number[]) =>
    getServices().clients.absenceAttendance.apiAbsenceGetEmploymentGroupsPost({
        companyIds,
        customerId: null,
    });
/**
 * Request all ogranization values from specific companies,
 *  return `departments` and other related organization values from given company id
 */
export const requestDepartments = (companyId: number) =>
    getServices()
        .clients.organization.apiOrganizationGetOrganizationTreeViewGet(companyId, "searchabsence")
        .then((value) => ({ key: "organizationTree", ...value }));
/**
 * Request all ogranization values from specific companies,
 *  return `departments` and other related organization values from given company id
 */
export const requestCompanyOrganization = (companyId: number) =>
    getServices()
        .clients.organization.apiOrganizationGetCompanyGroupWithRegionForCompanyIdGet(companyId)
        .then((value) => ({ key: "companiesWithRegion", ...value }));

export const activeTags = (tag: IFilter) => (isTag(tag) ? tag?.checked : true);

export const activeSearchNumber = (minMax: IFilter) => (isSearchNumber(minMax) ? !isNull(minMax.value) : true);
export const activeDate = (date: IFilter) => (isDate(date) ? !isNull(date.date) : true);
export const activeMonth = (month: IFilter) =>
    isMonth(month) ? !(isNull(month?.year) || isNull(month?.displayMonth)) : true;

export const findTagByReferenceKey = (value: string, tags: ITags[]) =>
    tags.find(flow(get("referenceKey"), isEqual(value)));

export const getTagView = (tag: ITags) => tag.label;
export const getDateView = (dateItem: IDate) => dateItem.date || "";
export const getFilterNumberView = (number: INumber) => number.label || "";

export const getSearchNumberView = (t: TFunction) => (number: INumber) => {
    if (number.id === "occurrences")
        return ` ${t(`search.searchQuery.filterName.numberOfAbsenceOccasions`)}: min ${number?.value}`;

    return `${t(`search.searchQuery.filterName.numberOfAbsenceDays`)}: ${
        number?.id === "maxDays" ? "max" : "min"
    } ${number?.value}`;
};

const getPeriodString = (t: TFunction, x: IDate[]) =>
    sortPeriod(x).reduce((prev: string, x: IDate) => {
        const text = t(`search.searchQuery.filterName.${x.id}`);
        if (!prev && x.referenceKey) {
            return `${text}: ${getDateDisplayValue(x.referenceKey, "d MMM yyyy")}`;
        }
        if (prev && !x.referenceKey) {
            return prev;
        }
        if (x.date) return `${prev} - ${getDateDisplayValue(x.referenceKey, "d MMM yyyy")}`;
        return prev;
    }, "");

const decorateCodeLongtermTagsLabel = (t: TFunction, tag: ITags) =>
    `${t(`search.searchQuery.filterName.${tag.id}`)}: ${t("longtermRules", {
        days: parseInt(tag.label),
    })} ${lowerFirst(t(getExtraRule(tag) || ""))}`;

const decorateLabel = (t: TFunction, tag: ITags) => `${t(`search.searchQuery.filterName.${tag.id}`)}: ${t(tag.label)}`;

// Add more decorates here for ITag
const decorateTagFilter = (t: TFunction, searchType: SearchTypes, tag: ITags) => {
    const correctTag = getPeriodsWithCorrectLabel(searchType)(tag);
    if (correctTag.id === "codes") {
        return {
            ...correctTag,
            label: decorateCodeLongtermTagsLabel(t, correctTag),
        };
    }
    return {
        ...correctTag,
        label: decorateLabel(t, correctTag),
    };
};

const decorateOccurrencesFilterLabel = (t: TFunction, number: INumber) =>
    `${t(`search.searchQuery.filterName.numberOfAbsenceOccasions`)}: min ${number?.value}`;

const decorateMinMaxDaysFilterLabel = (t: TFunction, number: INumber) =>
    `${t(`search.searchQuery.filterName.numberOfAbsenceDays`)}: ${
        number?.id === "maxDays" ? "max" : "min"
    } ${number?.value}`;

const decorateNumberfilter = (t: TFunction, number: INumber): INumber => {
    if (number.id === "occurrences") {
        return { ...number, label: decorateOccurrencesFilterLabel(t, number) };
    }
    if (number.id === "maxDays" || number.id === "minDays") {
        return { ...number, label: decorateMinMaxDaysFilterLabel(t, number) };
    }
    return { ...number, label: "" };
};

const removeDuplicateDateFilters = (prev: ISearchFilterTags) => {
    const previousDate = prev.filter(isDate);
    if (previousDate.length > 0) {
        return prev.filter((x) => x.id !== previousDate[0].id);
    }
    return prev;
};

const decorateDateFilter = (t: TFunction, date: IDate, filterDate: IDate[]) => ({
    id: filterDate.length > 0 ? "from" : date.id,
    referenceKey: filterDate.length > 0 ? "from" : date.date || "",
    date: getPeriodString(t, [...filterDate, { ...date, referenceKey: date.date || "" }]),
});
export const searchFilterDecorators =
    (searchType: SearchTypes, t: TFunction) => (prev: ISearchFilterTags, current: ITags | IMonth | IDate | INumber) => {
        if (isDate(current)) {
            let newPrev = removeDuplicateDateFilters(prev);
            return [...newPrev, decorateDateFilter(t, current, prev.filter(isDate))];
        }
        if (isTag(current)) {
            return [...prev, decorateTagFilter(t, searchType, current)];
        }
        if (isSearchNumber(current)) {
            return [...prev, decorateNumberfilter(t, current)];
        }
        return [...prev, current];
    };

/**
 * Return a translated string from a given tag based on label value,
 * `searchFilterDecorators` consist of a function that can add custom decorators
 * for all the filters and use the translation function to get all the translations
 * @param searchFilters
 * @param t
 * @returns string for the `ui`
 */
export const searchFilterStrings = (searchFilters: ISearchFilterTags, searchType: SearchTypes, t: TFunction) =>
    searchFilters
        .filter(activeTags)
        .filter(activeSearchNumber)
        .filter(activeDate)
        .reduce(searchFilterDecorators(searchType, t), [])
        .map<string>(
            flow(
                checkIfTagsAndRunCallback<string>(getTagView),
                checkIfNumberAndRunCallback<string>(getFilterNumberView),
                checkIfDateAndRunCallback<string>(getDateView),
            ),
        )
        .filter(isString);

// @ts-expect-error
export const requestFollowUpData: (
    companies: IDynamicData[],
    departments: IDynamicData[],
    topDepartment: TreeViewNode | undefined,
    tags: ISearchFilterTags,
    sorting: ISortingFilter,
    pageNumber: number,
    type: SearchTypes,
    requestType: "excel" | "search",
    workbookTitle: string,
    templates: MedHelpHCMDomainTemplate[],
    country?: string,
    includeArchived?: boolean,
) => MedHelpAbsenceFollowUpWebApiRequestSearchPeriods = (
    companies,
    departments,
    topDepartment,
    tags,
    sorting,
    pageNumber,
    type,
    requestType,
    workbookTitle,
    templates,
    country,
    includeArchived,
) => {
    const setFilters = createSearchRequestSchema(
        type,
        tags,
        sorting,
        requestType,
        workbookTitle,
        country,
        includeArchived,
    );

    //All departments are selected sends in empty array
    const allSelectedDepartments = isAllSelected(departments) ? [] : getReferenceKeyFromSelected(departments);

    let mockDepartments = undefined;
    //Don't send departments when running jest
    if (checkIfJestIsRunning() === true) {
        mockDepartments = topDepartment
            ? [...getReferenceKeyFromSelected(departments), topDepartment?.referenceKey]
            : getReferenceKeyFromSelected(departments);
    }

    const allStuff = {
        companyIds: getIdFromSelected(companies).map(Number),
        departments: allSelectedDepartments,
        page: pageNumber,
    };

    if (type === "monthlyReport") {
        return {
            ...setFilters,
            companyIds: getIdFromSelected(companies).map(Number),
            mockDepartments,
        };
    }
    if (type === "cases" || type === "activities") {
        return {
            ...setFilters,
            ...{
                departmentIds: allSelectedDepartments,
                page: pageNumber,
                templateIds: getTemplateIdsFilteredByCaseType(companies, templates, tags),
                language: country,
            },
            mockDepartments,
        };
    }
    return {
        ...setFilters,
        ...allStuff,
        mockDepartments,
    };
};

export const resetSpecificSearchFilter = (searchFilter: ISearchFilter): ISearchFilter => {
    const filters = searchFilter.filters.map((v) => {
        if (v.id === "codes" || v.id === "employmentGroups") {
            return {
                ...v,
                checked: false,
            };
        }
        if (isDate(v)) {
            const intialValue = getInitialPeriods().find((x) => x.referenceKey === v.referenceKey);
            return {
                ...v,
                ...intialValue,
            };
        }
        if (isMonth(v)) {
            return {
                ...v,
                year: null,
                month: null,
            };
        }
        if (isSearchNumber(v)) {
            return {
                ...v,
                value: null,
            };
        }
        return v;
    });
    return {
        filters,
        showBasedOn: "numberOfAbsenceOccasions",
        showDepartments: "onlyLatestDepartment",
        sorting: "startDate",
    };
};

export const addToSearchFilters = (value: ISeachActionPayloadContent, array: (ITags | IMonth | IDate | INumber)[]) =>
    array.filter((x) => x.referenceKey !== value.referenceKey).concat(value);

export const updateRadioFilters = (value: ISeachActionPayloadContent, array: (ITags | IMonth | IDate | INumber)[]) =>
    array.map((x) =>
        x.id === value.id
            ? {
                  ...x,
                  checked: x.referenceKey === value.referenceKey,
              }
            : x,
    );

export const createFilterOptionsFromSearch = (data: MedHelpAbsenceFollowUpWebApiServicesFilterResponse[]): ITags[] =>
    data.reduce((prev: ITags[], current: MedHelpAbsenceFollowUpWebApiServicesFilterResponse) => {
        const id = lowerCase(current.name || "");
        // adding more can create a white liste array with different values,
        if (id === "codes") {
            const tags = current.values?.map<ITags>((x) => ({
                id,
                label: x,
                referenceKey: x,
                checked: false,
            }));
            if (tags && prev) {
                return [...prev, ...tags];
            }
            return prev;
        }
        return prev;
    }, []);

/**
 * Convert and remove old `employementGroupsTag`, when new companie is seleceted
 * @returns  SearchFilterState
 */
export const addEmployementGroupsFilter = (employmentGroups: EmployementGroup[], filters: SearchFilterState) => {
    const employmentGroupsAsTag = uniqBy(
        (x) => x.label,
        employmentGroups.map((x) => {
            const value: ITags = {
                label: x.code || "none",
                referenceKey: x.codeId.toString(),
                checked: false,
                id: "employmentGroups",
            };
            return value;
        }),
    );
    return ReportSearch.reduce(
        (prev, key) => ({
            ...prev,
            [key]: filters[key].filters.filter((x) => x.id !== "employmentGroups").concat(employmentGroupsAsTag),
        }),
        filters,
    );
};
