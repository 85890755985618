import { IdNameMap } from "store/rtk-apis/customerCompany/customerCompanyApi";
import { TreeViewNode } from "swagger/customercompany";
import { AccessLevel, CompanyWithDepartments } from "./types";

export const findDepartmentChain = (organization: TreeViewNode[], id: number, recursive: boolean) => {
    // Helper function to perform recursive search
    const search = (node: TreeViewNode, id: number, recursive: boolean): TreeViewNode | null => {
        // Base case: if the current node matches the id
        if (node.id === id) {
            // Return the node according to the recursive flag
            if (recursive) {
                return { ...node };
            } else {
                return { ...node, children: undefined };
            }
        }

        // If the node has children, search recursively
        if (node.children) {
            for (const child of node.children) {
                const foundChild = search(child, id, recursive);
                if (foundChild) {
                    // Build the chain from the current node to the found child
                    const nodeCopy = { ...node };
                    nodeCopy.children = [foundChild];
                    return nodeCopy;
                }
            }
        }

        // Return null if the node is not found in this branch
        return null;
    };

    // Initialize the result array
    const result: TreeViewNode[] = [];

    // Loop over top-level nodes in the organization
    for (const node of organization) {
        const foundNode = search(node, id, recursive);
        if (foundNode) {
            result.push(foundNode);
            break; // Stop searching after finding the node
        }
    }

    return result;
};

export const getAccessLevel = (
    topDepartment: IdNameMap | null | undefined,
    accessRight: IdNameMap[] | boolean | null | undefined,
): AccessLevel | undefined => {
    if (accessRight === true) return "global";
    if (!Array.isArray(accessRight) || accessRight.length === 0) return undefined;
    if (accessRight.find((department) => department.id === topDepartment?.id)) return "company";
    if (accessRight.length > 0) return "department";
    return undefined;
};

export const getOtherCompanyDepartments = (otherCompanyAccessRights: any[] | undefined) => {
    const result: Record<string, CompanyWithDepartments[]> = {
        absenceAdministration: [],
        absenceBoard: [],
        absenceView: [],
        absenceFollowup: [],
        rehabProcessManagement: [],
        absenceStatistics: [],
        departmentAdministration: [],
    };

    otherCompanyAccessRights?.forEach((company) => {
        Object.entries(result).forEach(([key, value]) => {
            if (Array.isArray(company[key]) && company[key].length > 0) {
                value.push({
                    companyName: company.companyName || "",
                    departments: company[key] || [],
                });
            }
        });
    });

    return result;
};

export const departmentCount = (accessRights: IdNameMap[], orgTree: TreeViewNode[]): number => {
    const uniqueDeptIds = new Set<number>();

    // Map to store id to node mapping
    const idToNodeMap = new Map<number, TreeViewNode>();

    // Function to build idToNodeMap
    const buildIdToNodeMap = (nodes: TreeViewNode[]) => {
        for (const node of nodes) {
            if (node.id !== undefined) {
                idToNodeMap.set(node.id, node);
            }
            if (node.children) {
                buildIdToNodeMap(node.children);
            }
        }
    };

    // Build the map
    buildIdToNodeMap(orgTree);

    // Function to collect all ids from a node and its descendants
    const collectAllIds = (node: TreeViewNode, idSet: Set<number>) => {
        if (node.id !== undefined) {
            idSet.add(node.id);
        }
        if (node.children) {
            for (const child of node.children) {
                collectAllIds(child, idSet);
            }
        }
    };

    for (const accessRight of accessRights) {
        const id = accessRight.id;
        if (id === undefined) {
            continue;
        }

        if (accessRight.recursive) {
            const node = idToNodeMap.get(id);
            if (node) {
                collectAllIds(node, uniqueDeptIds);
            } else {
                // If node is not found, add the id itself
                uniqueDeptIds.add(id);
            }
        } else {
            uniqueDeptIds.add(id);
        }
    }

    return uniqueDeptIds.size;
};
