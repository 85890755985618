import { useCallback, useEffect, useState } from "react";

import { TableRow } from "@medhelp/ui";
import { useTranslation } from "react-i18next";
import { Icons } from "components/Icon";
import DepartmentListDesktopView from "./DepartmentListDesktopView";
import DepartmentListMobileView from "./DepartmentListMobileView";
import { twMerge } from "tailwind-merge";
import PopUpModal from "@medhelp/ui/Modals/PopUpModal/PopUpModal";
import Toast from "components/Toast/Toast";
import { MedHelpHCMDomainTemplateReason } from "swagger/healthCaseManagement/api";
export interface DepartmentListProps {
    id: string;
    reason: MedHelpHCMDomainTemplateReason[] | string | string[];
    createdBy: string;
    day: string | number;
    title?: string;
    isActive: boolean;
    description?: string;
    name?: string;
    containsChain?: boolean;
    healthCaseType?: string;
    type: string;
    companyName: string;
    partner: string;
}

export interface IActivityButtonValues {
    text: string;
    onClick: () => void;
    icon: Icons;
}

function DepartmentList({
    id,
    name,
    reason,
    createdBy,
    day,
    title,
    isActive,
    description,
    containsChain,
    healthCaseType,
    type,
    partner,
    companyName,
}: DepartmentListProps) {
    const [showDescription, setShowDescription] = useState(false);
    const [switchOnOff, setSwitchOnOff] = useState(isActive);
    const [showPopup, setShowPopup] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const { t } = useTranslation("administration");

    //toggle the on/off -switch
    useEffect(() => {
        setSwitchOnOff(isActive);
    }, [isActive]);

    // Toggle the on/off switch
    const handleToggleSwitch = () => setSwitchOnOff((prevSwitchOnOff) => !prevSwitchOnOff);

    // Shows description in the table
    const toggleShow = () => setShowDescription((prevShowDescription) => !prevShowDescription);

    // Shows pop-up of confirmation when switch is toggled
    const handlePopUp = () => setShowPopup(true); // Show confirmation pop-up

    // Cancel the toggle action
    const handleCancelButton = () => setShowPopup(false); // Just close the pop-up without changing the actual switch state

    // Show toast
    const handleShowToast = () => setShowToast(true);

    // handle delete
    const handleDelete = () => setIsDelete(true);

    // Confirm the toggle action
    const handleConfirmButton = () => {
        if (isDelete) {
            console.log("Confirmed delete");
            handleDelete();
            // Add delete action logic here
        } else {
            handleToggleSwitch();
        }
        setIsConfirmed(true);
        setShowPopup(false);
    };

    useEffect(() => {
        if (isConfirmed) {
            handleShowToast();
        }
        const timer = setTimeout(() => {
            setIsConfirmed(false);
            setShowToast(false);
            setIsDelete(false); // TODO: Delete this state once deleted action is implemented
        }, 3000);
        return () => clearTimeout(timer);
    }, [isConfirmed]);

    const getMiniMenuItems = useCallback(() => {
        const actionValues: IActivityButtonValues[] = [
            {
                text: t("Edit"),
                icon: "pencil",
                onClick: () => {
                    console.log("edit");
                },
            },
            {
                text: t("Delete"),
                icon: "trashCan",
                onClick: () => {
                    handleDelete();
                    handlePopUp();

                    console.log("delete");
                },
            },
        ];
        return actionValues;
    }, [t]);

    return (
        <>
            {showPopup && (
                <PopUpModal
                    open={showPopup}
                    setOpen={setShowPopup}
                    title={
                        isDelete
                            ? t("activeDeletePopupTitle")
                            : switchOnOff
                              ? t("activityOffPopUpTitle")
                              : t("activityOnPopUpTitle")
                    }
                    content={
                        <p className="text-sm text-lime-950">
                            {isDelete
                                ? t("activeDeletePopupText", { caseType: healthCaseType })
                                : switchOnOff
                                  ? t("activityOffPopUpText", { caseType: healthCaseType })
                                  : t("activityOnPopUpText", { caseType: healthCaseType })}
                        </p>
                    }
                    firstButtonText={t("cancel")}
                    secondButtonText={t("continue")}
                    handleFirstButtonTrigger={handleCancelButton}
                    handleSecondButtonTrigger={handleConfirmButton}
                />
            )}
            {showToast && (
                <Toast
                    message={
                        isDelete
                            ? t("activityDeleteToastMessage")
                            : switchOnOff
                              ? t("activityOnToastMessage")
                              : t("activityOffToastMessage")
                    }
                    className="!bg-mhblue !text-black md:m-auto"
                />
            )}
            <div className="bg-white">
                <TableRow className={twMerge("hidden flex-col py-3 lg:flex", !isActive && "text-gray-500")}>
                    <DepartmentListDesktopView
                        id={id}
                        key={id}
                        name={name}
                        reason={reason}
                        createdBy={createdBy}
                        day={day}
                        title={title}
                        isActive={isActive}
                        showDescription={showDescription}
                        toggleShow={toggleShow}
                        handleOnOff={handlePopUp}
                        miniMenuItems={getMiniMenuItems}
                        description={description}
                        containsChain={containsChain}
                        switchOnOff={switchOnOff}
                        type={type}
                        companyName={companyName}
                        partner={partner}
                    />
                </TableRow>
            </div>
            {/* MOBILEVIEW */}

            <div
                className={twMerge(
                    "flex flex-col border-y border-grey-200 bg-white py-6 pl-6 pr-3 lg:hidden",
                    !isActive && "text-gray-500",
                )}
            >
                <DepartmentListMobileView
                    id={id}
                    key={id}
                    title={title}
                    reason={reason}
                    createdBy={createdBy}
                    day={day}
                    isActive={isActive}
                    showDescription={showDescription}
                    description={description}
                    toggleShow={toggleShow}
                    handleOnOff={handlePopUp}
                    miniMenuItems={getMiniMenuItems}
                    containsChain={containsChain}
                    switchOnOff={switchOnOff}
                    type={type}
                    companyName={companyName}
                    partner={partner}
                />
            </div>
        </>
    );
}

export default DepartmentList;
