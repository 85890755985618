import TabMenu from "components/TabMenu";
import { Config } from "config";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

interface IErasureProps {
    companyId: number;
    hasConfig: boolean;
}

export const ErasureActive = ({ companyId, hasConfig }: IErasureProps) => {
    const { t } = useTranslation("administration");

    const Routes = useMemo(
        () => [
            {
                route: "administration/users/erasureActive",
                name: "",
                indexRoute: "settings",
                children: [
                    {
                        route: "settings",
                        name: t("settings"),
                        children: [],
                    },
                    {
                        route: `${Config.web_customerSupport_url}removeddataonemployments/${companyId}/1`,
                        name: t("showWithAbsenceHistory"),
                        children: [],
                        disabled: !hasConfig,
                    },
                    {
                        route: `${Config.web_customerSupport_url}removeddataonemployments/${companyId}/2`,
                        name: `${t("showWithCaseDocumentation")} `,
                        children: [],
                        disabled: !hasConfig,
                    },
                    {
                        route: `${Config.web_customerSupport_url}removeddataonemployments/${companyId}/3`,
                        name: t("showWithMedicalCertificate"),
                        children: [],
                        disabled: !hasConfig,
                    },
                    {
                        route: `${Config.web_customerSupport_url}removeddataonemployments/${companyId}/4`,
                        name: `${t("eraseEvents")} `,
                        children: [],
                        disabled: !hasConfig,
                    },
                ],
            },
        ],

        [companyId, hasConfig, t],
    );
    return (
        <div>
            <h2>{t("eraseInfoAcitveEmp")}</h2>
            <p>{t("erasureActiveEmpInfo")}</p>
            <p className="mt-4 font-bold">{t("conditions")}</p>
            <ul className="mb-6 list-disc pl-10">
                <li>{t("employeesWithCompletedPeriods")}</li>
                <li>{t("employeesWithCompletedCases")}</li>
                <li>{t("employeesWithCompletedMedicalCerts")}</li>
                <li>{t("caseRules")}</li>
                <li>{t("absenceStatisticsAggregated")}</li>
            </ul>
            <TabMenu routes={Routes} subMenuTo="administration/users/erasureActive">
                <div className="mt-10 p-4 shadow-lg">
                    <Outlet />
                </div>
            </TabMenu>
        </div>
    );
};

export default ErasureActive;
