import { useTranslation } from "react-i18next";
import Email from "./Email";
import InfoBox from "components/InfoBox";

const MessageTemplates = () => {
    const { t } = useTranslation();
    const infoText =
        "On this page you will get an overview of all messages that are sent from the platform to you company’s users. For email messages you can edit a company specific info text. For example encourage managers to report time in your company pay roll system. ";
    return (
        <div>
            <h1>{t("messageTemplates")}</h1>
            <InfoBox translatedText={infoText} />
            <div className="flex">
                <div className="flex flex-col gap-2 pr-4">
                    <label>Select Company</label>
                    <select className="h-12 w-[300px]">
                        <option>Medhelp AB</option>
                        <option>Avdelning E</option>
                        <option>Avdelning F</option>
                    </select>
                </div>
                <div className="flex flex-col gap-2">
                    <label>Chanel</label>
                    <select className="h-12 w-[300px]">
                        <option>Email</option>
                        <option>SMS</option>
                        <option>Notification</option>
                    </select>
                </div>
            </div>
            <Email />
        </div>
    );
};

export default MessageTemplates;
