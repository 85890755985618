import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import FollowUpReportsList from "./SearchTypesList";
import { SearchTypes } from "../../utils";
import FollowUpReportsHeader from "./SearchTypesHeader";
import { Dropdown } from "@medhelp/ui";
import { useAppSelector } from "store/hooks";
import { getHasHealthCaseManagement } from "../../redux/followUpSelectors";

interface IProps {
    data: string | null;
    setData: Dispatch<SetStateAction<SearchTypes | null>> | ((value: SearchTypes | null) => void);

    searchTypes: SearchTypes[];
    searchCaseTypes: SearchTypes[];
}
const SearchTypesDropdown = ({ data, setData, searchTypes, searchCaseTypes }: IProps) => {
    const { t } = useTranslation("followup");
    const hasHealthCaseManagement = useAppSelector(getHasHealthCaseManagement);
    const [followUpReportOpen, setFollowUpReportOpen] = useState(false);
    const onClickOutside = useCallback(
        (value: boolean) => {
            if (!value && followUpReportOpen) {
                setFollowUpReportOpen(false);
            }
        },
        [followUpReportOpen],
    );
    const setDataAndOpen = useCallback(
        (value?: SearchTypes) => {
            setData(value || null);
            setFollowUpReportOpen(false);
        },
        [setData],
    );
    return (
        <div className="w-full max-w-[335px] lg:w-[260px]" data-testid="search-types-dropdown-reports">
            <p className="font-bold">{t("search.selectFollowupReport")}</p>
            <Dropdown
                onClickOutside={onClickOutside}
                preset="secondary"
                setOpen={() => setFollowUpReportOpen(!followUpReportOpen)}
                open={followUpReportOpen}
                items={
                    <FollowUpReportsList
                        onClick={setDataAndOpen}
                        searchTypes={searchTypes}
                        searchCaseTypes={searchCaseTypes}
                        healthCaseManagement={hasHealthCaseManagement}
                    />
                }
                header={
                    <FollowUpReportsHeader name={data ? t(`search.searchOptions.${data}`) : t("selectReportType")} />
                }
            />
        </div>
    );
};

export default SearchTypesDropdown;
