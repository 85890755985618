import { twMerge } from "tailwind-merge";

interface ITableCell {
    className?: string;
    showOnMobile?: boolean;
    children?: React.ReactNode;
}

const TableCell = ({ className, showOnMobile, children }: ITableCell) => (
    <td className={twMerge("hidden p-6 lg:table-cell", showOnMobile && "table-cell", className)}>{children}</td>
);

export default TableCell;
