import { createSelector } from "@reduxjs/toolkit";
import {
    find,
    flow,
    get,
    isEqual,
    isNumber,
    reduce,
    sortBy,
    reverse,
    map,
    take,
    orderBy,
    isNull,
    set,
    includes,
    isEmpty,
    uniqBy,
    some,
    compact,
} from "lodash/fp";
import { MedHelpAbsenceFollowUpWebApiResponseAbsenceOverviewType } from "swagger/absencefollowup";
import { RootState } from "store";
import { selectors as MedicalCertificate } from "@medhelp/medical-certificate/redux";
import { selectors as AbsenceSelectors } from "pages/Absence/pages/absencereporting/redux";
import { DateIds, HealthCaseType, ISearchFieldValues, isMonth, ITable, ITableProps, MonthIds } from "../types";
import {
    getColumns,
    activeDate,
    activeSearchNumber,
    activeMonth,
    activeTags,
    SearchTypesFilter,
    SEARCH_PAGE_SIZE,
    type SearchTypes,
    createSearchSchema,
    selectCell,
    sortingFunctions,
    getByTanslation,
    getSearchTypes,
    CaseSearch,
    CellType,
    getSelectableFlattenedDepartments,
} from "../utils";
import { getDate, getDateForwardOrBack, isAfterDate, isBeforeDate, isBeforeToday } from "utils/date";
import { TFunction } from "i18next";
import { SearchNumberIds, TagIds, isSearchNumber, isTag, isDate } from "../types";
import { Traverser } from "domain/ogranization";
import { getStatus as getStatusDegree, orderDegrees, removeDuplicates } from "utils/degrees";
import { ISearchPayload } from ".";
import {
    getUserAccessRights,
    getUserAccountCompanyId,
    getHasCustomerSupport,
    getIsRehabProcessManagement,
} from "store/userSelectors";
import { getActivePowerbarSearch, getActiveSearchCompanyId } from "store/searchSelectors";
import { filterAccessRightBy, getEnumsFromAccessRightData, hasTopDeparments } from "domain/accessRights";
import { findAllByProperty, numberOfChildrens } from "domain/ogranization/iterateChildren";
import { findTopDepartments } from "domain/ogranization/treeViewNode";
import { selectors as RouteSelectors } from "store/route";
import {
    checkIfOnFollowUpRoute,
    getAccessRightForFollowUp,
    FollowUpReportSearchTypeAccessRights,
    compareSearchTypeByAccessRight,
    getAccessRightBySearchType,
    getAccessRightBasedOnSetRights,
} from "../accessRights";
import { NotificationType } from "swagger/messenger";
import { IHealthCase, Status } from "./followUpStateTypes";
import { getSsnWithDashWithReturnNull } from "utils/numbers";
import { isAfter } from "date-fns";

type AbsenceType = "Sick" | "CareOfChild";

const getNumberOfAbsenceDays = (
    absenceType: AbsenceType,
    absenceTypes?: MedHelpAbsenceFollowUpWebApiResponseAbsenceOverviewType[] | null,
) => {
    const days = absenceTypes?.find(flow(get("type"), isEqual(absenceType)))?.workingDays;
    if (!isNumber(days)) return 0;

    return days;
};

const getLastTwelveMonth = (state: RootState) =>
    find(flow(get("period"), isEqual("LastTwelveMonths")), state.followUp.absenceOverview);

const getAbsencStatisticOfGivenYear = (year: string) => (state: RootState) =>
    find(flow(get("period"), isEqual(year)), state.followUp.absenceOverview);

const getAbsenceOverview = (state: RootState) => state.followUp.absenceOverview;

const getAbsencStatisticOfEveryYear = createSelector(getAbsenceOverview, (absenceOverview) =>
    absenceOverview.filter(({ period }) => period !== "LastTwelveMonths"),
);

const getAbsenceActivityLogs = (state: RootState) => state.followUp.absenceActivityLogs;

const getMedicalCertificateRequired = (state: RootState) =>
    state.followUp.medicalCertificate.firstDayMedicalCertificate;

export const getNoAbsenceData = (state: RootState) => state.followUp.status.noData;

export const getCurrentSearch = (state: RootState) => state.followUp.currentSearch;

export const getRehab = (state: RootState) => state.followUp.rehab;

export const getSearchFilter = (state: RootState) => state.followUp.searchFilter;
export const getSortingFilters = (type: SearchTypes) => (state: RootState) => state.followUp.searchFilter[type].sorting;

export const getSearchPageNumber = (type: SearchTypes) => (state: RootState) =>
    state.followUp.pageNumber[type].currentSearchPageNumber;

export const getUserEmployment = (state: RootState) => state.followUp.userEmployment;

export const getPaginatorPageNumber = (type: SearchTypes) => (state: RootState) =>
    state.followUp.pageNumber[type].paginatorPageNumber;

export const getSearchStatus = (state: RootState) => state.followUp.searchStatus;

export const getHealthCasesStatus = (state: RootState) => state.followUp.status.healthCases;

const getCurrentFollowUp = (state: RootState) => state.followUp;

export const getCompanyIdBasedOnPowerbarSearchOrUser = createSelector(
    getActivePowerbarSearch,
    (activePowerbarSearch) => {
        if (activePowerbarSearch?.companyId) {
            return activePowerbarSearch?.companyId;
        }
        return null;
    },
);

export const getSearchHasAnyData = createSelector(getSearchStatus, (searchStatus) =>
    reduce((prev, current) => (prev ? prev : current === "ok"), false, searchStatus),
);

export const getActiveRehab = createSelector(
    getRehab,
    (rehab) => !isNull(rehab.initiationDate) && isNull(rehab.endDate),
);

export const getActiveRequiredMedicalCertificate = createSelector(
    getMedicalCertificateRequired,
    (medicalCertificateRequired) => {
        return medicalCertificateRequired?.filter(flow(get("endDate"), isAfterDate));
    },
);
export const getStatus = (state: RootState) => state.followUp.status;

export const getCurrentUser = (state: RootState) => state.followUp.currentUser;

export const getSearchHasMore = (type: SearchTypes | null) => (state: RootState) =>
    type ? state.followUp.searchHasMore[type] : null;

export const getUserSubscription = (state: RootState) => state.followUp.subscription.userSubscription;

export const getUserSubscriber = (state: RootState) => state.followUp.subscription.userSubscriber;

export const getMissingSubscriptionAccessRightsForUser = (state: RootState) =>
    state.followUp.subscription.missingSubscriptionAccessRightsForUser;

export const getUserEmploymentEventSubscriptions = createSelector(
    getUserEmployment,
    (userEmployment) => userEmployment?.eventSubscriptions,
);

export const getUserEmploymentId = createSelector(getUserEmployment, (userEmployment) => userEmployment?.employmentId);
export const getUserCompanyId = createSelector(getUserEmployment, (userEmployment) => userEmployment?.companyId);

export const getUserDeleteMessage = createSelector(
    getUserEmployment,
    (userEmployment) => userEmployment?.doNotDeleteMessage,
);
export const getUserMainManager = createSelector(getUserEmployment, (userEmployment) => userEmployment?.mainManager);

export const getDeviant = (state: RootState) => state.followUp.deviant;

export const getAbsenceList = (state: RootState) => state.followUp.absenceList;

export const search = (type: SearchTypes | null, pageNumber: number) => (state: RootState) => {
    return type ? state.followUp.search?.[type][pageNumber] : null;
};

export const sortSearch: (
    type: SearchTypes | null,
    currentSearchPageNumber: number,
) => (state: RootState) => ISearchPayload | null = (type, currentSearchPageNumber) =>
    createSelector(
        search(type, currentSearchPageNumber),
        getSortingFilters(type || "absencePeriod"),
        (search, sorting) => {
            if (!search?.page) return null;
            return set("page", sortingFunctions(type)[sorting](search.page), search);
        },
    );

export const getSortSearchPage = (type: SearchTypes | null, currentSearchPageNumber: number, pageNumber: number) =>
    createSelector(search(type, currentSearchPageNumber), (search) => {
        if (!search?.page) return null;
        let slicePage = [];
        if (pageNumber === 1) {
            slicePage = search.page.slice(0, 10);
        } else {
            const pageNumberWithInternval = ((pageNumber - 1) * 10) % SEARCH_PAGE_SIZE;
            slicePage = search.page.slice(pageNumberWithInternval, pageNumberWithInternval + 10);
        }
        return slicePage;
    });

export const getAbsenceListSortedByDate = (date: "startDate" | "endDate") =>
    createSelector(getAbsenceList, flow(sortBy(get(date)), reverse));

export const getAbsenceItemById = (id?: string) =>
    createSelector(getAbsenceList, (list) => list.find(flow(get("absenceReportId"), isEqual(id))));
export const getSortedDegrees = (id?: string) =>
    createSelector(getAbsenceItemById(id), (selectedReport) =>
        selectedReport?.degrees ? orderDegrees(removeDuplicates(selectedReport.degrees)) : [],
    );

/**
 * Check if degree is going up or down, depending on previous degree
 * @param id
 * @returns
 */
export const getIfDegreeIsIncreasingOrDecreasing: (
    id?: string,
) => (state: RootState) => "None" | "Up" | "Down" | "Empty" = (id) =>
    createSelector(getAbsenceItemById(id), getSortedDegrees(id), (selectedReport, degrees) => {
        return selectedReport ? getStatusDegree(selectedReport, degrees) : "Empty";
    });
export const getAbsenceListSorted = createSelector(getAbsenceList, (absenceList) => {
    const byStartEndDate = orderBy(({ endDate, startDate }) => endDate || startDate, "desc", absenceList);
    const ongoingFirst = sortBy((list) => {
        const checkEndate = list.endDate ? 2 : 1;
        return checkEndate;
    }, byStartEndDate);
    return ongoingFirst;
});

export const getOngoingAbsenceReports = createSelector(getAbsenceList, (absenceList) =>
    absenceList.filter(({ endDate }) => endDate === null || (endDate && isAfter(endDate, new Date()))),
);

export const getFollowUpCards = createSelector(
    AbsenceSelectors.getOngoingReports,
    MedicalCertificate.getCurrentCertificates,
    (onGoingAbsenceReports, currentCertificates) => {
        const test = currentCertificates[0];
        const ongoing = onGoingAbsenceReports[0];
        return [ongoing, test];
    },
);
export const getTwelveMonthAbsencePercent = createSelector(getLastTwelveMonth, (lastTwelveMonth) =>
    Math.floor(lastTwelveMonth?.absencePercent || 0),
);

export const getTwelveMonthDeviants = createSelector(
    getLastTwelveMonth,
    (lastTwelveMonth) => lastTwelveMonth?.totalDeviants,
);

export const getTwelveMonthBradfordFactor = createSelector(
    getLastTwelveMonth,
    (lastTwelveMonth) => lastTwelveMonth?.bradfordFactor,
);

export const getTwelveMonthTotalDays = createSelector(getLastTwelveMonth, (lastTwelveMonth) =>
    reduce((prev, current) => prev + (current?.workingDays || 0), 0, lastTwelveMonth?.absenceTypes),
);

export const getTwelveMonthAbsenceTypeDays = (absenceType: AbsenceType) =>
    createSelector(getLastTwelveMonth, (lastTwelveMonth) =>
        getNumberOfAbsenceDays(absenceType, lastTwelveMonth?.absenceTypes),
    );

export const getTwelveMonthAbsenceCount = createSelector(getLastTwelveMonth, (lastTwelveMonth) =>
    reduce((prev, current) => prev + (current?.absenceCount || 0), 0, lastTwelveMonth?.absenceTypes),
);

export const getSearchHasMoreByPage = (type: SearchTypes | null) =>
    createSelector(
        getSearchHasMore(type),
        (searchHasMore) => (page: number) => (searchHasMore ? searchHasMore[page] : false),
    );
export const getAbsenceOrSickDaysOfAYearPeriod = (absenceType: AbsenceType, year: string) =>
    createSelector(getAbsencStatisticOfGivenYear(year), (absenceStatisticYear) =>
        getNumberOfAbsenceDays(absenceType, absenceStatisticYear?.absenceTypes),
    );
export const getAllSickAbsenceDaysForYearsToChartData = createSelector(
    [getAbsencStatisticOfEveryYear],
    flow(
        sortBy(get("period")),
        reverse,
        take(5), // number of elements to show in the graph
        reverse,
        map((stats) => ({
            x: stats?.period || "",
            sick: getNumberOfAbsenceDays("Sick", stats?.absenceTypes),
            careOfChild: getNumberOfAbsenceDays("CareOfChild", stats?.absenceTypes),
        })),
    ),
);

export const getRepeatingAbsence = createSelector(getDeviant, (deviant) =>
    deviant
        .filter(flow(get("type"), isEqual("RecurringPeriod")))
        .map((x) => {
            const cool = x?.code.split("/");
            if (cool) {
                return {
                    times: cool[0],
                    numberOfMonths: cool[1],
                    ...x,
                };
            }
        })
        .filter(Boolean),
);

export const getLongTimeAbsence = createSelector(getDeviant, (deviant) =>
    deviant.filter(flow(get("type"), isEqual("LongTerm"))).map((longTimeAbsence) => ({
        days: longTimeAbsence.code.replace(/^\D+/g, ""),
        ...longTimeAbsence,
    })),
);

export const getAbsenceActivityLogsWithAbsenceReportId = createSelector(
    getAbsenceActivityLogs,
    (activityLogs) => (absenceReportId: string) =>
        activityLogs[absenceReportId]?.filter(
            (activity) => activity.editValues?.[0].propertyName.toLowerCase() !== "employmentgroup",
        ),
);
export const getAbsenceActivityLogsStatusWithAbsenceReportId = createSelector(
    getStatus,
    (status) => (absenceReportId: string) => status.activityLogs[absenceReportId],
);

export const getCompanies = (state: RootState) => state.followUp.companiesWithRegion;

export const getOrganizationTree = (state: RootState) => state.followUp.organizationTree;

export const getOrganizationTreeRehab = (state: RootState) => state.followUp.organizationTreeRehabRights.data;

export const getSearchCompanies = (state: RootState) => state.followUp.searchCompaniesDepartments.companies;

export const getSearchDepartments = (state: RootState) => state.followUp.searchCompaniesDepartments.departments;

export const getSearchDepartmentsStoredData = (state: RootState) =>
    state.followUp.searchCompaniesDepartments.storedDepartments;

export const getCurrentSearchCompanyId = (state: RootState) =>
    state.followUp.searchCompaniesDepartments.currentCompanySearchId;

export const getAllUniqueCompanyRegions = createSelector(getCompanies, (companies) =>
    uniqBy((x) => x.region, companies).map((x) => x.region),
);

export const getUserGuardId = (state: RootState) => state.followUp.userGuard;

export const searchComapniesFiltered = createSelector(
    getCurrentSearch,
    getSearchCompanies,
    getUserAccessRights,
    getHasCustomerSupport,
    RouteSelectors.getFollowUpCurrentRoute,
    (type, companies, accessRights, getHasCustomerSupport, route) => {
        if (!accessRights || !companies) return [];
        if (!checkIfOnFollowUpRoute(route)) return [];
        if (getHasCustomerSupport) return companies;
        return getAccessRightBySearchType(
            filterAccessRightBy(accessRights),
            "companyId",
            getAccessRightForFollowUp(route, type),
            companies,
        );
    },
);
export const getOnlySelectedCompanies = createSelector(searchComapniesFiltered, (companies) =>
    Traverser.filterChildren((x) => x.checked, companies),
);
export const selectAccessRightForFollowUp = createSelector(
    RouteSelectors.getFollowUpCurrentRoute,
    getCurrentSearch,
    (route, type) => {
        if (!checkIfOnFollowUpRoute(route)) return [];
        return getAccessRightForFollowUp(route, type);
    },
);
export const searchDepartmentsFiltered = createSelector(
    getCurrentSearch,
    getSearchDepartments,
    getOnlySelectedCompanies,
    getUserAccessRights,
    getHasCustomerSupport,
    RouteSelectors.getFollowUpCurrentRoute,
    (type, departments, companies, accessRights, getHasCustomerSupport, route) => {
        if (!accessRights) return [];
        if (!checkIfOnFollowUpRoute(route)) return [];

        if (getHasCustomerSupport) return departments;
        if (companies.length === 1) {
            const accessRightsTypes = getEnumsFromAccessRightData(FollowUpReportSearchTypeAccessRights[type]);
            const companyRights = accessRights.find(
                (x) =>
                    x.companyId === companies[0]?.id &&
                    includes(x.accessRightType, accessRightsTypes) &&
                    hasTopDeparments(x),
            );
            if (companyRights) {
                return departments;
            }
        }
        const data = getAccessRightBySearchType(
            filterAccessRightBy(accessRights),
            "externalId",
            getAccessRightForFollowUp(route, type),
            findAllByProperty("selectable", departments) || [],
        );
        return data;
    },
);

//Used by Organization
export const getTopDepartment = createSelector(
    searchDepartmentsFiltered,
    getOrganizationTree,
    getUserAccessRights,
    RouteSelectors.getFollowUpCurrentRoute,
    getCurrentSearch,
    getHasCustomerSupport,
    (departments, organizationTree, accessRights, route, type, getHasCustomerSupport) => {
        if (!checkIfOnFollowUpRoute(route)) return undefined;
        if (departments.length > 0) {
            const top = findTopDepartments(organizationTree);
            if (getHasCustomerSupport) return isEmpty(top) ? undefined : top;
            const access = getAccessRightBasedOnSetRights(
                filterAccessRightBy(accessRights),
                getAccessRightForFollowUp(route, type),
            );
            if (some((x) => x.externalId === top.id, access)) return top;
        }
        return undefined;
    },
);

export const getAvailableSearchTypes = createSelector(
    getUserAccessRights,
    getHasCustomerSupport,
    getAllUniqueCompanyRegions,
    (accessRights, getHasCustomerSupport, regions) => {
        if (!accessRights) return [];
        const searchTypes = getSearchTypes(regions);
        return getHasCustomerSupport ? searchTypes : compareSearchTypeByAccessRight(accessRights, searchTypes);
    },
);

export const getAvailableCaseSearchTypes = createSelector(
    getUserAccessRights,
    getHasCustomerSupport,
    (accessRights, getHasCustomerSupport) => {
        if (!accessRights) return [];
        const searchTypes = [...CaseSearch];
        return getHasCustomerSupport ? searchTypes : compareSearchTypeByAccessRight(accessRights, searchTypes);
    },
);

export const hasOnlyOneDeparment = createSelector(
    searchDepartmentsFiltered,
    (departments) => numberOfChildrens(departments) === 1,
);

export const hasOnlyOneCompany = createSelector(
    searchComapniesFiltered,
    (companies) => numberOfChildrens(companies) === 1,
);
export const onlyShowDepartments = createSelector(
    searchComapniesFiltered,
    (companies) => (companies?.length || 0) <= 1,
);

export const getSearchFilterFilters = createSelector(
    getCurrentSearch,
    getSearchFilter,
    (type, searchFilter) => searchFilter[type].filters,
);

export const getSearchTagsById = (id: TagIds) =>
    createSelector(getSearchFilterFilters, (searchFilters) =>
        searchFilters.filter(isTag).filter(flow(get("id"), isEqual(id))),
    );
export const getSearchTagByIdSorted = (id: TagIds, order: "asc" | "desc") =>
    createSelector(getSearchTagsById(id), (x) =>
        orderBy(flow(get("label"), getByTanslation("followup", "")), order, x),
    );

export const getSearchDateById = (id: DateIds) =>
    createSelector(getSearchFilterFilters, (searchFilters) =>
        searchFilters.filter(isDate).find(flow(get("id"), isEqual(id))),
    );

export const getSearchMonthById = (id: MonthIds) =>
    createSelector(getSearchFilterFilters, (searchFilters) => {
        const minMax = searchFilters.filter(isMonth);
        return (
            minMax.find(flow(get("id"), isEqual(id))) || {
                year: null,
                displayMonth: null,
                monthIndex: null,
            }
        );
    });

export const getIncludeArchivedEmployees = createSelector(
    getCurrentFollowUp,
    (followUp) => followUp.includeArchivedEmployees,
);

export const getSearchNumberById = (id: SearchNumberIds) =>
    createSelector(getSearchFilterFilters, (searchFilters) =>
        searchFilters.filter(isSearchNumber).find(flow(get("id"), isEqual(id))),
    );

export const getShowBasedOn = (type: SearchTypes) => (state: RootState) =>
    state.followUp.searchFilter[type].showBasedOn ?? "numberOfAbsenceOccasions";

export const getShowDeparments = (type: SearchTypes) => (state: RootState) =>
    state.followUp.searchFilter[type].showDepartments;

export const currentSearchHasAnyData = createSelector(
    getSearchStatus,
    getCurrentSearch,
    (searchStatus, currentSearch) => {
        if (currentSearch) {
            const status = searchStatus[currentSearch];
            return status === "ok" || status === "pending" || status === "empty";
        }
        return false;
    },
);

export const currentSearchHasError = createSelector(
    getSearchStatus,
    getCurrentSearch,
    (searchStatus, currentSearch) => {
        if (currentSearch) {
            const status = searchStatus[currentSearch];
            return status === "error";
        }
        return false;
    },
);
export const currentSearchIsEmpty = createSelector(getSearchStatus, getCurrentSearch, (searchStatus, currentSearch) => {
    if (currentSearch) {
        const status = searchStatus[currentSearch];
        return status === "empty";
    }
    return false;
});
export const hasDataToSearchFor = createSelector(
    searchDepartmentsFiltered,
    searchComapniesFiltered,
    (departments, companies) => {
        const hasSelectedDepartments = findAllByProperty("checked", departments);
        const hasSelectedCompanies = findAllByProperty("checked", companies);
        if (hasSelectedCompanies?.length === 1) {
            return (hasSelectedDepartments?.length ?? 0) > 0 || isEmpty(departments);
        }
        return (hasSelectedCompanies?.length ?? 0) > 0 || (hasSelectedDepartments?.length ?? 0) > 0;
    },
);
export const currentSearchIsLoading = createSelector(
    getSearchStatus,
    getCurrentSearch,
    (searchStatus, currentSearch) => {
        if (currentSearch) {
            const status = searchStatus[currentSearch];
            return status === "pending";
        }
        return false;
    },
);
export const getSearchTypeFilterBySearchType = createSelector(getCurrentSearch, (currentSearch) =>
    currentSearch ? SearchTypesFilter[currentSearch] : [],
);

export const getCurrentSearchFilters = createSelector(
    getSearchFilterFilters,
    getSearchTypeFilterBySearchType,
    (searchFilter, searchTypeFilter) => searchFilter.filter((x) => searchTypeFilter.some((y) => y === x.id)),
);

export const getOnlyActiveSearchFilters = createSelector(getCurrentSearchFilters, (currentSearchFilters) =>
    currentSearchFilters.filter(activeTags).filter(activeMonth).filter(activeDate).filter(activeSearchNumber),
);
export const getSortingOrBasedOn = (field: ISearchFieldValues, type: SearchTypes) =>
    createSelector(
        getShowBasedOn(type),
        getSortingFilters(type),
        getShowDeparments(type),
        (showBasedOn, sorting, showDepartments) => {
            if (field === "sorting") return sorting;
            if (field === "showDepartments") return showDepartments;
            return showBasedOn;
        },
    );

export const getAllSortingFilters = (type: SearchTypes) =>
    createSelector(
        getShowBasedOn(type),
        getSortingFilters(type),
        getShowDeparments(type),
        (showBasedOn, sorting, showDepartments) => ({
            showBasedOn,
            sorting,
            showDepartments,
        }),
    );

export const getSearchCount = (type: SearchTypes | null, currentSearchPageNumber: number) =>
    createSelector(search(type, currentSearchPageNumber), (search) => search?.totalCount);

const TableDataTypesMobile: CellType[] = [
    "type",
    "reimbursableType",
    "degrees",
    "activity",
    "caseType",
    "start",
    "caseStatus",
];

const TableDataTypesDesktop: CellType[] = [
    "type",
    "reimbursableType",
    "endStartDate",
    "deviantFrom",
    "deviantTo",
    "degrees",
    "numberOfDays",
    "caseType",
    "activity",
    "start",
    "caseManager",
    "affectedDepartments",
    "caseStatus",
];

export const getTableSearchProperties: (
    type: SearchTypes | null,
    currentSearchPageNumber: number,
    pageNumber: number,
    t: TFunction,
    mobile?: boolean,
) => (state: RootState) => ITable = (type, currentSearchPageNumber, pageNumber, t, mobile) =>
    createSelector(
        search(type, currentSearchPageNumber),
        getSortSearchPage(type, currentSearchPageNumber, pageNumber),
        (search, page) => {
            if (!page) return { data: [], numberOfPages: 0, totalCount: 0 };
            const tableData: ITableProps[] = page.map((pageData) => {
                const searchResult = createSearchSchema(type, pageData);
                const isCaseReport = type === "cases" || type === "activities";
                const getEmploymentId = () => {
                    if (isCaseReport) {
                        return pageData.id;
                    } else if (pageData.followUpAccess) {
                        return pageData.employmentId;
                    } else {
                        return null;
                    }
                };

                return {
                    cells: [
                        {
                            data: {
                                ...(isCaseReport
                                    ? {
                                          header: pageData.name,
                                          body: [
                                              getSsnWithDashWithReturnNull(pageData.description?.split(" ")[0]) ?? "",
                                          ],
                                          employeeNumber: pageData.description?.split(" ")[2],
                                      }
                                    : {
                                          header: pageData.fullName,
                                          body: compact([
                                              getSsnWithDashWithReturnNull(
                                                  pageData.socialSecurityNo || pageData.socialSecurityNumber,
                                              ),
                                              pageData.employmentNumber,
                                          ]),
                                          employeeNumber: pageData.employmentNumber,
                                      }),
                                text: pageData.archived ? t("followUpArchived") : undefined,
                                type: mobile ? "ColumnText" : "ProfileRow",
                            },
                            id: "employee",
                            order: 1,
                        },
                        ...(mobile
                            ? TableDataTypesMobile.map((cellType, index) => ({
                                  data: selectCell(t, cellType, searchResult),
                                  id: cellType,
                                  order: index + 2,
                              }))
                            : TableDataTypesDesktop.map((cellType, index) => ({
                                  data: selectCell(t, cellType, searchResult),
                                  id: cellType,
                                  order: index + 2,
                              }))),
                        {
                            data: {
                                id: pageData.employmentId,
                                type: pageData.followUpAccess || isCaseReport ? "Button" : "Empty",
                            },
                            id: "employmentId",
                            order: (mobile ? TableDataTypesMobile.length : TableDataTypesDesktop.length) + 2,
                        },
                    ],
                    theme: !(pageData.archived ?? false) ? "default" : "archive",
                    id: getEmploymentId(),
                };
            });
            const filterTableData = tableData.map((value) => ({
                ...value,
                cells: value.cells.filter((v) => includes(v.id, getColumns(type)) || v.id === "employmentId"),
            }));
            return {
                data: filterTableData,
                numberOfPages: SEARCH_PAGE_SIZE,
                totalCount: search?.totalCount || 0,
            };
        },
    );

export const getFollowUpState = (state: RootState) => state.followUp;

export const getHealthCases = (state: RootState) => state.followUp.healthCases;

export const getCaseTemplates = (state: RootState) => state.followUp.caseTemplateList.data;

export const getTemplateByRule = () => {};

export const getHealthCasesLoading = createSelector(getHealthCasesStatus, (healthCasesStatus) => {
    if (healthCasesStatus !== Status.PENDING) {
        return false;
    }
    return true;
});

export const getOngoingCases = createSelector(getHealthCases, (healthCases) => {
    return healthCases.filter(
        (healthCase) =>
            healthCase.status === "ongoing" || healthCase.status === "preliminary" || healthCase.status === "paused",
    );
});

export const getHasOngoingCaseByType = (type: HealthCaseType) =>
    createSelector(getOngoingCases, (ongoingCases) => {
        return ongoingCases.some((healthCase) => healthCase.type === type);
    });

export const getHistoryHealthCases = createSelector(getHealthCases, (healthCases) =>
    healthCases.filter(
        (healthCase) =>
            healthCase.status === "ended" || healthCase.status === "declined" || healthCase.status === "expired",
    ),
);

export const getSortedHistoryHealthCases = createSelector(getHistoryHealthCases, (historyHealthCases) => {
    return historyHealthCases.sort((a, b) => {
        return new Date(b.created ?? "").getTime() - new Date(a.created ?? "").getTime();
    });
});

export const getPreliminaryCaseExist = createSelector(getHealthCases, (healthCases) =>
    healthCases.some(({ status }) => status === "preliminary"),
);

export const getOngoingCaseOfTypeExist = (type: string) =>
    createSelector(getOngoingCases, (ongoingCases) => ongoingCases.some((healthCase) => healthCase.type === type));

export const getTemplateIdByType = (type: HealthCaseType) =>
    createSelector(getCaseTemplates, (templates) => templates.find((template) => template.healthCaseType === type)?.id);

export const getTemplateById = (id: string) =>
    createSelector(getCaseTemplates, (templates) => templates.find((template) => template.id === id));

export const getTemplateByType = (type: HealthCaseType) =>
    createSelector(getCaseTemplates, (templates) => templates.find((template) => template.healthCaseType === type));

export const getHasManualInitiationByType = (type: HealthCaseType) =>
    createSelector(getTemplateByType(type), (template) =>
        template?.ruleTemplates?.some((rule) => rule.ruleName === "ManualInitiation"),
    );

export const getHasManualInitiation = createSelector(getCaseTemplates, (templates) =>
    templates.some((template) => template.ruleTemplates?.some((rule) => rule.ruleName === "ManualInitiation")),
);

export const getHealthCase = (healthCaseId: string) =>
    createSelector(getHealthCases, (healthCases) => healthCases.find(({ id }) => id === healthCaseId));

export const getCaseRelatedAbsences = (healthCaseId: string) =>
    createSelector(getAbsenceList, getHealthCase(healthCaseId), (absences, selectedCase) => {
        const absenceReferenceKeys = selectedCase?.healthCaseExternalReferences
            ?.filter(({ type }) => type === "absence")
            .map((references) => references.refenceKey);

        const caseRelatedAbsences = absences.filter((absence) =>
            absenceReferenceKeys?.some((key) => key === absence.absenceReportId),
        );
        return caseRelatedAbsences;
    });

export const getCaseActivities = (healthCaseId: string | undefined) =>
    createSelector(getHealthCase(healthCaseId ?? ""), (healthCase) => {
        if (!isEmpty(healthCase?.healthCaseActivities)) {
            return orderBy((x) => getDate(x.date), "asc", healthCase?.healthCaseActivities);
        } else {
            return [];
        }
    });

export const getCaseActivitesCount = (healthCaseId: string | undefined) =>
    createSelector(getCaseActivities(healthCaseId ?? ""), (activities) => {
        return activities.length;
    });

export const getCaseActiveActivitesCount = (healthCaseId: string | undefined) =>
    createSelector(getCaseActivities(healthCaseId ?? ""), (activities) => {
        return activities.filter(({ performed, date }) => performed !== true && isBeforeToday(date)).length;
    });

export const getCaseNotes = (healthCaseId: string | undefined) =>
    createSelector(getHealthCase(healthCaseId ?? ""), (healthCase) => {
        const caseNotes = healthCase?.healthCaseDocuments.filter(({ type }) => type === "note");
        if (!isEmpty(caseNotes)) {
            return orderBy((x) => getDate(x.updated ?? x.created), "asc", caseNotes);
        } else {
            return [];
        }
    });

export const getCaseDocuments = (healthCaseId: string | undefined) =>
    createSelector(getHealthCase(healthCaseId ?? ""), (healthCase) => {
        const caseDocuments = healthCase?.healthCaseDocuments.filter(
            ({ type }) => type === "file" || type === "document",
        );

        if (!isEmpty(caseDocuments)) {
            return orderBy((x) => getDate(x.updated ?? x.created), "asc", caseDocuments);
        } else {
            return [];
        }
    });

export const getUserEmploymentFirstName = createSelector(
    getUserEmployment,
    (userEmployment) => userEmployment?.name?.split(" ")[0],
);

export const getCaseNotifications = (healthCaseId: string) =>
    createSelector(
        getHealthCases,
        (healthCases) => healthCases.find(({ id }) => id === healthCaseId)?.healthCaseNotifications,
    );

export interface ICaseEvent {
    id: string | undefined;
    type: string | null | undefined;
    operation: string | NotificationType | null | undefined;
    createdByUserName: number | string | null | undefined;
    description: string | null | undefined;
    timeStamp: string | null | undefined;
    reportedVia: string | null | undefined;
    message: string | null | undefined;
    receivers: string[] | null | undefined;
}

export const getAllCaseEvents = (healthCase: IHealthCase | undefined) =>
    createSelector(getCaseNotifications(healthCase?.id ?? ""), (notifications) => {
        const healthCaseEvents =
            healthCase?.healthCaseEvents?.map((event) => {
                const mappedEvents: ICaseEvent = {
                    id: event.id,
                    type: event.type,
                    operation: event.operation,
                    createdByUserName: event.createdByUserName,
                    description: event.description,
                    timeStamp: event.timeStamp,
                    reportedVia: event.reportedVia,
                    message: "",
                    receivers: [],
                };
                return mappedEvents;
            }) ?? [];
        const messageEvents =
            notifications?.map((event) => {
                const mappedEvents: ICaseEvent = {
                    id: event.notificationId,
                    type: "notification",
                    operation: event.notificationType,
                    createdByUserName: "",
                    description: "",
                    timeStamp: event.createdDate,
                    reportedVia: "",
                    message: event.content,
                    receivers: event.receivers,
                };
                return mappedEvents;
            }) ?? [];

        const allEvents = messageEvents?.concat(healthCaseEvents);
        return orderBy((x) => getDate(x.timeStamp), "desc", allEvents);
    });

export const getCaseResponsibleList = createSelector(
    getFollowUpState,
    (followUpState) => followUpState.caseResponsibleList,
);

export const getCaseResponsibleListStatus = createSelector(
    getFollowUpState,
    (followUpState) => followUpState.status.caseResponsibleList,
);

export const getTemplateFormDocuments = (caseType: string | null | undefined) =>
    createSelector(getCaseTemplates, (templates) => {
        if (caseType) {
            return templates.find((templates) => templates.healthCaseType === caseType)?.templateFormDocuments ?? [];
        } else {
            const allTemplates = templates.map((templates) => templates.templateFormDocuments).flat() ?? [];
            const uniqueTemplates = uniqBy("id", allTemplates);
            return uniqueTemplates;
        }
    });

export const caseIsExpanded = (healthCaseId: string) =>
    createSelector(getHealthCase(healthCaseId), (healthCase) => healthCase?.expanded ?? false);

export const getHasRehabServiceContract = createSelector(
    getFollowUpState,
    (followUpState) => followUpState.hasRehabServiceContract.data,
);

export const getRehabServiceContractStatus = (state: RootState) => state.followUp.hasRehabServiceContract.status;

export const getCaseTemplatesStatus = (state: RootState) => state.followUp.caseTemplateList.status;

export const getUserEmploymentStatus = (state: RootState) => state.followUp.status.userEmployment;

export const getHasHealthCaseManagement = createSelector(
    getHasRehabServiceContract,
    getCaseTemplates,
    (hasRehabServiceContract, caseTemplates) => {
        return hasRehabServiceContract && !isEmpty(caseTemplates);
    },
);

export const getTemplateReasonsSelectValues = (type: HealthCaseType) =>
    createSelector(getCaseTemplates, (templates) => {
        const reasons = templates.find((template) => template.healthCaseType === type)?.templateReasons;
        const reasonsSelectValues =
            reasons?.map((reason) => {
                return {
                    value: reason.name ?? "",
                    description: reason.title ?? "",
                };
            }) ?? [];
        return reasonsSelectValues;
    });

export const getRehabRoutineDocuments = (state: RootState) => state.followUp.rehabRoutineDocuments;

export const getHasAccessToEmployeeRehab = createSelector(
    getOrganizationTreeRehab,
    getHasCustomerSupport,
    getIsRehabProcessManagement,
    getUserEmployment,
    getUserAccessRights,
    (organizationTreeRehab, getHasCustomerSupport, isRehabProcessManagement, selectedEmployee, accessRights) => {
        if (!isRehabProcessManagement) {
            return false;
        }
        if (getHasCustomerSupport) {
            return true;
        }
        const hasRehabTopAccess = accessRights?.some(
            (accessRight) =>
                accessRight.name?.includes("**TopDepartment**") &&
                accessRight.accessRightType === 11 &&
                accessRight.companyId === selectedEmployee?.companyId,
        );
        const hasArchiveAccess = accessRights?.some(
            (accessRight) => accessRight.accessRightType === 8 && accessRight.companyId === selectedEmployee?.companyId,
        );
        if (hasRehabTopAccess && hasArchiveAccess) {
            return true;
        }
        if (organizationTreeRehab && selectedEmployee) {
            const flattenedSelectableDepartments = getSelectableFlattenedDepartments(organizationTreeRehab);
            const departmentsWithRehabAccessRight = flattenedSelectableDepartments.map((accessRight) => accessRight.id);
            const selectedEmployeeDepartments = selectedEmployee.departments?.map((department) => department.id);

            return departmentsWithRehabAccessRight.some((department) =>
                selectedEmployeeDepartments?.includes(department ?? 0),
            );
        }
        return false;
    },
);

export const getCostCalculations = (state: RootState) => state.followUp.costCalculation;

export const getCompanyGroup = (state: RootState) => state.followUp.companyGroup;

export const getCostCalculationStatus = (state: RootState) => state.followUp.costCalculation.status;

export const getCompanyId = createSelector(
    getActiveSearchCompanyId,
    getUserAccountCompanyId,
    (companyIdFromPowerBar, getUserAccountCompanyId) => companyIdFromPowerBar ?? getUserAccountCompanyId,
);

export const getIsLoadingRehabAccess = createSelector(
    getRehabServiceContractStatus,
    getUserEmploymentStatus,
    getCaseTemplatesStatus,
    getIsRehabProcessManagement,
    getOrganizationTreeRehab,
    (
        rehabServiceContractStatus,
        userEmploymentStatus,
        caseTemplatesStatus,
        isRehabProcessManagement,
        organizationTreeRehab,
    ) =>
        rehabServiceContractStatus === Status.PENDING ||
        userEmploymentStatus === Status.PENDING ||
        (caseTemplatesStatus === Status.PENDING && isRehabProcessManagement) ||
        (isRehabProcessManagement && isEmpty(organizationTreeRehab)),
);

export const getIsArchived = createSelector(
    getUserEmployment,
    (userEmployment) => (userEmployment?.inActive || userEmployment?.deleted) ?? false,
);

export const getUserEmploymentName = createSelector(getUserEmployment, (userEmployment) => userEmployment?.name ?? "");

export const getStartNewCaseIsDisabled = createSelector(
    getIsArchived,
    getCaseTemplates,
    getOngoingCases,
    (isArchived, templates, ongoingCases) => {
        // If user is archived, the button should be disabled
        if (isArchived) return true;

        // find all templates that have the rule "ManualInitiation"
        const templateWithManualInitiation = templates.filter((template) => {
            return template.ruleTemplates?.some((rule) => rule.ruleName === "ManualInitiation");
        });

        // disable button if every template with manual initiation already has an ongoing case
        const allCaseTypesWithManualInititationHaveOngoingCases = templateWithManualInitiation.every((template) => {
            return ongoingCases.some((caseItem) => caseItem.type === template.healthCaseType);
        });

        return allCaseTypesWithManualInititationHaveOngoingCases;
    },
);

export const getUpdateCaseStatusDisabled = (healthCaseId: string) =>
    createSelector(getHealthCase(healthCaseId), getHealthCases, (selectedCase, healthCases) => {
        const declinedMoreThanThreeMonthsAgo =
            (selectedCase?.status === "declined" ||
                selectedCase?.status === "expired" ||
                selectedCase?.status === "ended") &&
            isBeforeDate(selectedCase.end ?? undefined, getDateForwardOrBack(3, "months", "backwards"));

        const ongoingCaseWithSameTypeExists = healthCases.some(
            (caseItem) =>
                caseItem.id !== selectedCase?.id &&
                caseItem.type === selectedCase?.type &&
                caseItem.status === "ongoing",
        );

        const needToConfirmPreliminaryCase = healthCases.some((caseItem) => caseItem.status === "preliminary");

        return declinedMoreThanThreeMonthsAgo || ongoingCaseWithSameTypeExists || needToConfirmPreliminaryCase;
    });

export const getPartnerContactInformation = createSelector(
    getFollowUpState,
    (followUpState) => followUpState.partnerContactInformation?.contact_Description,
);

export const getPartnerContactInformationStatus = createSelector(
    getFollowUpState,
    (followUpState) => followUpState.status.partnerContactInformation,
);

export const getPartnerContactRequestStatus = createSelector(
    getFollowUpState,
    (followUpState) => followUpState.status.partnerContactRequest,
);

export const getShowRetailerHealthContact = createSelector(getFollowUpState, (followUpState) => {
    const retailerContactField = followUpState.configurationFields.find(
        (field) => field.name?.toLowerCase() === "retailercontactavailable",
    );
    return retailerContactField?.value?.toLowerCase() === "true";
});
