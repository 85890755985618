import { ToolConstructable, ToolSettings } from "@editorjs/editorjs";
import Table from "@editorjs/table";
import NestedList from "@editorjs/nested-list";
import Paragraph from "@editorjs/paragraph";
import Header from "@editorjs/header";
// @ts-ignore
import Marker from "@editorjs/marker";
// @ts-ignore
import BreakLine from "editorjs-break-line";
// @ts-ignore
import Underline from "@editorjs/underline";

export const EDITOR_JS_TOOLS:
    | {
          [toolName: string]: ToolConstructable | ToolSettings;
      }
    | undefined = {
    table: Table,
    marker: Marker as any,
    list: {
        class: NestedList as unknown as ToolConstructable,
        inlineToolbar: true,
        config: {
            defaultStyle: "unordered",
        },
    },
    header: {
        class: Header as unknown as ToolConstructable,
        config: {
            placeholder: "Enter a header",
            levels: [1, 2, 3, 4, 5, 6],
            defaultLevel: 3,
        },
    },
    paragraph: {
        class: Paragraph as unknown as ToolConstructable,
        inlineToolbar: true,
    },
    breakLine: {
        class: BreakLine,
        inlineToolbar: true,
        shortcut: "SHIFT+ENTER",
        config: {
            placeholder: "This is a break line",
        },
    },
    underline: Underline,
};
