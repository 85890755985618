import ArrowIcon from "../ArrowIconComponent/ArrowIconComponent";
import { useState } from "react";

interface CaseTableHeaderProps {
    heading: string;
    children: React.ReactNode;
}

export const CaseTableHeader = ({ heading, children }: CaseTableHeaderProps) => {
    const [show, setShow] = useState(true);
    return (
        <div className="mt-6 w-full rounded-lg pt-6 lg:bg-white lg:px-6">
            <div className="flex items-center pb-4">
                <div className="lg:hidden">
                    <button onClick={() => setShow(!show)} className="pr-3 focus:ring-0 focus:ring-offset-0">
                        <ArrowIcon show={show} />
                    </button>
                </div>
                <h4 className="capitalize">{heading}</h4>
            </div>
            {show && children}
        </div>
    );
};
