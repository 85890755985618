import React from "react";

interface ITertiaryButtonProps
    extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    id?: string;
    className?: string;
    disabled?: boolean;
    text?: string;
    children?: React.ReactNode;
}

const TertiaryButton = (props: ITertiaryButtonProps) => {
    const { id, text, className, onClick, children, disabled = false, ...rest } = props;
    return (
        <button
            type="button"
            id={id}
            className={`flex h-12 items-center justify-center border-b border-tertiaryButtonPrimary font-bold text-tertiaryButtonPrimary hover:border-tertiaryButtonSecondary focus:border-tertiaryButtonPrimary focus:outline-none focus:ring-1 focus:ring-tertiaryButtonFocusFrame active:border-tertiaryButtonSecondary ${className} ${
                disabled ? `pointer-events-none border-tertiaryButtonDisabled text-tertiaryButtonDisabled` : ""
            }`}
            onClick={(e) => {
                onClick!(e);
            }}
            onDoubleClick={() => console.log("not implemented")}
            onDoubleClickCapture={() => console.log("not implemented")}
            disabled={disabled}
            {...rest}
        >
            {children ? children : text}
        </button>
    );
};
export default TertiaryButton;
