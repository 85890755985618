import { LoadableComponentStatus } from "@medhelp/ui";
import { useAppSelector } from "store/hooks";
import { useAppDispatch } from "store/hooks";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { selectors, actions } from "../../redux";
import DepartmentsDropdown from "./DepartmentsDropdown";

export default function Index() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("followup");
    const departments = useAppSelector(selectors.searchDepartmentsFiltered);
    const getIncludeArchivedEmployees = useAppSelector(selectors.getIncludeArchivedEmployees);
    const status = useAppSelector(selectors.getStatus);
    const onlyOneDepartment = useAppSelector(selectors.hasOnlyOneDeparment);
    const setDepartmentsData = useCallback(
        (value: string) => {
            dispatch(
                actions.updateCompanyDepartmentSearch({
                    field: "departments",
                    value,
                }),
            );
        },
        [dispatch],
    );

    return (
        <div className="w-[335px] flex-1 lg:max-w-2xl" data-testid="departments-dropdowns-search-followup">
            <DepartmentsDropdown
                data={departments || []}
                setData={setDepartmentsData}
                viewElements={{
                    selectAll: !onlyOneDepartment ? t("selectAll") : undefined,
                    search: !onlyOneDepartment,
                }}
                disabled={getIncludeArchivedEmployees}
                keepClose={onlyOneDepartment || getIncludeArchivedEmployees}
                loading={status.organizationTree === LoadableComponentStatus.PENDING}
                header={t("department")}
            />
        </div>
    );
}
