import { useTranslation } from "react-i18next";
import { InfoBox } from "./InfoBox";
import { AccessRightKey } from "../types";

type InfoList = {
    heading: string;
    items: string[];
};

export const AccessRightInfo = ({ type }: { type: AccessRightKey }) => {
    const { t } = useTranslation("administration");

    const getInfoList = (key: string) => {
        const items: InfoList[] = t(`info.${key}`, { returnObjects: true });
        return (
            <InfoBox>
                <div className="flex flex-wrap gap-8">
                    {items.map((item) => (
                        <InfoList key={item.items[0]} heading={item.heading} items={item.items} />
                    ))}
                </div>
            </InfoBox>
        );
    };

    switch (type) {
        case "absenceReporting":
        case "fileImportAdministration":
            return <InfoBox text={t(`info.${type}`)} />;
        case "absenceAdministration":
        case "absenceBoard":
        case "absenceView":
        case "absenceFollowup":
        case "rehabProcessManagement":
        case "absenceStatistics":
        case "departmentAdministration":
        case "customerCompanyAdministration":
        case "customerOrganizationAdministration":
            return getInfoList(type);

        default:
            return <></>;
    }
};

interface InfoListProps {
    heading?: string;
    items: string[];
}

const InfoList = ({ heading, items }: InfoListProps) => (
    <div>
        {heading && <p className="pb-1.5 text-sm font-bold">{heading}</p>}
        <ul className="list-inside list-disc pl-2 text-sm">
            {items.map((item) => (
                <li key={item}>{item}</li>
            ))}
        </ul>
    </div>
);
