import { Modal } from "@medhelp/ui/Modal";
import PrimaryButton from "components/PrimaryButton";
import SecondaryButton from "components/SecondaryButton";
import TertiaryButton from "components/TertiaryButton";
import { ReactComponent as CloseIcon } from "icons/close.svg";

interface PopUpModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    title: string;
    content: React.ReactNode;
    firstButtonText: string;
    secondButtonText: string;
    handleFirstButtonTrigger: () => void;
    handleSecondButtonTrigger: () => void;
}
function PopUpModal({
    open,
    setOpen,
    title,
    content,
    firstButtonText,
    secondButtonText,
    handleFirstButtonTrigger,
    handleSecondButtonTrigger,
}: PopUpModalProps) {
    return (
        <Modal open={open} setOpen={setOpen} hideOnClickOutside={open}>
            <section className="!m-auto !mt-[8%] flex max-w-[528px] justify-center rounded-lg bg-white pb-12 pt-8 text-base leading-6 max-md:px-5">
                <div className="flex w-[403px] flex-col items-center justify-center overflow-hidden pl-6">
                    <div className="flex flex-col justify-center">
                        <header className="flex w-full items-center gap-2 self-stretch font-bold text-lime-950 max-md:max-w-full">
                            <p className="my-auto self-stretch text-sm font-bold">{title}</p>
                        </header>
                        <div className="mt-8">{content}</div>
                    </div>
                    <div className="mt-8 w-full flex-col items-center gap-6 whitespace-nowrap text-center leading-none lg:flex lg:flex-row">
                        <PrimaryButton
                            id="popup-model-cancel"
                            className="mb-4 w-full border border-solid bg-white text-sm lg:mb-0"
                            onClick={handleFirstButtonTrigger}
                        >
                            {firstButtonText}
                        </PrimaryButton>
                        <SecondaryButton
                            id="popup-model-continue"
                            className="w-full justify-center !bg-mhdarkgreen-original text-sm text-white"
                            onClick={handleSecondButtonTrigger}
                        >
                            {secondButtonText}
                        </SecondaryButton>
                    </div>
                </div>
                <TertiaryButton
                    className="flex border-none focus:border-none focus:ring-0"
                    onClick={handleFirstButtonTrigger}
                >
                    <CloseIcon className="h-6 w-6 fill-mhbeige" />
                </TertiaryButton>
            </section>
        </Modal>
    );
}

export default PopUpModal;
