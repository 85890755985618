import Icon from "components/Icon";
import { twMerge } from "tailwind-merge";
import { findDepartmentChain } from "../utils";
import { TreeViewNode } from "swagger/customercompany";

interface HierarchicalListProps {
    orgTreeView: TreeViewNode[];
    departmentId?: number;
    recursive: boolean;
}

const RecursiveNode = ({ node, level, departmentId }: { node: TreeViewNode; level: number; departmentId: number }) => (
    <div>
        <div
            className={twMerge("flex items-center gap-1.5", node.id === departmentId ? "font-bold" : "")}
            style={{ paddingLeft: `${level * 12}px` }}
        >
            {node?.children && node.children?.length > 0 ? (
                <Icon icon="caretDown" size={10} />
            ) : (
                level > 0 && <span className="w-2.5" />
            )}
            {node.label}
        </div>
        {node?.children?.map((child) => (
            <RecursiveNode key={child.id} node={child} level={level + 1} departmentId={departmentId} />
        ))}
    </div>
);

export const HierarchicalList = ({ orgTreeView, departmentId, recursive }: HierarchicalListProps) => {
    if (!departmentId) return null;
    const list = findDepartmentChain(orgTreeView || [], departmentId, recursive);
    return (
        <>{list?.map((item) => <RecursiveNode key={item.id} node={item} level={0} departmentId={departmentId} />)}</>
    );
};
