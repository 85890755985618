import * as React from "react";
import { twMerge } from "tailwind-merge";

export enum CardAlign {
    center,
    start,
    end,
}
export interface IProps {
    children: React.ReactNode;
    testId?: string;
    align?: CardAlign;
    className?: string;
}
const Card = ({ testId, children, align = CardAlign.center, className, ...rest }: IProps) => (
    <div
        id={testId}
        data-testid={testId}
        className={twMerge(`flex bg-primaryBg p-8 shadow-lg justify-${CardAlign[align]}`, className)}
        {...rest}
    >
        {children}
    </div>
);

export default Card;
