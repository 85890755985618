import Icon from "components/Icon";
import { ChangeEvent } from "react";
import { twMerge } from "tailwind-merge";

interface IProps {
    placeholder: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    setRef?: (node: HTMLInputElement) => void;
    setFocus?: (value: boolean) => void;
    className?: string;
}
const Search = ({ placeholder, onChange, setRef, setFocus, className, ...rest }: IProps) => {
    return (
        <div
            className={`relative left-0 top-0 flex w-full items-center border border-grey-500 bg-pageBg pl-2 ${className}`}
        >
            <Icon icon="magnifier" className="h-4 w-4 fill-none" />
            <input
                className={twMerge("search-bar ml-4 w-full bg-pageBg", className)}
                type="text"
                onChange={onChange}
                ref={setRef}
                onFocus={() => setFocus && setFocus(true)}
                onBlur={() => setFocus && setFocus(false)}
                placeholder={placeholder}
                {...rest}
            />
        </div>
    );
};

export default Search;
