import { twMerge } from "tailwind-merge";
import { ReactComponent as CheckboxIcon } from "../../icons/checkbox.svg";
import Icon from "components/Icon";
import { ChangeEvent } from "react";

interface ICheckboxProps {
    id: string;
    className?: string;
    checked: boolean;
    setChecked: (checked: boolean) => void;
    label?: string;
    disabled?: boolean;
    onBlur?: () => void;
}

export const CheckboxMigrate = (props: ICheckboxProps) => {
    const { id, disabled, className, checked, setChecked, label, onBlur } = props;

    return (
        <div className="flex items-center">
            <div
                className={`flex h-4 w-4 justify-center rounded border border-defaultCheckbox hover:border-hoverCheckbox ${checked ? "bg-defaultCheckbox hover:bg-hoverCheckbox" : "bg-transparent"} ${
                    disabled
                        ? "border-disabledCheckbox bg-disabledCheckbox hover:border-none hover:bg-disabledCheckbox"
                        : ""
                } ${className ?? ""}`}
                onBlur={onBlur}
            >
                <input
                    id={id}
                    type="checkbox"
                    className={`absolute opacity-0 ${!disabled && "cursor-pointer"}`}
                    checked={checked}
                    onChange={() => {
                        if (!disabled) setChecked(!checked);
                    }}
                />
                <CheckboxIcon
                    className={`w-3 fill-current ${checked ? "text-pageBg" : "text-transparent"} ${disabled ? "text-mhgrey-dark text-opacity-50" : ""}`}
                />
            </div>
            {label && <p className="ml-2">{label}</p>}
        </div>
    );
};

interface CheckboxProps {
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    checked?: boolean;
    disabled?: boolean;
    name?: string;
    alt?: string;
    size?: number;
}

export const Checkbox = ({ checked, onChange, disabled = false, name, alt, size = 18, ...rest }: CheckboxProps) => (
    <label
        className={twMerge(
            "flex items-center rounded-md hover:bg-mhgrey-light",
            disabled ? "cursor-not-allowed" : "cursor-pointer",
        )}
    >
        <input
            className="hidden"
            name={name}
            checked={checked}
            onChange={(e) => {
                onChange(e);
            }}
            disabled={disabled}
            alt={alt}
            {...rest}
            type="checkbox"
        />
        {checked ? (
            <Icon icon="checkBoxChecked" fill={disabled ? "#BBBDAC" : "#192D05"} size={size} />
        ) : (
            <Icon icon="checkBoxUncheched" stroke={disabled ? "#BBBDAC" : "#192D05"} size={size} />
        )}
    </label>
);

interface CheckboxWithLabelProps {
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    checked?: boolean;
    disabled?: boolean;
    name?: string;
    alt?: string;
    size?: number;
    label?: React.ReactNode;
}

export const CheckboxWithLabel = ({
    checked,
    onChange,
    disabled = false,
    name,
    alt,
    size = 18,
    label,
    ...rest
}: CheckboxWithLabelProps) => (
    <label
        className={twMerge(
            "flex items-center rounded-md hover:bg-mhgrey-light",
            disabled ? "cursor-not-allowed" : "cursor-pointer",
        )}
    >
        <input
            className="hidden"
            name={name}
            checked={checked}
            onChange={(e) => {
                onChange(e);
            }}
            disabled={disabled}
            alt={alt}
            {...rest}
            type="checkbox"
        />
        {checked ? (
            <Icon icon="checkBoxChecked" fill={disabled ? "#BBBDAC" : "#192D05"} size={size} />
        ) : (
            <Icon icon="checkBoxUncheched" stroke={disabled ? "#BBBDAC" : "#192D05"} size={size} />
        )}
        {label}
    </label>
);
