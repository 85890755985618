import MiniMenu from "components/MiniMenu/MiniMenu";
import { DepartmentListProps, IActivityButtonValues } from "./DepartmentList";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { ToggleSwitch } from "components/ToggleSwitch/ToggleSwitch";
import { isMiniMenuDisabled, setCreatedByName } from "pages/Administration/utils";
import ArrowIcon from "../ArrowIconComponent/ArrowIconComponent";

const DepartmentListMobileView = ({
    id,
    title,
    reason,
    createdBy,
    day,
    isActive,
    showDescription,
    toggleShow,
    switchOnOff,
    handleOnOff,
    miniMenuItems,
    description,
    type,
    partner,
    companyName,
}: DepartmentListProps & {
    showDescription: boolean;
    toggleShow: () => void;
    switchOnOff: boolean;
    handleOnOff: () => void;
    miniMenuItems: () => IActivityButtonValues[];
}) => {
    const { t } = useTranslation("administration");
    return (
        <div key={id} className="flex flex-col">
            <div className="flex w-full items-center justify-between">
                <p className="flex py-2 text-end text-base">{`${t("day")}${" "}${day}`}</p>
                <MiniMenu
                    buttonValues={miniMenuItems()}
                    buttonRight
                    className="!mt-2 rounded-sm"
                    id={`miniMenu`}
                    preset="white"
                    disabled={type === "chain"}
                    onlyOptionsDisabled={isMiniMenuDisabled(type, createdBy)}
                >
                    <div className="flex items-center justify-between border-t-[3px] p-4">
                        <p>{switchOnOff ? t("turnOff") : t("turnOn")}</p>
                        <ToggleSwitch onChange={handleOnOff} value={switchOnOff} name="" disabled={type === "chain"} />
                    </div>
                </MiniMenu>
            </div>
            <div className="flex flex-col pr-6">
                <div className="pb-0.5.5 flex pt-3">
                    <button
                        className={twMerge(
                            "pr-3 focus:ring-0 focus:ring-offset-0",
                            !isActive && "cursor-not-allowed text-gray-500",
                        )}
                        type="button"
                        onClick={toggleShow}
                        disabled={!isActive}
                    >
                        <ArrowIcon show={showDescription} />
                    </button>
                    <p className="!break-all text-lg font-black">{title}</p>
                </div>
                <div className="px-4">
                    <p className="pb-4 pl-2 text-sm text-grey-700">
                        {createdBy === "null" || createdBy === "standard" ? (
                            <>{t("rehabChain")}</>
                        ) : (
                            <>
                                {t("suggestedBy")} {setCreatedByName(createdBy, companyName, partner)}
                            </>
                        )}
                    </p>
                    <div className="flex-col items-center">
                        {reason.length > 0 ? (
                            Object.values(reason).map((data) => (
                                <p key={data.id} className="block text-sm">
                                    {data.title}
                                </p>
                            ))
                        ) : (
                            <p className="pl-2">{t("longtermAbsence")}</p>
                        )}
                    </div>
                </div>
            </div>

            {showDescription && description && (
                <div className="px-6 pb-6">
                    <div className="flex w-full flex-col bg-grey-50 px-8 py-6">
                        <h5 className="pb-2 text-sm font-bold">{t("description")}</h5>
                        <p className="text-base font-normal">{description}</p>
                    </div>
                </div>
            )}
        </div>
    );
};
export default DepartmentListMobileView;
