import Icon from "components/Icon";
import { saveAs } from "file-saver";
import { getServices } from "api/services";
import { useTranslation } from "react-i18next";

interface DownloadFileProps {
    id: string;
    filename: string;
}

export const DownloadFile = ({ id, filename }: DownloadFileProps) => {
    const { t } = useTranslation("fileImport");
    const handleDownload = async () => {
        try {
            // Dont use rtk-query for downloads, or files will be cached
            const response = await getServices().clients.fileImportStorage.fileImportDownloadIdGet(id, {
                responseType: "blob",
            });
            saveAs(response.data, filename);
        } catch (error) {
            console.error("Error downloading file:", error);
            alert(t("downloadFailed"));
        }
    };

    return (
        <button onClick={handleDownload}>
            <Icon icon="download" size={40} />
        </button>
    );
};
