/**
 * Pick stuff from the state related to this `reducer`
 */
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";
import { getActivePowerbarSearch } from "store/searchSelectors";
import {
    getAccordionProps,
    getOrgTreeViewAsDisplayList,
    handleCheckboxChangeFromPostItems,
    getOrgTreeViewAsDisplayListWithDisabledSubscribed,
    getEmploymentGroupValues,
    getCostCenterValues,
    getCountryAsPhonePrefix,
    getRegionAsCountry,
    getRegionAsLanguage,
    initialSelectedUserProps,
    getCreateOrUpdateUserAccountModel,
    getMonitorType,
    showSubscriptionOptions,
    subscriptionIsValid,
    updateAccordion,
    getMissingContactKeyFromCompanyUser,
    getMissingContactKey,
    getSubscriptionOptionGroups,
    getAllowedChannelForAcc,
    getContactKeyFromCompanyUser,
} from "../utils";
import {
    Country,
    IPermissionAccordions,
    ISubscriptionAccordionStateProps,
    ISubscriptionOptionsStateProps,
} from "../types";
import _ from "lodash";
import { CreateOrUpdateAccessRights } from "swagger/customercompany";
import {
    getChannels,
    getAvailableNotificationTypes,
    getRoles,
    getSavedAsConfig,
    validateNotificationType,
    getNotificationTypeGroups,
} from "../utils";
import { getCustomerSupportAccess, getUserCustomerOrganizationAdministrationRights } from "store/userSelectors";
import { EventSubscriptionPostModel, SearchUserAndDepartmentToAddModel } from "swagger/employeeattendance";
import { ICreateSubscriptionStateProps } from "../components/Subscriptions/CreateNewSubscription/CreateNewSubscription";

export const getUserSlice = (state: RootState) => state.user;
export const getUserContext = createSelector(getUserSlice, (userSlice) => userSlice.userContextExtended);

export const getAdministrationSlice = (state: RootState) => state.administration;
export const getAdminIsLoading = createSelector(
    getAdministrationSlice,
    (adminSlice) => adminSlice.administrationIsLoading,
);
export const getSaveOrUpdateSuccessful = createSelector(
    getAdministrationSlice,
    (adminSlice) => adminSlice.saveOrUpdateSuccessful,
);
export const getSelectedCompanyId = createSelector(
    getAdministrationSlice,
    (adminSlice) => adminSlice.selectedCompanyId,
);
export const getSelectedUserAccountId = createSelector(
    getAdministrationSlice,
    (adminSlice) => adminSlice.selectedUserAccountId,
);
export const getSelectedUserCompanyIds = createSelector(
    getAdministrationSlice,
    (adminSlice) => adminSlice.loggedInUserCompanies?.map((c) => c.id!) ?? [],
);

export const getErasureFinishedSettingsData = createSelector(
    getAdministrationSlice,
    (adminSlice) => adminSlice.erasureFinishedSettingsData,
);
export const getErasureFinishedSettingsStateProps = createSelector(
    getAdministrationSlice,
    (adminSlice) => adminSlice.erasureFinishedSettingsStateProps,
);

export const getErasureSettingPageProps = createSelector(
    getErasureFinishedSettingsData,
    getErasureFinishedSettingsStateProps,
    (settingsData, settingsProps) => ({
        ...settingsProps,
        isDirty: !_.isEqual(settingsData, settingsProps.companyErasureConfig),
    }),
);

export const getErasureActiveIsLoading = createSelector(
    getAdministrationSlice,
    (adminSlice) => adminSlice.erasureActiveIsLoading,
);

export const getErasureActiveSettingsData = createSelector(
    getAdministrationSlice,
    (adminSlice) => adminSlice.erasureActiveSettingsData,
);
export const companyHasErasureActiveConfig = createSelector(getErasureActiveSettingsData, (data) => !!data);
export const getErasureActiveSettingsStateProps = createSelector(
    getAdministrationSlice,
    (adminSlice) => adminSlice.erasureActiveSettingsStateProps,
);
export const getErasureActiveSettingPageProps = createSelector(
    getErasureActiveSettingsData,
    getErasureActiveSettingsStateProps,
    (activeSettingsData, activeSettingsProps) => ({
        ...activeSettingsProps,
        isDirty: !_.isEqual(activeSettingsData, activeSettingsProps.activeErasureConfig),
    }),
);

export const getRoleSubscriptionStateProps = createSelector(
    getAdministrationSlice,
    (adminSlice) => adminSlice.roleSubscriptionProps,
);
export const getRoleSubscriptionData = createSelector(
    getAdministrationSlice,
    (adminSlice) => adminSlice.roleSubscriptionData,
);
export const getAvailableNotifications = createSelector(
    getRoleSubscriptionData,
    (data) => data?.availableNotifications,
);
export const getSavedNotifications = createSelector(getRoleSubscriptionData, (data) => data?.selectedRoles);
export const getSelectedRoleSubscriptions = createSelector(
    getRoleSubscriptionStateProps,
    (stateProps) => stateProps?.selectedRoleSubscriptions,
);

export const getNewRowProps = createSelector(
    getRoleSubscriptionStateProps,
    getAvailableNotifications,
    getSelectedRoleSubscriptions,
    (roleSubscriptionsStateProps, available, selected) => ({
        availableNotificationTypes: getAvailableNotificationTypes(available, selected),
        channels: getChannels(roleSubscriptionsStateProps.newRoleSubscription, selected, available),
        roles: getRoles(roleSubscriptionsStateProps.newRoleSubscription, selected),
        newRow: roleSubscriptionsStateProps.newRoleSubscription,
        notificationSelectOpen: roleSubscriptionsStateProps.notificationSelectOpen,
    }),
);
export const getNewRow = createSelector(getRoleSubscriptionStateProps, (subProps) => subProps.newRoleSubscription);
export const getIsDirty = createSelector(
    getSelectedRoleSubscriptions,
    getSavedNotifications,
    getNewRow,

    (selected, saved, newRow) => !_.isEqual(selected, getSavedAsConfig(saved)) || !!newRow,
);
export const getRoleSubscriptionsGroups = createSelector(getSelectedRoleSubscriptions, (selected) =>
    getNotificationTypeGroups(selected),
);
export const getNewRowIsValid = createSelector(
    getNewRow,
    (newRow) =>
        newRow === null || (validateNotificationType(newRow.notificationType) && !!newRow.channel && !!newRow.role),
);

export const getEnableSave = createSelector(
    getIsDirty,
    getNewRowIsValid,
    (isDirty, rowIsValid) => rowIsValid && isDirty,
);

export const getSelectedCompany = createSelector(getAdministrationSlice, (adminSlice) => adminSlice.selectedCompany);
export const getUsersIsLoading = createSelector(getAdministrationSlice, (adminSlice) => adminSlice.usersIsLoading);
export const getSelectedCompanyUser = createSelector(
    getAdministrationSlice,
    (adminSlice) => adminSlice.selectedCompanyUser,
);

export const getUserEmploymentProps = createSelector(
    getAdministrationSlice,
    (adminSlice) => adminSlice.userEmploymentStateProps,
);
export const getUserEmploymentData = createSelector(
    getAdministrationSlice,
    (adminSlice) => adminSlice.userEmploymentData,
);
export const getSelectedCompanyEmploymentGroups = createSelector(
    getUserEmploymentData,
    (empData) => empData?.companyEmploymentGroups ?? null,
);
export const getCompanyCostCenters = createSelector(
    getUserEmploymentData,
    (empData) => empData?.companyCostCenters ?? null,
);
export const getSavedUserEmployment = createSelector(getUserEmploymentData, (empData) => empData?.employment);
export const getOrgTreeView = createSelector(getAdministrationSlice, (adminSlice) => adminSlice.organizationTreeView);

export const getActivePowerbarSearchItem = createSelector(getActivePowerbarSearch, (selectedSearch) => selectedSearch);

export const getPowerbarSelectedCompanyId = createSelector(getActivePowerbarSearchItem, (powerbarItem) =>
    powerbarItem?.companyId ? Number(powerbarItem.companyId) : null,
);
export const getSelectedCompanyUserUserAccountId = createSelector(
    getSelectedCompanyUser,
    (selectedCompanyUser) => selectedCompanyUser?.userAccountId,
);
export const getSelectedCompanyUserEmploymentId = createSelector(
    getSelectedCompanyUser,
    (selectedCompanyUser) => selectedCompanyUser?.employmentId,
);
export const getSelectedCompanyUserName = createSelector(
    getSelectedCompanyUser,
    (selectedCompanyUser) => selectedCompanyUser?.name ?? undefined,
);
export const getSelectedCompanyUserUserId = createSelector(
    getSelectedCompanyUser,
    (selectedCompanyUser) => selectedCompanyUser?.userId,
);
export const getSelectedCompanyUserPrimaryCompany = createSelector(
    getSelectedCompanyUser,
    (selectedCompanyUser) => selectedCompanyUser?.primaryCompany,
);
export const getSelectedCompanyUserPrimaryCompanyId = createSelector(
    getSelectedCompanyUserPrimaryCompany,
    (selectedCompanyUserPrimaryCompany) => selectedCompanyUserPrimaryCompany?.id,
);
export const getSelectedCompanyUserPrimaryCompanyName = createSelector(
    getSelectedCompanyUserPrimaryCompany,
    (selectedCompanyUserPrimaryCompany) => selectedCompanyUserPrimaryCompany?.name ?? "",
);

export const getAdminComponentProps = createSelector(
    getSelectedCompany,
    getSelectedCompanyUser,
    getSelectedCompanyUserPrimaryCompanyName,
    getUserCustomerOrganizationAdministrationRights,
    getCustomerSupportAccess,
    (selectedCompany, selectedCompanyUser, selectedCompanyUserPrimaryCompanyName, userIsCoa, userIsCs) => ({
        companyName: selectedCompany?.name ?? "",
        companyOrgNumber: selectedCompany?.number ?? "",
        userId: selectedCompanyUser?.userId ?? -1,
        userName: selectedCompanyUser?.name ?? "",
        userEmployment: selectedCompanyUserPrimaryCompanyName,
        userAccountId: selectedCompanyUser?.userAccountId ?? -1,
        isCoaOrCs: userIsCoa || userIsCs,
    }),
);

export const getSelectedUserId = createSelector(
    getSelectedCompanyUser,
    (selectedCompanyUser) => selectedCompanyUser?.userId,
);
export const getAddUserProps = createSelector(getAdministrationSlice, (adminSlice) => adminSlice.addUserStateProps);

export const getEmploymentStateProps = createSelector(
    getAdministrationSlice,
    (adminSlice) => adminSlice.employmentStateProps,
);

export const getSubscriptionStateProps = createSelector(
    getAdministrationSlice,
    (adminSlice) => adminSlice.subscriptionsProps,
);
export const getStateSubscriptionData = createSelector(
    getAdministrationSlice,
    (adminSlice) => adminSlice.subscriptionsData,
);
export const getUserSubsOnTableItems = createSelector(
    getStateSubscriptionData,
    (subData) => subData?.userSubsOnTableItems,
);
export const getSubsOnUserTableItems = createSelector(
    getStateSubscriptionData,
    (subData) => subData?.subsOnUserTableItems,
);
export const getSubscriptionIsLoading = createSelector(
    getSubscriptionStateProps,
    (subscriptionStateProps) => subscriptionStateProps.subscriptionIsLoading,
);
export const getActivSubscriptionTab = createSelector(
    getSubscriptionStateProps,
    (subscriptionStateProps) => subscriptionStateProps.activeTab,
);
export const getCurrentlyEditingStateAccordion = createSelector(
    getSubscriptionStateProps,
    (subscriptionStateProps) => subscriptionStateProps.currentlyEditingAccordion,
);
export const getDeleteSubscriptionId = createSelector(
    getSubscriptionStateProps,
    (subscriptionStateProps) => subscriptionStateProps.deleteSubscriptionId,
);
export const getCreateOrUpdateSuccessful = createSelector(
    getSubscriptionStateProps,
    (subscriptionStateProps) => subscriptionStateProps.createOrUpdateSuccessful,
);
export const getNewSubscriptionStateProps = createSelector(
    getSubscriptionStateProps,
    (subscriptionStateProps) => subscriptionStateProps.newSubscriptionStateProps,
);
export const getNewSubscriptionSearchProps = createSelector(
    getNewSubscriptionStateProps,
    (newSubStateProps) => newSubStateProps.newSubSearchProps,
);
export const getNewSubs = createSelector(
    getNewSubscriptionStateProps,
    (newSubStateProps) => newSubStateProps.newSubSearchProps,
);
export const getNewSubscriptionSearchData = createSelector(
    getStateSubscriptionData,
    (subscriptionData) => subscriptionData?.searchData,
);
export const getSubscriptionDepartmentsIsLoading = createSelector(
    getSubscriptionStateProps,
    (subscriptionStateProps) => subscriptionStateProps.newSubscriptionStateProps.departmentsIsLoading,
);
export const getNewExternalSubscriberProps = createSelector(
    getSubscriptionStateProps,
    (subscriptionStateProps) => subscriptionStateProps.newSubscriptionStateProps.newExternalSubscriber,
);
export const getSubscriptionTabProps = createSelector(
    getSelectedCompanyUser,
    getActivSubscriptionTab,
    getStateSubscriptionData,
    (selectedCompanyUser, activeTab, subscriptionData) => ({
        selectedUser: selectedCompanyUser?.name ?? "",
        activeTab,
        noOfSubscriptions: subscriptionData?.userSubsOnTableItems.length ?? 0,
        noOfSubscribers: subscriptionData?.subsOnUserTableItems.length ?? 0,
    }),
);

const getUserSubsOnProps = createSelector(
    getStateSubscriptionData,
    getCurrentlyEditingStateAccordion,
    getSelectedCompanyUser,
    getDeleteSubscriptionId,
    getSelectedCompanyId,
    (subData, currentAcc, selectedCompanyUser, deleteSubId, selectedCompanyId) => {
        if (!subData) return [];
        const userSubsOn: ISubscriptionAccordionStateProps[] = subData.userSubsOnTableItems?.map((tableItem) => {
            //Get and update modified accordion.
            if (tableItem.id === currentAcc.subscriptionId)
                return updateAccordion(currentAcc, tableItem, deleteSubId, selectedCompanyId);
            // Create new accordion
            else {
                const subscriptionOptionProps: ISubscriptionOptionsStateProps = {
                    subscriptionGroups: getSubscriptionOptionGroups(
                        tableItem.companyId,
                        subData.defaultSubscriptionTypes,
                        tableItem.directSubscriptionInformation,
                        getAllowedChannelForAcc(tableItem),
                    ),
                    contactKey: getContactKeyFromCompanyUser(selectedCompanyUser),
                    missingContactKey: getMissingContactKeyFromCompanyUser(selectedCompanyUser),
                    selectedUserName: selectedCompanyUser?.name ?? "",
                };
                return {
                    ...getAccordionProps(tableItem, tableItem.id ?? -1, subscriptionOptionProps, selectedCompanyId),
                    monitoredIds: tableItem.id ? [tableItem.id] : [],
                    subscribingUserAccountId: selectedCompanyUser?.userAccountId ?? -1,
                    currentTableItemUserAccountId: selectedCompanyUser?.userAccountId ?? -1,
                };
            }
        });
        return userSubsOn;
    },
);

const getSubsOnUserProps = createSelector(
    getStateSubscriptionData,
    getCurrentlyEditingStateAccordion,
    getSelectedCompanyUserName,
    getSelectedCompanyUserEmploymentId,
    getDeleteSubscriptionId,
    getSelectedCompanyId,

    (
        subData,
        currentAcc,
        selectedCompanyUserName,
        selectedCompanyUserId,
        deleteSubId,
        selectedCompanyId,
    ): ISubscriptionAccordionStateProps[] => {
        if (!subData) return [];
        const userSubsOn: ISubscriptionAccordionStateProps[] = subData.subsOnUserTableItems?.map((tableItem, i) => {
            //Get and update modified accordion.
            if (i === currentAcc.subscriptionId)
                return updateAccordion(currentAcc, tableItem, deleteSubId, selectedCompanyId, selectedCompanyUserName);
            // Create new accordion
            else {
                const subscriptionOptionProps: ISubscriptionOptionsStateProps = {
                    subscriptionGroups: getSubscriptionOptionGroups(
                        tableItem.companyId,
                        subData.defaultSubscriptionTypes,
                        tableItem.directSubscriptionInformation,
                        getAllowedChannelForAcc(tableItem),
                    ),
                    contactKey: tableItem.contactKey ?? [],
                    missingContactKey: getMissingContactKey(tableItem, subData.defaultSubscriptionTypes),
                    selectedUserName: tableItem.name ?? "",
                };
                return {
                    ...getAccordionProps(
                        tableItem,
                        // subData.defaultSubscriptionTypes,
                        i,
                        subscriptionOptionProps,
                        selectedCompanyId,
                    ),
                    monitoredIds: [selectedCompanyUserId ?? -1],
                };
            }
        });
        return userSubsOn;
    },
);
export const getNewSubscriptionProps = createSelector(
    getNewSubscriptionSearchProps,
    getNewSubscriptionSearchData,
    getOrgTreeView,
    getUserSubsOnTableItems,
    getSubscriptionDepartmentsIsLoading,
    getCurrentlyEditingStateAccordion,
    getNewExternalSubscriberProps,
    getSelectedCompanyUser,
    (
        searchProps,
        searchData,
        orgTreeView,
        userSubsOnItems,
        depIsLoading,
        currentAccordion,
        newExternalSub,
        selectedCompanyUser,
    ): ICreateSubscriptionStateProps => ({
        selectedLookupTypeRadioValue: currentAccordion.subscriptionMonitorType ?? 1,
        newSubSearchProps: {
            ...searchProps,
            //Todo filtrera bort redan valda
            searchResults:
                searchData?.filter((data) => data.lookupType === currentAccordion.subscriptionMonitorType) ?? null,
        },
        departmentsDisplayList: getOrgTreeViewAsDisplayListWithDisabledSubscribed(
            orgTreeView,
            currentAccordion.monitoredIds,
            userSubsOnItems?.filter((item) => item.monitoringType === 2).map((filtered) => filtered.id ?? -1),
        ),
        departmentsIsLoading: depIsLoading,
        showSubscriptionOptions: showSubscriptionOptions(
            currentAccordion,
            newExternalSub.searchIsLoading,
            searchProps.searchIsLoading,
        ),
        newExternalSubscriber: newExternalSub,
        subscriptionOptionProps: {
            ...handleCheckboxChangeFromPostItems(currentAccordion).subscriptionOptionProps,
            missingContactKey: getMissingContactKeyFromCompanyUser(
                currentAccordion.isSubsOnUserTab ? searchProps.selectedSearchCompanyUser : selectedCompanyUser,
                currentAccordion.subscriptionMonitorType,
            ),
        },
    }),
);
export const getNewSubscriptonIsValid = createSelector(
    getDeleteSubscriptionId,
    getCurrentlyEditingStateAccordion,
    (deleteSubId, currenlyEditing) => subscriptionIsValid(currenlyEditing, deleteSubId),
);
export const getSubscriptionProps = createSelector(
    getSubscriptionStateProps,
    getUserSubsOnProps,
    getSubsOnUserProps,
    getNewSubscriptionProps,
    getNewSubscriptonIsValid,
    (subscriptionStateProps, userSubsOnProps, subsOnUserProps, newSubProps, newSubIsValid) => ({
        ...subscriptionStateProps,
        userSubsOnAccordionProps: userSubsOnProps,
        subsOnUserAccordionProps: subsOnUserProps,
        newSubscriptionStateProps: newSubProps,
        newSubscriptonIsValid: newSubIsValid,
    }),
);
export const getSubscriptionPostModel = createSelector(
    getCurrentlyEditingStateAccordion,
    (currentAcc): EventSubscriptionPostModel => ({
        activationDate: currentAcc.startDate,
        deactivationDate: currentAcc.endDate ?? null,
        monitoringType: getMonitorType(currentAcc.subscriptionMonitorType),
        monitoredIds: currentAcc.monitoredIds,
        eventSubscriptionPostItems: currentAcc.postItems,
        subscribingUserAccountId: currentAcc.subscribingUserAccountId,
        name: "",
        contactKey: currentAcc.newExternalContactKey?.replace("-", ""),
    }),
);
export const getSearchUserAndCompanyToAddModel = createSelector(
    getSelectedUserCompanyIds,
    getSelectedCompanyId,
    getActivSubscriptionTab,
    getUserContext,
    (companyIds, selectedCompanyId, activeTab, userContext): SearchUserAndDepartmentToAddModel => ({
        companyIds: activeTab === "subsOnUser" ? companyIds : [selectedCompanyId ?? -1],
        includeExternal: activeTab === "subsOnUser",
        includeCompany:
            activeTab === "userSubsOn" &&
            (userContext.HasCustomerSupport || userContext.HasCustomerCompanyAdministration),
        searchNoneEmployeeUsers: activeTab === "subsOnUser",
    }),
);

export const getUserProps = createSelector(getAddUserProps, (addUserProps) => addUserProps.userProps);
export const getErrorMessages = createSelector(getAddUserProps, (addUserProps) => addUserProps.errorMessages);
export const getSsnSearchProps = createSelector(getAddUserProps, (addUserProps) => addUserProps.ssnSearchProps);
export const getSelectedCompanyRegion = createSelector(
    getAddUserProps,
    (addUserProps) => addUserProps.selectedCompanyRegion,
);
export const getAddUserIsDirty = createSelector(
    getSelectedCompanyUser,
    getUserProps,
    (selectedCompanyUser, userProps) => {
        const initialState = selectedCompanyUser
            ? getCreateOrUpdateUserAccountModel(selectedCompanyUser)
            : initialSelectedUserProps;
        return !_.isEqual(initialState, userProps);
    },
);
export const getCountry = createSelector(getUserProps, getSelectedCompanyRegion, (userProps, region) =>
    userProps.country ? (userProps.country as Country) : getRegionAsCountry(region),
);
export const getLanguage = createSelector(getUserProps, getSelectedCompanyRegion, (userProps, region) =>
    userProps.language ? userProps.language : getRegionAsLanguage(region),
);

export const getActivationDate = createSelector(getUserProps, (userProps) => userProps.periodActivationDate);
export const getDeActivationDate = createSelector(getUserProps, (userProps) => userProps.periodDeactivationDate);

export const getFirstName = createSelector(getUserProps, (userProps) => userProps.firstName);
export const getLastName = createSelector(getUserProps, (userProps) => userProps.lastName);
export const getSsn = createSelector(getUserProps, (userProps) => userProps.socialSecurityNumber);
export const getSsnError = createSelector(getErrorMessages, (errorMessages) => errorMessages.ssnErrorMsg);
export const getBirthYear = createSelector(getUserProps, (userProps) => userProps.birthYear);
export const getGender = createSelector(getUserProps, (userProps) => userProps.gender);

export const getPersonalInfo = createSelector(
    getFirstName,
    getLastName,
    getSsn,
    getSsnError,
    getBirthYear,
    getGender,
    getCountry,
    (firstName, lastName, ssn, ssnErrorMsg, birthYear, gender, country) => ({
        firstName: firstName ?? "",
        lastName: lastName ?? "",
        ssn: ssn ?? "",
        ssnPlaceholder: country === "SE" ? "YYYYMMDDXXXX" : "",
        ssnErrorMsg: ssnErrorMsg ?? "",
        birthYear: birthYear ?? null,
        gender: gender ?? 0,
        disableInputs: country !== "OTH",
    }),
);

export const getMobileCountryPrefix = createSelector(getUserProps, getCountry, (userProps, country) =>
    userProps.mobileCountryPrefix ? userProps.mobileCountryPrefix : getCountryAsPhonePrefix(country),
);
export const getMobileNumber = createSelector(getUserProps, (userProps) => userProps.mobileNumber);
export const getSecondaryMobileCountryPrefix = createSelector(getUserProps, getCountry, (userProps, country) =>
    userProps.secondaryMobileCountryPrefix ? userProps.secondaryMobileCountryPrefix : getCountryAsPhonePrefix(country),
);
export const getSecondaryMobileNumber = createSelector(getUserProps, (userProps) => userProps.secondaryMobileNumber);
export const getTelephoneCountryPrefix = createSelector(getUserProps, getCountry, (userProps, country) =>
    userProps.telephoneCountryPrefix ? userProps.telephoneCountryPrefix : getCountryAsPhonePrefix(country),
);
export const getTelephoneNumber = createSelector(getUserProps, (userProps) => userProps.telephoneNumber);
export const getEmail = createSelector(getUserProps, (userProps) => userProps.email);
export const getWorkEmailErrorMsg = createSelector(
    getErrorMessages,
    (errorMessages) => errorMessages.workEmailErrorMsg,
);
export const getContactInfo = createSelector(
    getMobileCountryPrefix,
    getMobileNumber,
    getSecondaryMobileCountryPrefix,
    getSecondaryMobileNumber,
    getTelephoneCountryPrefix,
    getTelephoneNumber,
    getEmail,
    getWorkEmailErrorMsg,
    (
        mobileCountryPrefix,
        mobileNumber,
        secondaryMobileCountryPrefix,
        secondaryMobileNumber,
        telephoneCountryPrefix,
        telephoneNumber,
        email,
        workEmailErrorMsg,
    ) => ({
        privatePhone: `${mobileCountryPrefix}-${mobileNumber}`,
        workPhone: `${secondaryMobileCountryPrefix}-${secondaryMobileNumber}`,
        homePhone: `${telephoneCountryPrefix}-${telephoneNumber}`,
        email: email ?? "",
        workEmailErrorMsg: workEmailErrorMsg ?? "",
    }),
);

export const getAnyErrorMessages = createSelector(
    getErrorMessages,
    (errorMessages) =>
        !errorMessages.ssnErrorMsg &&
        !errorMessages.workEmailErrorMsg &&
        !errorMessages.workPhoneErrorMsg &&
        !errorMessages.privatePhoneErrorMsg,
);

export const getAddUserButtonIsLoading = createSelector(
    getAddUserProps,
    (addUserProps) => addUserProps.saveButtonLoading,
);

export const getUserId = createSelector(getUserProps, (userProps) => userProps.userId);
export const getUserAccountId = createSelector(getUserProps, (userProps) => userProps.id);
export const getCompanyId = createSelector(getUserProps, (userProps) => userProps.companyId);

export const getEmploymentErrorMsg = createSelector(
    getEmploymentStateProps,
    (employmentStateProps) => employmentStateProps.organizationNumberErrorMsg,
);
export const getSaveButtonLoading = createSelector(
    getEmploymentStateProps,
    (employmentStateProps) => employmentStateProps.saveButtonLoading,
);
export const getUserEmployment = createSelector(
    getUserEmploymentProps,
    (userEmploymentProps) => userEmploymentProps.userEmployment,
);
export const getEmploymentSelectedDepartments = createSelector(
    getUserEmployment,
    (userEmployment) => userEmployment?.departments,
);
export const getEmploymentDisableAll = createSelector(
    getSelectedCompanyUserPrimaryCompany,
    getSelectedCompanyId,
    (selectedCompUserPrimaryCompany, selectedCompanyId) => selectedCompUserPrimaryCompany?.id !== selectedCompanyId,
);

export const getEmploymentInfoProps = createSelector(
    getSelectedCompanyUserPrimaryCompany,
    getSelectedCompanyEmploymentGroups,
    getUserEmploymentProps,
    getEmploymentDisableAll,
    (selectedCompUserPrimaryCompany, companyEmploymentGroups, userEmploymentProps, disableAll) => ({
        employedOn: selectedCompUserPrimaryCompany?.name ?? "",
        employmentNumber: userEmploymentProps.userEmployment?.employmentNumber ?? "",
        employmentGroupValues: getEmploymentGroupValues(
            companyEmploymentGroups,
            userEmploymentProps.userEmployment?.employmentGroups,
        ),
        disableAll,
    }),
);
export const getMainManagerSearchProps = createSelector(
    getEmploymentStateProps,
    (employmentStateProps) => employmentStateProps.mainManagerSearchProps,
);
export const getDepartmentDisplayList = createSelector(
    getOrgTreeView,
    getEmploymentSelectedDepartments,

    (orgTreeView, selectedDepts) =>
        getOrgTreeViewAsDisplayList(
            orgTreeView,
            selectedDepts?.map((dep) => dep.id ?? -1),
            undefined,
            true,
        ),
);

export const getBelongingProps = createSelector(
    getUserEmploymentProps,
    getCompanyCostCenters,
    getEmploymentDisableAll,
    getDepartmentDisplayList,
    getEmploymentErrorMsg,
    (userEmploymentProps, companyCostCenters, disableAll, displayList, errorMsg) => ({
        mainManagagerSearchValue: userEmploymentProps.mainManagagerSearchValue,
        mainManagagerSelectedName: userEmploymentProps?.userEmployment?.mainManager?.name ?? undefined,
        costCenterValues: getCostCenterValues(userEmploymentProps.userEmployment?.costCenter, companyCostCenters ?? []),
        disableAll,
        displayList,
        organizationNumber: userEmploymentProps.userEmployment?.organizationNumber ?? "",
        organizationNumberErrorMsg: errorMsg,
    }),
);
export const getEmploymentIsDirty = createSelector(
    getSavedUserEmployment,
    getUserEmployment,
    (savedUserEmployment, userEmployment) => !_.isEqual(savedUserEmployment, userEmployment),
);

export const getPermisssionData = createSelector(getAdministrationSlice, (adminSlice) => adminSlice.permissionData);
export const getSelectedCompanyAccessRights = createSelector(
    getPermisssionData,
    (permissionData) => permissionData?.selectedCompanyAccessRights,
);
export const getPermissionsProps = createSelector(
    getAdministrationSlice,
    (adminSlice) => adminSlice.permissionsStateProps,
);
export const getPermissionsAccordionStateProps = createSelector(
    getPermissionsProps,
    (permissionProps) => permissionProps.accordionProps,
);
export const getPermissionsAccordionProps = createSelector(
    getPermissionsAccordionStateProps,
    getSelectedCompanyAccessRights,
    getOrgTreeView,
    (permissionStateProps, accessRights, orgTreeView) => {
        const updated = _.cloneDeep(permissionStateProps);
        if (accessRights) {
            Object.keys(accessRights).forEach((arKey) => {
                const arValue = accessRights[arKey as keyof CreateOrUpdateAccessRights];
                if (typeof arValue === "boolean") {
                    for (const accordionKey of Object.keys(updated)) {
                        if (arKey === accordionKey) {
                            updated[accordionKey as keyof IPermissionAccordions].checked = arValue;
                            break;
                        }
                    }
                } else if (Array.isArray(arValue)) {
                    for (const accordionKey of Object.keys(updated)) {
                        if (arKey === accordionKey) {
                            updated[accordionKey as keyof IPermissionAccordions].depDisplayList =
                                getOrgTreeViewAsDisplayList(
                                    orgTreeView,
                                    arValue.map((ar) => ar.id ?? -1),
                                );
                            break;
                        }
                    }
                }
            });
        }
        return updated;
    },
);

export const getLoginData = createSelector(getAdministrationSlice, (adminSlice) => adminSlice.loginsData);
export const getLoginsStateProps = createSelector(getAdministrationSlice, (adminSlice) => adminSlice.loginsStateProps);
export const getLoginsStateAccordions = createSelector(
    getLoginsStateProps,
    (loginStateProps) => loginStateProps.accordionProps,
);
export const getActiveTab = createSelector(
    getLoginsStateAccordions,
    (accordions) => accordions.oneTimePassAccordion.activeTab,
);

export const getFileImportCustomerId = createSelector(
    getUserContext,
    getActivePowerbarSearch,
    (userContext, activePowerbarSearch) =>
        activePowerbarSearch?.customerId ? activePowerbarSearch.customerId : userContext?.userAccount?.customerId,
);

// Selector to get the list of all companies
export const selectLoggedInUserCompanies = createSelector(
    getAdministrationSlice,
    (adminSlice) => adminSlice.loggedInUserCompanies,
);
