import React from "react";
import * as TooltipRadix from "@radix-ui/react-tooltip";

interface TooltipType {
    children: React.ReactNode;
    content: React.ReactNode;
    open?: boolean;
    defaultOpen?: boolean;
    onOpenChange?: (open: boolean) => void;
    [x: string]: any;
}

const Tooltip = ({ children, content }: TooltipType) => {
    return (
        <TooltipRadix.Provider>
            <TooltipRadix.Root>
                <TooltipRadix.Trigger asChild>{children}</TooltipRadix.Trigger>
                <TooltipRadix.Portal>
                    <TooltipRadix.Content
                        className="select-none rounded-[4px] bg-black px-[15px] py-[10px] text-[15px] leading-none text-white shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] will-change-[transform,opacity] data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade"
                        sideOffset={0}
                    >
                        {content}
                        <TooltipRadix.Arrow className="fill-black" />
                    </TooltipRadix.Content>
                </TooltipRadix.Portal>
            </TooltipRadix.Root>
        </TooltipRadix.Provider>
    );
};

export default Tooltip;
