import Icon from "components/Icon";
import { useElementClicked } from "hooks";
import { debounce } from "lodash";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TailSpin } from "react-loader-spinner";
import { twMerge } from "tailwind-merge";
import Search from "../Search/Search";
import { showDataWithSearch } from "./utils";

export interface ISelectValue {
    description: string;
    value: string;
    icon?: React.ReactNode;
    nonTranslatedDesc?: string;
    disabled?: boolean;
}

export interface ISelectProps {
    options: ISelectValue[];
    onChange: (newValue: ISelectValue) => void;
    selectedValue?: ISelectValue;
    containerClassName?: string;
    className?: string;
    placeholder?: string;
    translationKey?: string;
    isLoading?: boolean;
    disabled?: boolean;
}
const SelectWithSearchParams = ({
    options,
    onChange,
    selectedValue,
    containerClassName,
    className,
    placeholder,
    translationKey,
    isLoading,
    disabled,
}: ISelectProps) => {
    const [open, setOpen] = useState(false);
    const [searchParams, setSearchParams] = useState("");
    const ref = useElementClicked((v) => {
        if (!disabled) setOpen(v);
    });

    const { t } = useTranslation(translationKey);

    // Filter options based on searchParams
    const filteredOptions = useMemo(() => {
        if (searchParams) {
            return showDataWithSearch(searchParams, options);
        }
        return options; // Return all options if no searchParams
    }, [options, searchParams]);

    const getDescription = useMemo(
        () => selectedValue?.description || t(selectedValue?.nonTranslatedDesc!),
        [t, selectedValue],
    );

    const onSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setSearchParams(event.target.value);
    }, []);

    const DebounceSearch = useMemo(() => {
        const debounceSearchQuery = debounce(onSearch, 100); // Add a delay for debouncing
        return debounceSearchQuery;
    }, [onSearch]);

    const SearchContainer = useMemo(
        () => <Search onChange={DebounceSearch} placeholder="Search..." className="text-md bg-white py-2" />,
        [DebounceSearch],
    );
    return (
        <div ref={ref} className={twMerge("relative h-auto w-full lg:px-0", containerClassName)}>
            <button
                className={twMerge(
                    "h-12 w-full border bg-pageBg",
                    disabled
                        ? "cursor-not-allowed border-mhgrey-medium text-mhgrey-medium"
                        : "cursor-pointer border-mhdarkgreen-original",
                    className,
                )}
            >
                <div className="flex w-full items-center">
                    {selectedValue?.icon && <div className="ml-3">{selectedValue.icon}</div>}
                    <p data-testid="selectedValue" className="w-full pl-2 text-left">
                        {selectedValue?.value ? getDescription : placeholder}
                    </p>
                    <div className="mx-3 flex">
                        <Icon icon={open ? "caretUp" : "caretDown"} size={14} />
                    </div>
                    {isLoading && (
                        <div className="absolute left-[250px] flex">
                            <TailSpin color="#3b3b36" height={20} width={20} />
                        </div>
                    )}
                </div>
            </button>
            {open && (
                <div className="absolute z-10 w-full">
                    <div className="flex flex-col bg-white shadow-xl">
                        {SearchContainer}
                        {filteredOptions.map((companies, i) => (
                            <button
                                key={companies.nonTranslatedDesc + i.toString()}
                                type="button"
                                className={twMerge(
                                    "flex items-center px-3 py-2 hover:bg-grey-100",
                                    companies.disabled && "opacity-50",
                                )}
                                disabled={companies.disabled}
                                onClick={() => {
                                    onChange(companies);
                                    setOpen(false);
                                }}
                            >
                                <div key={companies.nonTranslatedDesc}>{companies.icon}</div>
                                <p className="ml-2 text-left">
                                    {companies.description || t(companies.nonTranslatedDesc!)}
                                </p>
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};
export default SelectWithSearchParams;
