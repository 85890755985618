import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import MainWrapper from "components/MainWrapper";
import { SpinnerPage } from "components/Spinner";
import { actions as absenceReportsActions } from "pages/Absence/pages/absencereporting/redux";
import { getServices } from "./api/services";
import * as UserSelectors from "store/userSelectors";
import { useTranslation } from "react-i18next";
import { getConvertedAbsenceCause } from "./utils/postToPrincess";
import useEventListener from "@use-it/event-listener";
import { Config } from "config";
import { getUserRetailer } from "store/userSelectors";
import { getIsAuthByLogin } from "./pages/login/redux/loginSelectors";
import HelpMenu from "components/HelpMenu";
import { capitalize, isUndefined } from "lodash/fp";
import { userActions } from "store/userSlice";
import SessionModal from "pages/login/components/SessionModal";
import useOrganizationLogin from "hooks/useOrganizationLogin";
import { actions } from "store/route";
import { Status } from "store/userStateTypes";
import { setDefaultOptions } from "date-fns/setDefaultOptions";
import { getDateLocale } from "utils/date";
import FortnoxWelcomeWizard from "components/FortnoxWelcomeWizard";
import AuthenticatedRoutes from "components/AuthenticatedRoutes";
import UnAutheticatedRoutes from "components/UnAutheticatedRoutes";

export const App = () => {
    const isAuthByLogin = useAppSelector(getIsAuthByLogin);
    const dispatch = useAppDispatch();
    const isAuth = useAppSelector(UserSelectors.isAuth);
    const token = useAppSelector(UserSelectors.token);
    const userStatus = useAppSelector(UserSelectors.getUserStatus);
    const ableToAccess = useAppSelector(UserSelectors.ableToAccess);
    const assumedUser = useAppSelector(UserSelectors.getUserAssume);
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const context = useAppSelector(getUserRetailer);
    const userPrefLanguage = useAppSelector(UserSelectors.getUserLanguage);
    const hasFortnoxProduct = useAppSelector(UserSelectors.getHasFortnoxProduct);
    const IsAuthAndServiceReady = () => {
        const isServiceDefined = getServices && getServices();
        return !isUndefined(isServiceDefined) && isAuth;
    };
    const { pathname } = useLocation();
    useOrganizationLogin(setIsLoading);
    const [searchParams] = useSearchParams();
    const returnUrl = searchParams.get("returnUrl") ? searchParams.get("returnUrl") : undefined;

    useEffect(() => {
        const allowedReturnDomains = ["medhelp.se"];
        // Important to have this useEffect for Nurse/SSK flow.
        // Should be able to redirect them anywhere
        if (isAuth && returnUrl && returnUrl !== "") {
            // TODO: Migrate this logic to listen use returnUrl from backend instead of using query param value
            // Logic to block open redirects
            const domain = new URL(returnUrl);
            if (allowedReturnDomains.indexOf(domain.hostname) === -1) {
                console.warn("Open redirect blocked");
                return;
            }
            const redirectUrl = returnUrl.startsWith("http") ? returnUrl : `https://${returnUrl}`;
            location.href = redirectUrl;
        }
    }, [isAuth, returnUrl]);

    useEffect(() => {
        dispatch(actions.changeRoute(pathname));
    }, [pathname, dispatch]);
    useEffect(() => {
        if (isAuthByLogin) {
            dispatch(userActions.signIn());
        }
    }, [dispatch, isAuthByLogin]);

    useEffect(() => {
        if (userPrefLanguage && getServices() && userStatus === Status.OK) {
            if (!ableToAccess) {
                window.location.href = Config.web_oldAbsenceReporting_url;
            }
            i18n.changeLanguage(userPrefLanguage);
            setDefaultOptions({ locale: getDateLocale(userPrefLanguage) });
            setIsLoading(false);
        }
    }, [userPrefLanguage, i18n, ableToAccess, navigate, userStatus]);

    useEffect(() => {
        if (window && window.location !== window.parent.location) {
            const message = {
                type: "redirect",
                eventData: {
                    url: window.location.origin,
                },
            };
            window.parent.postMessage(message, Config.princessDomain!);
        }
    }, []);

    useEventListener(
        "message",
        async (event: any) => {
            const { data } = event;
            switch (data.type) {
                case "childSsn": {
                    dispatch(absenceReportsActions.updatePrincessChildSsn(data.eventData.ssn));
                    break;
                }
                case "contactReasonChange": {
                    const res = await getConvertedAbsenceCause(data.eventData.id, token!);
                    dispatch(absenceReportsActions.updatePrincessContactReasonChange(res.label.toString()));
                    break;
                }
                case "notetoemployer": {
                    dispatch(absenceReportsActions.updatePrincessNoteToEmployer(data.eventData.ssn));
                    break;
                }
                default:
                    break;
            }
        },
        window,
    );

    if (isLoading) return <SpinnerPage />;

    if (pathname) {
        const queryItems = pathname.split("/", 2);
        const lowerCaseQuery = queryItems[1].toLowerCase();
        if (["frisklinjen", "avonova", "falck"].includes(lowerCaseQuery)) {
            const queryContext = lowerCaseQuery === "frisklinjen" ? "Feelgood" : capitalize(lowerCaseQuery);
            dispatch(userActions.setUserRetailer(queryContext));
        }
    }

    const removeIcons = (iconName: string) => {
        const icons = document.getElementsByClassName(iconName);
        for (let i = 0; i < icons.length; i++) {
            icons[i].remove();
        }
    };

    const changeMetaThemeColor = (color: string) => {
        const metaThemeColor = window.document.querySelector("meta[name=theme-color]");
        if (!metaThemeColor) return;
        metaThemeColor.setAttribute("content", color);
    };

    const getRetailer = (): string => {
        if (context === "Avonova") {
            removeIcons("medhelpIcon");
            removeIcons("frisklinjenIcon");
            removeIcons("falckIcon");
            changeMetaThemeColor("#FAF7F6");
            return context;
        } else if (context === "Feelgood") {
            removeIcons("medhelpIcon");
            removeIcons("avonovaIcon");
            removeIcons("falckIcon");
            changeMetaThemeColor("#052651");
            return context;
        } else if (context === "Falck") {
            removeIcons("medhelpIcon");
            removeIcons("avonovaIcon");
            removeIcons("frisklinjenIcon");
            changeMetaThemeColor("#F0F0F0");
            return context;
        } else {
            removeIcons("avonovaIcon");
            removeIcons("frisklinjenIcon");
            removeIcons("falckIcon");
            changeMetaThemeColor("#192D05");
            return "Medhelp";
        }
    };

    return (
        <div className={`${getRetailer()} h-full ${assumedUser ? "border-8 border-red-500" : ""}`}>
            {IsAuthAndServiceReady() && !isLoading ? (
                <div>
                    <HelpMenu />
                    <SessionModal activeRetailer={context} />
                    {hasFortnoxProduct && <FortnoxWelcomeWizard />}
                    <MainWrapper>
                        <AuthenticatedRoutes />
                    </MainWrapper>
                </div>
            ) : (
                <div className="flex h-screen overflow-auto bg-pageBg">
                    <UnAutheticatedRoutes isAuth={isAuth} />
                </div>
            )}
        </div>
    );
};
