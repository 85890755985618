import { customerCompanyBaseApi as api } from "./customerCompanyBaseApi";
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getApiCompanyDataProcessingGetViewData: build.query<
            GetApiCompanyDataProcessingGetViewDataApiResponse,
            GetApiCompanyDataProcessingGetViewDataApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CompanyDataProcessing/GetViewData`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiCompanyDataProcessingGetImportCompanyStatus: build.query<
            GetApiCompanyDataProcessingGetImportCompanyStatusApiResponse,
            GetApiCompanyDataProcessingGetImportCompanyStatusApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CompanyDataProcessing/GetImportCompanyStatus`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        postApiCompanyDataProcessingValidateEmploymentImportRows: build.mutation<
            PostApiCompanyDataProcessingValidateEmploymentImportRowsApiResponse,
            PostApiCompanyDataProcessingValidateEmploymentImportRowsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CompanyDataProcessing/ValidateEmploymentImportRows`,
                method: "POST",
                body: queryArg.validateEmploymentImportRowsPostModel,
            }),
        }),
        postApiCompanyDataProcessingImportEmploymentRows: build.mutation<
            PostApiCompanyDataProcessingImportEmploymentRowsApiResponse,
            PostApiCompanyDataProcessingImportEmploymentRowsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CompanyDataProcessing/ImportEmploymentRows`,
                method: "POST",
                body: queryArg.validateEmploymentImportRowsPostModel,
            }),
        }),
        postApiCompanyDataProcessingRetryImportRows: build.mutation<
            PostApiCompanyDataProcessingRetryImportRowsApiResponse,
            PostApiCompanyDataProcessingRetryImportRowsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CompanyDataProcessing/RetryImportRows`,
                method: "POST",
                body: queryArg.retryImportRowsPostModel,
            }),
        }),
        getApiCustomerCompanyGetCompanyById: build.query<
            GetApiCustomerCompanyGetCompanyByIdApiResponse,
            GetApiCustomerCompanyGetCompanyByIdApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/GetCompanyById`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiCustomerCompanyGetCompanies: build.query<
            GetApiCustomerCompanyGetCompaniesApiResponse,
            GetApiCustomerCompanyGetCompaniesApiArg
        >({
            query: () => ({ url: `/api/CustomerCompany/GetCompanies` }),
        }),
        getApiCustomerCompanyGetCustomerSupportCompanies: build.query<
            GetApiCustomerCompanyGetCustomerSupportCompaniesApiResponse,
            GetApiCustomerCompanyGetCustomerSupportCompaniesApiArg
        >({
            query: () => ({
                url: `/api/CustomerCompany/GetCustomerSupportCompanies`,
            }),
        }),
        postApiCustomerCompanyGetCompaniesForUser: build.mutation<
            PostApiCustomerCompanyGetCompaniesForUserApiResponse,
            PostApiCustomerCompanyGetCompaniesForUserApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/GetCompaniesForUser`,
                method: "POST",
                body: queryArg.companyFilter,
            }),
        }),
        getApiCustomerCompanyGetCompanyRecursiveAccessRights: build.query<
            GetApiCustomerCompanyGetCompanyRecursiveAccessRightsApiResponse,
            GetApiCustomerCompanyGetCompanyRecursiveAccessRightsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/GetCompanyRecursiveAccessRights`,
                params: {
                    companyId: queryArg.companyId,
                    departmentId: queryArg.departmentId,
                },
            }),
        }),
        getApiCustomerCompanyGetStagedImportsCompanies: build.query<
            GetApiCustomerCompanyGetStagedImportsCompaniesApiResponse,
            GetApiCustomerCompanyGetStagedImportsCompaniesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/GetStagedImportsCompanies`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiCustomerCompanyGetOrganizationTreeView: build.query<
            GetApiCustomerCompanyGetOrganizationTreeViewApiResponse,
            GetApiCustomerCompanyGetOrganizationTreeViewApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/GetOrganizationTreeView`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiCustomerCompanyGetOrganizationDepartmentsEvents: build.query<
            GetApiCustomerCompanyGetOrganizationDepartmentsEventsApiResponse,
            GetApiCustomerCompanyGetOrganizationDepartmentsEventsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/GetOrganizationDepartmentsEvents`,
                params: {
                    companyId: queryArg.companyId,
                    pageSize: queryArg.pageSize,
                    pageNumber: queryArg.pageNumber,
                },
            }),
        }),
        getApiCustomerCompanyGetEmploymentGroups: build.query<
            GetApiCustomerCompanyGetEmploymentGroupsApiResponse,
            GetApiCustomerCompanyGetEmploymentGroupsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/GetEmploymentGroups`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        postApiCustomerCompanySaveDepartment: build.mutation<
            PostApiCustomerCompanySaveDepartmentApiResponse,
            PostApiCustomerCompanySaveDepartmentApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/SaveDepartment`,
                method: "POST",
                body: queryArg.department,
            }),
        }),
        deleteApiCustomerCompanyDeleteDepartment: build.mutation<
            DeleteApiCustomerCompanyDeleteDepartmentApiResponse,
            DeleteApiCustomerCompanyDeleteDepartmentApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/DeleteDepartment`,
                method: "DELETE",
                params: {
                    departmentId: queryArg.departmentId,
                },
            }),
        }),
        getApiCustomerCompanyGetEmploymentsForDepartmentId: build.query<
            GetApiCustomerCompanyGetEmploymentsForDepartmentIdApiResponse,
            GetApiCustomerCompanyGetEmploymentsForDepartmentIdApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/GetEmploymentsForDepartmentId`,
                params: {
                    departmentId: queryArg.departmentId,
                    type: queryArg["type"],
                },
            }),
        }),
        getApiCustomerCompanyGetUsersWithoutEmploymentForCompanyId: build.query<
            GetApiCustomerCompanyGetUsersWithoutEmploymentForCompanyIdApiResponse,
            GetApiCustomerCompanyGetUsersWithoutEmploymentForCompanyIdApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/GetUsersWithoutEmploymentForCompanyId`,
                params: {
                    companyId: queryArg.companyId,
                    otherCompanies: queryArg.otherCompanies,
                },
            }),
        }),
        postApiCustomerCompanyExportAllCompanyUsersAndEmployees: build.mutation<
            PostApiCustomerCompanyExportAllCompanyUsersAndEmployeesApiResponse,
            PostApiCustomerCompanyExportAllCompanyUsersAndEmployeesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/ExportAllCompanyUsersAndEmployees`,
                method: "POST",
                body: queryArg.exportAllCompanyUsersAndEmployeesModel,
            }),
        }),
        postApiCustomerCompanyExportDepartmentsToExcel: build.mutation<
            PostApiCustomerCompanyExportDepartmentsToExcelApiResponse,
            PostApiCustomerCompanyExportDepartmentsToExcelApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/ExportDepartmentsToExcel`,
                method: "POST",
                body: queryArg.exportAllDepartmentEmployeesModel,
            }),
        }),
        postApiCustomerCompanyExportAllDepartmentEmployees: build.mutation<
            PostApiCustomerCompanyExportAllDepartmentEmployeesApiResponse,
            PostApiCustomerCompanyExportAllDepartmentEmployeesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/ExportAllDepartmentEmployees`,
                method: "POST",
                body: queryArg.exportAllDepartmentEmployeesModel,
            }),
        }),
        getApiCustomerCompanyGetDepartmentInfo: build.query<
            GetApiCustomerCompanyGetDepartmentInfoApiResponse,
            GetApiCustomerCompanyGetDepartmentInfoApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/GetDepartmentInfo`,
                params: {
                    departmentId: queryArg.departmentId,
                },
            }),
        }),
        getApiCustomerCompanyGetDepartmentsCostCenterForCompany: build.query<
            GetApiCustomerCompanyGetDepartmentsCostCenterForCompanyApiResponse,
            GetApiCustomerCompanyGetDepartmentsCostCenterForCompanyApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/GetDepartmentsCostCenterForCompany`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiCustomerCompanyGetRemovedEmployments: build.query<
            GetApiCustomerCompanyGetRemovedEmploymentsApiResponse,
            GetApiCustomerCompanyGetRemovedEmploymentsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/GetRemovedEmployments`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        postApiCustomerCompanyDeleteRemovedEmployments: build.mutation<
            PostApiCustomerCompanyDeleteRemovedEmploymentsApiResponse,
            PostApiCustomerCompanyDeleteRemovedEmploymentsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/DeleteRemovedEmployments`,
                method: "POST",
                body: queryArg.body,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        postApiCustomerCompanyUpdateRemovedEmployments: build.mutation<
            PostApiCustomerCompanyUpdateRemovedEmploymentsApiResponse,
            PostApiCustomerCompanyUpdateRemovedEmploymentsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/UpdateRemovedEmployments`,
                method: "POST",
                body: queryArg.updateSecurityDeleteUserModel,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiCustomerCompanyGetEmploymentFromCompanyAndUserAccountId: build.query<
            GetApiCustomerCompanyGetEmploymentFromCompanyAndUserAccountIdApiResponse,
            GetApiCustomerCompanyGetEmploymentFromCompanyAndUserAccountIdApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/GetEmploymentFromCompanyAndUserAccountId`,
                params: {
                    companyId: queryArg.companyId,
                    userAccountId: queryArg.userAccountId,
                },
            }),
        }),
        getApiCustomerCompanyGetCompanyInfo: build.query<
            GetApiCustomerCompanyGetCompanyInfoApiResponse,
            GetApiCustomerCompanyGetCompanyInfoApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/GetCompanyInfo`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        postApiCustomerCompanySearchCompanyGroupUser: build.mutation<
            PostApiCustomerCompanySearchCompanyGroupUserApiResponse,
            PostApiCustomerCompanySearchCompanyGroupUserApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/SearchCompanyGroupUser`,
                method: "POST",
                body: queryArg.searchCompanyGroupUserModel,
            }),
        }),
        postApiCustomerCompanySearchCompanyGroupUserAndDepartment: build.mutation<
            PostApiCustomerCompanySearchCompanyGroupUserAndDepartmentApiResponse,
            PostApiCustomerCompanySearchCompanyGroupUserAndDepartmentApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/SearchCompanyGroupUserAndDepartment`,
                method: "POST",
                body: queryArg.searchCompanyGroupUserModel,
            }),
        }),
        postApiCustomerCompanySaveCostCenter: build.mutation<
            PostApiCustomerCompanySaveCostCenterApiResponse,
            PostApiCustomerCompanySaveCostCenterApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/SaveCostCenter`,
                method: "POST",
                body: queryArg.costCenter,
            }),
        }),
        postApiCustomerCompanyDeleteCostCenter: build.mutation<
            PostApiCustomerCompanyDeleteCostCenterApiResponse,
            PostApiCustomerCompanyDeleteCostCenterApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/DeleteCostCenter`,
                method: "POST",
                body: queryArg.costCenter,
            }),
        }),
        getApiCustomerCompanyGetCostCenter: build.query<
            GetApiCustomerCompanyGetCostCenterApiResponse,
            GetApiCustomerCompanyGetCostCenterApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/GetCostCenter`,
                params: {
                    companyId: queryArg.companyId,
                    code: queryArg.code,
                },
            }),
        }),
        getApiCustomerCompanyGetCostCentersForCompany: build.query<
            GetApiCustomerCompanyGetCostCentersForCompanyApiResponse,
            GetApiCustomerCompanyGetCostCentersForCompanyApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/GetCostCentersForCompany`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiCustomerCompanyGetCostCentersForCompanyFast: build.query<
            GetApiCustomerCompanyGetCostCentersForCompanyFastApiResponse,
            GetApiCustomerCompanyGetCostCentersForCompanyFastApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/GetCostCentersForCompanyFast`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiCustomerCompanyGetAbsenceBoardRehabilitationServiceContracts: build.query<
            GetApiCustomerCompanyGetAbsenceBoardRehabilitationServiceContractsApiResponse,
            GetApiCustomerCompanyGetAbsenceBoardRehabilitationServiceContractsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/GetAbsenceBoardRehabilitationServiceContracts`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiCustomerCompanyGetAbsenceBoardRehabilitationServiceContractsWithCustomerId: build.query<
            GetApiCustomerCompanyGetAbsenceBoardRehabilitationServiceContractsWithCustomerIdApiResponse,
            GetApiCustomerCompanyGetAbsenceBoardRehabilitationServiceContractsWithCustomerIdApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/GetAbsenceBoardRehabilitationServiceContractsWithCustomerId`,
                params: {
                    companyId: queryArg.companyId,
                    customerId: queryArg.customerId,
                },
            }),
        }),
        getApiCustomerCompanyGetEmployeeErasedEvents: build.query<
            GetApiCustomerCompanyGetEmployeeErasedEventsApiResponse,
            GetApiCustomerCompanyGetEmployeeErasedEventsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/GetEmployeeErasedEvents`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiCustomerCompanyGetRehabAndAbsenceErasedEvents: build.query<
            GetApiCustomerCompanyGetRehabAndAbsenceErasedEventsApiResponse,
            GetApiCustomerCompanyGetRehabAndAbsenceErasedEventsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/GetRehabAndAbsenceErasedEvents`,
                params: {
                    companyId: queryArg.companyId,
                    type: queryArg["type"],
                },
            }),
        }),
        getApiCustomerCompanyGetCompanyDataErasureConfiguration: build.query<
            GetApiCustomerCompanyGetCompanyDataErasureConfigurationApiResponse,
            GetApiCustomerCompanyGetCompanyDataErasureConfigurationApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/GetCompanyDataErasureConfiguration`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        postApiCustomerCompanySaveCompanySubscriptionDefault: build.mutation<
            PostApiCustomerCompanySaveCompanySubscriptionDefaultApiResponse,
            PostApiCustomerCompanySaveCompanySubscriptionDefaultApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/SaveCompanySubscriptionDefault`,
                method: "POST",
                body: queryArg.importSubscriptionDefault,
            }),
        }),
        postApiCustomerCompanyDeleteCompanySubscriptionDefault: build.mutation<
            PostApiCustomerCompanyDeleteCompanySubscriptionDefaultApiResponse,
            PostApiCustomerCompanyDeleteCompanySubscriptionDefaultApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/DeleteCompanySubscriptionDefault`,
                method: "POST",
                params: {
                    companyId: queryArg.companyId,
                    id: queryArg.id,
                },
            }),
        }),
        getApiCustomerCompanyGetCompanyImportSubscriptionDefaults: build.query<
            GetApiCustomerCompanyGetCompanyImportSubscriptionDefaultsApiResponse,
            GetApiCustomerCompanyGetCompanyImportSubscriptionDefaultsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/GetCompanyImportSubscriptionDefaults`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        postApiCustomerCompanySaveCompanyDataErasureConfiguration: build.mutation<
            PostApiCustomerCompanySaveCompanyDataErasureConfigurationApiResponse,
            PostApiCustomerCompanySaveCompanyDataErasureConfigurationApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/SaveCompanyDataErasureConfiguration`,
                method: "POST",
                body: queryArg.companyDataErasureConfigurationPostModel,
            }),
        }),
        getApiCustomerCompanyGetCustomerBillingConfiguration: build.query<
            GetApiCustomerCompanyGetCustomerBillingConfigurationApiResponse,
            GetApiCustomerCompanyGetCustomerBillingConfigurationApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/GetCustomerBillingConfiguration`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiCustomerCompanyGetEmployeeCount: build.query<
            GetApiCustomerCompanyGetEmployeeCountApiResponse,
            GetApiCustomerCompanyGetEmployeeCountApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/GetEmployeeCount`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiCustomerCompanyGetCustomerProductByCompanyId: build.query<
            GetApiCustomerCompanyGetCustomerProductByCompanyIdApiResponse,
            GetApiCustomerCompanyGetCustomerProductByCompanyIdApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerCompany/GetCustomerProductByCompanyId`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        postApiCustomerSupportSearchEmployments: build.mutation<
            PostApiCustomerSupportSearchEmploymentsApiResponse,
            PostApiCustomerSupportSearchEmploymentsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerSupport/SearchEmployments`,
                method: "POST",
                body: queryArg.searchViewModel,
            }),
        }),
        postApiCustomerSupportSearchCompanies: build.mutation<
            PostApiCustomerSupportSearchCompaniesApiResponse,
            PostApiCustomerSupportSearchCompaniesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerSupport/SearchCompanies`,
                method: "POST",
                body: queryArg.searchViewModel,
            }),
        }),
        postApiCustomerSupportSearchCompanyUserByTelephoneNumber: build.mutation<
            PostApiCustomerSupportSearchCompanyUserByTelephoneNumberApiResponse,
            PostApiCustomerSupportSearchCompanyUserByTelephoneNumberApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerSupport/SearchCompanyUserByTelephoneNumber`,
                method: "POST",
                body: queryArg.searchViewModel,
            }),
        }),
        postApiCustomerSupportSearchCompanyUserByEmail: build.mutation<
            PostApiCustomerSupportSearchCompanyUserByEmailApiResponse,
            PostApiCustomerSupportSearchCompanyUserByEmailApiArg
        >({
            query: (queryArg) => ({
                url: `/api/CustomerSupport/SearchCompanyUserByEmail`,
                method: "POST",
                body: queryArg.searchViewModel,
            }),
        }),
        getApiDataAnonymizationAbsence: build.query<
            GetApiDataAnonymizationAbsenceApiResponse,
            GetApiDataAnonymizationAbsenceApiArg
        >({
            query: (queryArg) => ({
                url: `/api/DataAnonymization/Absence`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        deleteApiDataAnonymizationAbsence: build.mutation<
            DeleteApiDataAnonymizationAbsenceApiResponse,
            DeleteApiDataAnonymizationAbsenceApiArg
        >({
            query: (queryArg) => ({
                url: `/api/DataAnonymization/Absence`,
                method: "DELETE",
                body: queryArg.employments,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiDataAnonymizationMedicalCertificate: build.query<
            GetApiDataAnonymizationMedicalCertificateApiResponse,
            GetApiDataAnonymizationMedicalCertificateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/DataAnonymization/MedicalCertificate`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        deleteApiDataAnonymizationMedicalCertificate: build.mutation<
            DeleteApiDataAnonymizationMedicalCertificateApiResponse,
            DeleteApiDataAnonymizationMedicalCertificateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/DataAnonymization/MedicalCertificate`,
                method: "DELETE",
                body: queryArg.employments,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiDataAnonymizationRehab: build.query<
            GetApiDataAnonymizationRehabApiResponse,
            GetApiDataAnonymizationRehabApiArg
        >({
            query: (queryArg) => ({
                url: `/api/DataAnonymization/Rehab`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        deleteApiDataAnonymizationRehab: build.mutation<
            DeleteApiDataAnonymizationRehabApiResponse,
            DeleteApiDataAnonymizationRehabApiArg
        >({
            query: (queryArg) => ({
                url: `/api/DataAnonymization/Rehab`,
                method: "DELETE",
                body: queryArg.employments,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiDataAnonymizationGetCompanyDataErasureAvailableConfiguration: build.query<
            GetApiDataAnonymizationGetCompanyDataErasureAvailableConfigurationApiResponse,
            GetApiDataAnonymizationGetCompanyDataErasureAvailableConfigurationApiArg
        >({
            query: (queryArg) => ({
                url: `/api/DataAnonymization/GetCompanyDataErasureAvailableConfiguration`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        postApiDataAnonymizationSaveCompanyDataErasureAvailableConfiguration: build.mutation<
            PostApiDataAnonymizationSaveCompanyDataErasureAvailableConfigurationApiResponse,
            PostApiDataAnonymizationSaveCompanyDataErasureAvailableConfigurationApiArg
        >({
            query: (queryArg) => ({
                url: `/api/DataAnonymization/SaveCompanyDataErasureAvailableConfiguration`,
                method: "POST",
                body: queryArg.companyDataErasureAvailableConfiguration,
            }),
        }),
        getApiEntityCopy: build.query<GetApiEntityCopyApiResponse, GetApiEntityCopyApiArg>({
            query: (queryArg) => ({
                url: `/api/EntityCopy`,
                params: {
                    companyIds: queryArg.companyIds,
                },
            }),
        }),
        postApiEntityCopy: build.mutation<PostApiEntityCopyApiResponse, PostApiEntityCopyApiArg>({
            query: (queryArg) => ({
                url: `/api/EntityCopy`,
                method: "POST",
                body: queryArg.copyEntityList,
            }),
        }),
        getApiFileImportStatusGetLastImportRowChangeEvents: build.query<
            GetApiFileImportStatusGetLastImportRowChangeEventsApiResponse,
            GetApiFileImportStatusGetLastImportRowChangeEventsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/FileImportStatus/GetLastImportRowChangeEvents`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        postApiFileImportStatusChangeAutoImport: build.mutation<
            PostApiFileImportStatusChangeAutoImportApiResponse,
            PostApiFileImportStatusChangeAutoImportApiArg
        >({
            query: (queryArg) => ({
                url: `/api/FileImportStatus/ChangeAutoImport`,
                method: "POST",
                body: queryArg.changeAutoImportPostModel,
            }),
        }),
        getApiFileImportStatusGetAutoCompleteStatus: build.query<
            GetApiFileImportStatusGetAutoCompleteStatusApiResponse,
            GetApiFileImportStatusGetAutoCompleteStatusApiArg
        >({
            query: (queryArg) => ({
                url: `/api/FileImportStatus/GetAutoCompleteStatus`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        postApiFileImportStatusStartManualMerge: build.mutation<
            PostApiFileImportStatusStartManualMergeApiResponse,
            PostApiFileImportStatusStartManualMergeApiArg
        >({
            query: (queryArg) => ({
                url: `/api/FileImportStatus/StartManualMerge`,
                method: "POST",
                body: queryArg.startManualMergePostModel,
            }),
        }),
        postApiFileImportStatusDownloadFileOnHold: build.mutation<
            PostApiFileImportStatusDownloadFileOnHoldApiResponse,
            PostApiFileImportStatusDownloadFileOnHoldApiArg
        >({
            query: (queryArg) => ({
                url: `/api/FileImportStatus/DownloadFileOnHold`,
                method: "POST",
                body: queryArg.importedFileOnHold,
            }),
        }),
        getApiFileImportStatusGetCompanyProgress: build.query<
            GetApiFileImportStatusGetCompanyProgressApiResponse,
            GetApiFileImportStatusGetCompanyProgressApiArg
        >({
            query: (queryArg) => ({
                url: `/api/FileImportStatus/GetCompanyProgress`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiFileImportStatusGetStageInfo: build.query<
            GetApiFileImportStatusGetStageInfoApiResponse,
            GetApiFileImportStatusGetStageInfoApiArg
        >({
            query: (queryArg) => ({
                url: `/api/FileImportStatus/GetStageInfo`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiFileImportStatusCheckFileImportConfiguration: build.query<
            GetApiFileImportStatusCheckFileImportConfigurationApiResponse,
            GetApiFileImportStatusCheckFileImportConfigurationApiArg
        >({
            query: (queryArg) => ({
                url: `/api/FileImportStatus/CheckFileImportConfiguration`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        postApiStagingGetStagedChanges: build.mutation<
            PostApiStagingGetStagedChangesApiResponse,
            PostApiStagingGetStagedChangesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Staging/GetStagedChanges`,
                method: "POST",
                body: queryArg.stagedChangesFilter,
            }),
        }),
        postApiStagingGetLatestEventsDateForProvider: build.mutation<
            PostApiStagingGetLatestEventsDateForProviderApiResponse,
            PostApiStagingGetLatestEventsDateForProviderApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Staging/GetLatestEventsDateForProvider`,
                method: "POST",
                body: queryArg.stagedChangesFilter,
            }),
        }),
        postApiStagingDownloadFile: build.mutation<
            PostApiStagingDownloadFileApiResponse,
            PostApiStagingDownloadFileApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Staging/DownloadFile`,
                method: "POST",
                body: queryArg.importedFile,
            }),
        }),
        postApiStagingResolveImportErrors: build.mutation<
            PostApiStagingResolveImportErrorsApiResponse,
            PostApiStagingResolveImportErrorsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Staging/ResolveImportErrors`,
                method: "POST",
                body: queryArg.resolveImportErrorsPostModel,
            }),
        }),
        postApiStagingGetSkippedRows: build.mutation<
            PostApiStagingGetSkippedRowsApiResponse,
            PostApiStagingGetSkippedRowsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Staging/GetSkippedRows`,
                method: "POST",
                body: queryArg.skippedRowsPostModel,
            }),
        }),
        postApiStagingGetImportErrors: build.mutation<
            PostApiStagingGetImportErrorsApiResponse,
            PostApiStagingGetImportErrorsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Staging/GetImportErrors`,
                method: "POST",
                body: queryArg.importErrorsPostModel,
            }),
        }),
        postApiStagingGetImportedFiles: build.mutation<
            PostApiStagingGetImportedFilesApiResponse,
            PostApiStagingGetImportedFilesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Staging/GetImportedFiles`,
                method: "POST",
                body: queryArg.stagedChangesFilter,
            }),
        }),
        getApiStatus: build.query<GetApiStatusApiResponse, GetApiStatusApiArg>({
            query: () => ({ url: `/api/Status` }),
        }),
        getApiStatusGetStatusAll: build.query<GetApiStatusGetStatusAllApiResponse, GetApiStatusGetStatusAllApiArg>({
            query: () => ({ url: `/api/Status/GetStatusAll` }),
        }),
        getApiSubscriptionType: build.query<GetApiSubscriptionTypeApiResponse, GetApiSubscriptionTypeApiArg>({
            query: (queryArg) => ({
                url: `/api/SubscriptionType`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        putApiSubscriptionType: build.mutation<PutApiSubscriptionTypeApiResponse, PutApiSubscriptionTypeApiArg>({
            query: (queryArg) => ({
                url: `/api/SubscriptionType`,
                method: "PUT",
                body: queryArg.setDepartmentManagerChannelModel,
            }),
        }),
        postApiUserCreateUser: build.mutation<PostApiUserCreateUserApiResponse, PostApiUserCreateUserApiArg>({
            query: (queryArg) => ({
                url: `/api/User/CreateUser`,
                method: "POST",
                body: queryArg.createOrUpdateUserAccount,
            }),
        }),
        postApiUserCreateEmployment: build.mutation<
            PostApiUserCreateEmploymentApiResponse,
            PostApiUserCreateEmploymentApiArg
        >({
            query: (queryArg) => ({
                url: `/api/User/CreateEmployment`,
                method: "POST",
                body: queryArg.createOrUpdateEmployment,
            }),
        }),
        postApiUserCreateAccessRights: build.mutation<
            PostApiUserCreateAccessRightsApiResponse,
            PostApiUserCreateAccessRightsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/User/CreateAccessRights`,
                method: "POST",
                body: queryArg.createOrUpdateAccessRights,
            }),
        }),
        postApiUserCreateCompanyAccessRights: build.mutation<
            PostApiUserCreateCompanyAccessRightsApiResponse,
            PostApiUserCreateCompanyAccessRightsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/User/CreateCompanyAccessRights`,
                method: "POST",
                body: queryArg.createOrUpdateCompanyAccessRights,
            }),
        }),
        postApiUserCreateDepartmentAccessRights: build.mutation<
            PostApiUserCreateDepartmentAccessRightsApiResponse,
            PostApiUserCreateDepartmentAccessRightsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/User/CreateDepartmentAccessRights`,
                method: "POST",
                body: queryArg.createOrUpdateDepartmentsAccessRights,
            }),
        }),
        postApiUserUpdateEmployeeContactDetails: build.mutation<
            PostApiUserUpdateEmployeeContactDetailsApiResponse,
            PostApiUserUpdateEmployeeContactDetailsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/User/UpdateEmployeeContactDetails`,
                method: "POST",
                body: queryArg.contactInformation,
                params: {
                    userAccountId: queryArg.userAccountId,
                    companyId: queryArg.companyId,
                },
            }),
        }),
        postApiUserSaveContactInformation: build.mutation<
            PostApiUserSaveContactInformationApiResponse,
            PostApiUserSaveContactInformationApiArg
        >({
            query: (queryArg) => ({
                url: `/api/User/SaveContactInformation`,
                method: "POST",
                body: queryArg.createOrUpdateContactInformation,
            }),
        }),
        getApiUserSearchCompanyUserBySsn: build.query<
            GetApiUserSearchCompanyUserBySsnApiResponse,
            GetApiUserSearchCompanyUserBySsnApiArg
        >({
            query: (queryArg) => ({
                url: `/api/User/SearchCompanyUserBySsn`,
                params: {
                    countryCode: queryArg.countryCode,
                    companyId: queryArg.companyId,
                    searchString: queryArg.searchString,
                },
            }),
        }),
        postApiUserSearchCompanyGroupUserBySsn: build.mutation<
            PostApiUserSearchCompanyGroupUserBySsnApiResponse,
            PostApiUserSearchCompanyGroupUserBySsnApiArg
        >({
            query: (queryArg) => ({
                url: `/api/User/SearchCompanyGroupUserBySsn`,
                method: "POST",
                body: queryArg.searchCompanyGroupUserBySsn,
            }),
        }),
        getApiUserSearchEmploymentsByName: build.query<
            GetApiUserSearchEmploymentsByNameApiResponse,
            GetApiUserSearchEmploymentsByNameApiArg
        >({
            query: (queryArg) => ({
                url: `/api/User/SearchEmploymentsByName`,
                params: {
                    companyId: queryArg.companyId,
                    searchString: queryArg.searchString,
                },
            }),
        }),
        getApiUserSearchCompanyGroupUsersByName: build.query<
            GetApiUserSearchCompanyGroupUsersByNameApiResponse,
            GetApiUserSearchCompanyGroupUsersByNameApiArg
        >({
            query: (queryArg) => ({
                url: `/api/User/SearchCompanyGroupUsersByName`,
                params: {
                    companyId: queryArg.companyId,
                    searchString: queryArg.searchString,
                },
            }),
        }),
        getApiUserSearchCompanyUsersByName: build.query<
            GetApiUserSearchCompanyUsersByNameApiResponse,
            GetApiUserSearchCompanyUsersByNameApiArg
        >({
            query: (queryArg) => ({
                url: `/api/User/SearchCompanyUsersByName`,
                params: {
                    companyId: queryArg.companyId,
                    searchString: queryArg.searchString,
                },
            }),
        }),
        getApiUserGetAccessRightsFromUserAccountId: build.query<
            GetApiUserGetAccessRightsFromUserAccountIdApiResponse,
            GetApiUserGetAccessRightsFromUserAccountIdApiArg
        >({
            query: (queryArg) => ({
                url: `/api/User/GetAccessRightsFromUserAccountId`,
                params: {
                    userAccountId: queryArg.userAccountId,
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiUserGetAccessRightsForCompanyGroup: build.query<
            GetApiUserGetAccessRightsForCompanyGroupApiResponse,
            GetApiUserGetAccessRightsForCompanyGroupApiArg
        >({
            query: (queryArg) => ({
                url: `/api/User/GetAccessRightsForCompanyGroup`,
                params: {
                    userAccountId: queryArg.userAccountId,
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiUserGetUserAccountsAndAccessRightsByDepartmentId: build.query<
            GetApiUserGetUserAccountsAndAccessRightsByDepartmentIdApiResponse,
            GetApiUserGetUserAccountsAndAccessRightsByDepartmentIdApiArg
        >({
            query: (queryArg) => ({
                url: `/api/User/GetUserAccountsAndAccessRightsByDepartmentId`,
                params: {
                    departmentId: queryArg.departmentId,
                },
            }),
        }),
        getApiUserGetUserData: build.query<GetApiUserGetUserDataApiResponse, GetApiUserGetUserDataApiArg>({
            query: (queryArg) => ({
                url: `/api/User/GetUserData`,
                params: {
                    userAccountId: queryArg.userAccountId,
                    companyId: queryArg.companyId,
                    departmentId: queryArg.departmentId,
                },
            }),
        }),
        getApiUserGetUserAccountsAndAccessRightsByCompanyId: build.query<
            GetApiUserGetUserAccountsAndAccessRightsByCompanyIdApiResponse,
            GetApiUserGetUserAccountsAndAccessRightsByCompanyIdApiArg
        >({
            query: (queryArg) => ({
                url: `/api/User/GetUserAccountsAndAccessRightsByCompanyId`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiUserGetUserAccountIdFromUserId: build.query<
            GetApiUserGetUserAccountIdFromUserIdApiResponse,
            GetApiUserGetUserAccountIdFromUserIdApiArg
        >({
            query: (queryArg) => ({
                url: `/api/User/GetUserAccountIdFromUserId`,
                params: {
                    userId: queryArg.userId,
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiUserGetLoginCredentialsFromUserAccountId: build.query<
            GetApiUserGetLoginCredentialsFromUserAccountIdApiResponse,
            GetApiUserGetLoginCredentialsFromUserAccountIdApiArg
        >({
            query: (queryArg) => ({
                url: `/api/User/GetLoginCredentialsFromUserAccountId`,
                params: {
                    userAccountId: queryArg.userAccountId,
                },
            }),
        }),
        getApiUserGetAllLoginCredentials: build.query<
            GetApiUserGetAllLoginCredentialsApiResponse,
            GetApiUserGetAllLoginCredentialsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/User/GetAllLoginCredentials`,
                params: {
                    userAccountId: queryArg.userAccountId,
                },
            }),
        }),
        getApiUserGetCompanyUserFromUserAccountId: build.query<
            GetApiUserGetCompanyUserFromUserAccountIdApiResponse,
            GetApiUserGetCompanyUserFromUserAccountIdApiArg
        >({
            query: (queryArg) => ({
                url: `/api/User/GetCompanyUserFromUserAccountId`,
                params: {
                    userAccountId: queryArg.userAccountId,
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiUserGetCompanyUserFromUserAccountIdEdit: build.query<
            GetApiUserGetCompanyUserFromUserAccountIdEditApiResponse,
            GetApiUserGetCompanyUserFromUserAccountIdEditApiArg
        >({
            query: (queryArg) => ({
                url: `/api/User/GetCompanyUserFromUserAccountIdEdit`,
                params: {
                    userAccountId: queryArg.userAccountId,
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiUserGetCompanyUserFromUserAccountIdInCompanyGroup: build.query<
            GetApiUserGetCompanyUserFromUserAccountIdInCompanyGroupApiResponse,
            GetApiUserGetCompanyUserFromUserAccountIdInCompanyGroupApiArg
        >({
            query: (queryArg) => ({
                url: `/api/User/GetCompanyUserFromUserAccountIdInCompanyGroup`,
                params: {
                    userAccountId: queryArg.userAccountId,
                    companyId: queryArg.companyId,
                },
            }),
        }),
        postApiUserDeleteLoginCredential: build.mutation<
            PostApiUserDeleteLoginCredentialApiResponse,
            PostApiUserDeleteLoginCredentialApiArg
        >({
            query: (queryArg) => ({
                url: `/api/User/DeleteLoginCredential`,
                method: "POST",
                body: queryArg.loginCredentials,
            }),
        }),
        postApiUserSaveOtpLoginCredential: build.mutation<
            PostApiUserSaveOtpLoginCredentialApiResponse,
            PostApiUserSaveOtpLoginCredentialApiArg
        >({
            query: (queryArg) => ({
                url: `/api/User/SaveOtpLoginCredential`,
                method: "POST",
                body: queryArg.otpLoginCredential,
            }),
        }),
        postApiUserSaveLoginCredential: build.mutation<
            PostApiUserSaveLoginCredentialApiResponse,
            PostApiUserSaveLoginCredentialApiArg
        >({
            query: (queryArg) => ({
                url: `/api/User/SaveLoginCredential`,
                method: "POST",
                body: queryArg.loginCredentials,
            }),
        }),
        postApiUserChangeLoginCredentialsPassword: build.mutation<
            PostApiUserChangeLoginCredentialsPasswordApiResponse,
            PostApiUserChangeLoginCredentialsPasswordApiArg
        >({
            query: (queryArg) => ({
                url: `/api/User/ChangeLoginCredentialsPassword`,
                method: "POST",
                body: queryArg.passwordChange,
            }),
        }),
        getApiUserGetRemovedUsers: build.query<GetApiUserGetRemovedUsersApiResponse, GetApiUserGetRemovedUsersApiArg>({
            query: (queryArg) => ({
                url: `/api/User/GetRemovedUsers`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        postApiUserReactivateUserAccount: build.mutation<
            PostApiUserReactivateUserAccountApiResponse,
            PostApiUserReactivateUserAccountApiArg
        >({
            query: (queryArg) => ({
                url: `/api/User/ReactivateUserAccount`,
                method: "POST",
                body: queryArg.reactivateUserPostModel,
            }),
        }),
        getApiUserGetUnstartedUsers: build.query<
            GetApiUserGetUnstartedUsersApiResponse,
            GetApiUserGetUnstartedUsersApiArg
        >({
            query: (queryArg) => ({
                url: `/api/User/GetUnstartedUsers`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        postApiUserExportAllCompanyPermissions: build.mutation<
            PostApiUserExportAllCompanyPermissionsApiResponse,
            PostApiUserExportAllCompanyPermissionsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/User/ExportAllCompanyPermissions`,
                method: "POST",
                body: queryArg.exportAllCompanyPermissionsModel,
            }),
        }),
        postApiUserExportAllDepartmentPermissions: build.mutation<
            PostApiUserExportAllDepartmentPermissionsApiResponse,
            PostApiUserExportAllDepartmentPermissionsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/User/ExportAllDepartmentPermissions`,
                method: "POST",
                body: queryArg.exportAllDepartmentPermissionsModel,
            }),
        }),
        getApiUserGetLoginConfiguration: build.query<
            GetApiUserGetLoginConfigurationApiResponse,
            GetApiUserGetLoginConfigurationApiArg
        >({
            query: (queryArg) => ({
                url: `/api/User/GetLoginConfiguration`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiUserReserveUserAccountIds: build.query<
            GetApiUserReserveUserAccountIdsApiResponse,
            GetApiUserReserveUserAccountIdsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/User/ReserveUserAccountIds`,
                params: {
                    count: queryArg.count,
                },
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as customerCompanyApi };
export type GetApiCompanyDataProcessingGetViewDataApiResponse = unknown;
export type GetApiCompanyDataProcessingGetViewDataApiArg = {
    companyId?: number;
};
export type GetApiCompanyDataProcessingGetImportCompanyStatusApiResponse = unknown;
export type GetApiCompanyDataProcessingGetImportCompanyStatusApiArg = {
    companyId?: number;
};
export type PostApiCompanyDataProcessingValidateEmploymentImportRowsApiResponse = unknown;
export type PostApiCompanyDataProcessingValidateEmploymentImportRowsApiArg = {
    validateEmploymentImportRowsPostModel: ValidateEmploymentImportRowsPostModel;
};
export type PostApiCompanyDataProcessingImportEmploymentRowsApiResponse = unknown;
export type PostApiCompanyDataProcessingImportEmploymentRowsApiArg = {
    validateEmploymentImportRowsPostModel: ValidateEmploymentImportRowsPostModel;
};
export type PostApiCompanyDataProcessingRetryImportRowsApiResponse = unknown;
export type PostApiCompanyDataProcessingRetryImportRowsApiArg = {
    retryImportRowsPostModel: RetryImportRowsPostModel;
};
export type GetApiCustomerCompanyGetCompanyByIdApiResponse = /** status 200 Success */ Company;
export type GetApiCustomerCompanyGetCompanyByIdApiArg = {
    companyId?: number;
};
export type GetApiCustomerCompanyGetCompaniesApiResponse = unknown;
export type GetApiCustomerCompanyGetCompaniesApiArg = void;
export type GetApiCustomerCompanyGetCustomerSupportCompaniesApiResponse = unknown;
export type GetApiCustomerCompanyGetCustomerSupportCompaniesApiArg = void;
export type PostApiCustomerCompanyGetCompaniesForUserApiResponse = /** status 200 Success */ Company[];
export type PostApiCustomerCompanyGetCompaniesForUserApiArg = {
    companyFilter: CompanyFilter;
};
export type GetApiCustomerCompanyGetCompanyRecursiveAccessRightsApiResponse = unknown;
export type GetApiCustomerCompanyGetCompanyRecursiveAccessRightsApiArg = {
    companyId?: number;
    departmentId?: number;
};
export type GetApiCustomerCompanyGetStagedImportsCompaniesApiResponse = unknown;
export type GetApiCustomerCompanyGetStagedImportsCompaniesApiArg = {
    companyId?: number;
};
export type GetApiCustomerCompanyGetOrganizationTreeViewApiResponse = /** status 200 Success */ TreeViewNode[];
export type GetApiCustomerCompanyGetOrganizationTreeViewApiArg = {
    companyId?: number;
};
export type GetApiCustomerCompanyGetOrganizationDepartmentsEventsApiResponse = unknown;
export type GetApiCustomerCompanyGetOrganizationDepartmentsEventsApiArg = {
    companyId?: number;
    pageSize?: number;
    pageNumber?: number;
};
export type GetApiCustomerCompanyGetEmploymentGroupsApiResponse = unknown;
export type GetApiCustomerCompanyGetEmploymentGroupsApiArg = {
    companyId?: number;
};
export type PostApiCustomerCompanySaveDepartmentApiResponse = unknown;
export type PostApiCustomerCompanySaveDepartmentApiArg = {
    department: Department;
};
export type DeleteApiCustomerCompanyDeleteDepartmentApiResponse = unknown;
export type DeleteApiCustomerCompanyDeleteDepartmentApiArg = {
    departmentId?: number;
};
export type GetApiCustomerCompanyGetEmploymentsForDepartmentIdApiResponse = /** status 200 Success */ UserEmployment[];
export type GetApiCustomerCompanyGetEmploymentsForDepartmentIdApiArg = {
    departmentId?: number;
    type?: UserType;
};
export type GetApiCustomerCompanyGetUsersWithoutEmploymentForCompanyIdApiResponse = unknown;
export type GetApiCustomerCompanyGetUsersWithoutEmploymentForCompanyIdApiArg = {
    companyId?: number;
    otherCompanies?: boolean;
};
export type PostApiCustomerCompanyExportAllCompanyUsersAndEmployeesApiResponse = unknown;
export type PostApiCustomerCompanyExportAllCompanyUsersAndEmployeesApiArg = {
    exportAllCompanyUsersAndEmployeesModel: ExportAllCompanyUsersAndEmployeesModel;
};
export type PostApiCustomerCompanyExportDepartmentsToExcelApiResponse = unknown;
export type PostApiCustomerCompanyExportDepartmentsToExcelApiArg = {
    exportAllDepartmentEmployeesModel: ExportAllDepartmentEmployeesModel;
};
export type PostApiCustomerCompanyExportAllDepartmentEmployeesApiResponse = unknown;
export type PostApiCustomerCompanyExportAllDepartmentEmployeesApiArg = {
    exportAllDepartmentEmployeesModel: ExportAllDepartmentEmployeesModel;
};
export type GetApiCustomerCompanyGetDepartmentInfoApiResponse = /** status 200 Success */ Department;
export type GetApiCustomerCompanyGetDepartmentInfoApiArg = {
    departmentId?: number;
};
export type GetApiCustomerCompanyGetDepartmentsCostCenterForCompanyApiResponse = unknown;
export type GetApiCustomerCompanyGetDepartmentsCostCenterForCompanyApiArg = {
    companyId?: number;
};
export type GetApiCustomerCompanyGetRemovedEmploymentsApiResponse = unknown;
export type GetApiCustomerCompanyGetRemovedEmploymentsApiArg = {
    companyId?: number;
};
export type PostApiCustomerCompanyDeleteRemovedEmploymentsApiResponse = unknown;
export type PostApiCustomerCompanyDeleteRemovedEmploymentsApiArg = {
    companyId?: number;
    body: number[];
};
export type PostApiCustomerCompanyUpdateRemovedEmploymentsApiResponse = unknown;
export type PostApiCustomerCompanyUpdateRemovedEmploymentsApiArg = {
    companyId?: number;
    updateSecurityDeleteUserModel: UpdateSecurityDeleteUserModel;
};
export type GetApiCustomerCompanyGetEmploymentFromCompanyAndUserAccountIdApiResponse =
    /** status 200 Success */ CreateOrUpdateEmployment;
export type GetApiCustomerCompanyGetEmploymentFromCompanyAndUserAccountIdApiArg = {
    companyId?: number;
    userAccountId?: number;
};
export type GetApiCustomerCompanyGetCompanyInfoApiResponse = unknown;
export type GetApiCustomerCompanyGetCompanyInfoApiArg = {
    companyId?: number;
};
export type PostApiCustomerCompanySearchCompanyGroupUserApiResponse = unknown;
export type PostApiCustomerCompanySearchCompanyGroupUserApiArg = {
    searchCompanyGroupUserModel: SearchCompanyGroupUserModel;
};
export type PostApiCustomerCompanySearchCompanyGroupUserAndDepartmentApiResponse = unknown;
export type PostApiCustomerCompanySearchCompanyGroupUserAndDepartmentApiArg = {
    searchCompanyGroupUserModel: SearchCompanyGroupUserModel;
};
export type PostApiCustomerCompanySaveCostCenterApiResponse = unknown;
export type PostApiCustomerCompanySaveCostCenterApiArg = {
    costCenter: CostCenter;
};
export type PostApiCustomerCompanyDeleteCostCenterApiResponse = unknown;
export type PostApiCustomerCompanyDeleteCostCenterApiArg = {
    costCenter: CostCenter;
};
export type GetApiCustomerCompanyGetCostCenterApiResponse = unknown;
export type GetApiCustomerCompanyGetCostCenterApiArg = {
    companyId?: number;
    code?: string;
};
export type GetApiCustomerCompanyGetCostCentersForCompanyApiResponse = unknown;
export type GetApiCustomerCompanyGetCostCentersForCompanyApiArg = {
    companyId?: number;
};
export type GetApiCustomerCompanyGetCostCentersForCompanyFastApiResponse = /** status 200 Success */ CostCenter[];
export type GetApiCustomerCompanyGetCostCentersForCompanyFastApiArg = {
    companyId?: number;
};
export type GetApiCustomerCompanyGetAbsenceBoardRehabilitationServiceContractsApiResponse = unknown;
export type GetApiCustomerCompanyGetAbsenceBoardRehabilitationServiceContractsApiArg = {
    companyId?: number;
};
export type GetApiCustomerCompanyGetAbsenceBoardRehabilitationServiceContractsWithCustomerIdApiResponse = unknown;
export type GetApiCustomerCompanyGetAbsenceBoardRehabilitationServiceContractsWithCustomerIdApiArg = {
    companyId?: number;
    customerId?: number;
};
export type GetApiCustomerCompanyGetEmployeeErasedEventsApiResponse = unknown;
export type GetApiCustomerCompanyGetEmployeeErasedEventsApiArg = {
    companyId?: number;
};
export type GetApiCustomerCompanyGetRehabAndAbsenceErasedEventsApiResponse = unknown;
export type GetApiCustomerCompanyGetRehabAndAbsenceErasedEventsApiArg = {
    companyId?: number;
    type?: string;
};
export type GetApiCustomerCompanyGetCompanyDataErasureConfigurationApiResponse = unknown;
export type GetApiCustomerCompanyGetCompanyDataErasureConfigurationApiArg = {
    companyId?: number;
};
export type PostApiCustomerCompanySaveCompanySubscriptionDefaultApiResponse = unknown;
export type PostApiCustomerCompanySaveCompanySubscriptionDefaultApiArg = {
    importSubscriptionDefault: ImportSubscriptionDefault;
};
export type PostApiCustomerCompanyDeleteCompanySubscriptionDefaultApiResponse = unknown;
export type PostApiCustomerCompanyDeleteCompanySubscriptionDefaultApiArg = {
    companyId?: number;
    id?: number;
};
export type GetApiCustomerCompanyGetCompanyImportSubscriptionDefaultsApiResponse = unknown;
export type GetApiCustomerCompanyGetCompanyImportSubscriptionDefaultsApiArg = {
    companyId?: number;
};
export type PostApiCustomerCompanySaveCompanyDataErasureConfigurationApiResponse = unknown;
export type PostApiCustomerCompanySaveCompanyDataErasureConfigurationApiArg = {
    companyDataErasureConfigurationPostModel: CompanyDataErasureConfigurationPostModel;
};
export type GetApiCustomerCompanyGetCustomerBillingConfigurationApiResponse =
    /** status 200 Success */ CustomerBillingConfigurationPartial;
export type GetApiCustomerCompanyGetCustomerBillingConfigurationApiArg = {
    companyId?: number;
};
export type GetApiCustomerCompanyGetEmployeeCountApiResponse = unknown;
export type GetApiCustomerCompanyGetEmployeeCountApiArg = {
    companyId?: number;
};
export type GetApiCustomerCompanyGetCustomerProductByCompanyIdApiResponse = /** status 200 Success */ CustomerProduct;
export type GetApiCustomerCompanyGetCustomerProductByCompanyIdApiArg = {
    companyId?: number;
};
export type PostApiCustomerSupportSearchEmploymentsApiResponse = /** status 200 Success */ CatalogEntryModelRead[];
export type PostApiCustomerSupportSearchEmploymentsApiArg = {
    searchViewModel: SearchViewModel;
};
export type PostApiCustomerSupportSearchCompaniesApiResponse = /** status 200 Success */ CustomerCompanyCache[];
export type PostApiCustomerSupportSearchCompaniesApiArg = {
    searchViewModel: SearchViewModel;
};
export type PostApiCustomerSupportSearchCompanyUserByTelephoneNumberApiResponse =
    /** status 200 Success */ CatalogEntryModelRead[];
export type PostApiCustomerSupportSearchCompanyUserByTelephoneNumberApiArg = {
    searchViewModel: SearchViewModel;
};
export type PostApiCustomerSupportSearchCompanyUserByEmailApiResponse =
    /** status 200 Success */ CatalogEntryModelRead[];
export type PostApiCustomerSupportSearchCompanyUserByEmailApiArg = {
    searchViewModel: SearchViewModel;
};
export type GetApiDataAnonymizationAbsenceApiResponse = /** status 200 Success */ RemoveDataOnEmployment[];
export type GetApiDataAnonymizationAbsenceApiArg = {
    companyId?: number;
};
export type DeleteApiDataAnonymizationAbsenceApiResponse = unknown;
export type DeleteApiDataAnonymizationAbsenceApiArg = {
    companyId?: number;
    employments: Employments;
};
export type GetApiDataAnonymizationMedicalCertificateApiResponse = /** status 200 Success */ RemoveDataOnEmployment[];
export type GetApiDataAnonymizationMedicalCertificateApiArg = {
    companyId?: number;
};
export type DeleteApiDataAnonymizationMedicalCertificateApiResponse = unknown;
export type DeleteApiDataAnonymizationMedicalCertificateApiArg = {
    companyId?: number;
    employments: Employments;
};
export type GetApiDataAnonymizationRehabApiResponse = /** status 200 Success */ RemoveDataOnEmployment[];
export type GetApiDataAnonymizationRehabApiArg = {
    companyId?: number;
};
export type DeleteApiDataAnonymizationRehabApiResponse = unknown;
export type DeleteApiDataAnonymizationRehabApiArg = {
    companyId?: number;
    employments: Employments;
};
export type GetApiDataAnonymizationGetCompanyDataErasureAvailableConfigurationApiResponse =
    /** status 200 Success */ CompanyDataErasureAvailableConfiguration;
export type GetApiDataAnonymizationGetCompanyDataErasureAvailableConfigurationApiArg = {
    companyId?: number;
};
export type PostApiDataAnonymizationSaveCompanyDataErasureAvailableConfigurationApiResponse =
    /** status 200 Success */ CompanyDataErasureAvailableConfiguration;
export type PostApiDataAnonymizationSaveCompanyDataErasureAvailableConfigurationApiArg = {
    companyDataErasureAvailableConfiguration: CompanyDataErasureAvailableConfiguration;
};
export type GetApiEntityCopyApiResponse = /** status 200 Success */ CopyableEmploymentModel[];
export type GetApiEntityCopyApiArg = {
    companyIds?: string;
};
export type PostApiEntityCopyApiResponse = /** status 200 Success */ EntitiesToBeCopied[];
export type PostApiEntityCopyApiArg = {
    copyEntityList: CopyEntityList;
};
export type GetApiFileImportStatusGetLastImportRowChangeEventsApiResponse = unknown;
export type GetApiFileImportStatusGetLastImportRowChangeEventsApiArg = {
    companyId?: number;
};
export type PostApiFileImportStatusChangeAutoImportApiResponse = unknown;
export type PostApiFileImportStatusChangeAutoImportApiArg = {
    changeAutoImportPostModel: ChangeAutoImportPostModel;
};
export type GetApiFileImportStatusGetAutoCompleteStatusApiResponse = unknown;
export type GetApiFileImportStatusGetAutoCompleteStatusApiArg = {
    companyId?: number;
};
export type PostApiFileImportStatusStartManualMergeApiResponse = unknown;
export type PostApiFileImportStatusStartManualMergeApiArg = {
    startManualMergePostModel: StartManualMergePostModel;
};
export type PostApiFileImportStatusDownloadFileOnHoldApiResponse = unknown;
export type PostApiFileImportStatusDownloadFileOnHoldApiArg = {
    importedFileOnHold: ImportedFileOnHold;
};
export type GetApiFileImportStatusGetCompanyProgressApiResponse = unknown;
export type GetApiFileImportStatusGetCompanyProgressApiArg = {
    companyId?: number;
};
export type GetApiFileImportStatusGetStageInfoApiResponse = unknown;
export type GetApiFileImportStatusGetStageInfoApiArg = {
    companyId?: number;
};
export type GetApiFileImportStatusCheckFileImportConfigurationApiResponse = unknown;
export type GetApiFileImportStatusCheckFileImportConfigurationApiArg = {
    companyId?: number;
};
export type PostApiStagingGetStagedChangesApiResponse = unknown;
export type PostApiStagingGetStagedChangesApiArg = {
    stagedChangesFilter: StagedChangesFilter;
};
export type PostApiStagingGetLatestEventsDateForProviderApiResponse = unknown;
export type PostApiStagingGetLatestEventsDateForProviderApiArg = {
    stagedChangesFilter: StagedChangesFilter;
};
export type PostApiStagingDownloadFileApiResponse = unknown;
export type PostApiStagingDownloadFileApiArg = {
    importedFile: ImportedFile;
};
export type PostApiStagingResolveImportErrorsApiResponse = unknown;
export type PostApiStagingResolveImportErrorsApiArg = {
    resolveImportErrorsPostModel: ResolveImportErrorsPostModel;
};
export type PostApiStagingGetSkippedRowsApiResponse = unknown;
export type PostApiStagingGetSkippedRowsApiArg = {
    skippedRowsPostModel: SkippedRowsPostModel;
};
export type PostApiStagingGetImportErrorsApiResponse = unknown;
export type PostApiStagingGetImportErrorsApiArg = {
    importErrorsPostModel: ImportErrorsPostModel;
};
export type PostApiStagingGetImportedFilesApiResponse = unknown;
export type PostApiStagingGetImportedFilesApiArg = {
    stagedChangesFilter: StagedChangesFilter;
};
export type GetApiStatusApiResponse = unknown;
export type GetApiStatusApiArg = void;
export type GetApiStatusGetStatusAllApiResponse = unknown;
export type GetApiStatusGetStatusAllApiArg = void;
export type GetApiSubscriptionTypeApiResponse = unknown;
export type GetApiSubscriptionTypeApiArg = {
    companyId?: number;
};
export type PutApiSubscriptionTypeApiResponse = unknown;
export type PutApiSubscriptionTypeApiArg = {
    setDepartmentManagerChannelModel: SetDepartmentManagerChannelModel;
};
export type PostApiUserCreateUserApiResponse = unknown;
export type PostApiUserCreateUserApiArg = {
    createOrUpdateUserAccount: CreateOrUpdateUserAccount;
};
export type PostApiUserCreateEmploymentApiResponse = unknown;
export type PostApiUserCreateEmploymentApiArg = {
    createOrUpdateEmployment: CreateOrUpdateEmployment;
};
export type PostApiUserCreateAccessRightsApiResponse = unknown;
export type PostApiUserCreateAccessRightsApiArg = {
    createOrUpdateAccessRights: CreateOrUpdateAccessRights;
};
export type PostApiUserCreateCompanyAccessRightsApiResponse = unknown;
export type PostApiUserCreateCompanyAccessRightsApiArg = {
    createOrUpdateCompanyAccessRights: CreateOrUpdateCompanyAccessRights;
};
export type PostApiUserCreateDepartmentAccessRightsApiResponse = unknown;
export type PostApiUserCreateDepartmentAccessRightsApiArg = {
    createOrUpdateDepartmentsAccessRights: CreateOrUpdateDepartmentsAccessRights;
};
export type PostApiUserUpdateEmployeeContactDetailsApiResponse = unknown;
export type PostApiUserUpdateEmployeeContactDetailsApiArg = {
    userAccountId?: number;
    companyId?: number;
    contactInformation: ContactInformation;
};
export type PostApiUserSaveContactInformationApiResponse = unknown;
export type PostApiUserSaveContactInformationApiArg = {
    createOrUpdateContactInformation: CreateOrUpdateContactInformation;
};
export type GetApiUserSearchCompanyUserBySsnApiResponse = unknown;
export type GetApiUserSearchCompanyUserBySsnApiArg = {
    countryCode?: string;
    companyId?: number;
    searchString?: string;
};
export type PostApiUserSearchCompanyGroupUserBySsnApiResponse = /** status 200 Success */ CompanyUserRead;
export type PostApiUserSearchCompanyGroupUserBySsnApiArg = {
    searchCompanyGroupUserBySsn: SearchCompanyGroupUserBySsn;
};
export type GetApiUserSearchEmploymentsByNameApiResponse = unknown;
export type GetApiUserSearchEmploymentsByNameApiArg = {
    companyId?: number;
    searchString?: string;
};
export type GetApiUserSearchCompanyGroupUsersByNameApiResponse = unknown;
export type GetApiUserSearchCompanyGroupUsersByNameApiArg = {
    companyId?: number;
    searchString?: string;
};
export type GetApiUserSearchCompanyUsersByNameApiResponse = unknown;
export type GetApiUserSearchCompanyUsersByNameApiArg = {
    companyId?: number;
    searchString?: string;
};
export type GetApiUserGetAccessRightsFromUserAccountIdApiResponse = unknown;
export type GetApiUserGetAccessRightsFromUserAccountIdApiArg = {
    userAccountId?: number;
    companyId?: number;
};
export type GetApiUserGetAccessRightsForCompanyGroupApiResponse =
    /** status 200 Success */ CreateOrUpdateAccessRights[];
export type GetApiUserGetAccessRightsForCompanyGroupApiArg = {
    userAccountId?: number;
    companyId?: number;
};
export type GetApiUserGetUserAccountsAndAccessRightsByDepartmentIdApiResponse =
    /** status 200 Success */ UserAccountRead;
export type GetApiUserGetUserAccountsAndAccessRightsByDepartmentIdApiArg = {
    departmentId?: number;
};
export type GetApiUserGetUserDataApiResponse = unknown;
export type GetApiUserGetUserDataApiArg = {
    userAccountId?: number;
    companyId?: number;
    departmentId?: number;
};
export type GetApiUserGetUserAccountsAndAccessRightsByCompanyIdApiResponse = unknown;
export type GetApiUserGetUserAccountsAndAccessRightsByCompanyIdApiArg = {
    companyId?: number;
};
export type GetApiUserGetUserAccountIdFromUserIdApiResponse = unknown;
export type GetApiUserGetUserAccountIdFromUserIdApiArg = {
    userId?: number;
    companyId?: number;
};
export type GetApiUserGetLoginCredentialsFromUserAccountIdApiResponse = unknown;
export type GetApiUserGetLoginCredentialsFromUserAccountIdApiArg = {
    userAccountId?: number;
};
export type GetApiUserGetAllLoginCredentialsApiResponse = unknown;
export type GetApiUserGetAllLoginCredentialsApiArg = {
    userAccountId?: number;
};
export type GetApiUserGetCompanyUserFromUserAccountIdApiResponse = /** status 200 Success */ CompanyUserRead;
export type GetApiUserGetCompanyUserFromUserAccountIdApiArg = {
    userAccountId?: number;
    companyId?: number;
};
export type GetApiUserGetCompanyUserFromUserAccountIdEditApiResponse = unknown;
export type GetApiUserGetCompanyUserFromUserAccountIdEditApiArg = {
    userAccountId?: number;
    companyId?: number;
};
export type GetApiUserGetCompanyUserFromUserAccountIdInCompanyGroupApiResponse = unknown;
export type GetApiUserGetCompanyUserFromUserAccountIdInCompanyGroupApiArg = {
    userAccountId?: number;
    companyId?: number;
};
export type PostApiUserDeleteLoginCredentialApiResponse = unknown;
export type PostApiUserDeleteLoginCredentialApiArg = {
    loginCredentials: LoginCredentials;
};
export type PostApiUserSaveOtpLoginCredentialApiResponse = unknown;
export type PostApiUserSaveOtpLoginCredentialApiArg = {
    otpLoginCredential: OtpLoginCredential;
};
export type PostApiUserSaveLoginCredentialApiResponse = unknown;
export type PostApiUserSaveLoginCredentialApiArg = {
    loginCredentials: LoginCredentials;
};
export type PostApiUserChangeLoginCredentialsPasswordApiResponse = unknown;
export type PostApiUserChangeLoginCredentialsPasswordApiArg = {
    passwordChange: PasswordChange;
};
export type GetApiUserGetRemovedUsersApiResponse = unknown;
export type GetApiUserGetRemovedUsersApiArg = {
    companyId?: number;
};
export type PostApiUserReactivateUserAccountApiResponse = unknown;
export type PostApiUserReactivateUserAccountApiArg = {
    reactivateUserPostModel: ReactivateUserPostModel;
};
export type GetApiUserGetUnstartedUsersApiResponse = unknown;
export type GetApiUserGetUnstartedUsersApiArg = {
    companyId?: number;
};
export type PostApiUserExportAllCompanyPermissionsApiResponse = unknown;
export type PostApiUserExportAllCompanyPermissionsApiArg = {
    exportAllCompanyPermissionsModel: ExportAllCompanyPermissionsModel;
};
export type PostApiUserExportAllDepartmentPermissionsApiResponse = unknown;
export type PostApiUserExportAllDepartmentPermissionsApiArg = {
    exportAllDepartmentPermissionsModel: ExportAllDepartmentPermissionsModel;
};
export type GetApiUserGetLoginConfigurationApiResponse = unknown;
export type GetApiUserGetLoginConfigurationApiArg = {
    companyId?: number;
};
export type GetApiUserReserveUserAccountIdsApiResponse = unknown;
export type GetApiUserReserveUserAccountIdsApiArg = {
    count?: number;
};
export type ImportColumnType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17;
export type ImportColumn = {
    type?: ImportColumnType;
    value?: object | null;
};
export type ImportRow = {
    ignore?: boolean;
    columns?: ImportColumn[] | null;
};
export type ValidateEmploymentImportRowsPostModel = {
    companyId?: number;
    performedByUserAccountId?: number;
    importRows?: ImportRow[] | null;
};
export type RetryImportRowsPostModel = {
    companyId?: number;
    traceId?: string;
};
export type Company = {
    id?: number;
    name?: string | null;
    number?: string | null;
    departmentIds?: number[] | null;
    automatedOrganizationalChangeProcess?: boolean;
    isReadOnly?: boolean;
};
export type CompanyFilter = {
    companyId?: number;
};
export type TreeViewNode = {
    id?: number;
    label?: string | null;
    icon?: string | null;
    type?: string | null;
    employees?: number;
    selectable?: boolean;
    children?: TreeViewNode[] | null;
    top?: boolean;
};
export type DepartmentManager = {
    id?: number;
    name?: string | null;
};
export type Department = {
    id?: number;
    name?: string | null;
    path?: string | null;
    parentId?: number;
    companyId?: number;
    defaultManagerEmploymentId?: number | null;
    defaultManager?: DepartmentManager | null;
    businessUnitNumber?: string | null;
    costCenter?: string | null;
    employees?: number;
    defaultManagerUserAccountId?: number | null;
    companyName?: string | null;
    referenceKey?: string;
};
export type PrimaryCompany = {
    id?: number;
    name?: string | null;
    customerName?: string | null;
    registrationNumber?: string | null;
};
export type UserEmployment = {
    name?: string | null;
    userId?: number;
    employmentNumber?: string | null;
    employmentId?: number;
    socialSecurityNumber?: string | null;
    userAccountId?: number;
    firstName?: string | null;
    lastName?: string | null;
    costCenters?: string[] | null;
    primaryCompany?: PrimaryCompany | null;
};
export type UserType = 0 | 1 | 2 | 3 | 4;
export type UserTypeExportFilter = 0 | 1 | 2 | 3 | 5;
export type UserExportTransations = {
    userWithoutEmployment?: string | null;
    employee?: string | null;
    associatedEmployee?: string | null;
    userFromOtherCompany?: string | null;
    workbookTitle?: string | null;
    titles?: string[] | null;
};
export type ExportAllCompanyUsersAndEmployeesModel = {
    companyId?: number;
    userTypeFilter?: UserTypeExportFilter;
    translations?: UserExportTransations | null;
};
export type DepartmentsEmployeesExportType = 1 | 2 | 3 | 4;
export type ExportAllDepartmentEmployeesModel = {
    companyId?: number;
    departmentId?: number;
    includeChildDepartments?: boolean;
    translations?: UserExportTransations | null;
    departmentEmployeesType?: DepartmentsEmployeesExportType;
};
export type UpdateSecurityDeleteUserModel = {
    employmentSecured?: number[] | null;
    employmentUnSecured?: number[] | null;
};
export type IdNameCompanyMap = {
    companyId?: number;
    companyName?: string | null;
    id?: number;
    userAccountId?: number;
    name?: string | null;
    recursive?: boolean;
};
export type EmploymentGroup = {
    id?: number;
    nameIdentifier?: string | null;
    name?: string | null;
    companyId?: number;
};
export type CreateOrUpdateEmployment = {
    id?: number;
    userAccountId?: number;
    companyId?: number;
    employmentNumber?: string | null;
    costCenter?: string | null;
    organizationNumber?: string | null;
    mainManager?: IdNameCompanyMap | null;
    departments?: Department[] | null;
    employmentGroups?: EmploymentGroup[] | null;
};
export type SearchCompanyGroupUserModel = {
    searchString?: string | null;
    companyIds?: number[] | null;
    selectedCompanyId?: number;
};
export type CostCenter = {
    code?: string | null;
    companyId?: number;
    description?: string | null;
    isUsed?: boolean;
};
export type ImportSubscriptionDefault = {
    id?: number;
    companyId?: number;
    eventCode?: string | null;
    notificationChannel?: number;
    notificationEventType?: number;
    role?: number;
};
export type CompanyDataErasureConfigurationPostModel = {
    companyId?: number;
    automatical?: boolean;
    noAbsenceHistoryOrRehabErasureMonthInterval?: number | null;
    absenceHistoryErasureMonthInterval?: number | null;
    rehabErasureMonthInterval?: number | null;
    workRelatedAbsenceErasureMonthInterval?: number | null;
    performedByUserAccountId?: number;
};
export type CustomerGroupType = 0 | 1 | 2;
export type CustomerBillingConfigurationPartial = {
    customerGroupType?: CustomerGroupType;
    isConfigured?: boolean;
};
export type Product = {
    id?: number;
    name?: string | null;
};
export type Subcategory = {
    id?: number;
    name?: string | null;
};
export type CustomerProduct = {
    product?: Product | null;
    subcategory?: Subcategory | null;
};
export type CatalogEntryModel = {
    id?: number;
    userAccountId?: number;
    socialSecurityNumber?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    employmentId?: number;
    companyId?: number;
    companyName?: string | null;
    employmentNumber?: string | null;
    startDate?: string;
    endDate?: string | null;
    removalDate?: string | null;
    automaticAnonymizationDate?: string | null;
    telephoneNumbers?: string[] | null;
    endedEmployment?: boolean;
    retailerId?: number;
    retailerName?: string | null;
    doNotDelete?: boolean;
    email?: string | null;
    referenceKey?: string | null;
    hasAbsence?: boolean;
    hasRehab?: boolean;
    maxEndPeriod?: string | null;
    minStartPeriod?: string | null;
    erasurePeriod?: string | null;
    departments?: string[] | null;
    customerId?: number;
};
export type CatalogEntryModelRead = {
    id?: number;
    userAccountId?: number;
    socialSecurityNumber?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    name?: string | null;
    employmentId?: number;
    companyId?: number;
    companyName?: string | null;
    employmentNumber?: string | null;
    startDate?: string;
    endDate?: string | null;
    removalDate?: string | null;
    automaticAnonymizationDate?: string | null;
    telephoneNumbers?: string[] | null;
    endedEmployment?: boolean;
    displayField?: string | null;
    retailerId?: number;
    retailerName?: string | null;
    doNotDelete?: boolean;
    email?: string | null;
    referenceKey?: string | null;
    hasAbsence?: boolean;
    hasRehab?: boolean;
    maxEndPeriod?: string | null;
    minStartPeriod?: string | null;
    erasurePeriod?: string | null;
    departments?: string[] | null;
    customerId?: number;
};
export type SearchViewModel = {
    searchTerms?: string | null;
    multipleRetailers?: boolean;
    includeHistory?: boolean;
};
export type CustomerCompanyCache = {
    customerId?: number;
    companyId?: number;
    companyName?: string | null;
};
export type RemoveDataOnEmployment = {
    employmentReference?: string;
    employmentId?: number;
    firstName?: string | null;
    lastName?: string | null;
    employmentNumber?: string | null;
    socialSecurityNumber?: string | null;
    doNotDelete?: boolean;
    from?: string;
    to?: string;
};
export type Employments = {
    employmentReferences?: string[] | null;
};
export type CompanyDataErasureAvailableConfiguration = {
    companyId?: number;
    absenceHistory?: number | null;
    caseDocumentation?: number | null;
    medicalCertificates?: number | null;
};
export type EntityType = 0 | 1 | 2;
export type GuidListData = {
    mappingType?: EntityType;
    id?: string;
    targetId?: string;
};
export type CopyableEmploymentModel = {
    companyId?: number;
    employmentId?: number;
    employmentNumber?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    removalDate?: string | null;
    socialSecurityNumber?: string | null;
    userAccountId?: number;
    creationDate?: string | null;
    endDateDate?: string | null;
    startDate?: string | null;
    employmentIdReference?: string;
    doNotDelete?: boolean;
    socialSecurityNumberToDisplay?: string | null;
    insertedAt?: string | null;
    updatedAt?: string | null;
    targetEmploymentId?: number;
    targetEmploymentIdReference?: string;
    targetCompanyId?: number;
    idMapping?: GuidListData[] | null;
};
export type CopyEntityMessage = {
    originEmploymentId?: number;
    tagetEmploymentId?: number;
    originCustomerId?: number;
    originCompanyId?: number;
    targetCustomerId?: number;
    targetCompanyId?: number;
    timestamp?: string;
    performedBy?: number;
    employmentIdReference?: string;
    targetEmploymentIdReference?: string;
    idMapping?: GuidListData[] | null;
};
export type EntitiesToBeCopied = {
    copyEntityMessages?: CopyEntityMessage[] | null;
    features?: string[] | null;
};
export type CopyEntity = {
    originId?: string;
    originEmploymentId?: number;
    targetId?: string;
    targetEmploymentId?: number;
};
export type CopyEntityList = {
    customerId?: number;
    copyEntities?: CopyEntity[] | null;
    features?: string[] | null;
};
export type ChangeAutoImportPostModel = {
    companyId?: number;
    value?: boolean;
    performedByUserAccountId?: number;
};
export type StartManualMergePostModel = {
    companyId?: number;
    importIds?: number[] | null;
    performedByUserAccountId?: number;
};
export type ImportedFileOnHold = {
    companyId?: number;
    externalKey?: string | null;
};
export type StagedChangeType = 0 | 1 | 2;
export type StagedChangesFilter = {
    stagedChangeType?: StagedChangeType;
    companyIds?: number[] | null;
    startDate?: string;
    endDate?: string;
    page?: number;
    pageSize?: number;
};
export type ImportedFile = {
    externalKey?: string | null;
    name?: string | null;
    companyId?: number;
};
export type ResolveImportErrorsPostModel = {
    companyId?: number;
    importIds?: number[] | null;
};
export type SkippedRowsPostModel = {
    companyId?: number;
    startDate?: string;
    endDate?: string;
};
export type ImportErrorsPostModel = {
    companyId?: number;
    startDate?: string;
    endDate?: string;
};
export type DepartmentManagerChannelType = 0 | 1 | 2 | 3;
export type SetDepartmentManagerChannelModel = {
    subscriptionTypeId?: number;
    departmentManagerChannelType?: DepartmentManagerChannelType;
};
export type CreateOrUpdateUserAccount = {
    id?: number;
    userId?: number;
    firstName?: string | null;
    lastName?: string | null;
    socialSecurityNumber: string;
    companyId?: number;
    country?: string | null;
    language?: string | null;
    mobileNumber?: string | null;
    mobileCountryPrefix?: string | null;
    telephoneNumber?: string | null;
    telephoneCountryPrefix?: string | null;
    email?: string | null;
    homeAddress?: string | null;
    secondaryMobileCountryPrefix?: string | null;
    secondaryMobileNumber?: string | null;
    secondaryEmail?: string | null;
    gender?: number | null;
    birthYear?: number | null;
    customerId?: number;
    periodActivationDate?: string;
    periodDeactivationDate?: string | null;
};
export type IdNameMap = {
    id?: number;
    userAccountId?: number;
    name?: string | null;
    recursive?: boolean;
};
export type CreateOrUpdateAccessRights = {
    absenceReporting?: boolean;
    absenceAdministration?: IdNameMap[] | null;
    absenceFollowup?: IdNameMap[] | null;
    absenceView?: IdNameMap[] | null;
    customerCompanyAdministration?: boolean;
    absenceReportingAssistance?: boolean;
    medicalAdvice?: boolean;
    absenceArchive?: boolean;
    medicalAdvising?: boolean;
    departmentAdministration?: IdNameMap[] | null;
    rehabProcessManagement?: IdNameMap[] | null;
    absenceStatistics?: IdNameMap[] | null;
    absenceBoard?: IdNameMap[] | null;
    showRehabProcessManagement?: boolean;
    showAbsenceBoard?: boolean;
    companyName?: string | null;
    topDepartment?: IdNameMap | null;
    customerOrganizationAdministration?: boolean;
    fileImportAdministration?: boolean;
    userAccountId?: number;
    companyId?: number;
    performedByUserAccountId?: number;
};
export type CreateOrUpdateCompanyAccessRights = {
    customerCompanyAdministration?: boolean;
    absenceArchive?: boolean;
    userAccountId?: number;
    companyId?: number;
    performedByUserAccountId?: number;
};
export type CreateOrUpdateDepartmentsAccessRights = {
    departmentId?: number;
    absenceAdministration?: IdNameMap[] | null;
    absenceFollowup?: IdNameMap[] | null;
    absenceView?: IdNameMap[] | null;
    departmentAdministration?: IdNameMap[] | null;
    rehabProcessManagement?: IdNameMap[] | null;
    absenceStatistics?: IdNameMap[] | null;
    absenceBoard?: IdNameMap[] | null;
    userAccountId?: number;
    companyId?: number;
    performedByUserAccountId?: number;
};
export type ContactInformation = {
    telephoneCountryPrefix?: string | null;
    telephoneNumber?: string | null;
    mobileCountryPrefix?: string | null;
    mobileNumber?: string | null;
    email?: string | null;
    homeAddress?: string | null;
    updatedAt?: string | null;
    secondaryMobileCountryPrefix?: string | null;
    secondaryMobileNumber?: string | null;
    secondaryEmail?: string | null;
};
export type ContactInformationRead = {
    telephoneCountryPrefix?: string | null;
    telephoneNumber?: string | null;
    telephone?: string | null;
    mobileCountryPrefix?: string | null;
    mobileNumber?: string | null;
    mobile?: string | null;
    email?: string | null;
    homeAddress?: string | null;
    updatedAt?: string | null;
    secondaryMobile?: string | null;
    secondaryMobileCountryPrefix?: string | null;
    secondaryMobileNumber?: string | null;
    secondaryEmail?: string | null;
};
export type CreateOrUpdateContactInformation = {
    userAccountId?: number;
    companyId?: number;
    telephoneCountryPrefix?: string | null;
    telephoneNumber?: string | null;
    mobileCountryPrefix?: string | null;
    mobileNumber?: string | null;
    email?: string | null;
    homeAddress?: string | null;
    updatedAt?: string | null;
    secondaryMobileCountryPrefix?: string | null;
    secondaryMobileNumber?: string | null;
    secondaryEmail?: string | null;
};
export type AccountAccessRightGroupPeriod = {
    activationDate?: string;
    deactivationDate?: string | null;
};
export type CompanyUser = {
    employmentId?: number;
    language?: string | null;
    accountAccessRightGroupPeriod?: AccountAccessRightGroupPeriod | null;
    primaryCompany?: PrimaryCompany | null;
    editable?: boolean | null;
    userAccountId?: number;
    userId?: number;
    companyId?: number;
    id?: number;
    firstName?: string | null;
    lastName?: string | null;
    socialSecurityNumber?: string | null;
    contactInformation?: ContactInformation | null;
    countryCode?: string | null;
    birthYear?: number | null;
    gender?: number | null;
};
export type CompanyUserRead = {
    employmentId?: number;
    language?: string | null;
    accountAccessRightGroupPeriod?: AccountAccessRightGroupPeriod | null;
    primaryCompany?: PrimaryCompany | null;
    editable?: boolean | null;
    userAccountId?: number;
    userId?: number;
    companyId?: number;
    id?: number;
    firstName?: string | null;
    lastName?: string | null;
    name?: string | null;
    socialSecurityNumber?: string | null;
    contactInformation?: ContactInformationRead | null;
    countryCode?: string | null;
    maskedSocialSecurityNumber?: string | null;
    birthYear?: number | null;
    gender?: number | null;
};
export type SearchCompanyGroupUserBySsn = {
    countryCode?: string | null;
    includeAllCodes?: boolean | null;
    searchString?: string | null;
    companyIds?: number[] | null;
    selectedCompanyId?: number;
};
export type UserPreferences = {
    language?: string | null;
};
export type User = {
    id?: number;
    firstName?: string | null;
    lastName?: string | null;
    socialSecurityNumber?: string | null;
    contactInformation?: ContactInformation | null;
    userPreferences?: UserPreferences | null;
    countryCode?: string | null;
    gender?: number | null;
    birthYear?: number | null;
};
export type UserRead = {
    id?: number;
    firstName?: string | null;
    lastName?: string | null;
    name?: string | null;
    socialSecurityNumber?: string | null;
    contactInformation?: ContactInformationRead | null;
    userPreferences?: UserPreferences | null;
    countryCode?: string | null;
    maskedSocialSecurityNumber?: string | null;
    gender?: number | null;
    birthYear?: number | null;
};
export type AccessRightType =
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 19
    | 20
    | 21
    | 22
    | 23
    | 24;
export type AccountAccessRightViewModel = {
    name?: string | null;
    accessRightType?: AccessRightType;
    externalId?: number;
    companyId?: number;
    recursive?: boolean;
};
export type UserAccountOperationType = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export type AccountAccessChangeEvent = {
    id?: number;
    eventDate?: string;
    effectiveDate?: string;
    companyId?: number;
    userAccountOperationType?: UserAccountOperationType;
    resourceId?: number | null;
    groupReferenceId?: number | null;
};
export type UserAccount = {
    id?: number;
    user?: User | null;
    companyId?: number | null;
    customerId?: number;
    contactInformation?: ContactInformation | null;
    accessRights?: AccountAccessRightViewModel[] | null;
    accountAccessChangeEvents?: AccountAccessChangeEvent[] | null;
    activationDate?: string | null;
    deactivationDate?: string | null;
};
export type Int32BooleanValueTuple = object;
export type UserAccountRead = {
    id?: number;
    user?: UserRead | null;
    companyId?: number | null;
    customerId?: number;
    contactInformation?: ContactInformationRead | null;
    accessRights?: AccountAccessRightViewModel[] | null;
    accountAccessChangeEvents?: AccountAccessChangeEvent[] | null;
    activationDate?: string | null;
    deactivationDate?: string | null;
    absenceViewDepartmentIds?: number[] | null;
    absenceFollowUpDepartmentIds?: number[] | null;
    customerSupportRetailers?: IdNameMap[] | null;
    customerCompanyAdministratorCompanies?: Company[] | null;
    dataErasureCompanies?: Company[] | null;
    isCustomerSupport?: boolean;
    hasDataErasureForOldData?: boolean;
    customerOrganizationAdministration?: boolean;
    departmentAdministrationDepartments?: Department[] | null;
    departmentAdministrationDepartmentIds?: Int32BooleanValueTuple[] | null;
    customerCompanyAdministration?: boolean;
};
export type CredentialType = 1 | 2 | 3 | 4 | 5 | 6;
export type LoginCredentials = {
    id?: number;
    credentialType?: CredentialType;
    identifier?: string | null;
    userId?: number | null;
    userAccountId?: number | null;
    allowCrossSessionToken?: boolean;
};
export type OtpLoginCredential = {
    countryCode?: string | null;
    mobileNumber?: string | null;
    email?: string | null;
    userId?: number | null;
    userAccountId?: number | null;
    allowCrossSessionToken?: boolean;
};
export type PasswordChange = {
    userAccountId?: number;
    newPassword?: string | null;
    newPasswordMatch?: string | null;
};
export type ReactivateUserPostModel = {
    userAccountId?: number;
    companyId?: number;
    performedByUserAccountId?: number;
};
export type PermissionsTranslations = {
    usersCount?: string | null;
    accessRights?: string | null;
    workbookTitle?: string | null;
    titles?: string[] | null;
};
export type ExportAllCompanyPermissionsModel = {
    companyId?: number;
    translations?: PermissionsTranslations | null;
    includeDepartmentsAccessRights?: boolean;
};
export type ExportAllDepartmentPermissionsModel = {
    departmentId?: number;
    translations?: PermissionsTranslations | null;
};
export const {
    useGetApiCompanyDataProcessingGetViewDataQuery,
    useGetApiCompanyDataProcessingGetImportCompanyStatusQuery,
    usePostApiCompanyDataProcessingValidateEmploymentImportRowsMutation,
    usePostApiCompanyDataProcessingImportEmploymentRowsMutation,
    usePostApiCompanyDataProcessingRetryImportRowsMutation,
    useGetApiCustomerCompanyGetCompanyByIdQuery,
    useGetApiCustomerCompanyGetCompaniesQuery,
    useGetApiCustomerCompanyGetCustomerSupportCompaniesQuery,
    usePostApiCustomerCompanyGetCompaniesForUserMutation,
    useGetApiCustomerCompanyGetCompanyRecursiveAccessRightsQuery,
    useGetApiCustomerCompanyGetStagedImportsCompaniesQuery,
    useGetApiCustomerCompanyGetOrganizationTreeViewQuery,
    useGetApiCustomerCompanyGetOrganizationDepartmentsEventsQuery,
    useGetApiCustomerCompanyGetEmploymentGroupsQuery,
    usePostApiCustomerCompanySaveDepartmentMutation,
    useDeleteApiCustomerCompanyDeleteDepartmentMutation,
    useGetApiCustomerCompanyGetEmploymentsForDepartmentIdQuery,
    useGetApiCustomerCompanyGetUsersWithoutEmploymentForCompanyIdQuery,
    usePostApiCustomerCompanyExportAllCompanyUsersAndEmployeesMutation,
    usePostApiCustomerCompanyExportDepartmentsToExcelMutation,
    usePostApiCustomerCompanyExportAllDepartmentEmployeesMutation,
    useGetApiCustomerCompanyGetDepartmentInfoQuery,
    useGetApiCustomerCompanyGetDepartmentsCostCenterForCompanyQuery,
    useGetApiCustomerCompanyGetRemovedEmploymentsQuery,
    usePostApiCustomerCompanyDeleteRemovedEmploymentsMutation,
    usePostApiCustomerCompanyUpdateRemovedEmploymentsMutation,
    useGetApiCustomerCompanyGetEmploymentFromCompanyAndUserAccountIdQuery,
    useGetApiCustomerCompanyGetCompanyInfoQuery,
    usePostApiCustomerCompanySearchCompanyGroupUserMutation,
    usePostApiCustomerCompanySearchCompanyGroupUserAndDepartmentMutation,
    usePostApiCustomerCompanySaveCostCenterMutation,
    usePostApiCustomerCompanyDeleteCostCenterMutation,
    useGetApiCustomerCompanyGetCostCenterQuery,
    useGetApiCustomerCompanyGetCostCentersForCompanyQuery,
    useGetApiCustomerCompanyGetCostCentersForCompanyFastQuery,
    useGetApiCustomerCompanyGetAbsenceBoardRehabilitationServiceContractsQuery,
    useGetApiCustomerCompanyGetAbsenceBoardRehabilitationServiceContractsWithCustomerIdQuery,
    useGetApiCustomerCompanyGetEmployeeErasedEventsQuery,
    useGetApiCustomerCompanyGetRehabAndAbsenceErasedEventsQuery,
    useGetApiCustomerCompanyGetCompanyDataErasureConfigurationQuery,
    usePostApiCustomerCompanySaveCompanySubscriptionDefaultMutation,
    usePostApiCustomerCompanyDeleteCompanySubscriptionDefaultMutation,
    useGetApiCustomerCompanyGetCompanyImportSubscriptionDefaultsQuery,
    usePostApiCustomerCompanySaveCompanyDataErasureConfigurationMutation,
    useGetApiCustomerCompanyGetCustomerBillingConfigurationQuery,
    useGetApiCustomerCompanyGetEmployeeCountQuery,
    useGetApiCustomerCompanyGetCustomerProductByCompanyIdQuery,
    usePostApiCustomerSupportSearchEmploymentsMutation,
    usePostApiCustomerSupportSearchCompaniesMutation,
    usePostApiCustomerSupportSearchCompanyUserByTelephoneNumberMutation,
    usePostApiCustomerSupportSearchCompanyUserByEmailMutation,
    useGetApiDataAnonymizationAbsenceQuery,
    useDeleteApiDataAnonymizationAbsenceMutation,
    useGetApiDataAnonymizationMedicalCertificateQuery,
    useDeleteApiDataAnonymizationMedicalCertificateMutation,
    useGetApiDataAnonymizationRehabQuery,
    useDeleteApiDataAnonymizationRehabMutation,
    useGetApiDataAnonymizationGetCompanyDataErasureAvailableConfigurationQuery,
    usePostApiDataAnonymizationSaveCompanyDataErasureAvailableConfigurationMutation,
    useGetApiEntityCopyQuery,
    usePostApiEntityCopyMutation,
    useGetApiFileImportStatusGetLastImportRowChangeEventsQuery,
    usePostApiFileImportStatusChangeAutoImportMutation,
    useGetApiFileImportStatusGetAutoCompleteStatusQuery,
    usePostApiFileImportStatusStartManualMergeMutation,
    usePostApiFileImportStatusDownloadFileOnHoldMutation,
    useGetApiFileImportStatusGetCompanyProgressQuery,
    useGetApiFileImportStatusGetStageInfoQuery,
    useGetApiFileImportStatusCheckFileImportConfigurationQuery,
    usePostApiStagingGetStagedChangesMutation,
    usePostApiStagingGetLatestEventsDateForProviderMutation,
    usePostApiStagingDownloadFileMutation,
    usePostApiStagingResolveImportErrorsMutation,
    usePostApiStagingGetSkippedRowsMutation,
    usePostApiStagingGetImportErrorsMutation,
    usePostApiStagingGetImportedFilesMutation,
    useGetApiStatusQuery,
    useGetApiStatusGetStatusAllQuery,
    useGetApiSubscriptionTypeQuery,
    usePutApiSubscriptionTypeMutation,
    usePostApiUserCreateUserMutation,
    usePostApiUserCreateEmploymentMutation,
    usePostApiUserCreateAccessRightsMutation,
    usePostApiUserCreateCompanyAccessRightsMutation,
    usePostApiUserCreateDepartmentAccessRightsMutation,
    usePostApiUserUpdateEmployeeContactDetailsMutation,
    usePostApiUserSaveContactInformationMutation,
    useGetApiUserSearchCompanyUserBySsnQuery,
    usePostApiUserSearchCompanyGroupUserBySsnMutation,
    useGetApiUserSearchEmploymentsByNameQuery,
    useGetApiUserSearchCompanyGroupUsersByNameQuery,
    useGetApiUserSearchCompanyUsersByNameQuery,
    useGetApiUserGetAccessRightsFromUserAccountIdQuery,
    useGetApiUserGetAccessRightsForCompanyGroupQuery,
    useGetApiUserGetUserAccountsAndAccessRightsByDepartmentIdQuery,
    useGetApiUserGetUserDataQuery,
    useGetApiUserGetUserAccountsAndAccessRightsByCompanyIdQuery,
    useGetApiUserGetUserAccountIdFromUserIdQuery,
    useGetApiUserGetLoginCredentialsFromUserAccountIdQuery,
    useGetApiUserGetAllLoginCredentialsQuery,
    useGetApiUserGetCompanyUserFromUserAccountIdQuery,
    useGetApiUserGetCompanyUserFromUserAccountIdEditQuery,
    useGetApiUserGetCompanyUserFromUserAccountIdInCompanyGroupQuery,
    usePostApiUserDeleteLoginCredentialMutation,
    usePostApiUserSaveOtpLoginCredentialMutation,
    usePostApiUserSaveLoginCredentialMutation,
    usePostApiUserChangeLoginCredentialsPasswordMutation,
    useGetApiUserGetRemovedUsersQuery,
    usePostApiUserReactivateUserAccountMutation,
    useGetApiUserGetUnstartedUsersQuery,
    usePostApiUserExportAllCompanyPermissionsMutation,
    usePostApiUserExportAllDepartmentPermissionsMutation,
    useGetApiUserGetLoginConfigurationQuery,
    useGetApiUserReserveUserAccountIdsQuery,
} = injectedRtkApi;
