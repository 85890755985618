import { getServices } from "api/services";
import { ProductType } from "../utils/serviceTypes";
import { MedHelpHCMWebApiModelsTemplateRule, MedHelpHCMWebApiModelsCreateTemplate } from "swagger/healthCaseManagement";
import {
    riskInitiationHCMRuleObject,
    socialInsuranceHCMRuleObject,
    manualInitiationHCMRuleObject,
    healthCaseInactiveHCMRuleObject,
    deviantInitiation3in6HCMRuleObject,
    deviantInitiation6in12HCMRuleObject,
    danishLongTermSickLeaveHCMRuleObject,
} from "../utils/ruleObjects";

export interface GenerateHCMTemplatePromises extends MedHelpHCMWebApiModelsCreateTemplate {
    product: ProductType;
    country: string;
}

export const generateHCMTemplatePromises = async ({
    customerId,
    companyId,
    product,
    country,
    retailerId,
}: GenerateHCMTemplatePromises) => {
    const HCMPromises = [];

    const {
        data: { id: HealthPromotionTemplateId },
    } = await getServices().clients.healthCaseTemplates.templatePost({
        customerId,
        companyId,
        name: "healthpromotion",
        healthCaseType: "healthpromotion",
        premium: true,
        retailerId,
    });

    if (HealthPromotionTemplateId) {
        // Default health promotion rules
        const healthPromotionRules: MedHelpHCMWebApiModelsTemplateRule[] = [
            deviantInitiation3in6HCMRuleObject,
            healthCaseInactiveHCMRuleObject,
            manualInitiationHCMRuleObject,
        ];

        // Sweden specific rules
        if (country === "Sweden") {
            healthPromotionRules.push(deviantInitiation6in12HCMRuleObject);
        }

        // Denmark specific rules
        if (country === "Denmark") {
            healthPromotionRules.push(danishLongTermSickLeaveHCMRuleObject);
        }

        // All non legacy products should have the risk initiation rule
        if (product !== "legacy") {
            healthPromotionRules.push(riskInitiationHCMRuleObject);
        }

        HCMPromises.push(
            getServices().clients.healthCaseTemplates.templateRulesTemplateIdPost(HealthPromotionTemplateId, {
                rules: healthPromotionRules,
            }),
        );
    }

    const {
        data: { id: RehabTemplateId },
    } = await getServices().clients.healthCaseTemplates.templatePost({
        customerId,
        companyId,
        name: "rehab",
        healthCaseType: "rehab",
        premium: true,
        predefinedChain: true,
        retailerId,
    });

    if (RehabTemplateId) {
        // Default rehab rules
        const rehabRules: MedHelpHCMWebApiModelsTemplateRule[] = [];

        // Sweden specific rules
        if (country === "Sweden") {
            rehabRules.push({ ruleType: "closing", ruleName: "ChainInactive", params: "87" });
        }

        // Denmark specific rules
        if (country === "Denmark") {
            rehabRules.push({ ruleType: "closing", ruleName: "ChainInactive", params: "0" });
            rehabRules.push({ ruleType: "initiation", ruleName: "DeviantInitiation", params: "longterm,14" });
        }

        // Swedish non base companies should have the social insurance rule
        if (product !== "base" && country === "Sweden") {
            rehabRules.push(socialInsuranceHCMRuleObject);
        }

        // Base companies should be able to manually initiate a rehab case
        if (product === "base") {
            rehabRules.push(manualInitiationHCMRuleObject);
        }

        HCMPromises.push(
            getServices().clients.healthCaseTemplates.templateRulesTemplateIdPost(RehabTemplateId, {
                rules: rehabRules,
            }),
        );
    }
    return HCMPromises;
};
