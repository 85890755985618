import { useAppSelector } from "store/hooks";
import {
    getAdminIsLoading,
    getSelectedCompanyId,
    getSelectedCompanyUserUserId,
    getSelectedUserAccountId,
} from "pages/Administration/redux/administrationSelectors";
import AddUser from "./AddUser";
import { getCustomerSupportAccess } from "store/userSelectors";

export default function Index() {
    const isLoading = useAppSelector(getAdminIsLoading);
    const selectedCompanyId = useAppSelector(getSelectedCompanyId);
    const selectedUserAccountId = useAppSelector(getSelectedUserAccountId);
    const selectedUserUserId = useAppSelector(getSelectedCompanyUserUserId);
    const isCs = useAppSelector(getCustomerSupportAccess);

    return isLoading ? (
        <></>
    ) : (
        <AddUser
            tabsDisabled={!selectedUserAccountId}
            companyId={selectedCompanyId ?? -1}
            userId={selectedUserUserId ?? -1}
            isCs={isCs}
        />
    );
}
