import React, { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { IFieldTemplateExtended } from "interfaces/IFieldTemplateExtended";
import AbsenceReportFieldWrapper from "../AbsenceReportFieldWrapper";
import { FindDynamicComponent } from "components/Dynamic";
import { CheckboxMigrate } from "components/Checkbox";
import RadioButtonGroup from "components/RadioButtonGroup";
import TertiaryButton from "components/TertiaryButton";
import PrimaryButton from "components/PrimaryButton";
import * as UserSelectors from "store/userSelectors";
import { SpinnerWithOverlay } from "components/Spinner";
import { IAbsenceReportExtended } from "interfaces/IAbsenceReportExtended";
import { actions as absenceReportsActions, thunkActions, selectors as absenceReportsSelectors } from "../../redux";
import {
    getAbsenceReportFieldValueByName,
    getAbsenceReportFieldByName,
    absenceReportIsValid,
    absenceReportMandatoryfieldsIsValid,
    getAbsenceReportValueByFieldName,
} from "../../utils/absenceReports";
import GetWidget from "../GetWidget";
import AbsenceReportBase from "../AbsenceReportBase";
import Icon from "components/Icon";
import { useDateTimepickerProps, useSingleDatepickerProps } from "hooks";

interface IAbsenceReportCreate {
    onSubmit: () => void;
    onCancel: () => void;
    show?: boolean;
    className?: string;
}

const AbsenceReportCreate = ({ className, onSubmit, onCancel, show }: IAbsenceReportCreate) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const hasMedicalAdvisory = useAppSelector(UserSelectors.getHasMedicalAdvisory);
    const template = useAppSelector(absenceReportsSelectors.getSelectedTemplate);
    const templateFieldsWithoutRequestedMedicalAdvisory = useAppSelector(
        absenceReportsSelectors.getTemplateFieldsWithoutRequestedMedicalAdvisory,
    );
    const showAbsenceCauseToEmployer = useAppSelector(absenceReportsSelectors.getShowAbsenceCauseToEmployer);
    const absenceReportType = useAppSelector(absenceReportsSelectors.getAbsenceReportType);
    const sendNotification = useAppSelector(absenceReportsSelectors.getSendNotification);
    const employmentGuid = useAppSelector(absenceReportsSelectors.getEmploymentGuid);
    const selectedAbsenceReport = useAppSelector(absenceReportsSelectors.getSelectedAbsenceReport);
    const { Princess } = useAppSelector(absenceReportsSelectors.getAbsenceReportsSlice);

    useEffect(() => {
        if (selectedAbsenceReport?.isSubmitted && !selectedAbsenceReport.isLoading && !selectedAbsenceReport.isError) {
            onSubmit();
        }
    }, [selectedAbsenceReport, onSubmit]);

    const confidentialFieldIsValid = useCallback(() => {
        const absenceCauseField = templateFieldsWithoutRequestedMedicalAdvisory?.find(
            (field) => field.name === "AbsenceCause" && !field.widget && !field.confidential,
        );
        return (absenceCauseField && showAbsenceCauseToEmployer) || !absenceCauseField;
    }, [showAbsenceCauseToEmployer, templateFieldsWithoutRequestedMedicalAdvisory]);

    const handleOnCompleteAbsenceBase = useCallback(
        (absenceReport: IAbsenceReportExtended) => {
            if (absenceReportIsValid(absenceReport)) {
                dispatch(
                    absenceReportsActions.updateSelectedAbsenceReportBase({
                        ...absenceReport,
                        employmentId: employmentGuid,
                    }),
                );
            }
        },
        [dispatch, employmentGuid],
    );

    const handleOnUpdateAbsenceReportBaseChange = useCallback(
        (fieldName: string, value: any, endDate?: string | undefined) => {
            if (selectedAbsenceReport) {
                if (fieldName === "degree") {
                    dispatch(
                        absenceReportsActions.updateSelectedAbsenceReportDegrees({
                            value,
                            timeStamp: selectedAbsenceReport.start,
                        }),
                    );
                } else {
                    dispatch(
                        absenceReportsActions.updateSelectedAbsenceReportBaseDates({
                            fieldName,
                            value,
                            endDate,
                        }),
                    );
                }
            }
        },
        [selectedAbsenceReport, dispatch],
    );

    const handleUpdateField = useCallback(
        (fieldName: string, value: string) => {
            dispatch(
                absenceReportsActions.updateSelectedAbsenceReportField({
                    fieldName,
                    value,
                    templateFields: template?.fields ?? [],
                }),
            );
        },
        [dispatch, template?.fields],
    );

    const handleSendNotification = (value: boolean) => {
        dispatch(absenceReportsActions.updateSuppressNotification(!value));
    };

    const handleSubmit = useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault();
            if (selectedAbsenceReport && template?.id) {
                dispatch(
                    thunkActions.submitSelectedAbsenceReport({
                        absenceReport: selectedAbsenceReport,
                        templateId: template.id,
                        resetAbsenceReport: true,
                        postToPrincess: hasMedicalAdvisory,
                        activity: "new",
                    }),
                );
                if (onSubmit) onSubmit();
            }
        },
        [selectedAbsenceReport, template?.id, onSubmit, dispatch, hasMedicalAdvisory],
    );

    const handleOnCancel = useCallback(() => {
        dispatch(absenceReportsActions.resetSelectedAbsenceReport());
        onCancel();
    }, [dispatch, onCancel]);

    useEffect(() => {
        if (Princess && selectedAbsenceReport) {
            if (
                Princess.noteToEmployer &&
                Princess.noteToEmployer !== "" &&
                getAbsenceReportFieldByName(selectedAbsenceReport?.fields!, "Message") &&
                getAbsenceReportFieldValueByName("Message", selectedAbsenceReport?.fields!) !== Princess.noteToEmployer
            ) {
                handleUpdateField("Message", Princess.noteToEmployer);
            }
        }
    }, [Princess, selectedAbsenceReport, handleUpdateField]);

    const datepickerProps = useSingleDatepickerProps({
        onChangeDate: (v) => handleUpdateField("date", v ?? ""),
        givenSelectedDate: getAbsenceReportFieldValueByName("DynamicDate", selectedAbsenceReport?.fields ?? []),
        placeholder: t("choseDate"),
    });

    const dateTimepickerProps = useDateTimepickerProps({
        onChangeDateTime: (v) => handleUpdateField("datetime", v ?? ""),
        givenSelectedDateTime: getAbsenceReportFieldValueByName("DynamicDateTime", selectedAbsenceReport?.fields ?? []),
        placeholder: t("choseDate"),
    });
    if (!show || !absenceReportType) return null;

    return (
        <div className={`my-6 w-full shadow-lg xxs:mb-8 md:mt-0 ${className} relative border border-grey-300`}>
            <div className="relative w-full bg-pageBg xs:p-8">
                {selectedAbsenceReport?.isLoading && <SpinnerWithOverlay />}
                <div id="adminCreate" className="bg-pageBg p-4 xs:p-8">
                    <div className="flex justify-between">
                        <h3 className="mb-2">{t(_.camelCase(template?.absenceReportType ?? ""))}</h3>
                        <button onClick={handleOnCancel}>
                            <Icon icon="close" />
                        </button>
                    </div>
                    <AbsenceReportBase
                        isUpdate={selectedAbsenceReport?.employmentId ? true : false}
                        isEdit={false}
                        onComplete={handleOnCompleteAbsenceBase}
                        onUpdate={handleOnUpdateAbsenceReportBaseChange}
                    />
                    {templateFieldsWithoutRequestedMedicalAdvisory.map((field: IFieldTemplateExtended) => {
                        return (
                            <div key={field.id} className={`${!selectedAbsenceReport && "opacity-50"}`}>
                                {field.widget && selectedAbsenceReport && template?.fields ? (
                                    <GetWidget
                                        absenceReport={selectedAbsenceReport}
                                        template={template}
                                        allFields={template.fields}
                                        templateField={field}
                                        absenceReportFields={selectedAbsenceReport?.fields ?? []}
                                    />
                                ) : (
                                    <>
                                        {field.name?.toLowerCase() !== "mobile" && (
                                            <AbsenceReportFieldWrapper
                                                heading={field.header!}
                                                isMandatory={field.mandatory!}
                                                modalHeading={field.header!}
                                                modalTopContent={field.description!}
                                            >
                                                <FindDynamicComponent
                                                    templateField={field}
                                                    value={getAbsenceReportFieldValueByName(
                                                        field.name!,
                                                        selectedAbsenceReport?.fields ?? [],
                                                    )}
                                                    disabled={!selectedAbsenceReport}
                                                    isHorizontal={false}
                                                    updateField={(value: string) => {
                                                        handleUpdateField(field.name!, value);
                                                    }}
                                                    datepickerProps={datepickerProps}
                                                    dateTimepickerProps={dateTimepickerProps}
                                                />
                                            </AbsenceReportFieldWrapper>
                                        )}

                                        {field.name === "AbsenceCause" && (
                                            <AbsenceReportFieldWrapper
                                                heading={t("confidential")}
                                                isMandatory={!field.confidential!}
                                            >
                                                <RadioButtonGroup
                                                    isHorizontal
                                                    disabled={
                                                        getAbsenceReportValueByFieldName(
                                                            field.name!,
                                                            selectedAbsenceReport?.fields,
                                                        ) === ""
                                                    }
                                                    clicked={(value: any) => {
                                                        handleUpdateField("AbsenceCause", value);
                                                    }}
                                                    selectedValue={showAbsenceCauseToEmployer}
                                                    values={[
                                                        {
                                                            description: t("yes"),
                                                            value: "true",
                                                        },
                                                        {
                                                            description: t("no"),
                                                            value: "false",
                                                        },
                                                    ]}
                                                    id="AbsenceCause"
                                                />
                                            </AbsenceReportFieldWrapper>
                                        )}
                                    </>
                                )}
                            </div>
                        );
                    })}
                    {!hasMedicalAdvisory && (
                        <AbsenceReportFieldWrapper heading="" isMandatory={false}>
                            <CheckboxMigrate
                                id="suppressNotification"
                                checked={sendNotification}
                                disabled={!selectedAbsenceReport}
                                setChecked={handleSendNotification}
                                label={t("sendNotifications")}
                            />
                        </AbsenceReportFieldWrapper>
                    )}
                </div>
                <div className="shadow-top z-11 flex h-24 items-center justify-center bg-pageBg px-6 xs:relative xs:z-0 xs:mt-8 xs:h-auto xs:w-auto xs:justify-start xs:px-0">
                    <div className="mr-6 flex w-auto justify-center">
                        <TertiaryButton id="Button" text={t("abort")} onClick={handleOnCancel} />
                    </div>
                    <PrimaryButton
                        id="Button"
                        text={t("registerReport")}
                        disabled={
                            !template ||
                            !confidentialFieldIsValid() ||
                            !absenceReportMandatoryfieldsIsValid(
                                selectedAbsenceReport,
                                templateFieldsWithoutRequestedMedicalAdvisory,
                            )
                        }
                        onClick={handleSubmit}
                    />
                </div>
            </div>
        </div>
    );
};
export default AbsenceReportCreate;
