import React, { useState, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";
import { thunkActions, actions } from "../../redux";
import { IFieldTemplateExtended } from "interfaces/IFieldTemplateExtended";
import { FindDynamicComponent } from "components/Dynamic";
import { CheckboxMigrate } from "components/Checkbox";
import RadioButtonGroup from "components/RadioButtonGroup";
import TertiaryButton from "components/TertiaryButton";
import PrimaryButton from "components/PrimaryButton";
import { SpinnerWithOverlay } from "components/Spinner";
import * as UserSelectors from "store/userSelectors";
import {
    getAbsenceReportFieldValueByName,
    absenceReportMandatoryfieldsIsValid,
    getAbsenceReportFieldByName,
} from "../../utils/absenceReports";
import { selectors as absenceReportsSelectors, actions as absenceReportsActions } from "../../redux";
import AbsenceReportBase from "../AbsenceReportBase";
import AbsenceReportFieldWrapper from "../AbsenceReportFieldWrapper";
import GetWidget from "../GetWidget";
import { useDateTimepickerProps, useSingleDatepickerProps } from "hooks";

interface IAbsenceReportEdit {
    className?: string;
    absenceReportId: string;
    onCancel: () => void;
}

const AbsenceReportEdit = ({ className, onCancel }: IAbsenceReportEdit) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [isChanged, setIsChanged] = useState(false);

    const hasMedicalAdvisory = useAppSelector(UserSelectors.getHasMedicalAdvisory);
    const template = useAppSelector(absenceReportsSelectors.getSelectedTemplate);
    const templateFieldsWithoutRequestedMedicalAdvisory = useAppSelector(
        absenceReportsSelectors.getTemplateFieldsWithoutRequestedMedicalAdvisory,
    );
    const selectedAbsenceReport = useAppSelector(absenceReportsSelectors.getSelectedAbsenceReport);
    const sendNotification = useAppSelector(absenceReportsSelectors.getSendNotification);

    const handleOnUpdateAbsenceReportBaseChange = (
        fieldName: string,
        value: any,
        endDate?: string | undefined,
        timeStamp?: string | undefined,
    ) => {
        if (selectedAbsenceReport && selectedAbsenceReport.id) {
            if (fieldName === "degree") {
                dispatch(
                    absenceReportsActions.updateSelectedAbsenceReportDegrees({
                        value,
                        timeStamp,
                    }),
                );
            } else {
                dispatch(
                    absenceReportsActions.updateSelectedAbsenceReportBaseDates({
                        fieldName,
                        value,
                        endDate,
                    }),
                );
            }
        }
        setIsChanged(true);
    };

    const handleUpdateField = useCallback(
        (fieldName: string, value: string) => {
            dispatch(
                actions.updateSelectedAbsenceReportField({
                    fieldName,
                    value,
                    templateFields: template?.fields ?? [],
                }),
            );
            setIsChanged(true);
        },
        [dispatch, template?.fields],
    );

    const handleSendNotification = (value: boolean) => {
        dispatch(actions.updateSuppressNotification(!value));
    };

    const handleSubmit = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        if (selectedAbsenceReport && template?.id) {
            dispatch(
                thunkActions.submitSelectedAbsenceReport({
                    absenceReport: selectedAbsenceReport,
                    templateId: template.id,
                    resetAbsenceReport: true,
                    postToPrincess: hasMedicalAdvisory,
                    activity: "edit",
                }),
            );
        }
    };

    const handleOnCancel = () => {
        dispatch(actions.resetSelectedAbsenceReport());
        onCancel();
    };

    const datepickerProps = useSingleDatepickerProps({
        onChangeDate: (v) => handleUpdateField("DynamicDate", v ?? ""),
        givenSelectedDate: getAbsenceReportFieldValueByName("DynamicDate", selectedAbsenceReport?.fields ?? []),
        placeholder: t("choseDate"),
    });

    const dateTimepickerProps = useDateTimepickerProps({
        onChangeDateTime: (v) => handleUpdateField("DynamicDateTime", v ?? ""),
        givenSelectedDateTime: getAbsenceReportFieldValueByName("DynamicDateTime", selectedAbsenceReport?.fields ?? []),
        placeholder: t("choseDate"),
    });

    return (
        <div className={`my-6 w-full shadow-lg xxs:mb-8 ${className} relative border border-grey-300`}>
            <div className="flex w-full flex-col xs:p-0 md:p-6">
                {selectedAbsenceReport?.isLoading && <SpinnerWithOverlay />}
                <div id="adminEdit" className="relative w-full bg-pageBg xs:p-8">
                    <div id="adminEdit" className="relative w-full">
                        <div className="bg-pageBg p-4 xs:p-8">
                            <h3>{t("editReport")}</h3>
                            {selectedAbsenceReport && (
                                <AbsenceReportBase
                                    isEdit={true}
                                    isUpdate={selectedAbsenceReport ? true : false}
                                    onUpdate={handleOnUpdateAbsenceReportBaseChange}
                                />
                            )}
                            {templateFieldsWithoutRequestedMedicalAdvisory.map((field: IFieldTemplateExtended) => {
                                return (
                                    <div key={field.id}>
                                        {field.widget && selectedAbsenceReport && template?.fields ? (
                                            <GetWidget
                                                absenceReport={selectedAbsenceReport}
                                                template={template}
                                                allFields={template.fields}
                                                templateField={field}
                                                absenceReportFields={selectedAbsenceReport?.fields ?? []}
                                            />
                                        ) : (
                                            <>
                                                {getAbsenceReportFieldValueByName(
                                                    field.name ?? "",
                                                    selectedAbsenceReport?.fields ?? [],
                                                ).toLowerCase() !== "confidential" &&
                                                    field.name?.toLowerCase() !== "mobile" && (
                                                        <AbsenceReportFieldWrapper
                                                            heading={field.header!}
                                                            isMandatory={field.mandatory!}
                                                            modalHeading={field.header!}
                                                            modalTopContent={field.description!}
                                                        >
                                                            <FindDynamicComponent
                                                                templateField={field}
                                                                value={getAbsenceReportFieldValueByName(
                                                                    field.name!,
                                                                    selectedAbsenceReport?.fields ?? [],
                                                                )}
                                                                disabled={field.name === "Mobile" ? true : false}
                                                                updateField={(value: string) => {
                                                                    handleUpdateField(field.name!, value);
                                                                }}
                                                                datepickerProps={datepickerProps}
                                                                dateTimepickerProps={dateTimepickerProps}
                                                            />
                                                        </AbsenceReportFieldWrapper>
                                                    )}
                                                {field.name === "AbsenceCause" && (
                                                    <AbsenceReportFieldWrapper
                                                        heading={t("confidential")}
                                                        isMandatory={!field.confidential!}
                                                    >
                                                        <RadioButtonGroup
                                                            isHorizontal
                                                            disabled={
                                                                getAbsenceReportFieldValueByName(
                                                                    field.name!,
                                                                    selectedAbsenceReport?.fields ?? [],
                                                                ).toLowerCase() === "confidential" ||
                                                                getAbsenceReportFieldValueByName(
                                                                    field.name!,
                                                                    selectedAbsenceReport?.fields ?? [],
                                                                ) === ""
                                                            }
                                                            clicked={(value: any) => {
                                                                handleUpdateField("AbsenceCause", value);
                                                            }}
                                                            selectedValue={String(
                                                                !getAbsenceReportFieldByName(
                                                                    selectedAbsenceReport?.fields ?? [],
                                                                    "AbsenceCause",
                                                                )?.confidential,
                                                            )}
                                                            values={[
                                                                {
                                                                    description: t("yes"),
                                                                    value: "true",
                                                                },
                                                                {
                                                                    description: t("no"),
                                                                    value: "false",
                                                                },
                                                            ]}
                                                            id="AbsenceCause"
                                                        />
                                                    </AbsenceReportFieldWrapper>
                                                )}
                                            </>
                                        )}
                                    </div>
                                );
                            })}
                            <AbsenceReportFieldWrapper heading="" isMandatory={false}>
                                <CheckboxMigrate
                                    id="suppressNotification"
                                    checked={sendNotification}
                                    disabled={!selectedAbsenceReport?.id}
                                    setChecked={handleSendNotification}
                                    label={t("sendNotifications")}
                                />
                            </AbsenceReportFieldWrapper>
                        </div>
                        <div className="shadow-top z-20 flex h-24 items-center justify-center bg-pageBg px-6 xs:relative xs:z-0 xs:mt-8 xs:h-auto xs:w-auto xs:justify-start xs:px-0">
                            <div className="mr-6 flex w-auto justify-center">
                                <TertiaryButton id="Button" text={t("abort")} onClick={handleOnCancel} />
                            </div>
                            <PrimaryButton
                                id="Button"
                                text={t("saveChanges")}
                                disabled={
                                    (!isChanged && !selectedAbsenceReport?.isPreliminary) ||
                                    !absenceReportMandatoryfieldsIsValid(
                                        selectedAbsenceReport,
                                        templateFieldsWithoutRequestedMedicalAdvisory,
                                    )
                                }
                                onClick={handleSubmit}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AbsenceReportEdit;
