/* tslint:disable */
/* eslint-disable */
/**
 * MedHelp.HCM.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface MedHelpHCMDomainActivityReason
 */
export interface MedHelpHCMDomainActivityReason {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainActivityReason
     */
    'activityId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainActivityReason
     */
    'reasonId'?: string;
}
/**
 * 
 * @export
 * @interface MedHelpHCMDomainActivityTemplate
 */
export interface MedHelpHCMDomainActivityTemplate {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainActivityTemplate
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainActivityTemplate
     */
    'templateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainActivityTemplate
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainActivityTemplate
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainActivityTemplate
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpHCMDomainActivityTemplate
     */
    'showFormDocument'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainActivityTemplate
     */
    'formDocumentId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMDomainActivityTemplate
     */
    'day'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainActivityTemplate
     */
    'type'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMDomainActivityTemplate
     */
    'createdBy'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainActivityTemplate
     */
    'createdDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpHCMDomainActivityTemplate
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainActivityTemplate
     */
    'source'?: string | null;
    /**
     * 
     * @type {Array<MedHelpHCMDomainTemplateReason>}
     * @memberof MedHelpHCMDomainActivityTemplate
     */
    'templateReasons'?: Array<MedHelpHCMDomainTemplateReason> | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMDomainPredefinedActivity
 */
export interface MedHelpHCMDomainPredefinedActivity {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainPredefinedActivity
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainPredefinedActivity
     */
    'region'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainPredefinedActivity
     */
    'source'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainPredefinedActivity
     */
    'caseType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainPredefinedActivity
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainPredefinedActivity
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainPredefinedActivity
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpHCMDomainPredefinedActivity
     */
    'showFormDocument'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainPredefinedActivity
     */
    'formDocumentId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMDomainPredefinedActivity
     */
    'day'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainPredefinedActivity
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainPredefinedActivity
     */
    'kind'?: string | null;
    /**
     * 
     * @type {Array<MedHelpHCMDomainPredefinedActivityDescriptions>}
     * @memberof MedHelpHCMDomainPredefinedActivity
     */
    'translations'?: Array<MedHelpHCMDomainPredefinedActivityDescriptions> | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMDomainPredefinedActivityDescriptions
 */
export interface MedHelpHCMDomainPredefinedActivityDescriptions {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainPredefinedActivityDescriptions
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMDomainPredefinedActivityDescriptions
     */
    'day'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainPredefinedActivityDescriptions
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainPredefinedActivityDescriptions
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainPredefinedActivityDescriptions
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainPredefinedActivityDescriptions
     */
    'language'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMDomainPredefinedReason
 */
export interface MedHelpHCMDomainPredefinedReason {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainPredefinedReason
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainPredefinedReason
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainPredefinedReason
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainPredefinedReason
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpHCMDomainPredefinedReason
     */
    'isManual'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainPredefinedReason
     */
    'healthCaseType'?: string | null;
    /**
     * 
     * @type {Array<MedHelpHCMDomainPredefinedReasonDescriptions>}
     * @memberof MedHelpHCMDomainPredefinedReason
     */
    'translations'?: Array<MedHelpHCMDomainPredefinedReasonDescriptions> | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMDomainPredefinedReasonDescriptions
 */
export interface MedHelpHCMDomainPredefinedReasonDescriptions {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainPredefinedReasonDescriptions
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainPredefinedReasonDescriptions
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainPredefinedReasonDescriptions
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainPredefinedReasonDescriptions
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainPredefinedReasonDescriptions
     */
    'language'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMDomainRulesRuleTemplate
 */
export interface MedHelpHCMDomainRulesRuleTemplate {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainRulesRuleTemplate
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainRulesRuleTemplate
     */
    'templateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainRulesRuleTemplate
     */
    'ruleType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainRulesRuleTemplate
     */
    'ruleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainRulesRuleTemplate
     */
    'params'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpHCMDomainRulesRuleTemplate
     */
    'startDirect'?: boolean;
}
/**
 * 
 * @export
 * @interface MedHelpHCMDomainTemplate
 */
export interface MedHelpHCMDomainTemplate {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainTemplate
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainTemplate
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainTemplate
     */
    'healthCaseType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMDomainTemplate
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMDomainTemplate
     */
    'companyId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpHCMDomainTemplate
     */
    'premium'?: boolean;
    /**
     * 
     * @type {Array<MedHelpHCMDomainActivityTemplate>}
     * @memberof MedHelpHCMDomainTemplate
     */
    'activityTemplates'?: Array<MedHelpHCMDomainActivityTemplate> | null;
    /**
     * 
     * @type {Array<MedHelpHCMDomainRulesRuleTemplate>}
     * @memberof MedHelpHCMDomainTemplate
     */
    'ruleTemplates'?: Array<MedHelpHCMDomainRulesRuleTemplate> | null;
    /**
     * 
     * @type {Array<MedHelpHCMDomainTemplateReason>}
     * @memberof MedHelpHCMDomainTemplate
     */
    'templateReasons'?: Array<MedHelpHCMDomainTemplateReason> | null;
    /**
     * 
     * @type {Array<MedHelpHCMDomainTemplateFormDocument>}
     * @memberof MedHelpHCMDomainTemplate
     */
    'templateFormDocuments'?: Array<MedHelpHCMDomainTemplateFormDocument> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpHCMDomainTemplate
     */
    'containsChain'?: boolean;
}
/**
 * 
 * @export
 * @interface MedHelpHCMDomainTemplateFormDocument
 */
export interface MedHelpHCMDomainTemplateFormDocument {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainTemplateFormDocument
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainTemplateFormDocument
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainTemplateFormDocument
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainTemplateFormDocument
     */
    'ownerReferenceType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainTemplateFormDocument
     */
    'ownerReferenceKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainTemplateFormDocument
     */
    'pdfDocumentReferenceKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainTemplateFormDocument
     */
    'region'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainTemplateFormDocument
     */
    'language'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpHCMDomainTemplateFormDocument
     */
    'active'?: boolean;
}
/**
 * 
 * @export
 * @interface MedHelpHCMDomainTemplateReason
 */
export interface MedHelpHCMDomainTemplateReason {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainTemplateReason
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainTemplateReason
     */
    'templateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainTemplateReason
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainTemplateReason
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMDomainTemplateReason
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpHCMDomainTemplateReason
     */
    'isManual'?: boolean;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiControllersHealthCaseActivitiesResponse
 */
export interface MedHelpHCMWebApiControllersHealthCaseActivitiesResponse {
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMWebApiControllersHealthCaseActivitiesResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMWebApiControllersHealthCaseActivitiesResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMWebApiControllersHealthCaseActivitiesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMWebApiControllersHealthCaseActivitiesResponse
     */
    'totalUniqueEmployments'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMWebApiControllersHealthCaseActivitiesResponse
     */
    'totalUniqueCases'?: number;
    /**
     * 
     * @type {Array<MedHelpHCMWebApiResponseSearchHealthCaseActivitiesResponse>}
     * @memberof MedHelpHCMWebApiControllersHealthCaseActivitiesResponse
     */
    'page'?: Array<MedHelpHCMWebApiResponseSearchHealthCaseActivitiesResponse> | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiControllersHealthCaseResponse
 */
export interface MedHelpHCMWebApiControllersHealthCaseResponse {
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMWebApiControllersHealthCaseResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMWebApiControllersHealthCaseResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMWebApiControllersHealthCaseResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMWebApiControllersHealthCaseResponse
     */
    'totalUniqueEmployments'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMWebApiControllersHealthCaseResponse
     */
    'totalUniqueCases'?: number;
    /**
     * 
     * @type {Array<MedHelpHCMWebApiResponseSearchHealthCaseResponse>}
     * @memberof MedHelpHCMWebApiControllersHealthCaseResponse
     */
    'page'?: Array<MedHelpHCMWebApiResponseSearchHealthCaseResponse> | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiControllersPingModel
 */
export interface MedHelpHCMWebApiControllersPingModel {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiControllersPingModel
     */
    'message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiControllersPingModel
     */
    'timestamp'?: string;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiModelsAddTemplateActivities
 */
export interface MedHelpHCMWebApiModelsAddTemplateActivities {
    /**
     * 
     * @type {Array<MedHelpHCMWebApiModelsTemplateActivity>}
     * @memberof MedHelpHCMWebApiModelsAddTemplateActivities
     */
    'activities'?: Array<MedHelpHCMWebApiModelsTemplateActivity> | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiModelsAddTemplateRules
 */
export interface MedHelpHCMWebApiModelsAddTemplateRules {
    /**
     * 
     * @type {Array<MedHelpHCMWebApiModelsTemplateRule>}
     * @memberof MedHelpHCMWebApiModelsAddTemplateRules
     */
    'rules'?: Array<MedHelpHCMWebApiModelsTemplateRule> | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiModelsCreateHealthCaseActivityModel
 */
export interface MedHelpHCMWebApiModelsCreateHealthCaseActivityModel {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsCreateHealthCaseActivityModel
     */
    'healthCaseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsCreateHealthCaseActivityModel
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsCreateHealthCaseActivityModel
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsCreateHealthCaseActivityModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpHCMWebApiModelsCreateHealthCaseActivityModel
     */
    'showFormDocument'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsCreateHealthCaseActivityModel
     */
    'formDocumentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsCreateHealthCaseActivityModel
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiModelsCreateHealthCaseDocument
 */
export interface MedHelpHCMWebApiModelsCreateHealthCaseDocument {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsCreateHealthCaseDocument
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsCreateHealthCaseDocument
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsCreateHealthCaseDocument
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiModelsCreateHealthCaseModel
 */
export interface MedHelpHCMWebApiModelsCreateHealthCaseModel {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsCreateHealthCaseModel
     */
    'employmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsCreateHealthCaseModel
     */
    'templateId'?: string;
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMWebApiModelsCreateHealthCaseModel
     */
    'responsibleUserAccountId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsCreateHealthCaseModel
     */
    'responsibleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsCreateHealthCaseModel
     */
    'initiationCauseCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsCreateHealthCaseModel
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsCreateHealthCaseModel
     */
    'startDate'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiModelsCreateTemplate
 */
export interface MedHelpHCMWebApiModelsCreateTemplate {
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMWebApiModelsCreateTemplate
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMWebApiModelsCreateTemplate
     */
    'companyId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsCreateTemplate
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsCreateTemplate
     */
    'healthCaseType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpHCMWebApiModelsCreateTemplate
     */
    'premium'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpHCMWebApiModelsCreateTemplate
     */
    'predefinedChain'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMWebApiModelsCreateTemplate
     */
    'retailerId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsCreateTemplate
     */
    'region'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsCreateTemplate
     */
    'source'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiModelsDownloadFileWithTemplateModel
 */
export interface MedHelpHCMWebApiModelsDownloadFileWithTemplateModel {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsDownloadFileWithTemplateModel
     */
    'templateDocumentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsDownloadFileWithTemplateModel
     */
    'annotation'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiModelsEndHealthCaseModel
 */
export interface MedHelpHCMWebApiModelsEndHealthCaseModel {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsEndHealthCaseModel
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsEndHealthCaseModel
     */
    'reason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsEndHealthCaseModel
     */
    'comment'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiModelsExportHealthCaseModel
 */
export interface MedHelpHCMWebApiModelsExportHealthCaseModel {
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpHCMWebApiModelsExportHealthCaseModel
     */
    'details'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpHCMWebApiModelsExportHealthCaseModel
     */
    'activities'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpHCMWebApiModelsExportHealthCaseModel
     */
    'notes'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpHCMWebApiModelsExportHealthCaseModel
     */
    'documentsAndTemplates'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpHCMWebApiModelsExportHealthCaseModel
     */
    'caseLog'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsExportHealthCaseModel
     */
    'language'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiModelsSaveTemplateDocuments
 */
export interface MedHelpHCMWebApiModelsSaveTemplateDocuments {
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpHCMWebApiModelsSaveTemplateDocuments
     */
    'documentIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiModelsSetHealthCaseResponsibleModel
 */
export interface MedHelpHCMWebApiModelsSetHealthCaseResponsibleModel {
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMWebApiModelsSetHealthCaseResponsibleModel
     */
    'userAccountId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsSetHealthCaseResponsibleModel
     */
    'userName'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiModelsSetHealthCaseStatusModel
 */
export interface MedHelpHCMWebApiModelsSetHealthCaseStatusModel {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsSetHealthCaseStatusModel
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsSetHealthCaseStatusModel
     */
    'reason'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiModelsTemplateActivity
 */
export interface MedHelpHCMWebApiModelsTemplateActivity {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsTemplateActivity
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsTemplateActivity
     */
    'templateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsTemplateActivity
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsTemplateActivity
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsTemplateActivity
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpHCMWebApiModelsTemplateActivity
     */
    'showFormDocument'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsTemplateActivity
     */
    'formDocumentId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMWebApiModelsTemplateActivity
     */
    'day'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsTemplateActivity
     */
    'type'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMWebApiModelsTemplateActivity
     */
    'createdBy'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpHCMWebApiModelsTemplateActivity
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsTemplateActivity
     */
    'source'?: string | null;
    /**
     * 
     * @type {Array<MedHelpHCMWebApiModelsTemplateDescription>}
     * @memberof MedHelpHCMWebApiModelsTemplateActivity
     */
    'translations'?: Array<MedHelpHCMWebApiModelsTemplateDescription> | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiModelsTemplateDescription
 */
export interface MedHelpHCMWebApiModelsTemplateDescription {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsTemplateDescription
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsTemplateDescription
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsTemplateDescription
     */
    'language'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiModelsTemplateRule
 */
export interface MedHelpHCMWebApiModelsTemplateRule {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsTemplateRule
     */
    'ruleType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsTemplateRule
     */
    'ruleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsTemplateRule
     */
    'params'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpHCMWebApiModelsTemplateRule
     */
    'startDirect'?: boolean;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiModelsUpdateFileWithTemplateModel
 */
export interface MedHelpHCMWebApiModelsUpdateFileWithTemplateModel {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsUpdateFileWithTemplateModel
     */
    'annotation'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiModelsUpdateHealthCaseActivityModel
 */
export interface MedHelpHCMWebApiModelsUpdateHealthCaseActivityModel {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsUpdateHealthCaseActivityModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsUpdateHealthCaseActivityModel
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsUpdateHealthCaseActivityModel
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsUpdateHealthCaseActivityModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpHCMWebApiModelsUpdateHealthCaseActivityModel
     */
    'showFormDocument'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsUpdateHealthCaseActivityModel
     */
    'formDocumentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsUpdateHealthCaseActivityModel
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiModelsUpdateHealthCaseDocument
 */
export interface MedHelpHCMWebApiModelsUpdateHealthCaseDocument {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsUpdateHealthCaseDocument
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsUpdateHealthCaseDocument
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsUpdateHealthCaseDocument
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiModelsUploadFileWithTemplateModel
 */
export interface MedHelpHCMWebApiModelsUploadFileWithTemplateModel {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsUploadFileWithTemplateModel
     */
    'documentTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsUploadFileWithTemplateModel
     */
    'annotation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiModelsUploadFileWithTemplateModel
     */
    'type'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiRequestCopyHealthCaseDocumentsRequest
 */
export interface MedHelpHCMWebApiRequestCopyHealthCaseDocumentsRequest {
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpHCMWebApiRequestCopyHealthCaseDocumentsRequest
     */
    'moveNotCopy'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiRequestCopyHealthCaseDocumentsRequest
     */
    'originHealthCaseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiRequestCopyHealthCaseDocumentsRequest
     */
    'targetHealthCaseId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpHCMWebApiRequestCopyHealthCaseDocumentsRequest
     */
    'healthCaseDocumentIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiRequestDashboardFiltersRequest
 */
export interface MedHelpHCMWebApiRequestDashboardFiltersRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpHCMWebApiRequestDashboardFiltersRequest
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpHCMWebApiRequestDashboardFiltersRequest
     */
    'templateIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpHCMWebApiRequestDashboardFiltersRequest
     */
    'genders'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpHCMWebApiRequestDashboardFiltersRequest
     */
    'employmentGroups'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiRequestExcelSearchHealthCaseActivitiesRequest
 */
export interface MedHelpHCMWebApiRequestExcelSearchHealthCaseActivitiesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpHCMWebApiRequestExcelSearchHealthCaseActivitiesRequest
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpHCMWebApiRequestExcelSearchHealthCaseActivitiesRequest
     */
    'templateIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpHCMWebApiRequestExcelSearchHealthCaseActivitiesRequest
     */
    'genders'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpHCMWebApiRequestExcelSearchHealthCaseActivitiesRequest
     */
    'employmentGroups'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiRequestExcelSearchHealthCaseActivitiesRequest
     */
    'from'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiRequestExcelSearchHealthCaseActivitiesRequest
     */
    'to'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpHCMWebApiRequestExcelSearchHealthCaseActivitiesRequest
     */
    'status'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpHCMWebApiRequestExcelSearchHealthCaseActivitiesRequest
     */
    'descending'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiRequestExcelSearchHealthCaseActivitiesRequest
     */
    'groupBy'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMWebApiRequestExcelSearchHealthCaseActivitiesRequest
     */
    'page'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiRequestExcelSearchHealthCaseActivitiesRequest
     */
    'language'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMWebApiRequestExcelSearchHealthCaseActivitiesRequest
     */
    'delayedByDays'?: number | null;
    /**
     * 
     * @type {MedHelpHCMWebApiRequestExcelTranslations}
     * @memberof MedHelpHCMWebApiRequestExcelSearchHealthCaseActivitiesRequest
     */
    'translation'?: MedHelpHCMWebApiRequestExcelTranslations;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiRequestExcelSearchHealthCaseRequest
 */
export interface MedHelpHCMWebApiRequestExcelSearchHealthCaseRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpHCMWebApiRequestExcelSearchHealthCaseRequest
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpHCMWebApiRequestExcelSearchHealthCaseRequest
     */
    'templateIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpHCMWebApiRequestExcelSearchHealthCaseRequest
     */
    'genders'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpHCMWebApiRequestExcelSearchHealthCaseRequest
     */
    'employmentGroups'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiRequestExcelSearchHealthCaseRequest
     */
    'from'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiRequestExcelSearchHealthCaseRequest
     */
    'to'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpHCMWebApiRequestExcelSearchHealthCaseRequest
     */
    'status'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpHCMWebApiRequestExcelSearchHealthCaseRequest
     */
    'descending'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiRequestExcelSearchHealthCaseRequest
     */
    'groupBy'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMWebApiRequestExcelSearchHealthCaseRequest
     */
    'page'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiRequestExcelSearchHealthCaseRequest
     */
    'language'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpHCMWebApiRequestExcelSearchHealthCaseRequest
     */
    'causes'?: Array<string> | null;
    /**
     * 
     * @type {MedHelpHCMWebApiRequestExcelTranslations}
     * @memberof MedHelpHCMWebApiRequestExcelSearchHealthCaseRequest
     */
    'translation'?: MedHelpHCMWebApiRequestExcelTranslations;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiRequestExcelTranslations
 */
export interface MedHelpHCMWebApiRequestExcelTranslations {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiRequestExcelTranslations
     */
    'workbookTitle'?: string | null;
    /**
     * 
     * @type {Array<MedHelpHCMWebApiRequestNameValue>}
     * @memberof MedHelpHCMWebApiRequestExcelTranslations
     */
    'titles'?: Array<MedHelpHCMWebApiRequestNameValue> | null;
    /**
     * 
     * @type {Array<MedHelpHCMWebApiRequestNameValue>}
     * @memberof MedHelpHCMWebApiRequestExcelTranslations
     */
    'healthCaseType'?: Array<MedHelpHCMWebApiRequestNameValue> | null;
    /**
     * 
     * @type {Array<MedHelpHCMWebApiRequestNameValue>}
     * @memberof MedHelpHCMWebApiRequestExcelTranslations
     */
    'status'?: Array<MedHelpHCMWebApiRequestNameValue> | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiRequestNameValue
 */
export interface MedHelpHCMWebApiRequestNameValue {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiRequestNameValue
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiRequestNameValue
     */
    'value'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiRequestSearchHealthCaseActivitiesRequest
 */
export interface MedHelpHCMWebApiRequestSearchHealthCaseActivitiesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpHCMWebApiRequestSearchHealthCaseActivitiesRequest
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpHCMWebApiRequestSearchHealthCaseActivitiesRequest
     */
    'templateIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpHCMWebApiRequestSearchHealthCaseActivitiesRequest
     */
    'genders'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpHCMWebApiRequestSearchHealthCaseActivitiesRequest
     */
    'employmentGroups'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiRequestSearchHealthCaseActivitiesRequest
     */
    'from'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiRequestSearchHealthCaseActivitiesRequest
     */
    'to'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpHCMWebApiRequestSearchHealthCaseActivitiesRequest
     */
    'status'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpHCMWebApiRequestSearchHealthCaseActivitiesRequest
     */
    'descending'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiRequestSearchHealthCaseActivitiesRequest
     */
    'groupBy'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMWebApiRequestSearchHealthCaseActivitiesRequest
     */
    'page'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiRequestSearchHealthCaseActivitiesRequest
     */
    'language'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMWebApiRequestSearchHealthCaseActivitiesRequest
     */
    'delayedByDays'?: number | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiRequestSearchHealthCaseRequest
 */
export interface MedHelpHCMWebApiRequestSearchHealthCaseRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpHCMWebApiRequestSearchHealthCaseRequest
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpHCMWebApiRequestSearchHealthCaseRequest
     */
    'templateIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpHCMWebApiRequestSearchHealthCaseRequest
     */
    'genders'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpHCMWebApiRequestSearchHealthCaseRequest
     */
    'employmentGroups'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiRequestSearchHealthCaseRequest
     */
    'from'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiRequestSearchHealthCaseRequest
     */
    'to'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpHCMWebApiRequestSearchHealthCaseRequest
     */
    'status'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpHCMWebApiRequestSearchHealthCaseRequest
     */
    'descending'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiRequestSearchHealthCaseRequest
     */
    'groupBy'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMWebApiRequestSearchHealthCaseRequest
     */
    'page'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiRequestSearchHealthCaseRequest
     */
    'language'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpHCMWebApiRequestSearchHealthCaseRequest
     */
    'causes'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiResponseChainInformation
 */
export interface MedHelpHCMWebApiResponseChainInformation {
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMWebApiResponseChainInformation
     */
    'day'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseChainInformation
     */
    'status'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiResponseDepartment
 */
export interface MedHelpHCMWebApiResponseDepartment {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseDepartment
     */
    'departmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseDepartment
     */
    'departmentName'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiResponseHealthCase
 */
export interface MedHelpHCMWebApiResponseHealthCase {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCase
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCase
     */
    'employmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCase
     */
    'status'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMWebApiResponseHealthCase
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCase
     */
    'templateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCase
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCase
     */
    'updated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCase
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCase
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCase
     */
    'end'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpHCMWebApiResponseHealthCase
     */
    'premium'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCase
     */
    'automaticEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCase
     */
    'responsible'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCase
     */
    'initiationCause'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCase
     */
    'initiationCauseCode'?: string | null;
    /**
     * 
     * @type {MedHelpHCMWebApiResponseChainInformation}
     * @memberof MedHelpHCMWebApiResponseHealthCase
     */
    'chainInformation'?: MedHelpHCMWebApiResponseChainInformation;
    /**
     * 
     * @type {Array<MedHelpHCMWebApiResponseHealthCaseEvent>}
     * @memberof MedHelpHCMWebApiResponseHealthCase
     */
    'healthCaseEvents'?: Array<MedHelpHCMWebApiResponseHealthCaseEvent> | null;
    /**
     * 
     * @type {Array<MedHelpHCMWebApiResponseHealthCaseExternalReference>}
     * @memberof MedHelpHCMWebApiResponseHealthCase
     */
    'healthCaseExternalReferences'?: Array<MedHelpHCMWebApiResponseHealthCaseExternalReference> | null;
    /**
     * 
     * @type {Array<MedHelpHCMWebApiResponseHealthCaseCause>}
     * @memberof MedHelpHCMWebApiResponseHealthCase
     */
    'healthCaseCauses'?: Array<MedHelpHCMWebApiResponseHealthCaseCause> | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiResponseHealthCaseActivitiesResult
 */
export interface MedHelpHCMWebApiResponseHealthCaseActivitiesResult {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseActivitiesResult
     */
    'employmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseActivitiesResult
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseActivitiesResult
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseActivitiesResult
     */
    'employmentNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseActivitiesResult
     */
    'socialSecurityNo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseActivitiesResult
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseActivitiesResult
     */
    'healthCaseActivityId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseActivitiesResult
     */
    'healthCaseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseActivitiesResult
     */
    'healthCaseType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseActivitiesResult
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseActivitiesResult
     */
    'cause'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseActivitiesResult
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMWebApiResponseHealthCaseActivitiesResult
     */
    'responsibleUserAccountId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseActivitiesResult
     */
    'responsibleName'?: string | null;
    /**
     * 
     * @type {Array<MedHelpHCMWebApiResponseDepartment>}
     * @memberof MedHelpHCMWebApiResponseHealthCaseActivitiesResult
     */
    'departments'?: Array<MedHelpHCMWebApiResponseDepartment> | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiResponseHealthCaseActivity
 */
export interface MedHelpHCMWebApiResponseHealthCaseActivity {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseActivity
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseActivity
     */
    'healthCaseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseActivity
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseActivity
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseActivity
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpHCMWebApiResponseHealthCaseActivity
     */
    'showFormDocument'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseActivity
     */
    'formDocumentId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMWebApiResponseHealthCaseActivity
     */
    'day'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseActivity
     */
    'source'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpHCMWebApiResponseHealthCaseActivity
     */
    'performed'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMWebApiResponseHealthCaseActivity
     */
    'performedBy'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseActivity
     */
    'performedByUserName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseActivity
     */
    'performedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseActivity
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseActivity
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseActivity
     */
    'updated'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMWebApiResponseHealthCaseActivity
     */
    'createdBy'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseActivity
     */
    'createdByUserName'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiResponseHealthCaseCause
 */
export interface MedHelpHCMWebApiResponseHealthCaseCause {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseCause
     */
    'cause'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseCause
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseCause
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiResponseHealthCaseDocument
 */
export interface MedHelpHCMWebApiResponseHealthCaseDocument {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseDocument
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseDocument
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseDocument
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseDocument
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseDocument
     */
    'created'?: string;
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMWebApiResponseHealthCaseDocument
     */
    'createdBy'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseDocument
     */
    'createdByUserName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseDocument
     */
    'updated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseDocument
     */
    'templateDocumentId'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiResponseHealthCaseEvent
 */
export interface MedHelpHCMWebApiResponseHealthCaseEvent {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseEvent
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseEvent
     */
    'healthCaseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseEvent
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseEvent
     */
    'timeStamp'?: string;
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMWebApiResponseHealthCaseEvent
     */
    'userAccountId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseEvent
     */
    'reportedVia'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseEvent
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseEvent
     */
    'operation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseEvent
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseEvent
     */
    'createdByUserName'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiResponseHealthCaseExternalReference
 */
export interface MedHelpHCMWebApiResponseHealthCaseExternalReference {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseExternalReference
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseExternalReference
     */
    'refenceKey'?: string;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiResponseHealthCasePeriodResponse
 */
export interface MedHelpHCMWebApiResponseHealthCasePeriodResponse {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCasePeriodResponse
     */
    'employmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCasePeriodResponse
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCasePeriodResponse
     */
    'to'?: string;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiResponseHealthCaseResult
 */
export interface MedHelpHCMWebApiResponseHealthCaseResult {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseResult
     */
    'employmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseResult
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseResult
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseResult
     */
    'employmentNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseResult
     */
    'socialSecurityNo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseResult
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseResult
     */
    'healthCaseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseResult
     */
    'healthCaseType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseResult
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseResult
     */
    'cause'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseResult
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseResult
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseResult
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMWebApiResponseHealthCaseResult
     */
    'responsibleUserAccountId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseHealthCaseResult
     */
    'responsibleName'?: string | null;
    /**
     * 
     * @type {Array<MedHelpHCMWebApiResponseDepartment>}
     * @memberof MedHelpHCMWebApiResponseHealthCaseResult
     */
    'departments'?: Array<MedHelpHCMWebApiResponseDepartment> | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiResponseOverviewStatus
 */
export interface MedHelpHCMWebApiResponseOverviewStatus {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseOverviewStatus
     */
    'status'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMWebApiResponseOverviewStatus
     */
    'totalCount'?: number;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiResponseResponsibleResponse
 */
export interface MedHelpHCMWebApiResponseResponsibleResponse {
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMWebApiResponseResponsibleResponse
     */
    'userAccountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseResponsibleResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpHCMWebApiResponseResponsibleResponse
     */
    'departments'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiResponseSearchHealthCaseActivitiesResponse
 */
export interface MedHelpHCMWebApiResponseSearchHealthCaseActivitiesResponse {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseSearchHealthCaseActivitiesResponse
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseSearchHealthCaseActivitiesResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseSearchHealthCaseActivitiesResponse
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<MedHelpHCMWebApiResponseHealthCaseActivitiesResult>}
     * @memberof MedHelpHCMWebApiResponseSearchHealthCaseActivitiesResponse
     */
    'healthCaseActivitiess'?: Array<MedHelpHCMWebApiResponseHealthCaseActivitiesResult> | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiResponseSearchHealthCaseResponse
 */
export interface MedHelpHCMWebApiResponseSearchHealthCaseResponse {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseSearchHealthCaseResponse
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseSearchHealthCaseResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseSearchHealthCaseResponse
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<MedHelpHCMWebApiResponseHealthCaseResult>}
     * @memberof MedHelpHCMWebApiResponseSearchHealthCaseResponse
     */
    'healthCases'?: Array<MedHelpHCMWebApiResponseHealthCaseResult> | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiResponseStatusOverviewResponse
 */
export interface MedHelpHCMWebApiResponseStatusOverviewResponse {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseStatusOverviewResponse
     */
    'templateName'?: string | null;
    /**
     * 
     * @type {Array<MedHelpHCMWebApiResponseOverviewStatus>}
     * @memberof MedHelpHCMWebApiResponseStatusOverviewResponse
     */
    'overviewStatus'?: Array<MedHelpHCMWebApiResponseOverviewStatus> | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiResponseTemplateFormDocumentResponse
 */
export interface MedHelpHCMWebApiResponseTemplateFormDocumentResponse {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseTemplateFormDocumentResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseTemplateFormDocumentResponse
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseTemplateFormDocumentResponse
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseTemplateFormDocumentResponse
     */
    'ownerReferenceType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseTemplateFormDocumentResponse
     */
    'language'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseTemplateFormDocumentResponse
     */
    'pdfDocumentReferenceKey'?: string;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiResponseTodoAndOngoingResponse
 */
export interface MedHelpHCMWebApiResponseTodoAndOngoingResponse {
    /**
     * 
     * @type {Array<MedHelpHCMWebApiResponseTodoAndOngoingRow>}
     * @memberof MedHelpHCMWebApiResponseTodoAndOngoingResponse
     */
    'attentions'?: Array<MedHelpHCMWebApiResponseTodoAndOngoingRow> | null;
    /**
     * 
     * @type {Array<MedHelpHCMWebApiResponseTodoAndOngoingRow>}
     * @memberof MedHelpHCMWebApiResponseTodoAndOngoingResponse
     */
    'ongoing'?: Array<MedHelpHCMWebApiResponseTodoAndOngoingRow> | null;
}
/**
 * 
 * @export
 * @interface MedHelpHCMWebApiResponseTodoAndOngoingRow
 */
export interface MedHelpHCMWebApiResponseTodoAndOngoingRow {
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseTodoAndOngoingRow
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseTodoAndOngoingRow
     */
    'employmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseTodoAndOngoingRow
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseTodoAndOngoingRow
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpHCMWebApiResponseTodoAndOngoingRow
     */
    'responsibleName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpHCMWebApiResponseTodoAndOngoingRow
     */
    'responsibleUserAccountId'?: number | null;
}

/**
 * ActivityReasonApi - axios parameter creator
 * @export
 */
export const ActivityReasonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MedHelpHCMDomainActivityReason} [medHelpHCMDomainActivityReason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityReasonDelete: async (medHelpHCMDomainActivityReason?: MedHelpHCMDomainActivityReason, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ActivityReason`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpHCMDomainActivityReason, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpHCMDomainActivityReason} [medHelpHCMDomainActivityReason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityReasonPost: async (medHelpHCMDomainActivityReason?: MedHelpHCMDomainActivityReason, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ActivityReason`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpHCMDomainActivityReason, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivityReasonApi - functional programming interface
 * @export
 */
export const ActivityReasonApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActivityReasonApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MedHelpHCMDomainActivityReason} [medHelpHCMDomainActivityReason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityReasonDelete(medHelpHCMDomainActivityReason?: MedHelpHCMDomainActivityReason, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityReasonDelete(medHelpHCMDomainActivityReason, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivityReasonApi.activityReasonDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {MedHelpHCMDomainActivityReason} [medHelpHCMDomainActivityReason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityReasonPost(medHelpHCMDomainActivityReason?: MedHelpHCMDomainActivityReason, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityReasonPost(medHelpHCMDomainActivityReason, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivityReasonApi.activityReasonPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ActivityReasonApi - factory interface
 * @export
 */
export const ActivityReasonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActivityReasonApiFp(configuration)
    return {
        /**
         * 
         * @param {MedHelpHCMDomainActivityReason} [medHelpHCMDomainActivityReason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityReasonDelete(medHelpHCMDomainActivityReason?: MedHelpHCMDomainActivityReason, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.activityReasonDelete(medHelpHCMDomainActivityReason, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpHCMDomainActivityReason} [medHelpHCMDomainActivityReason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityReasonPost(medHelpHCMDomainActivityReason?: MedHelpHCMDomainActivityReason, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.activityReasonPost(medHelpHCMDomainActivityReason, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActivityReasonApi - object-oriented interface
 * @export
 * @class ActivityReasonApi
 * @extends {BaseAPI}
 */
export class ActivityReasonApi extends BaseAPI {
    /**
     * 
     * @param {MedHelpHCMDomainActivityReason} [medHelpHCMDomainActivityReason] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityReasonApi
     */
    public activityReasonDelete(medHelpHCMDomainActivityReason?: MedHelpHCMDomainActivityReason, options?: RawAxiosRequestConfig) {
        return ActivityReasonApiFp(this.configuration).activityReasonDelete(medHelpHCMDomainActivityReason, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpHCMDomainActivityReason} [medHelpHCMDomainActivityReason] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityReasonApi
     */
    public activityReasonPost(medHelpHCMDomainActivityReason?: MedHelpHCMDomainActivityReason, options?: RawAxiosRequestConfig) {
        return ActivityReasonApiFp(this.configuration).activityReasonPost(medHelpHCMDomainActivityReason, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CopyEntityApi - axios parameter creator
 * @export
 */
export const CopyEntityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} employmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyEntityEmploymentIdListGet: async (employmentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employmentId' is not null or undefined
            assertParamExists('copyEntityEmploymentIdListGet', 'employmentId', employmentId)
            const localVarPath = `/CopyEntity/{employmentId}/list`
                .replace(`{${"employmentId"}}`, encodeURIComponent(String(employmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CopyEntityApi - functional programming interface
 * @export
 */
export const CopyEntityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CopyEntityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} employmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyEntityEmploymentIdListGet(employmentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyEntityEmploymentIdListGet(employmentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CopyEntityApi.copyEntityEmploymentIdListGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CopyEntityApi - factory interface
 * @export
 */
export const CopyEntityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CopyEntityApiFp(configuration)
    return {
        /**
         * 
         * @param {string} employmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyEntityEmploymentIdListGet(employmentId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.copyEntityEmploymentIdListGet(employmentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CopyEntityApi - object-oriented interface
 * @export
 * @class CopyEntityApi
 * @extends {BaseAPI}
 */
export class CopyEntityApi extends BaseAPI {
    /**
     * 
     * @param {string} employmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CopyEntityApi
     */
    public copyEntityEmploymentIdListGet(employmentId: string, options?: RawAxiosRequestConfig) {
        return CopyEntityApiFp(this.configuration).copyEntityEmploymentIdListGet(employmentId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MedHelpHCMWebApiRequestDashboardFiltersRequest} [medHelpHCMWebApiRequestDashboardFiltersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardAttentionsAndOngoingPost: async (medHelpHCMWebApiRequestDashboardFiltersRequest?: MedHelpHCMWebApiRequestDashboardFiltersRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Dashboard/AttentionsAndOngoing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpHCMWebApiRequestDashboardFiltersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpHCMWebApiRequestDashboardFiltersRequest} [medHelpHCMWebApiRequestDashboardFiltersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardStatusOverviewPost: async (medHelpHCMWebApiRequestDashboardFiltersRequest?: MedHelpHCMWebApiRequestDashboardFiltersRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Dashboard/StatusOverview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpHCMWebApiRequestDashboardFiltersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MedHelpHCMWebApiRequestDashboardFiltersRequest} [medHelpHCMWebApiRequestDashboardFiltersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardAttentionsAndOngoingPost(medHelpHCMWebApiRequestDashboardFiltersRequest?: MedHelpHCMWebApiRequestDashboardFiltersRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpHCMWebApiResponseTodoAndOngoingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardAttentionsAndOngoingPost(medHelpHCMWebApiRequestDashboardFiltersRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DashboardApi.dashboardAttentionsAndOngoingPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {MedHelpHCMWebApiRequestDashboardFiltersRequest} [medHelpHCMWebApiRequestDashboardFiltersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardStatusOverviewPost(medHelpHCMWebApiRequestDashboardFiltersRequest?: MedHelpHCMWebApiRequestDashboardFiltersRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedHelpHCMWebApiResponseStatusOverviewResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardStatusOverviewPost(medHelpHCMWebApiRequestDashboardFiltersRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DashboardApi.dashboardStatusOverviewPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardApiFp(configuration)
    return {
        /**
         * 
         * @param {MedHelpHCMWebApiRequestDashboardFiltersRequest} [medHelpHCMWebApiRequestDashboardFiltersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardAttentionsAndOngoingPost(medHelpHCMWebApiRequestDashboardFiltersRequest?: MedHelpHCMWebApiRequestDashboardFiltersRequest, options?: RawAxiosRequestConfig): AxiosPromise<MedHelpHCMWebApiResponseTodoAndOngoingResponse> {
            return localVarFp.dashboardAttentionsAndOngoingPost(medHelpHCMWebApiRequestDashboardFiltersRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpHCMWebApiRequestDashboardFiltersRequest} [medHelpHCMWebApiRequestDashboardFiltersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardStatusOverviewPost(medHelpHCMWebApiRequestDashboardFiltersRequest?: MedHelpHCMWebApiRequestDashboardFiltersRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<MedHelpHCMWebApiResponseStatusOverviewResponse>> {
            return localVarFp.dashboardStatusOverviewPost(medHelpHCMWebApiRequestDashboardFiltersRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI {
    /**
     * 
     * @param {MedHelpHCMWebApiRequestDashboardFiltersRequest} [medHelpHCMWebApiRequestDashboardFiltersRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardAttentionsAndOngoingPost(medHelpHCMWebApiRequestDashboardFiltersRequest?: MedHelpHCMWebApiRequestDashboardFiltersRequest, options?: RawAxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardAttentionsAndOngoingPost(medHelpHCMWebApiRequestDashboardFiltersRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpHCMWebApiRequestDashboardFiltersRequest} [medHelpHCMWebApiRequestDashboardFiltersRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardStatusOverviewPost(medHelpHCMWebApiRequestDashboardFiltersRequest?: MedHelpHCMWebApiRequestDashboardFiltersRequest, options?: RawAxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardStatusOverviewPost(medHelpHCMWebApiRequestDashboardFiltersRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DataAnonymizationApi - axios parameter creator
 * @export
 */
export const DataAnonymizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} employmentId 
         * @param {string} [maxEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataAnonymizationEmploymentEmploymentIdDelete: async (employmentId: string, maxEnd?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employmentId' is not null or undefined
            assertParamExists('dataAnonymizationEmploymentEmploymentIdDelete', 'employmentId', employmentId)
            const localVarPath = `/DataAnonymization/employment/{employmentId}`
                .replace(`{${"employmentId"}}`, encodeURIComponent(String(employmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (maxEnd !== undefined) {
                localVarQueryParameter['maxEnd'] = (maxEnd as any instanceof Date) ?
                    (maxEnd as any).toISOString() :
                    maxEnd;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataAnonymizationEmploymentsDocumentationCustomerIdCompanyIdGet: async (customerId: number, companyId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('dataAnonymizationEmploymentsDocumentationCustomerIdCompanyIdGet', 'customerId', customerId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('dataAnonymizationEmploymentsDocumentationCustomerIdCompanyIdGet', 'companyId', companyId)
            const localVarPath = `/DataAnonymization/employments/documentation/{customerId}/{companyId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataAnonymizationEmploymentsOngoingCustomerIdCompanyIdGet: async (customerId: number, companyId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('dataAnonymizationEmploymentsOngoingCustomerIdCompanyIdGet', 'customerId', customerId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('dataAnonymizationEmploymentsOngoingCustomerIdCompanyIdGet', 'companyId', companyId)
            const localVarPath = `/DataAnonymization/employments/ongoing/{customerId}/{companyId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} companyId 
         * @param {string} [maxEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataAnonymizationHealthcaseperiodsCustomerIdCompanyIdGet: async (customerId: number, companyId: number, maxEnd?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('dataAnonymizationHealthcaseperiodsCustomerIdCompanyIdGet', 'customerId', customerId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('dataAnonymizationHealthcaseperiodsCustomerIdCompanyIdGet', 'companyId', companyId)
            const localVarPath = `/DataAnonymization/healthcaseperiods/{customerId}/{companyId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (maxEnd !== undefined) {
                localVarQueryParameter['maxEnd'] = (maxEnd as any instanceof Date) ?
                    (maxEnd as any).toISOString() :
                    maxEnd;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataAnonymizationApi - functional programming interface
 * @export
 */
export const DataAnonymizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataAnonymizationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} employmentId 
         * @param {string} [maxEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataAnonymizationEmploymentEmploymentIdDelete(employmentId: string, maxEnd?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataAnonymizationEmploymentEmploymentIdDelete(employmentId, maxEnd, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DataAnonymizationApi.dataAnonymizationEmploymentEmploymentIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataAnonymizationEmploymentsDocumentationCustomerIdCompanyIdGet(customerId: number, companyId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataAnonymizationEmploymentsDocumentationCustomerIdCompanyIdGet(customerId, companyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DataAnonymizationApi.dataAnonymizationEmploymentsDocumentationCustomerIdCompanyIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataAnonymizationEmploymentsOngoingCustomerIdCompanyIdGet(customerId: number, companyId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataAnonymizationEmploymentsOngoingCustomerIdCompanyIdGet(customerId, companyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DataAnonymizationApi.dataAnonymizationEmploymentsOngoingCustomerIdCompanyIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} companyId 
         * @param {string} [maxEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataAnonymizationHealthcaseperiodsCustomerIdCompanyIdGet(customerId: number, companyId: number, maxEnd?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedHelpHCMWebApiResponseHealthCasePeriodResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataAnonymizationHealthcaseperiodsCustomerIdCompanyIdGet(customerId, companyId, maxEnd, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DataAnonymizationApi.dataAnonymizationHealthcaseperiodsCustomerIdCompanyIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DataAnonymizationApi - factory interface
 * @export
 */
export const DataAnonymizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataAnonymizationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} employmentId 
         * @param {string} [maxEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataAnonymizationEmploymentEmploymentIdDelete(employmentId: string, maxEnd?: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.dataAnonymizationEmploymentEmploymentIdDelete(employmentId, maxEnd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataAnonymizationEmploymentsDocumentationCustomerIdCompanyIdGet(customerId: number, companyId: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.dataAnonymizationEmploymentsDocumentationCustomerIdCompanyIdGet(customerId, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataAnonymizationEmploymentsOngoingCustomerIdCompanyIdGet(customerId: number, companyId: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.dataAnonymizationEmploymentsOngoingCustomerIdCompanyIdGet(customerId, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} companyId 
         * @param {string} [maxEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataAnonymizationHealthcaseperiodsCustomerIdCompanyIdGet(customerId: number, companyId: number, maxEnd?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<MedHelpHCMWebApiResponseHealthCasePeriodResponse>> {
            return localVarFp.dataAnonymizationHealthcaseperiodsCustomerIdCompanyIdGet(customerId, companyId, maxEnd, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DataAnonymizationApi - object-oriented interface
 * @export
 * @class DataAnonymizationApi
 * @extends {BaseAPI}
 */
export class DataAnonymizationApi extends BaseAPI {
    /**
     * 
     * @param {string} employmentId 
     * @param {string} [maxEnd] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAnonymizationApi
     */
    public dataAnonymizationEmploymentEmploymentIdDelete(employmentId: string, maxEnd?: string, options?: RawAxiosRequestConfig) {
        return DataAnonymizationApiFp(this.configuration).dataAnonymizationEmploymentEmploymentIdDelete(employmentId, maxEnd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAnonymizationApi
     */
    public dataAnonymizationEmploymentsDocumentationCustomerIdCompanyIdGet(customerId: number, companyId: number, options?: RawAxiosRequestConfig) {
        return DataAnonymizationApiFp(this.configuration).dataAnonymizationEmploymentsDocumentationCustomerIdCompanyIdGet(customerId, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAnonymizationApi
     */
    public dataAnonymizationEmploymentsOngoingCustomerIdCompanyIdGet(customerId: number, companyId: number, options?: RawAxiosRequestConfig) {
        return DataAnonymizationApiFp(this.configuration).dataAnonymizationEmploymentsOngoingCustomerIdCompanyIdGet(customerId, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {number} companyId 
     * @param {string} [maxEnd] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAnonymizationApi
     */
    public dataAnonymizationHealthcaseperiodsCustomerIdCompanyIdGet(customerId: number, companyId: number, maxEnd?: string, options?: RawAxiosRequestConfig) {
        return DataAnonymizationApiFp(this.configuration).dataAnonymizationHealthcaseperiodsCustomerIdCompanyIdGet(customerId, companyId, maxEnd, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ExcelApi - axios parameter creator
 * @export
 */
export const ExcelApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MedHelpHCMWebApiRequestExcelSearchHealthCaseActivitiesRequest} [medHelpHCMWebApiRequestExcelSearchHealthCaseActivitiesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excelHeathCaseActivitiesPost: async (medHelpHCMWebApiRequestExcelSearchHealthCaseActivitiesRequest?: MedHelpHCMWebApiRequestExcelSearchHealthCaseActivitiesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Excel/HeathCaseActivities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpHCMWebApiRequestExcelSearchHealthCaseActivitiesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpHCMWebApiRequestExcelSearchHealthCaseRequest} [medHelpHCMWebApiRequestExcelSearchHealthCaseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excelHeathCasesPost: async (medHelpHCMWebApiRequestExcelSearchHealthCaseRequest?: MedHelpHCMWebApiRequestExcelSearchHealthCaseRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Excel/HeathCases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpHCMWebApiRequestExcelSearchHealthCaseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExcelApi - functional programming interface
 * @export
 */
export const ExcelApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExcelApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MedHelpHCMWebApiRequestExcelSearchHealthCaseActivitiesRequest} [medHelpHCMWebApiRequestExcelSearchHealthCaseActivitiesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async excelHeathCaseActivitiesPost(medHelpHCMWebApiRequestExcelSearchHealthCaseActivitiesRequest?: MedHelpHCMWebApiRequestExcelSearchHealthCaseActivitiesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.excelHeathCaseActivitiesPost(medHelpHCMWebApiRequestExcelSearchHealthCaseActivitiesRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExcelApi.excelHeathCaseActivitiesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {MedHelpHCMWebApiRequestExcelSearchHealthCaseRequest} [medHelpHCMWebApiRequestExcelSearchHealthCaseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async excelHeathCasesPost(medHelpHCMWebApiRequestExcelSearchHealthCaseRequest?: MedHelpHCMWebApiRequestExcelSearchHealthCaseRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.excelHeathCasesPost(medHelpHCMWebApiRequestExcelSearchHealthCaseRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExcelApi.excelHeathCasesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ExcelApi - factory interface
 * @export
 */
export const ExcelApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExcelApiFp(configuration)
    return {
        /**
         * 
         * @param {MedHelpHCMWebApiRequestExcelSearchHealthCaseActivitiesRequest} [medHelpHCMWebApiRequestExcelSearchHealthCaseActivitiesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excelHeathCaseActivitiesPost(medHelpHCMWebApiRequestExcelSearchHealthCaseActivitiesRequest?: MedHelpHCMWebApiRequestExcelSearchHealthCaseActivitiesRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.excelHeathCaseActivitiesPost(medHelpHCMWebApiRequestExcelSearchHealthCaseActivitiesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpHCMWebApiRequestExcelSearchHealthCaseRequest} [medHelpHCMWebApiRequestExcelSearchHealthCaseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excelHeathCasesPost(medHelpHCMWebApiRequestExcelSearchHealthCaseRequest?: MedHelpHCMWebApiRequestExcelSearchHealthCaseRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.excelHeathCasesPost(medHelpHCMWebApiRequestExcelSearchHealthCaseRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExcelApi - object-oriented interface
 * @export
 * @class ExcelApi
 * @extends {BaseAPI}
 */
export class ExcelApi extends BaseAPI {
    /**
     * 
     * @param {MedHelpHCMWebApiRequestExcelSearchHealthCaseActivitiesRequest} [medHelpHCMWebApiRequestExcelSearchHealthCaseActivitiesRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExcelApi
     */
    public excelHeathCaseActivitiesPost(medHelpHCMWebApiRequestExcelSearchHealthCaseActivitiesRequest?: MedHelpHCMWebApiRequestExcelSearchHealthCaseActivitiesRequest, options?: RawAxiosRequestConfig) {
        return ExcelApiFp(this.configuration).excelHeathCaseActivitiesPost(medHelpHCMWebApiRequestExcelSearchHealthCaseActivitiesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpHCMWebApiRequestExcelSearchHealthCaseRequest} [medHelpHCMWebApiRequestExcelSearchHealthCaseRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExcelApi
     */
    public excelHeathCasesPost(medHelpHCMWebApiRequestExcelSearchHealthCaseRequest?: MedHelpHCMWebApiRequestExcelSearchHealthCaseRequest, options?: RawAxiosRequestConfig) {
        return ExcelApiFp(this.configuration).excelHeathCasesPost(medHelpHCMWebApiRequestExcelSearchHealthCaseRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * HealthCaseApi - axios parameter creator
 * @export
 */
export const HealthCaseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} employmentId 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseEmploymentIdListGet: async (employmentId: string, language?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employmentId' is not null or undefined
            assertParamExists('healthCaseEmploymentIdListGet', 'employmentId', employmentId)
            const localVarPath = `/HealthCase/{employmentId}/list`
                .replace(`{${"employmentId"}}`, encodeURIComponent(String(employmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseHealthCaseIdActivatePost: async (healthCaseId: string, body?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthCaseId' is not null or undefined
            assertParamExists('healthCaseHealthCaseIdActivatePost', 'healthCaseId', healthCaseId)
            const localVarPath = `/HealthCase/{healthCaseId}/activate`
                .replace(`{${"healthCaseId"}}`, encodeURIComponent(String(healthCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseHealthCaseIdDeclinePost: async (healthCaseId: string, body?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthCaseId' is not null or undefined
            assertParamExists('healthCaseHealthCaseIdDeclinePost', 'healthCaseId', healthCaseId)
            const localVarPath = `/HealthCase/{healthCaseId}/decline`
                .replace(`{${"healthCaseId"}}`, encodeURIComponent(String(healthCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseHealthCaseIdDelete: async (healthCaseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthCaseId' is not null or undefined
            assertParamExists('healthCaseHealthCaseIdDelete', 'healthCaseId', healthCaseId)
            const localVarPath = `/HealthCase/{healthCaseId}`
                .replace(`{${"healthCaseId"}}`, encodeURIComponent(String(healthCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {MedHelpHCMWebApiModelsExportHealthCaseModel} [medHelpHCMWebApiModelsExportHealthCaseModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseHealthCaseIdExportPost: async (healthCaseId: string, medHelpHCMWebApiModelsExportHealthCaseModel?: MedHelpHCMWebApiModelsExportHealthCaseModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthCaseId' is not null or undefined
            assertParamExists('healthCaseHealthCaseIdExportPost', 'healthCaseId', healthCaseId)
            const localVarPath = `/HealthCase/{healthCaseId}/export`
                .replace(`{${"healthCaseId"}}`, encodeURIComponent(String(healthCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpHCMWebApiModelsExportHealthCaseModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {MedHelpHCMWebApiModelsEndHealthCaseModel} [medHelpHCMWebApiModelsEndHealthCaseModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseHealthCaseIdPost: async (healthCaseId: string, medHelpHCMWebApiModelsEndHealthCaseModel?: MedHelpHCMWebApiModelsEndHealthCaseModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthCaseId' is not null or undefined
            assertParamExists('healthCaseHealthCaseIdPost', 'healthCaseId', healthCaseId)
            const localVarPath = `/HealthCase/{healthCaseId}`
                .replace(`{${"healthCaseId"}}`, encodeURIComponent(String(healthCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpHCMWebApiModelsEndHealthCaseModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {MedHelpHCMWebApiModelsSetHealthCaseResponsibleModel} [medHelpHCMWebApiModelsSetHealthCaseResponsibleModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseHealthCaseIdSetresponsiblePost: async (healthCaseId: string, medHelpHCMWebApiModelsSetHealthCaseResponsibleModel?: MedHelpHCMWebApiModelsSetHealthCaseResponsibleModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthCaseId' is not null or undefined
            assertParamExists('healthCaseHealthCaseIdSetresponsiblePost', 'healthCaseId', healthCaseId)
            const localVarPath = `/HealthCase/{healthCaseId}/setresponsible`
                .replace(`{${"healthCaseId"}}`, encodeURIComponent(String(healthCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpHCMWebApiModelsSetHealthCaseResponsibleModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {MedHelpHCMWebApiModelsSetHealthCaseStatusModel} [medHelpHCMWebApiModelsSetHealthCaseStatusModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseHealthCaseIdStatusPost: async (healthCaseId: string, medHelpHCMWebApiModelsSetHealthCaseStatusModel?: MedHelpHCMWebApiModelsSetHealthCaseStatusModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthCaseId' is not null or undefined
            assertParamExists('healthCaseHealthCaseIdStatusPost', 'healthCaseId', healthCaseId)
            const localVarPath = `/HealthCase/{healthCaseId}/status`
                .replace(`{${"healthCaseId"}}`, encodeURIComponent(String(healthCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpHCMWebApiModelsSetHealthCaseStatusModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseIdGet: async (id: string, language?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('healthCaseIdGet', 'id', id)
            const localVarPath = `/HealthCase/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpHCMWebApiModelsCreateHealthCaseModel} [medHelpHCMWebApiModelsCreateHealthCaseModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCasePost: async (medHelpHCMWebApiModelsCreateHealthCaseModel?: MedHelpHCMWebApiModelsCreateHealthCaseModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/HealthCase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpHCMWebApiModelsCreateHealthCaseModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthCaseApi - functional programming interface
 * @export
 */
export const HealthCaseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthCaseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} employmentId 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCaseEmploymentIdListGet(employmentId: string, language?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedHelpHCMWebApiResponseHealthCase>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCaseEmploymentIdListGet(employmentId, language, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthCaseApi.healthCaseEmploymentIdListGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCaseHealthCaseIdActivatePost(healthCaseId: string, body?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCaseHealthCaseIdActivatePost(healthCaseId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthCaseApi.healthCaseHealthCaseIdActivatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCaseHealthCaseIdDeclinePost(healthCaseId: string, body?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCaseHealthCaseIdDeclinePost(healthCaseId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthCaseApi.healthCaseHealthCaseIdDeclinePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCaseHealthCaseIdDelete(healthCaseId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCaseHealthCaseIdDelete(healthCaseId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthCaseApi.healthCaseHealthCaseIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {MedHelpHCMWebApiModelsExportHealthCaseModel} [medHelpHCMWebApiModelsExportHealthCaseModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCaseHealthCaseIdExportPost(healthCaseId: string, medHelpHCMWebApiModelsExportHealthCaseModel?: MedHelpHCMWebApiModelsExportHealthCaseModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCaseHealthCaseIdExportPost(healthCaseId, medHelpHCMWebApiModelsExportHealthCaseModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthCaseApi.healthCaseHealthCaseIdExportPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {MedHelpHCMWebApiModelsEndHealthCaseModel} [medHelpHCMWebApiModelsEndHealthCaseModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCaseHealthCaseIdPost(healthCaseId: string, medHelpHCMWebApiModelsEndHealthCaseModel?: MedHelpHCMWebApiModelsEndHealthCaseModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCaseHealthCaseIdPost(healthCaseId, medHelpHCMWebApiModelsEndHealthCaseModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthCaseApi.healthCaseHealthCaseIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {MedHelpHCMWebApiModelsSetHealthCaseResponsibleModel} [medHelpHCMWebApiModelsSetHealthCaseResponsibleModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCaseHealthCaseIdSetresponsiblePost(healthCaseId: string, medHelpHCMWebApiModelsSetHealthCaseResponsibleModel?: MedHelpHCMWebApiModelsSetHealthCaseResponsibleModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCaseHealthCaseIdSetresponsiblePost(healthCaseId, medHelpHCMWebApiModelsSetHealthCaseResponsibleModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthCaseApi.healthCaseHealthCaseIdSetresponsiblePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {MedHelpHCMWebApiModelsSetHealthCaseStatusModel} [medHelpHCMWebApiModelsSetHealthCaseStatusModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCaseHealthCaseIdStatusPost(healthCaseId: string, medHelpHCMWebApiModelsSetHealthCaseStatusModel?: MedHelpHCMWebApiModelsSetHealthCaseStatusModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCaseHealthCaseIdStatusPost(healthCaseId, medHelpHCMWebApiModelsSetHealthCaseStatusModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthCaseApi.healthCaseHealthCaseIdStatusPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCaseIdGet(id: string, language?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedHelpHCMWebApiResponseHealthCase>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCaseIdGet(id, language, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthCaseApi.healthCaseIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {MedHelpHCMWebApiModelsCreateHealthCaseModel} [medHelpHCMWebApiModelsCreateHealthCaseModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCasePost(medHelpHCMWebApiModelsCreateHealthCaseModel?: MedHelpHCMWebApiModelsCreateHealthCaseModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCasePost(medHelpHCMWebApiModelsCreateHealthCaseModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthCaseApi.healthCasePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * HealthCaseApi - factory interface
 * @export
 */
export const HealthCaseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthCaseApiFp(configuration)
    return {
        /**
         * 
         * @param {string} employmentId 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseEmploymentIdListGet(employmentId: string, language?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<MedHelpHCMWebApiResponseHealthCase>> {
            return localVarFp.healthCaseEmploymentIdListGet(employmentId, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseHealthCaseIdActivatePost(healthCaseId: string, body?: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.healthCaseHealthCaseIdActivatePost(healthCaseId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseHealthCaseIdDeclinePost(healthCaseId: string, body?: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.healthCaseHealthCaseIdDeclinePost(healthCaseId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseHealthCaseIdDelete(healthCaseId: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.healthCaseHealthCaseIdDelete(healthCaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {MedHelpHCMWebApiModelsExportHealthCaseModel} [medHelpHCMWebApiModelsExportHealthCaseModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseHealthCaseIdExportPost(healthCaseId: string, medHelpHCMWebApiModelsExportHealthCaseModel?: MedHelpHCMWebApiModelsExportHealthCaseModel, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.healthCaseHealthCaseIdExportPost(healthCaseId, medHelpHCMWebApiModelsExportHealthCaseModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {MedHelpHCMWebApiModelsEndHealthCaseModel} [medHelpHCMWebApiModelsEndHealthCaseModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseHealthCaseIdPost(healthCaseId: string, medHelpHCMWebApiModelsEndHealthCaseModel?: MedHelpHCMWebApiModelsEndHealthCaseModel, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.healthCaseHealthCaseIdPost(healthCaseId, medHelpHCMWebApiModelsEndHealthCaseModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {MedHelpHCMWebApiModelsSetHealthCaseResponsibleModel} [medHelpHCMWebApiModelsSetHealthCaseResponsibleModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseHealthCaseIdSetresponsiblePost(healthCaseId: string, medHelpHCMWebApiModelsSetHealthCaseResponsibleModel?: MedHelpHCMWebApiModelsSetHealthCaseResponsibleModel, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.healthCaseHealthCaseIdSetresponsiblePost(healthCaseId, medHelpHCMWebApiModelsSetHealthCaseResponsibleModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {MedHelpHCMWebApiModelsSetHealthCaseStatusModel} [medHelpHCMWebApiModelsSetHealthCaseStatusModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseHealthCaseIdStatusPost(healthCaseId: string, medHelpHCMWebApiModelsSetHealthCaseStatusModel?: MedHelpHCMWebApiModelsSetHealthCaseStatusModel, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.healthCaseHealthCaseIdStatusPost(healthCaseId, medHelpHCMWebApiModelsSetHealthCaseStatusModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseIdGet(id: string, language?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<MedHelpHCMWebApiResponseHealthCase>> {
            return localVarFp.healthCaseIdGet(id, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpHCMWebApiModelsCreateHealthCaseModel} [medHelpHCMWebApiModelsCreateHealthCaseModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCasePost(medHelpHCMWebApiModelsCreateHealthCaseModel?: MedHelpHCMWebApiModelsCreateHealthCaseModel, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.healthCasePost(medHelpHCMWebApiModelsCreateHealthCaseModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthCaseApi - object-oriented interface
 * @export
 * @class HealthCaseApi
 * @extends {BaseAPI}
 */
export class HealthCaseApi extends BaseAPI {
    /**
     * 
     * @param {string} employmentId 
     * @param {string} [language] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCaseApi
     */
    public healthCaseEmploymentIdListGet(employmentId: string, language?: string, options?: RawAxiosRequestConfig) {
        return HealthCaseApiFp(this.configuration).healthCaseEmploymentIdListGet(employmentId, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} healthCaseId 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCaseApi
     */
    public healthCaseHealthCaseIdActivatePost(healthCaseId: string, body?: string, options?: RawAxiosRequestConfig) {
        return HealthCaseApiFp(this.configuration).healthCaseHealthCaseIdActivatePost(healthCaseId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} healthCaseId 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCaseApi
     */
    public healthCaseHealthCaseIdDeclinePost(healthCaseId: string, body?: string, options?: RawAxiosRequestConfig) {
        return HealthCaseApiFp(this.configuration).healthCaseHealthCaseIdDeclinePost(healthCaseId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} healthCaseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCaseApi
     */
    public healthCaseHealthCaseIdDelete(healthCaseId: string, options?: RawAxiosRequestConfig) {
        return HealthCaseApiFp(this.configuration).healthCaseHealthCaseIdDelete(healthCaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} healthCaseId 
     * @param {MedHelpHCMWebApiModelsExportHealthCaseModel} [medHelpHCMWebApiModelsExportHealthCaseModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCaseApi
     */
    public healthCaseHealthCaseIdExportPost(healthCaseId: string, medHelpHCMWebApiModelsExportHealthCaseModel?: MedHelpHCMWebApiModelsExportHealthCaseModel, options?: RawAxiosRequestConfig) {
        return HealthCaseApiFp(this.configuration).healthCaseHealthCaseIdExportPost(healthCaseId, medHelpHCMWebApiModelsExportHealthCaseModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} healthCaseId 
     * @param {MedHelpHCMWebApiModelsEndHealthCaseModel} [medHelpHCMWebApiModelsEndHealthCaseModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCaseApi
     */
    public healthCaseHealthCaseIdPost(healthCaseId: string, medHelpHCMWebApiModelsEndHealthCaseModel?: MedHelpHCMWebApiModelsEndHealthCaseModel, options?: RawAxiosRequestConfig) {
        return HealthCaseApiFp(this.configuration).healthCaseHealthCaseIdPost(healthCaseId, medHelpHCMWebApiModelsEndHealthCaseModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} healthCaseId 
     * @param {MedHelpHCMWebApiModelsSetHealthCaseResponsibleModel} [medHelpHCMWebApiModelsSetHealthCaseResponsibleModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCaseApi
     */
    public healthCaseHealthCaseIdSetresponsiblePost(healthCaseId: string, medHelpHCMWebApiModelsSetHealthCaseResponsibleModel?: MedHelpHCMWebApiModelsSetHealthCaseResponsibleModel, options?: RawAxiosRequestConfig) {
        return HealthCaseApiFp(this.configuration).healthCaseHealthCaseIdSetresponsiblePost(healthCaseId, medHelpHCMWebApiModelsSetHealthCaseResponsibleModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} healthCaseId 
     * @param {MedHelpHCMWebApiModelsSetHealthCaseStatusModel} [medHelpHCMWebApiModelsSetHealthCaseStatusModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCaseApi
     */
    public healthCaseHealthCaseIdStatusPost(healthCaseId: string, medHelpHCMWebApiModelsSetHealthCaseStatusModel?: MedHelpHCMWebApiModelsSetHealthCaseStatusModel, options?: RawAxiosRequestConfig) {
        return HealthCaseApiFp(this.configuration).healthCaseHealthCaseIdStatusPost(healthCaseId, medHelpHCMWebApiModelsSetHealthCaseStatusModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [language] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCaseApi
     */
    public healthCaseIdGet(id: string, language?: string, options?: RawAxiosRequestConfig) {
        return HealthCaseApiFp(this.configuration).healthCaseIdGet(id, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpHCMWebApiModelsCreateHealthCaseModel} [medHelpHCMWebApiModelsCreateHealthCaseModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCaseApi
     */
    public healthCasePost(medHelpHCMWebApiModelsCreateHealthCaseModel?: MedHelpHCMWebApiModelsCreateHealthCaseModel, options?: RawAxiosRequestConfig) {
        return HealthCaseApiFp(this.configuration).healthCasePost(medHelpHCMWebApiModelsCreateHealthCaseModel, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * HealthCaseActivityApi - axios parameter creator
 * @export
 */
export const HealthCaseActivityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} healthCaseId 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseActivityHealthCaseIdListGet: async (healthCaseId: string, language?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthCaseId' is not null or undefined
            assertParamExists('healthCaseActivityHealthCaseIdListGet', 'healthCaseId', healthCaseId)
            const localVarPath = `/HealthCaseActivity/{healthCaseId}/list`
                .replace(`{${"healthCaseId"}}`, encodeURIComponent(String(healthCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseActivityIdDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('healthCaseActivityIdDelete', 'id', id)
            const localVarPath = `/HealthCaseActivity/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseActivityIdPerformedPut: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('healthCaseActivityIdPerformedPut', 'id', id)
            const localVarPath = `/HealthCaseActivity/{id}/performed`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseActivityIdUndoperformedPut: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('healthCaseActivityIdUndoperformedPut', 'id', id)
            const localVarPath = `/HealthCaseActivity/{id}/undoperformed`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpHCMWebApiModelsCreateHealthCaseActivityModel} [medHelpHCMWebApiModelsCreateHealthCaseActivityModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseActivityPost: async (medHelpHCMWebApiModelsCreateHealthCaseActivityModel?: MedHelpHCMWebApiModelsCreateHealthCaseActivityModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/HealthCaseActivity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpHCMWebApiModelsCreateHealthCaseActivityModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpHCMWebApiModelsUpdateHealthCaseActivityModel} [medHelpHCMWebApiModelsUpdateHealthCaseActivityModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseActivityPut: async (medHelpHCMWebApiModelsUpdateHealthCaseActivityModel?: MedHelpHCMWebApiModelsUpdateHealthCaseActivityModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/HealthCaseActivity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpHCMWebApiModelsUpdateHealthCaseActivityModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthCaseActivityApi - functional programming interface
 * @export
 */
export const HealthCaseActivityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthCaseActivityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} healthCaseId 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCaseActivityHealthCaseIdListGet(healthCaseId: string, language?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedHelpHCMWebApiResponseHealthCaseActivity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCaseActivityHealthCaseIdListGet(healthCaseId, language, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthCaseActivityApi.healthCaseActivityHealthCaseIdListGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCaseActivityIdDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCaseActivityIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthCaseActivityApi.healthCaseActivityIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCaseActivityIdPerformedPut(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCaseActivityIdPerformedPut(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthCaseActivityApi.healthCaseActivityIdPerformedPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCaseActivityIdUndoperformedPut(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCaseActivityIdUndoperformedPut(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthCaseActivityApi.healthCaseActivityIdUndoperformedPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {MedHelpHCMWebApiModelsCreateHealthCaseActivityModel} [medHelpHCMWebApiModelsCreateHealthCaseActivityModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCaseActivityPost(medHelpHCMWebApiModelsCreateHealthCaseActivityModel?: MedHelpHCMWebApiModelsCreateHealthCaseActivityModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCaseActivityPost(medHelpHCMWebApiModelsCreateHealthCaseActivityModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthCaseActivityApi.healthCaseActivityPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {MedHelpHCMWebApiModelsUpdateHealthCaseActivityModel} [medHelpHCMWebApiModelsUpdateHealthCaseActivityModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCaseActivityPut(medHelpHCMWebApiModelsUpdateHealthCaseActivityModel?: MedHelpHCMWebApiModelsUpdateHealthCaseActivityModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCaseActivityPut(medHelpHCMWebApiModelsUpdateHealthCaseActivityModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthCaseActivityApi.healthCaseActivityPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * HealthCaseActivityApi - factory interface
 * @export
 */
export const HealthCaseActivityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthCaseActivityApiFp(configuration)
    return {
        /**
         * 
         * @param {string} healthCaseId 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseActivityHealthCaseIdListGet(healthCaseId: string, language?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<MedHelpHCMWebApiResponseHealthCaseActivity>> {
            return localVarFp.healthCaseActivityHealthCaseIdListGet(healthCaseId, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseActivityIdDelete(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.healthCaseActivityIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseActivityIdPerformedPut(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.healthCaseActivityIdPerformedPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseActivityIdUndoperformedPut(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.healthCaseActivityIdUndoperformedPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpHCMWebApiModelsCreateHealthCaseActivityModel} [medHelpHCMWebApiModelsCreateHealthCaseActivityModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseActivityPost(medHelpHCMWebApiModelsCreateHealthCaseActivityModel?: MedHelpHCMWebApiModelsCreateHealthCaseActivityModel, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.healthCaseActivityPost(medHelpHCMWebApiModelsCreateHealthCaseActivityModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpHCMWebApiModelsUpdateHealthCaseActivityModel} [medHelpHCMWebApiModelsUpdateHealthCaseActivityModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseActivityPut(medHelpHCMWebApiModelsUpdateHealthCaseActivityModel?: MedHelpHCMWebApiModelsUpdateHealthCaseActivityModel, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.healthCaseActivityPut(medHelpHCMWebApiModelsUpdateHealthCaseActivityModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthCaseActivityApi - object-oriented interface
 * @export
 * @class HealthCaseActivityApi
 * @extends {BaseAPI}
 */
export class HealthCaseActivityApi extends BaseAPI {
    /**
     * 
     * @param {string} healthCaseId 
     * @param {string} [language] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCaseActivityApi
     */
    public healthCaseActivityHealthCaseIdListGet(healthCaseId: string, language?: string, options?: RawAxiosRequestConfig) {
        return HealthCaseActivityApiFp(this.configuration).healthCaseActivityHealthCaseIdListGet(healthCaseId, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCaseActivityApi
     */
    public healthCaseActivityIdDelete(id: string, options?: RawAxiosRequestConfig) {
        return HealthCaseActivityApiFp(this.configuration).healthCaseActivityIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCaseActivityApi
     */
    public healthCaseActivityIdPerformedPut(id: string, options?: RawAxiosRequestConfig) {
        return HealthCaseActivityApiFp(this.configuration).healthCaseActivityIdPerformedPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCaseActivityApi
     */
    public healthCaseActivityIdUndoperformedPut(id: string, options?: RawAxiosRequestConfig) {
        return HealthCaseActivityApiFp(this.configuration).healthCaseActivityIdUndoperformedPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpHCMWebApiModelsCreateHealthCaseActivityModel} [medHelpHCMWebApiModelsCreateHealthCaseActivityModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCaseActivityApi
     */
    public healthCaseActivityPost(medHelpHCMWebApiModelsCreateHealthCaseActivityModel?: MedHelpHCMWebApiModelsCreateHealthCaseActivityModel, options?: RawAxiosRequestConfig) {
        return HealthCaseActivityApiFp(this.configuration).healthCaseActivityPost(medHelpHCMWebApiModelsCreateHealthCaseActivityModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpHCMWebApiModelsUpdateHealthCaseActivityModel} [medHelpHCMWebApiModelsUpdateHealthCaseActivityModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCaseActivityApi
     */
    public healthCaseActivityPut(medHelpHCMWebApiModelsUpdateHealthCaseActivityModel?: MedHelpHCMWebApiModelsUpdateHealthCaseActivityModel, options?: RawAxiosRequestConfig) {
        return HealthCaseActivityApiFp(this.configuration).healthCaseActivityPut(medHelpHCMWebApiModelsUpdateHealthCaseActivityModel, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * HealthCaseDocumentApi - axios parameter creator
 * @export
 */
export const HealthCaseDocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} pdfDocumentReferenceKey 
         * @param {string} [employmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseDocumentFetchtemplatedocumentlinkPdfDocumentReferenceKeyGet: async (pdfDocumentReferenceKey: string, employmentId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pdfDocumentReferenceKey' is not null or undefined
            assertParamExists('healthCaseDocumentFetchtemplatedocumentlinkPdfDocumentReferenceKeyGet', 'pdfDocumentReferenceKey', pdfDocumentReferenceKey)
            const localVarPath = `/HealthCaseDocument/fetchtemplatedocumentlink/{pdfDocumentReferenceKey}`
                .replace(`{${"pdfDocumentReferenceKey"}}`, encodeURIComponent(String(pdfDocumentReferenceKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (employmentId !== undefined) {
                localVarQueryParameter['employmentId'] = employmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseDocumentHealthCaseIdDownloadfileDocumentIdPost: async (healthCaseId: string, documentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthCaseId' is not null or undefined
            assertParamExists('healthCaseDocumentHealthCaseIdDownloadfileDocumentIdPost', 'healthCaseId', healthCaseId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('healthCaseDocumentHealthCaseIdDownloadfileDocumentIdPost', 'documentId', documentId)
            const localVarPath = `/HealthCaseDocument/{healthCaseId}/downloadfile/{documentId}`
                .replace(`{${"healthCaseId"}}`, encodeURIComponent(String(healthCaseId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseDocumentHealthCaseIdDownloadfilewithtemplateDocumentIdGet: async (healthCaseId: string, documentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthCaseId' is not null or undefined
            assertParamExists('healthCaseDocumentHealthCaseIdDownloadfilewithtemplateDocumentIdGet', 'healthCaseId', healthCaseId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('healthCaseDocumentHealthCaseIdDownloadfilewithtemplateDocumentIdGet', 'documentId', documentId)
            const localVarPath = `/HealthCaseDocument/{healthCaseId}/downloadfilewithtemplate/{documentId}`
                .replace(`{${"healthCaseId"}}`, encodeURIComponent(String(healthCaseId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseDocumentHealthCaseIdListGet: async (healthCaseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthCaseId' is not null or undefined
            assertParamExists('healthCaseDocumentHealthCaseIdListGet', 'healthCaseId', healthCaseId)
            const localVarPath = `/HealthCaseDocument/{healthCaseId}/list`
                .replace(`{${"healthCaseId"}}`, encodeURIComponent(String(healthCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {MedHelpHCMWebApiModelsCreateHealthCaseDocument} [medHelpHCMWebApiModelsCreateHealthCaseDocument] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseDocumentHealthCaseIdPost: async (healthCaseId: string, medHelpHCMWebApiModelsCreateHealthCaseDocument?: MedHelpHCMWebApiModelsCreateHealthCaseDocument, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthCaseId' is not null or undefined
            assertParamExists('healthCaseDocumentHealthCaseIdPost', 'healthCaseId', healthCaseId)
            const localVarPath = `/HealthCaseDocument/{healthCaseId}`
                .replace(`{${"healthCaseId"}}`, encodeURIComponent(String(healthCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpHCMWebApiModelsCreateHealthCaseDocument, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {string} documentId 
         * @param {MedHelpHCMWebApiModelsUpdateFileWithTemplateModel} [medHelpHCMWebApiModelsUpdateFileWithTemplateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseDocumentHealthCaseIdUpdatefilewithtemplateDocumentIdPut: async (healthCaseId: string, documentId: string, medHelpHCMWebApiModelsUpdateFileWithTemplateModel?: MedHelpHCMWebApiModelsUpdateFileWithTemplateModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthCaseId' is not null or undefined
            assertParamExists('healthCaseDocumentHealthCaseIdUpdatefilewithtemplateDocumentIdPut', 'healthCaseId', healthCaseId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('healthCaseDocumentHealthCaseIdUpdatefilewithtemplateDocumentIdPut', 'documentId', documentId)
            const localVarPath = `/HealthCaseDocument/{healthCaseId}/updatefilewithtemplate/{documentId}`
                .replace(`{${"healthCaseId"}}`, encodeURIComponent(String(healthCaseId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpHCMWebApiModelsUpdateFileWithTemplateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {File} [file] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseDocumentHealthCaseIdUploadfilePost: async (healthCaseId: string, file?: File, type?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthCaseId' is not null or undefined
            assertParamExists('healthCaseDocumentHealthCaseIdUploadfilePost', 'healthCaseId', healthCaseId)
            const localVarPath = `/HealthCaseDocument/{healthCaseId}/uploadfile`
                .replace(`{${"healthCaseId"}}`, encodeURIComponent(String(healthCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
            if (type !== undefined) { 
                localVarFormParams.append('Type', type as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {MedHelpHCMWebApiModelsUploadFileWithTemplateModel} [medHelpHCMWebApiModelsUploadFileWithTemplateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseDocumentHealthCaseIdUploadfilewithtemplatePost: async (healthCaseId: string, medHelpHCMWebApiModelsUploadFileWithTemplateModel?: MedHelpHCMWebApiModelsUploadFileWithTemplateModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthCaseId' is not null or undefined
            assertParamExists('healthCaseDocumentHealthCaseIdUploadfilewithtemplatePost', 'healthCaseId', healthCaseId)
            const localVarPath = `/HealthCaseDocument/{healthCaseId}/uploadfilewithtemplate`
                .replace(`{${"healthCaseId"}}`, encodeURIComponent(String(healthCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpHCMWebApiModelsUploadFileWithTemplateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseDocumentIdDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('healthCaseDocumentIdDelete', 'id', id)
            const localVarPath = `/HealthCaseDocument/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpHCMWebApiRequestCopyHealthCaseDocumentsRequest} [medHelpHCMWebApiRequestCopyHealthCaseDocumentsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseDocumentMovehealthcasedocumentationPost: async (medHelpHCMWebApiRequestCopyHealthCaseDocumentsRequest?: MedHelpHCMWebApiRequestCopyHealthCaseDocumentsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/HealthCaseDocument/movehealthcasedocumentation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpHCMWebApiRequestCopyHealthCaseDocumentsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpHCMWebApiModelsUpdateHealthCaseDocument} [medHelpHCMWebApiModelsUpdateHealthCaseDocument] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseDocumentPut: async (medHelpHCMWebApiModelsUpdateHealthCaseDocument?: MedHelpHCMWebApiModelsUpdateHealthCaseDocument, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/HealthCaseDocument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpHCMWebApiModelsUpdateHealthCaseDocument, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthCaseDocumentApi - functional programming interface
 * @export
 */
export const HealthCaseDocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthCaseDocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} pdfDocumentReferenceKey 
         * @param {string} [employmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCaseDocumentFetchtemplatedocumentlinkPdfDocumentReferenceKeyGet(pdfDocumentReferenceKey: string, employmentId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpHCMWebApiModelsDownloadFileWithTemplateModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCaseDocumentFetchtemplatedocumentlinkPdfDocumentReferenceKeyGet(pdfDocumentReferenceKey, employmentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthCaseDocumentApi.healthCaseDocumentFetchtemplatedocumentlinkPdfDocumentReferenceKeyGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCaseDocumentHealthCaseIdDownloadfileDocumentIdPost(healthCaseId: string, documentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCaseDocumentHealthCaseIdDownloadfileDocumentIdPost(healthCaseId, documentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthCaseDocumentApi.healthCaseDocumentHealthCaseIdDownloadfileDocumentIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCaseDocumentHealthCaseIdDownloadfilewithtemplateDocumentIdGet(healthCaseId: string, documentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpHCMWebApiModelsDownloadFileWithTemplateModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCaseDocumentHealthCaseIdDownloadfilewithtemplateDocumentIdGet(healthCaseId, documentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthCaseDocumentApi.healthCaseDocumentHealthCaseIdDownloadfilewithtemplateDocumentIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCaseDocumentHealthCaseIdListGet(healthCaseId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedHelpHCMWebApiResponseHealthCaseDocument>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCaseDocumentHealthCaseIdListGet(healthCaseId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthCaseDocumentApi.healthCaseDocumentHealthCaseIdListGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {MedHelpHCMWebApiModelsCreateHealthCaseDocument} [medHelpHCMWebApiModelsCreateHealthCaseDocument] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCaseDocumentHealthCaseIdPost(healthCaseId: string, medHelpHCMWebApiModelsCreateHealthCaseDocument?: MedHelpHCMWebApiModelsCreateHealthCaseDocument, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCaseDocumentHealthCaseIdPost(healthCaseId, medHelpHCMWebApiModelsCreateHealthCaseDocument, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthCaseDocumentApi.healthCaseDocumentHealthCaseIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {string} documentId 
         * @param {MedHelpHCMWebApiModelsUpdateFileWithTemplateModel} [medHelpHCMWebApiModelsUpdateFileWithTemplateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCaseDocumentHealthCaseIdUpdatefilewithtemplateDocumentIdPut(healthCaseId: string, documentId: string, medHelpHCMWebApiModelsUpdateFileWithTemplateModel?: MedHelpHCMWebApiModelsUpdateFileWithTemplateModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCaseDocumentHealthCaseIdUpdatefilewithtemplateDocumentIdPut(healthCaseId, documentId, medHelpHCMWebApiModelsUpdateFileWithTemplateModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthCaseDocumentApi.healthCaseDocumentHealthCaseIdUpdatefilewithtemplateDocumentIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {File} [file] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCaseDocumentHealthCaseIdUploadfilePost(healthCaseId: string, file?: File, type?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCaseDocumentHealthCaseIdUploadfilePost(healthCaseId, file, type, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthCaseDocumentApi.healthCaseDocumentHealthCaseIdUploadfilePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {MedHelpHCMWebApiModelsUploadFileWithTemplateModel} [medHelpHCMWebApiModelsUploadFileWithTemplateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCaseDocumentHealthCaseIdUploadfilewithtemplatePost(healthCaseId: string, medHelpHCMWebApiModelsUploadFileWithTemplateModel?: MedHelpHCMWebApiModelsUploadFileWithTemplateModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCaseDocumentHealthCaseIdUploadfilewithtemplatePost(healthCaseId, medHelpHCMWebApiModelsUploadFileWithTemplateModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthCaseDocumentApi.healthCaseDocumentHealthCaseIdUploadfilewithtemplatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCaseDocumentIdDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCaseDocumentIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthCaseDocumentApi.healthCaseDocumentIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {MedHelpHCMWebApiRequestCopyHealthCaseDocumentsRequest} [medHelpHCMWebApiRequestCopyHealthCaseDocumentsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCaseDocumentMovehealthcasedocumentationPost(medHelpHCMWebApiRequestCopyHealthCaseDocumentsRequest?: MedHelpHCMWebApiRequestCopyHealthCaseDocumentsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCaseDocumentMovehealthcasedocumentationPost(medHelpHCMWebApiRequestCopyHealthCaseDocumentsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthCaseDocumentApi.healthCaseDocumentMovehealthcasedocumentationPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {MedHelpHCMWebApiModelsUpdateHealthCaseDocument} [medHelpHCMWebApiModelsUpdateHealthCaseDocument] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCaseDocumentPut(medHelpHCMWebApiModelsUpdateHealthCaseDocument?: MedHelpHCMWebApiModelsUpdateHealthCaseDocument, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCaseDocumentPut(medHelpHCMWebApiModelsUpdateHealthCaseDocument, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthCaseDocumentApi.healthCaseDocumentPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * HealthCaseDocumentApi - factory interface
 * @export
 */
export const HealthCaseDocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthCaseDocumentApiFp(configuration)
    return {
        /**
         * 
         * @param {string} pdfDocumentReferenceKey 
         * @param {string} [employmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseDocumentFetchtemplatedocumentlinkPdfDocumentReferenceKeyGet(pdfDocumentReferenceKey: string, employmentId?: string, options?: RawAxiosRequestConfig): AxiosPromise<MedHelpHCMWebApiModelsDownloadFileWithTemplateModel> {
            return localVarFp.healthCaseDocumentFetchtemplatedocumentlinkPdfDocumentReferenceKeyGet(pdfDocumentReferenceKey, employmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseDocumentHealthCaseIdDownloadfileDocumentIdPost(healthCaseId: string, documentId: string, options?: RawAxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.healthCaseDocumentHealthCaseIdDownloadfileDocumentIdPost(healthCaseId, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseDocumentHealthCaseIdDownloadfilewithtemplateDocumentIdGet(healthCaseId: string, documentId: string, options?: RawAxiosRequestConfig): AxiosPromise<MedHelpHCMWebApiModelsDownloadFileWithTemplateModel> {
            return localVarFp.healthCaseDocumentHealthCaseIdDownloadfilewithtemplateDocumentIdGet(healthCaseId, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseDocumentHealthCaseIdListGet(healthCaseId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<MedHelpHCMWebApiResponseHealthCaseDocument>> {
            return localVarFp.healthCaseDocumentHealthCaseIdListGet(healthCaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {MedHelpHCMWebApiModelsCreateHealthCaseDocument} [medHelpHCMWebApiModelsCreateHealthCaseDocument] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseDocumentHealthCaseIdPost(healthCaseId: string, medHelpHCMWebApiModelsCreateHealthCaseDocument?: MedHelpHCMWebApiModelsCreateHealthCaseDocument, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.healthCaseDocumentHealthCaseIdPost(healthCaseId, medHelpHCMWebApiModelsCreateHealthCaseDocument, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {string} documentId 
         * @param {MedHelpHCMWebApiModelsUpdateFileWithTemplateModel} [medHelpHCMWebApiModelsUpdateFileWithTemplateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseDocumentHealthCaseIdUpdatefilewithtemplateDocumentIdPut(healthCaseId: string, documentId: string, medHelpHCMWebApiModelsUpdateFileWithTemplateModel?: MedHelpHCMWebApiModelsUpdateFileWithTemplateModel, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.healthCaseDocumentHealthCaseIdUpdatefilewithtemplateDocumentIdPut(healthCaseId, documentId, medHelpHCMWebApiModelsUpdateFileWithTemplateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {File} [file] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseDocumentHealthCaseIdUploadfilePost(healthCaseId: string, file?: File, type?: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.healthCaseDocumentHealthCaseIdUploadfilePost(healthCaseId, file, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} healthCaseId 
         * @param {MedHelpHCMWebApiModelsUploadFileWithTemplateModel} [medHelpHCMWebApiModelsUploadFileWithTemplateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseDocumentHealthCaseIdUploadfilewithtemplatePost(healthCaseId: string, medHelpHCMWebApiModelsUploadFileWithTemplateModel?: MedHelpHCMWebApiModelsUploadFileWithTemplateModel, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.healthCaseDocumentHealthCaseIdUploadfilewithtemplatePost(healthCaseId, medHelpHCMWebApiModelsUploadFileWithTemplateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseDocumentIdDelete(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.healthCaseDocumentIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpHCMWebApiRequestCopyHealthCaseDocumentsRequest} [medHelpHCMWebApiRequestCopyHealthCaseDocumentsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseDocumentMovehealthcasedocumentationPost(medHelpHCMWebApiRequestCopyHealthCaseDocumentsRequest?: MedHelpHCMWebApiRequestCopyHealthCaseDocumentsRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.healthCaseDocumentMovehealthcasedocumentationPost(medHelpHCMWebApiRequestCopyHealthCaseDocumentsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpHCMWebApiModelsUpdateHealthCaseDocument} [medHelpHCMWebApiModelsUpdateHealthCaseDocument] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCaseDocumentPut(medHelpHCMWebApiModelsUpdateHealthCaseDocument?: MedHelpHCMWebApiModelsUpdateHealthCaseDocument, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.healthCaseDocumentPut(medHelpHCMWebApiModelsUpdateHealthCaseDocument, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthCaseDocumentApi - object-oriented interface
 * @export
 * @class HealthCaseDocumentApi
 * @extends {BaseAPI}
 */
export class HealthCaseDocumentApi extends BaseAPI {
    /**
     * 
     * @param {string} pdfDocumentReferenceKey 
     * @param {string} [employmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCaseDocumentApi
     */
    public healthCaseDocumentFetchtemplatedocumentlinkPdfDocumentReferenceKeyGet(pdfDocumentReferenceKey: string, employmentId?: string, options?: RawAxiosRequestConfig) {
        return HealthCaseDocumentApiFp(this.configuration).healthCaseDocumentFetchtemplatedocumentlinkPdfDocumentReferenceKeyGet(pdfDocumentReferenceKey, employmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} healthCaseId 
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCaseDocumentApi
     */
    public healthCaseDocumentHealthCaseIdDownloadfileDocumentIdPost(healthCaseId: string, documentId: string, options?: RawAxiosRequestConfig) {
        return HealthCaseDocumentApiFp(this.configuration).healthCaseDocumentHealthCaseIdDownloadfileDocumentIdPost(healthCaseId, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} healthCaseId 
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCaseDocumentApi
     */
    public healthCaseDocumentHealthCaseIdDownloadfilewithtemplateDocumentIdGet(healthCaseId: string, documentId: string, options?: RawAxiosRequestConfig) {
        return HealthCaseDocumentApiFp(this.configuration).healthCaseDocumentHealthCaseIdDownloadfilewithtemplateDocumentIdGet(healthCaseId, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} healthCaseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCaseDocumentApi
     */
    public healthCaseDocumentHealthCaseIdListGet(healthCaseId: string, options?: RawAxiosRequestConfig) {
        return HealthCaseDocumentApiFp(this.configuration).healthCaseDocumentHealthCaseIdListGet(healthCaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} healthCaseId 
     * @param {MedHelpHCMWebApiModelsCreateHealthCaseDocument} [medHelpHCMWebApiModelsCreateHealthCaseDocument] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCaseDocumentApi
     */
    public healthCaseDocumentHealthCaseIdPost(healthCaseId: string, medHelpHCMWebApiModelsCreateHealthCaseDocument?: MedHelpHCMWebApiModelsCreateHealthCaseDocument, options?: RawAxiosRequestConfig) {
        return HealthCaseDocumentApiFp(this.configuration).healthCaseDocumentHealthCaseIdPost(healthCaseId, medHelpHCMWebApiModelsCreateHealthCaseDocument, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} healthCaseId 
     * @param {string} documentId 
     * @param {MedHelpHCMWebApiModelsUpdateFileWithTemplateModel} [medHelpHCMWebApiModelsUpdateFileWithTemplateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCaseDocumentApi
     */
    public healthCaseDocumentHealthCaseIdUpdatefilewithtemplateDocumentIdPut(healthCaseId: string, documentId: string, medHelpHCMWebApiModelsUpdateFileWithTemplateModel?: MedHelpHCMWebApiModelsUpdateFileWithTemplateModel, options?: RawAxiosRequestConfig) {
        return HealthCaseDocumentApiFp(this.configuration).healthCaseDocumentHealthCaseIdUpdatefilewithtemplateDocumentIdPut(healthCaseId, documentId, medHelpHCMWebApiModelsUpdateFileWithTemplateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} healthCaseId 
     * @param {File} [file] 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCaseDocumentApi
     */
    public healthCaseDocumentHealthCaseIdUploadfilePost(healthCaseId: string, file?: File, type?: string, options?: RawAxiosRequestConfig) {
        return HealthCaseDocumentApiFp(this.configuration).healthCaseDocumentHealthCaseIdUploadfilePost(healthCaseId, file, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} healthCaseId 
     * @param {MedHelpHCMWebApiModelsUploadFileWithTemplateModel} [medHelpHCMWebApiModelsUploadFileWithTemplateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCaseDocumentApi
     */
    public healthCaseDocumentHealthCaseIdUploadfilewithtemplatePost(healthCaseId: string, medHelpHCMWebApiModelsUploadFileWithTemplateModel?: MedHelpHCMWebApiModelsUploadFileWithTemplateModel, options?: RawAxiosRequestConfig) {
        return HealthCaseDocumentApiFp(this.configuration).healthCaseDocumentHealthCaseIdUploadfilewithtemplatePost(healthCaseId, medHelpHCMWebApiModelsUploadFileWithTemplateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCaseDocumentApi
     */
    public healthCaseDocumentIdDelete(id: string, options?: RawAxiosRequestConfig) {
        return HealthCaseDocumentApiFp(this.configuration).healthCaseDocumentIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpHCMWebApiRequestCopyHealthCaseDocumentsRequest} [medHelpHCMWebApiRequestCopyHealthCaseDocumentsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCaseDocumentApi
     */
    public healthCaseDocumentMovehealthcasedocumentationPost(medHelpHCMWebApiRequestCopyHealthCaseDocumentsRequest?: MedHelpHCMWebApiRequestCopyHealthCaseDocumentsRequest, options?: RawAxiosRequestConfig) {
        return HealthCaseDocumentApiFp(this.configuration).healthCaseDocumentMovehealthcasedocumentationPost(medHelpHCMWebApiRequestCopyHealthCaseDocumentsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpHCMWebApiModelsUpdateHealthCaseDocument} [medHelpHCMWebApiModelsUpdateHealthCaseDocument] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCaseDocumentApi
     */
    public healthCaseDocumentPut(medHelpHCMWebApiModelsUpdateHealthCaseDocument?: MedHelpHCMWebApiModelsUpdateHealthCaseDocument, options?: RawAxiosRequestConfig) {
        return HealthCaseDocumentApiFp(this.configuration).healthCaseDocumentPut(medHelpHCMWebApiModelsUpdateHealthCaseDocument, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PingApi - axios parameter creator
 * @export
 */
export const PingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpHCMWebApiControllersPingModel} [medHelpHCMWebApiControllersPingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingPost: async (medHelpHCMWebApiControllersPingModel?: MedHelpHCMWebApiControllersPingModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpHCMWebApiControllersPingModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PingApi - functional programming interface
 * @export
 */
export const PingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pingGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pingGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PingApi.pingGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {MedHelpHCMWebApiControllersPingModel} [medHelpHCMWebApiControllersPingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pingPost(medHelpHCMWebApiControllersPingModel?: MedHelpHCMWebApiControllersPingModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pingPost(medHelpHCMWebApiControllersPingModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PingApi.pingPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PingApi - factory interface
 * @export
 */
export const PingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PingApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.pingGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpHCMWebApiControllersPingModel} [medHelpHCMWebApiControllersPingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingPost(medHelpHCMWebApiControllersPingModel?: MedHelpHCMWebApiControllersPingModel, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.pingPost(medHelpHCMWebApiControllersPingModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PingApi - object-oriented interface
 * @export
 * @class PingApi
 * @extends {BaseAPI}
 */
export class PingApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PingApi
     */
    public pingGet(options?: RawAxiosRequestConfig) {
        return PingApiFp(this.configuration).pingGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpHCMWebApiControllersPingModel} [medHelpHCMWebApiControllersPingModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PingApi
     */
    public pingPost(medHelpHCMWebApiControllersPingModel?: MedHelpHCMWebApiControllersPingModel, options?: RawAxiosRequestConfig) {
        return PingApiFp(this.configuration).pingPost(medHelpHCMWebApiControllersPingModel, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PredefinedActivityApi - axios parameter creator
 * @export
 */
export const PredefinedActivityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predefinedActivityIdDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('predefinedActivityIdDelete', 'id', id)
            const localVarPath = `/PredefinedActivity/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpHCMDomainPredefinedActivity} [medHelpHCMDomainPredefinedActivity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predefinedActivityPost: async (medHelpHCMDomainPredefinedActivity?: MedHelpHCMDomainPredefinedActivity, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/PredefinedActivity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpHCMDomainPredefinedActivity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpHCMDomainPredefinedActivity} [medHelpHCMDomainPredefinedActivity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predefinedActivityPut: async (medHelpHCMDomainPredefinedActivity?: MedHelpHCMDomainPredefinedActivity, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/PredefinedActivity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpHCMDomainPredefinedActivity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} region 
         * @param {string} source 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predefinedActivityRegionSourceListGet: async (region: string, source: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'region' is not null or undefined
            assertParamExists('predefinedActivityRegionSourceListGet', 'region', region)
            // verify required parameter 'source' is not null or undefined
            assertParamExists('predefinedActivityRegionSourceListGet', 'source', source)
            const localVarPath = `/PredefinedActivity/{region}/{source}/list`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)))
                .replace(`{${"source"}}`, encodeURIComponent(String(source)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PredefinedActivityApi - functional programming interface
 * @export
 */
export const PredefinedActivityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PredefinedActivityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async predefinedActivityIdDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.predefinedActivityIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PredefinedActivityApi.predefinedActivityIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {MedHelpHCMDomainPredefinedActivity} [medHelpHCMDomainPredefinedActivity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async predefinedActivityPost(medHelpHCMDomainPredefinedActivity?: MedHelpHCMDomainPredefinedActivity, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.predefinedActivityPost(medHelpHCMDomainPredefinedActivity, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PredefinedActivityApi.predefinedActivityPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {MedHelpHCMDomainPredefinedActivity} [medHelpHCMDomainPredefinedActivity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async predefinedActivityPut(medHelpHCMDomainPredefinedActivity?: MedHelpHCMDomainPredefinedActivity, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.predefinedActivityPut(medHelpHCMDomainPredefinedActivity, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PredefinedActivityApi.predefinedActivityPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} region 
         * @param {string} source 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async predefinedActivityRegionSourceListGet(region: string, source: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedHelpHCMDomainPredefinedActivity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.predefinedActivityRegionSourceListGet(region, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PredefinedActivityApi.predefinedActivityRegionSourceListGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PredefinedActivityApi - factory interface
 * @export
 */
export const PredefinedActivityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PredefinedActivityApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predefinedActivityIdDelete(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.predefinedActivityIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpHCMDomainPredefinedActivity} [medHelpHCMDomainPredefinedActivity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predefinedActivityPost(medHelpHCMDomainPredefinedActivity?: MedHelpHCMDomainPredefinedActivity, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.predefinedActivityPost(medHelpHCMDomainPredefinedActivity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpHCMDomainPredefinedActivity} [medHelpHCMDomainPredefinedActivity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predefinedActivityPut(medHelpHCMDomainPredefinedActivity?: MedHelpHCMDomainPredefinedActivity, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.predefinedActivityPut(medHelpHCMDomainPredefinedActivity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} region 
         * @param {string} source 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predefinedActivityRegionSourceListGet(region: string, source: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<MedHelpHCMDomainPredefinedActivity>> {
            return localVarFp.predefinedActivityRegionSourceListGet(region, source, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PredefinedActivityApi - object-oriented interface
 * @export
 * @class PredefinedActivityApi
 * @extends {BaseAPI}
 */
export class PredefinedActivityApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredefinedActivityApi
     */
    public predefinedActivityIdDelete(id: string, options?: RawAxiosRequestConfig) {
        return PredefinedActivityApiFp(this.configuration).predefinedActivityIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpHCMDomainPredefinedActivity} [medHelpHCMDomainPredefinedActivity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredefinedActivityApi
     */
    public predefinedActivityPost(medHelpHCMDomainPredefinedActivity?: MedHelpHCMDomainPredefinedActivity, options?: RawAxiosRequestConfig) {
        return PredefinedActivityApiFp(this.configuration).predefinedActivityPost(medHelpHCMDomainPredefinedActivity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpHCMDomainPredefinedActivity} [medHelpHCMDomainPredefinedActivity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredefinedActivityApi
     */
    public predefinedActivityPut(medHelpHCMDomainPredefinedActivity?: MedHelpHCMDomainPredefinedActivity, options?: RawAxiosRequestConfig) {
        return PredefinedActivityApiFp(this.configuration).predefinedActivityPut(medHelpHCMDomainPredefinedActivity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} region 
     * @param {string} source 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredefinedActivityApi
     */
    public predefinedActivityRegionSourceListGet(region: string, source: string, options?: RawAxiosRequestConfig) {
        return PredefinedActivityApiFp(this.configuration).predefinedActivityRegionSourceListGet(region, source, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PredefinedReasonApi - axios parameter creator
 * @export
 */
export const PredefinedReasonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} healthcasetype 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predefinedReasonHealthcasetypeListGet: async (healthcasetype: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthcasetype' is not null or undefined
            assertParamExists('predefinedReasonHealthcasetypeListGet', 'healthcasetype', healthcasetype)
            const localVarPath = `/PredefinedReason/{healthcasetype}/list`
                .replace(`{${"healthcasetype"}}`, encodeURIComponent(String(healthcasetype)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predefinedReasonIdDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('predefinedReasonIdDelete', 'id', id)
            const localVarPath = `/PredefinedReason/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpHCMDomainPredefinedReason} [medHelpHCMDomainPredefinedReason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predefinedReasonPost: async (medHelpHCMDomainPredefinedReason?: MedHelpHCMDomainPredefinedReason, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/PredefinedReason`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpHCMDomainPredefinedReason, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpHCMDomainPredefinedReason} [medHelpHCMDomainPredefinedReason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predefinedReasonPut: async (medHelpHCMDomainPredefinedReason?: MedHelpHCMDomainPredefinedReason, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/PredefinedReason`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpHCMDomainPredefinedReason, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PredefinedReasonApi - functional programming interface
 * @export
 */
export const PredefinedReasonApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PredefinedReasonApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} healthcasetype 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async predefinedReasonHealthcasetypeListGet(healthcasetype: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedHelpHCMDomainPredefinedReason>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.predefinedReasonHealthcasetypeListGet(healthcasetype, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PredefinedReasonApi.predefinedReasonHealthcasetypeListGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async predefinedReasonIdDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.predefinedReasonIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PredefinedReasonApi.predefinedReasonIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {MedHelpHCMDomainPredefinedReason} [medHelpHCMDomainPredefinedReason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async predefinedReasonPost(medHelpHCMDomainPredefinedReason?: MedHelpHCMDomainPredefinedReason, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.predefinedReasonPost(medHelpHCMDomainPredefinedReason, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PredefinedReasonApi.predefinedReasonPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {MedHelpHCMDomainPredefinedReason} [medHelpHCMDomainPredefinedReason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async predefinedReasonPut(medHelpHCMDomainPredefinedReason?: MedHelpHCMDomainPredefinedReason, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.predefinedReasonPut(medHelpHCMDomainPredefinedReason, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PredefinedReasonApi.predefinedReasonPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PredefinedReasonApi - factory interface
 * @export
 */
export const PredefinedReasonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PredefinedReasonApiFp(configuration)
    return {
        /**
         * 
         * @param {string} healthcasetype 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predefinedReasonHealthcasetypeListGet(healthcasetype: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<MedHelpHCMDomainPredefinedReason>> {
            return localVarFp.predefinedReasonHealthcasetypeListGet(healthcasetype, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predefinedReasonIdDelete(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.predefinedReasonIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpHCMDomainPredefinedReason} [medHelpHCMDomainPredefinedReason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predefinedReasonPost(medHelpHCMDomainPredefinedReason?: MedHelpHCMDomainPredefinedReason, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.predefinedReasonPost(medHelpHCMDomainPredefinedReason, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpHCMDomainPredefinedReason} [medHelpHCMDomainPredefinedReason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predefinedReasonPut(medHelpHCMDomainPredefinedReason?: MedHelpHCMDomainPredefinedReason, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.predefinedReasonPut(medHelpHCMDomainPredefinedReason, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PredefinedReasonApi - object-oriented interface
 * @export
 * @class PredefinedReasonApi
 * @extends {BaseAPI}
 */
export class PredefinedReasonApi extends BaseAPI {
    /**
     * 
     * @param {string} healthcasetype 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredefinedReasonApi
     */
    public predefinedReasonHealthcasetypeListGet(healthcasetype: string, options?: RawAxiosRequestConfig) {
        return PredefinedReasonApiFp(this.configuration).predefinedReasonHealthcasetypeListGet(healthcasetype, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredefinedReasonApi
     */
    public predefinedReasonIdDelete(id: string, options?: RawAxiosRequestConfig) {
        return PredefinedReasonApiFp(this.configuration).predefinedReasonIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpHCMDomainPredefinedReason} [medHelpHCMDomainPredefinedReason] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredefinedReasonApi
     */
    public predefinedReasonPost(medHelpHCMDomainPredefinedReason?: MedHelpHCMDomainPredefinedReason, options?: RawAxiosRequestConfig) {
        return PredefinedReasonApiFp(this.configuration).predefinedReasonPost(medHelpHCMDomainPredefinedReason, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpHCMDomainPredefinedReason} [medHelpHCMDomainPredefinedReason] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredefinedReasonApi
     */
    public predefinedReasonPut(medHelpHCMDomainPredefinedReason?: MedHelpHCMDomainPredefinedReason, options?: RawAxiosRequestConfig) {
        return PredefinedReasonApiFp(this.configuration).predefinedReasonPut(medHelpHCMDomainPredefinedReason, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ResponsibleApi - axios parameter creator
 * @export
 */
export const ResponsibleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} employmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        responsibleEmploymentIdListGet: async (employmentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employmentId' is not null or undefined
            assertParamExists('responsibleEmploymentIdListGet', 'employmentId', employmentId)
            const localVarPath = `/Responsible/{employmentId}/list`
                .replace(`{${"employmentId"}}`, encodeURIComponent(String(employmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ResponsibleApi - functional programming interface
 * @export
 */
export const ResponsibleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ResponsibleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} employmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async responsibleEmploymentIdListGet(employmentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedHelpHCMWebApiResponseResponsibleResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.responsibleEmploymentIdListGet(employmentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ResponsibleApi.responsibleEmploymentIdListGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ResponsibleApi - factory interface
 * @export
 */
export const ResponsibleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ResponsibleApiFp(configuration)
    return {
        /**
         * 
         * @param {string} employmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        responsibleEmploymentIdListGet(employmentId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<MedHelpHCMWebApiResponseResponsibleResponse>> {
            return localVarFp.responsibleEmploymentIdListGet(employmentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ResponsibleApi - object-oriented interface
 * @export
 * @class ResponsibleApi
 * @extends {BaseAPI}
 */
export class ResponsibleApi extends BaseAPI {
    /**
     * 
     * @param {string} employmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResponsibleApi
     */
    public responsibleEmploymentIdListGet(employmentId: string, options?: RawAxiosRequestConfig) {
        return ResponsibleApiFp(this.configuration).responsibleEmploymentIdListGet(employmentId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SearchHealthCaseApi - axios parameter creator
 * @export
 */
export const SearchHealthCaseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MedHelpHCMWebApiRequestSearchHealthCaseActivitiesRequest} [medHelpHCMWebApiRequestSearchHealthCaseActivitiesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchHealthCaseSearchHealthCaseActivitiesPost: async (medHelpHCMWebApiRequestSearchHealthCaseActivitiesRequest?: MedHelpHCMWebApiRequestSearchHealthCaseActivitiesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/SearchHealthCase/SearchHealthCaseActivities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpHCMWebApiRequestSearchHealthCaseActivitiesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpHCMWebApiRequestSearchHealthCaseRequest} [medHelpHCMWebApiRequestSearchHealthCaseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchHealthCaseSearchHealthCasePost: async (medHelpHCMWebApiRequestSearchHealthCaseRequest?: MedHelpHCMWebApiRequestSearchHealthCaseRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/SearchHealthCase/SearchHealthCase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpHCMWebApiRequestSearchHealthCaseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchHealthCaseApi - functional programming interface
 * @export
 */
export const SearchHealthCaseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchHealthCaseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MedHelpHCMWebApiRequestSearchHealthCaseActivitiesRequest} [medHelpHCMWebApiRequestSearchHealthCaseActivitiesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchHealthCaseSearchHealthCaseActivitiesPost(medHelpHCMWebApiRequestSearchHealthCaseActivitiesRequest?: MedHelpHCMWebApiRequestSearchHealthCaseActivitiesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpHCMWebApiControllersHealthCaseActivitiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchHealthCaseSearchHealthCaseActivitiesPost(medHelpHCMWebApiRequestSearchHealthCaseActivitiesRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SearchHealthCaseApi.searchHealthCaseSearchHealthCaseActivitiesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {MedHelpHCMWebApiRequestSearchHealthCaseRequest} [medHelpHCMWebApiRequestSearchHealthCaseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchHealthCaseSearchHealthCasePost(medHelpHCMWebApiRequestSearchHealthCaseRequest?: MedHelpHCMWebApiRequestSearchHealthCaseRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpHCMWebApiControllersHealthCaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchHealthCaseSearchHealthCasePost(medHelpHCMWebApiRequestSearchHealthCaseRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SearchHealthCaseApi.searchHealthCaseSearchHealthCasePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SearchHealthCaseApi - factory interface
 * @export
 */
export const SearchHealthCaseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchHealthCaseApiFp(configuration)
    return {
        /**
         * 
         * @param {MedHelpHCMWebApiRequestSearchHealthCaseActivitiesRequest} [medHelpHCMWebApiRequestSearchHealthCaseActivitiesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchHealthCaseSearchHealthCaseActivitiesPost(medHelpHCMWebApiRequestSearchHealthCaseActivitiesRequest?: MedHelpHCMWebApiRequestSearchHealthCaseActivitiesRequest, options?: RawAxiosRequestConfig): AxiosPromise<MedHelpHCMWebApiControllersHealthCaseActivitiesResponse> {
            return localVarFp.searchHealthCaseSearchHealthCaseActivitiesPost(medHelpHCMWebApiRequestSearchHealthCaseActivitiesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpHCMWebApiRequestSearchHealthCaseRequest} [medHelpHCMWebApiRequestSearchHealthCaseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchHealthCaseSearchHealthCasePost(medHelpHCMWebApiRequestSearchHealthCaseRequest?: MedHelpHCMWebApiRequestSearchHealthCaseRequest, options?: RawAxiosRequestConfig): AxiosPromise<MedHelpHCMWebApiControllersHealthCaseResponse> {
            return localVarFp.searchHealthCaseSearchHealthCasePost(medHelpHCMWebApiRequestSearchHealthCaseRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchHealthCaseApi - object-oriented interface
 * @export
 * @class SearchHealthCaseApi
 * @extends {BaseAPI}
 */
export class SearchHealthCaseApi extends BaseAPI {
    /**
     * 
     * @param {MedHelpHCMWebApiRequestSearchHealthCaseActivitiesRequest} [medHelpHCMWebApiRequestSearchHealthCaseActivitiesRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchHealthCaseApi
     */
    public searchHealthCaseSearchHealthCaseActivitiesPost(medHelpHCMWebApiRequestSearchHealthCaseActivitiesRequest?: MedHelpHCMWebApiRequestSearchHealthCaseActivitiesRequest, options?: RawAxiosRequestConfig) {
        return SearchHealthCaseApiFp(this.configuration).searchHealthCaseSearchHealthCaseActivitiesPost(medHelpHCMWebApiRequestSearchHealthCaseActivitiesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpHCMWebApiRequestSearchHealthCaseRequest} [medHelpHCMWebApiRequestSearchHealthCaseRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchHealthCaseApi
     */
    public searchHealthCaseSearchHealthCasePost(medHelpHCMWebApiRequestSearchHealthCaseRequest?: MedHelpHCMWebApiRequestSearchHealthCaseRequest, options?: RawAxiosRequestConfig) {
        return SearchHealthCaseApiFp(this.configuration).searchHealthCaseSearchHealthCasePost(medHelpHCMWebApiRequestSearchHealthCaseRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TemplateApi - axios parameter creator
 * @export
 */
export const TemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} templateId 
         * @param {MedHelpHCMWebApiModelsAddTemplateActivities} [medHelpHCMWebApiModelsAddTemplateActivities] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateAddactivitiesTemplateIdPost: async (templateId: string, medHelpHCMWebApiModelsAddTemplateActivities?: MedHelpHCMWebApiModelsAddTemplateActivities, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('templateAddactivitiesTemplateIdPost', 'templateId', templateId)
            const localVarPath = `/Template/addactivities/{templateId}`
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpHCMWebApiModelsAddTemplateActivities, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} templateId 
         * @param {string} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateDeleteactivitiesTemplateIdActivityIdDelete: async (templateId: string, activityId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('templateDeleteactivitiesTemplateIdActivityIdDelete', 'templateId', templateId)
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('templateDeleteactivitiesTemplateIdActivityIdDelete', 'activityId', activityId)
            const localVarPath = `/Template/deleteactivities/{templateId}/{activityId}`
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)))
                .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} templateId 
         * @param {MedHelpHCMWebApiModelsSaveTemplateDocuments} [medHelpHCMWebApiModelsSaveTemplateDocuments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateDocumentsTemplateIdPost: async (templateId: string, medHelpHCMWebApiModelsSaveTemplateDocuments?: MedHelpHCMWebApiModelsSaveTemplateDocuments, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('templateDocumentsTemplateIdPost', 'templateId', templateId)
            const localVarPath = `/Template/documents/{templateId}`
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpHCMWebApiModelsSaveTemplateDocuments, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} [companyId] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateListCustomerIdGet: async (customerId: number, companyId?: number, language?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('templateListCustomerIdGet', 'customerId', customerId)
            const localVarPath = `/Template/list/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpHCMWebApiModelsCreateTemplate} [medHelpHCMWebApiModelsCreateTemplate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templatePost: async (medHelpHCMWebApiModelsCreateTemplate?: MedHelpHCMWebApiModelsCreateTemplate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpHCMWebApiModelsCreateTemplate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} templateId 
         * @param {MedHelpHCMWebApiModelsAddTemplateRules} [medHelpHCMWebApiModelsAddTemplateRules] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateRulesTemplateIdPost: async (templateId: string, medHelpHCMWebApiModelsAddTemplateRules?: MedHelpHCMWebApiModelsAddTemplateRules, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('templateRulesTemplateIdPost', 'templateId', templateId)
            const localVarPath = `/Template/rules/{templateId}`
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpHCMWebApiModelsAddTemplateRules, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} templateId 
         * @param {string} ruleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateRulesTemplateIdRuleIdDelete: async (templateId: string, ruleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('templateRulesTemplateIdRuleIdDelete', 'templateId', templateId)
            // verify required parameter 'ruleId' is not null or undefined
            assertParamExists('templateRulesTemplateIdRuleIdDelete', 'ruleId', ruleId)
            const localVarPath = `/Template/rules/{templateId}/{ruleId}`
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)))
                .replace(`{${"ruleId"}}`, encodeURIComponent(String(ruleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTemplateIdDelete: async (templateId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('templateTemplateIdDelete', 'templateId', templateId)
            const localVarPath = `/Template/{templateId}`
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} companyId 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTemplateReasonsCustomerIdCompanyIdGet: async (customerId: number, companyId: number, language?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('templateTemplateReasonsCustomerIdCompanyIdGet', 'customerId', customerId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('templateTemplateReasonsCustomerIdCompanyIdGet', 'companyId', companyId)
            const localVarPath = `/Template/templateReasons/{customerId}/{companyId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} templateId 
         * @param {MedHelpHCMWebApiModelsAddTemplateActivities} [medHelpHCMWebApiModelsAddTemplateActivities] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateUpdateactivitiesTemplateIdPost: async (templateId: string, medHelpHCMWebApiModelsAddTemplateActivities?: MedHelpHCMWebApiModelsAddTemplateActivities, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('templateUpdateactivitiesTemplateIdPost', 'templateId', templateId)
            const localVarPath = `/Template/updateactivities/{templateId}`
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpHCMWebApiModelsAddTemplateActivities, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TemplateApi - functional programming interface
 * @export
 */
export const TemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} templateId 
         * @param {MedHelpHCMWebApiModelsAddTemplateActivities} [medHelpHCMWebApiModelsAddTemplateActivities] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateAddactivitiesTemplateIdPost(templateId: string, medHelpHCMWebApiModelsAddTemplateActivities?: MedHelpHCMWebApiModelsAddTemplateActivities, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpHCMDomainTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateAddactivitiesTemplateIdPost(templateId, medHelpHCMWebApiModelsAddTemplateActivities, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TemplateApi.templateAddactivitiesTemplateIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} templateId 
         * @param {string} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateDeleteactivitiesTemplateIdActivityIdDelete(templateId: string, activityId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpHCMDomainTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateDeleteactivitiesTemplateIdActivityIdDelete(templateId, activityId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TemplateApi.templateDeleteactivitiesTemplateIdActivityIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} templateId 
         * @param {MedHelpHCMWebApiModelsSaveTemplateDocuments} [medHelpHCMWebApiModelsSaveTemplateDocuments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateDocumentsTemplateIdPost(templateId: string, medHelpHCMWebApiModelsSaveTemplateDocuments?: MedHelpHCMWebApiModelsSaveTemplateDocuments, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpHCMDomainTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateDocumentsTemplateIdPost(templateId, medHelpHCMWebApiModelsSaveTemplateDocuments, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TemplateApi.templateDocumentsTemplateIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} [companyId] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateListCustomerIdGet(customerId: number, companyId?: number, language?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedHelpHCMDomainTemplate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateListCustomerIdGet(customerId, companyId, language, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TemplateApi.templateListCustomerIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {MedHelpHCMWebApiModelsCreateTemplate} [medHelpHCMWebApiModelsCreateTemplate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templatePost(medHelpHCMWebApiModelsCreateTemplate?: MedHelpHCMWebApiModelsCreateTemplate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpHCMDomainTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templatePost(medHelpHCMWebApiModelsCreateTemplate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TemplateApi.templatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} templateId 
         * @param {MedHelpHCMWebApiModelsAddTemplateRules} [medHelpHCMWebApiModelsAddTemplateRules] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateRulesTemplateIdPost(templateId: string, medHelpHCMWebApiModelsAddTemplateRules?: MedHelpHCMWebApiModelsAddTemplateRules, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpHCMDomainTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateRulesTemplateIdPost(templateId, medHelpHCMWebApiModelsAddTemplateRules, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TemplateApi.templateRulesTemplateIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} templateId 
         * @param {string} ruleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateRulesTemplateIdRuleIdDelete(templateId: string, ruleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpHCMDomainTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateRulesTemplateIdRuleIdDelete(templateId, ruleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TemplateApi.templateRulesTemplateIdRuleIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateTemplateIdDelete(templateId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateTemplateIdDelete(templateId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TemplateApi.templateTemplateIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} companyId 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateTemplateReasonsCustomerIdCompanyIdGet(customerId: number, companyId: number, language?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<MedHelpHCMDomainTemplateReason>; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateTemplateReasonsCustomerIdCompanyIdGet(customerId, companyId, language, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TemplateApi.templateTemplateReasonsCustomerIdCompanyIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} templateId 
         * @param {MedHelpHCMWebApiModelsAddTemplateActivities} [medHelpHCMWebApiModelsAddTemplateActivities] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateUpdateactivitiesTemplateIdPost(templateId: string, medHelpHCMWebApiModelsAddTemplateActivities?: MedHelpHCMWebApiModelsAddTemplateActivities, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpHCMDomainTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateUpdateactivitiesTemplateIdPost(templateId, medHelpHCMWebApiModelsAddTemplateActivities, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TemplateApi.templateUpdateactivitiesTemplateIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TemplateApi - factory interface
 * @export
 */
export const TemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TemplateApiFp(configuration)
    return {
        /**
         * 
         * @param {string} templateId 
         * @param {MedHelpHCMWebApiModelsAddTemplateActivities} [medHelpHCMWebApiModelsAddTemplateActivities] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateAddactivitiesTemplateIdPost(templateId: string, medHelpHCMWebApiModelsAddTemplateActivities?: MedHelpHCMWebApiModelsAddTemplateActivities, options?: RawAxiosRequestConfig): AxiosPromise<MedHelpHCMDomainTemplate> {
            return localVarFp.templateAddactivitiesTemplateIdPost(templateId, medHelpHCMWebApiModelsAddTemplateActivities, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} templateId 
         * @param {string} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateDeleteactivitiesTemplateIdActivityIdDelete(templateId: string, activityId: string, options?: RawAxiosRequestConfig): AxiosPromise<MedHelpHCMDomainTemplate> {
            return localVarFp.templateDeleteactivitiesTemplateIdActivityIdDelete(templateId, activityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} templateId 
         * @param {MedHelpHCMWebApiModelsSaveTemplateDocuments} [medHelpHCMWebApiModelsSaveTemplateDocuments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateDocumentsTemplateIdPost(templateId: string, medHelpHCMWebApiModelsSaveTemplateDocuments?: MedHelpHCMWebApiModelsSaveTemplateDocuments, options?: RawAxiosRequestConfig): AxiosPromise<MedHelpHCMDomainTemplate> {
            return localVarFp.templateDocumentsTemplateIdPost(templateId, medHelpHCMWebApiModelsSaveTemplateDocuments, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} [companyId] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateListCustomerIdGet(customerId: number, companyId?: number, language?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<MedHelpHCMDomainTemplate>> {
            return localVarFp.templateListCustomerIdGet(customerId, companyId, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpHCMWebApiModelsCreateTemplate} [medHelpHCMWebApiModelsCreateTemplate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templatePost(medHelpHCMWebApiModelsCreateTemplate?: MedHelpHCMWebApiModelsCreateTemplate, options?: RawAxiosRequestConfig): AxiosPromise<MedHelpHCMDomainTemplate> {
            return localVarFp.templatePost(medHelpHCMWebApiModelsCreateTemplate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} templateId 
         * @param {MedHelpHCMWebApiModelsAddTemplateRules} [medHelpHCMWebApiModelsAddTemplateRules] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateRulesTemplateIdPost(templateId: string, medHelpHCMWebApiModelsAddTemplateRules?: MedHelpHCMWebApiModelsAddTemplateRules, options?: RawAxiosRequestConfig): AxiosPromise<MedHelpHCMDomainTemplate> {
            return localVarFp.templateRulesTemplateIdPost(templateId, medHelpHCMWebApiModelsAddTemplateRules, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} templateId 
         * @param {string} ruleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateRulesTemplateIdRuleIdDelete(templateId: string, ruleId: string, options?: RawAxiosRequestConfig): AxiosPromise<MedHelpHCMDomainTemplate> {
            return localVarFp.templateRulesTemplateIdRuleIdDelete(templateId, ruleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTemplateIdDelete(templateId: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.templateTemplateIdDelete(templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} companyId 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTemplateReasonsCustomerIdCompanyIdGet(customerId: number, companyId: number, language?: string, options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: Array<MedHelpHCMDomainTemplateReason>; }> {
            return localVarFp.templateTemplateReasonsCustomerIdCompanyIdGet(customerId, companyId, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} templateId 
         * @param {MedHelpHCMWebApiModelsAddTemplateActivities} [medHelpHCMWebApiModelsAddTemplateActivities] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateUpdateactivitiesTemplateIdPost(templateId: string, medHelpHCMWebApiModelsAddTemplateActivities?: MedHelpHCMWebApiModelsAddTemplateActivities, options?: RawAxiosRequestConfig): AxiosPromise<MedHelpHCMDomainTemplate> {
            return localVarFp.templateUpdateactivitiesTemplateIdPost(templateId, medHelpHCMWebApiModelsAddTemplateActivities, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TemplateApi - object-oriented interface
 * @export
 * @class TemplateApi
 * @extends {BaseAPI}
 */
export class TemplateApi extends BaseAPI {
    /**
     * 
     * @param {string} templateId 
     * @param {MedHelpHCMWebApiModelsAddTemplateActivities} [medHelpHCMWebApiModelsAddTemplateActivities] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public templateAddactivitiesTemplateIdPost(templateId: string, medHelpHCMWebApiModelsAddTemplateActivities?: MedHelpHCMWebApiModelsAddTemplateActivities, options?: RawAxiosRequestConfig) {
        return TemplateApiFp(this.configuration).templateAddactivitiesTemplateIdPost(templateId, medHelpHCMWebApiModelsAddTemplateActivities, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} templateId 
     * @param {string} activityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public templateDeleteactivitiesTemplateIdActivityIdDelete(templateId: string, activityId: string, options?: RawAxiosRequestConfig) {
        return TemplateApiFp(this.configuration).templateDeleteactivitiesTemplateIdActivityIdDelete(templateId, activityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} templateId 
     * @param {MedHelpHCMWebApiModelsSaveTemplateDocuments} [medHelpHCMWebApiModelsSaveTemplateDocuments] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public templateDocumentsTemplateIdPost(templateId: string, medHelpHCMWebApiModelsSaveTemplateDocuments?: MedHelpHCMWebApiModelsSaveTemplateDocuments, options?: RawAxiosRequestConfig) {
        return TemplateApiFp(this.configuration).templateDocumentsTemplateIdPost(templateId, medHelpHCMWebApiModelsSaveTemplateDocuments, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {number} [companyId] 
     * @param {string} [language] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public templateListCustomerIdGet(customerId: number, companyId?: number, language?: string, options?: RawAxiosRequestConfig) {
        return TemplateApiFp(this.configuration).templateListCustomerIdGet(customerId, companyId, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpHCMWebApiModelsCreateTemplate} [medHelpHCMWebApiModelsCreateTemplate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public templatePost(medHelpHCMWebApiModelsCreateTemplate?: MedHelpHCMWebApiModelsCreateTemplate, options?: RawAxiosRequestConfig) {
        return TemplateApiFp(this.configuration).templatePost(medHelpHCMWebApiModelsCreateTemplate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} templateId 
     * @param {MedHelpHCMWebApiModelsAddTemplateRules} [medHelpHCMWebApiModelsAddTemplateRules] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public templateRulesTemplateIdPost(templateId: string, medHelpHCMWebApiModelsAddTemplateRules?: MedHelpHCMWebApiModelsAddTemplateRules, options?: RawAxiosRequestConfig) {
        return TemplateApiFp(this.configuration).templateRulesTemplateIdPost(templateId, medHelpHCMWebApiModelsAddTemplateRules, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} templateId 
     * @param {string} ruleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public templateRulesTemplateIdRuleIdDelete(templateId: string, ruleId: string, options?: RawAxiosRequestConfig) {
        return TemplateApiFp(this.configuration).templateRulesTemplateIdRuleIdDelete(templateId, ruleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public templateTemplateIdDelete(templateId: string, options?: RawAxiosRequestConfig) {
        return TemplateApiFp(this.configuration).templateTemplateIdDelete(templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {number} companyId 
     * @param {string} [language] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public templateTemplateReasonsCustomerIdCompanyIdGet(customerId: number, companyId: number, language?: string, options?: RawAxiosRequestConfig) {
        return TemplateApiFp(this.configuration).templateTemplateReasonsCustomerIdCompanyIdGet(customerId, companyId, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} templateId 
     * @param {MedHelpHCMWebApiModelsAddTemplateActivities} [medHelpHCMWebApiModelsAddTemplateActivities] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public templateUpdateactivitiesTemplateIdPost(templateId: string, medHelpHCMWebApiModelsAddTemplateActivities?: MedHelpHCMWebApiModelsAddTemplateActivities, options?: RawAxiosRequestConfig) {
        return TemplateApiFp(this.configuration).templateUpdateactivitiesTemplateIdPost(templateId, medHelpHCMWebApiModelsAddTemplateActivities, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TemplateFormDocumentApi - axios parameter creator
 * @export
 */
export const TemplateFormDocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} retailerId 
         * @param {number} [customerId] 
         * @param {string} [region] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateFormDocumentListRetailerIdGet: async (retailerId: number, customerId?: number, region?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'retailerId' is not null or undefined
            assertParamExists('templateFormDocumentListRetailerIdGet', 'retailerId', retailerId)
            const localVarPath = `/TemplateFormDocument/list/{retailerId}`
                .replace(`{${"retailerId"}}`, encodeURIComponent(String(retailerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (region !== undefined) {
                localVarQueryParameter['region'] = region;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TemplateFormDocumentApi - functional programming interface
 * @export
 */
export const TemplateFormDocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TemplateFormDocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} retailerId 
         * @param {number} [customerId] 
         * @param {string} [region] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateFormDocumentListRetailerIdGet(retailerId: number, customerId?: number, region?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedHelpHCMWebApiResponseTemplateFormDocumentResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateFormDocumentListRetailerIdGet(retailerId, customerId, region, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TemplateFormDocumentApi.templateFormDocumentListRetailerIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TemplateFormDocumentApi - factory interface
 * @export
 */
export const TemplateFormDocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TemplateFormDocumentApiFp(configuration)
    return {
        /**
         * 
         * @param {number} retailerId 
         * @param {number} [customerId] 
         * @param {string} [region] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateFormDocumentListRetailerIdGet(retailerId: number, customerId?: number, region?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<MedHelpHCMWebApiResponseTemplateFormDocumentResponse>> {
            return localVarFp.templateFormDocumentListRetailerIdGet(retailerId, customerId, region, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TemplateFormDocumentApi - object-oriented interface
 * @export
 * @class TemplateFormDocumentApi
 * @extends {BaseAPI}
 */
export class TemplateFormDocumentApi extends BaseAPI {
    /**
     * 
     * @param {number} retailerId 
     * @param {number} [customerId] 
     * @param {string} [region] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateFormDocumentApi
     */
    public templateFormDocumentListRetailerIdGet(retailerId: number, customerId?: number, region?: string, options?: RawAxiosRequestConfig) {
        return TemplateFormDocumentApiFp(this.configuration).templateFormDocumentListRetailerIdGet(retailerId, customerId, region, options).then((request) => request(this.axios, this.basePath));
    }
}



