import { useTranslation } from "react-i18next";
import { ISubscriptionOptionsPageProps } from "pages/Administration/types";
import { Heading, SingleDatepicker } from "@medhelp/ui";
import {
    getDateStringForwardOrBack,
    getStringStartOfDateString,
    isBeforeToday,
    isSameOrBeforeDateDay,
    newDateString,
} from "utils/date";
import NotificationTypeRow from "../NotificationTypeRow";
import SubscriptionGroup from "../SubscriptionGroup";
import ContactKey from "../ContactKey";

const SubscriptionOptions = ({
    startDatepickerProps,
    endDatepickerProps,
    subscriptionGroups,
    onCheckboxChange,
    disableEditSubscription,
    subscribeOnUserDisplayProps,
    onMissingContactKeyClicked,
    contactKey,
    missingContactKey,
    selectedUserName,
    newSubIsLoading,
    isSubsOnUser,
}: ISubscriptionOptionsPageProps) => {
    const { t } = useTranslation("administration");
    return (
        <div id="subscription-options">
            {!newSubIsLoading && (
                <>
                    <div className="mb-10 w-3/5">
                        <ContactKey
                            contactKey={contactKey}
                            missingContactKey={missingContactKey}
                            selectedUserName={selectedUserName}
                            onMissingContactKeyClicked={onMissingContactKeyClicked}
                            hideContactKeyInSubOptions={isSubsOnUser}
                        />
                    </div>
                    <div className="flex">
                        <div className="mr-4 flex flex-col">
                            <Heading heading={t("startDate")} mandatory />
                            <SingleDatepicker
                                {...startDatepickerProps}
                                disabled={disableEditSubscription || isBeforeToday(startDatepickerProps.selectedDate)}
                                disableDatesBefore={getStringStartOfDateString("days")}
                                mobileHeading={t("startDate")}
                                isMandatory
                            />
                        </div>
                        <div className="flex flex-col">
                            <Heading heading={t("endDate")} />
                            <SingleDatepicker
                                {...endDatepickerProps}
                                disabled={disableEditSubscription}
                                disableDatesBefore={
                                    isSameOrBeforeDateDay(startDatepickerProps.selectedDate)
                                        ? getDateStringForwardOrBack(1, "days", "forward", newDateString())
                                        : startDatepickerProps.selectedDate
                                }
                                mobileHeading={t("endDate")}
                                isMandatory
                            />
                        </div>
                    </div>
                    {subscriptionGroups.map((group, i) => (
                        <div key={group.groupName + "-" + i}>
                            <SubscriptionGroup
                                {...group}
                                onCheckboxChange={onCheckboxChange}
                                disableEditSubscription={disableEditSubscription}
                            >
                                {group.notificationTypeRows.map((notificationTypeRowProps, i) => (
                                    <div key={group.subscriptionId + "-" + i}>
                                        <NotificationTypeRow
                                            {...notificationTypeRowProps}
                                            disableEditSubscription={disableEditSubscription}
                                            onCheckboxChange={onCheckboxChange}
                                        />
                                    </div>
                                ))}
                            </SubscriptionGroup>
                        </div>
                    ))}
                    {subscribeOnUserDisplayProps?.groups && subscribeOnUserDisplayProps.groups.length > 0 && (
                        <div className="mt-6">
                            <p>
                                {t("isSubscribingOnVia", {
                                    subscriber: subscribeOnUserDisplayProps.subscriber,
                                    name: subscribeOnUserDisplayProps.user,
                                })}
                            </p>
                            {subscribeOnUserDisplayProps.groups.map((group) => (
                                <div key={group.groupName} className="flex border-b py-6">
                                    <p className="mr-6 font-bold">{t(group.groupName)}</p>
                                    <div className="flex flex-wrap">
                                        {group.subscriptionLabels.map((label, i) => (
                                            <div key={label} className="flex">
                                                {label}
                                                {i !== group.subscriptionLabels.length - 1 && (
                                                    <div className="mx-2">|</div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default SubscriptionOptions;
