import _ from "lodash";
import React from "react";
import { useDetectOutsideClick } from "../../hooks";
import PrimaryButton from "../PrimaryButton";
import { ReactComponent as PlusIcon } from "../../icons/plus.svg";
import SecondaryButton from "../SecondaryButton";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

export interface ISelectValue {
    description: string;
    value: string;
    nonTranslatedDescription?: string;
    id?: string;
}

export const convertEnumsToISelectValues = (
    enumObject: { [x: string]: string | number },
    enumName?: string,
    translate?: TFunction,
): ISelectValue[] => {
    const converted: ISelectValue[] = Object.keys(enumObject).map((key) => {
        // @ts-ignore
        const desc = _.camelCase(enumObject[key]);
        // console.log({ key, desc });
        return {
            description: translate ? translate(`${enumName}.${key}`) : "",
            value: key,
            nonTranslatedDescription: enumName ? `${enumName}.${key}` : desc,
        };
    });

    if (converted.length > 1) {
        const length = converted.length;
        // const reduced = converted.slice(0, length / 2);
        // console.log({ converted, length, reduced });
        const reduced = converted.slice(length / 2, length);
        return reduced;
    }

    return converted;
};

export const getEnumSingleValue = (enumObject: { [x: string]: string | number }, key: string): string | number => {
    const value = enumObject[key];
    // console.log({ value });
    return value;
};

interface ISelectProps {
    id: string;
    size?: ISizePreset;
    className?: string;
    header?: string;
    placeholder?: string;
    selectedValue: string;
    values: ISelectValue[];
    onChange: (value: string | undefined) => void;
    disabled?: boolean;
    errorMessage?: string;
    errorClassName?: string;
    onBlur?: () => void;
    useCustomSelect?: boolean;
    hidePlaceHolderInList?: boolean;
    hasOngoingAbsenceReport?: boolean;
    sortDescriptions?: boolean;
}

const sizePreset = {
    small: "w-full xxs:w-24/100 xxs:mr-4",
    half: "w-full xxs:w-48/100",
    large: "w-full md:w-[624px]",
};

export type ISizePreset = keyof typeof sizePreset;

const Select = (props: ISelectProps & { children?: React.ReactNode }) => {
    const {
        children,
        id,
        size,
        className,
        header,
        placeholder = "",
        selectedValue,
        values,
        onChange,
        disabled,
        errorMessage,
        errorClassName,
        onBlur,
        useCustomSelect,
        hidePlaceHolderInList,
        hasOngoingAbsenceReport,
        sortDescriptions,
        ...rest
    } = props;
    const { isOutside: open, setIsOutside: setOpen, setContainer } = useDetectOutsideClick(false, false);
    const { t } = useTranslation();

    const isMobile = (): boolean => false;

    let descriptions = values.map((c) => (c.description ? c.description : t(c.nonTranslatedDescription as string)));
    if (sortDescriptions) {
        descriptions = values
            ?.sort((a, b) => {
                if (!a.value || !b.value) {
                    return Number.MAX_VALUE;
                }
                return a.description.localeCompare(b.description, "sv");
            })
            .map((c) => c.description);
    }

    if (placeholder && !hidePlaceHolderInList) descriptions.unshift(placeholder);
    const selectedValueIsPlaceHolder = selectedValue === null || selectedValue === "" || selectedValue === placeholder;

    const select = (
        <select
            data-testid="select"
            className={`h-12 w-full whitespace-pre-wrap border pl-2 ${disabled ? "disabled opacity-50" : "default border-mhdarkgreen-original"} sm:whitespace-no-wrap bg-pageBg ${disabled && "cursor-not-allowed bg-mhgrey-medium opacity-50"} ${!useCustomSelect && selectedValueIsPlaceHolder ? "text-mhgrey-medium" : "text-mhdarkgreen-original"} ${useCustomSelect && "select-text-center w-full appearance-none border-none bg-primary-500 text-center font-bold"} ${className} `}
            value={values?.find((c) => c?.value === selectedValue)?.description || placeholder}
            disabled={disabled}
            onChange={(e) => {
                const i = values.find((c) => c.description === e?.target?.value);
                onChange(i?.value || placeholder);
            }}
            {...rest}
        >
            {hidePlaceHolderInList && (
                <option key="placeholder" disabled hidden>
                    {placeholder}
                </option>
            )}
            {descriptions?.map((x, i) => (
                <option key={x + i.toString()} value={x}>
                    {x}
                </option>
            ))}
        </select>
    );
    let customSelect;
    if (useCustomSelect) {
        // console.log('USING CUSTOM SELECT');
        customSelect = isMobile() ? (
            <div
                className={`flex h-10 w-full items-center justify-center pl-2 ${
                    hasOngoingAbsenceReport ? "border border-mhdarkgreen-original" : "bg-primary-500"
                }`}
            >
                <PlusIcon />
                <select
                    data-testid="select"
                    className={`ml-4 whitespace-pre-wrap ${disabled ? "opacity-50" : "border-mhdarkgreen-original"} sm:whitespace-no-wrap bg-transparent ${disabled && "cursor-not-allowed bg-mhgrey-medium opacity-50"} ${!useCustomSelect && selectedValueIsPlaceHolder ? "text-mhgrey-medium" : "text-mhdarkgreen-original"} ${useCustomSelect && "appearance-none font-bold"} `}
                    value={values?.find((c) => c?.value === selectedValue)?.description || placeholder}
                    disabled={disabled}
                    onChange={(e) => {
                        const i = values.find((c) => c.description === e?.target?.value);
                        onChange(i?.value || placeholder);
                    }}
                    {...rest}
                >
                    {hidePlaceHolderInList && (
                        <option key="placeholder" disabled hidden>
                            {placeholder}
                        </option>
                    )}
                    {/* selected={selectedValue === x} to make clicktests work */}
                    {descriptions?.map((x) => (
                        <option key={x} value={x}>
                            {x}
                        </option>
                    ))}
                </select>
            </div>
        ) : (
            <div id="customSelectContainer" ref={setContainer} className="flex flex-col">
                {hasOngoingAbsenceReport ? (
                    <SecondaryButton
                        id="createdArUser"
                        text={placeholder}
                        disabled={disabled}
                        onClick={() => setOpen(!open)}
                        className="flex w-full justify-center self-center"
                        leftIcon={<PlusIcon />}
                    />
                ) : (
                    <PrimaryButton
                        id={id}
                        text={placeholder}
                        onClick={() => {
                            setOpen(!open);
                        }}
                        disabled={disabled}
                        leftIcon={<PlusIcon />}
                    />
                )}
                <div
                    id="miniMenuItemsContainer"
                    className={`custom-select-items-container absolute left-0 z-10 flex w-full flex-col bg-white py-2 shadow-lg ${open ? "" : "hidden"}`}
                >
                    {values.map((c) => (
                        <button
                            type="button"
                            key={c.description}
                            className="mini-menu-foucs bg-white py-2 pl-4 text-left hover:bg-mhgrey-light"
                            onClick={() => {
                                onChange(c.value);
                                setOpen(!open);
                            }}
                        >
                            {c.description}
                        </button>
                    ))}
                </div>
            </div>
        );
    }

    return (
        <div id={id} onBlur={onBlur} className={`transparent relative ${sizePreset[size!]} ${className}`}>
            {header && <p className="mb-2">{header}</p>}
            {useCustomSelect ? customSelect : select}
            {errorMessage && <p className={`pl-3 text-base text-mhred-alert1 ${errorClassName}`}>{errorMessage}</p>}
            {children}
        </div>
    );
};
export default Select;
