import { Retailers } from "globalTypes";
import { twMerge } from "tailwind-merge";
import "../../css/toggleSwitch.css";

interface ToggleSwitchProps {
    onChange: (value: React.SyntheticEvent) => void;
    value: boolean;
    name: string;
    theme?: Retailers;
    disabled?: boolean;
}
export const ToggleSwitch = ({ name, value, onChange, theme, disabled, ...props }: ToggleSwitchProps) => {
    return (
        <button
            id={name}
            className={twMerge(
                "toggle focus:ring-0",
                theme,
                value ? "toggle-on" : "toggle-off",
                disabled ? "toggle-off !cursor-not-allowed" : "",
            )}
            onClick={(e) => onChange(e)}
            {...props}
            disabled={disabled}
        >
            <div className="rectangle-12640"></div>
            <div className="input-field-text">{value ? "On" : "Off"}</div>
            <div className="ellipse-26"></div>
        </button>
    );
};
