import { Navigate, Route, Routes } from "react-router-dom";

import GlobalMarket from "pages/login/pages/GlobalMarket";

import LoginPage from "pages/login";

import Fortnox from "pages/Fortnox/Fortnox";

interface IAuthenticatedRoutesProps {
    isAuth: boolean;
}

const UnAutheticatedRoutes = ({ isAuth }: IAuthenticatedRoutesProps) => {
    return (
        <Routes>
            {!isAuth && <Route path="*" element={<Navigate replace to="/login" />} />}
            <Route path="/login" element={<GlobalMarket />} />
            <Route path="/frisklinjen/login" element={<GlobalMarket />} />
            <Route path="/avonova/login" element={<GlobalMarket />} />
            <Route path="/login/:param" element={<LoginPage />} />
            <Route path="/frisklinjen/login/:param" element={<LoginPage />} />
            <Route path="/avonova/login/:param" element={<LoginPage />} />
            <Route path="fortnox" element={<Fortnox />} />
        </Routes>
    );
};

export default UnAutheticatedRoutes;
