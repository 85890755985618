import React from "react";
import { useTranslation } from "react-i18next";
import InputAdmin from "../InputAdmin";
import { IDynamicComponent } from "./IDynamicComponent";
import { isNumber } from "lodash";

const DynamicNumber = (
    props: IDynamicComponent<string> & {
        placeholder: string;
        children?: React.ReactNode;
    },
) => {
    const { id, value, onChange, children, placeholder, disabled } = props;
    const { t } = useTranslation();

    let errorMessage = "";
    let valid = true;
    if (value) {
        valid = isNumber(value);
    }
    if (!valid) {
        errorMessage = t("invalidNumber");
    }

    return (
        <div>
            <InputAdmin
                id={id}
                type="number"
                preset="background"
                placeholder={placeholder}
                value={value ?? ""}
                onChange={onChange}
                errorMessage={errorMessage}
                disabled={disabled}
            />
            {children}
        </div>
    );
};
export default DynamicNumber;
