import React from "react";
import { useTranslation } from "react-i18next";
import { Config } from "../../config";

interface IProps {
    errorText?: string;
}

export const Custom500: React.FunctionComponent<IProps> = (props) => {
    const { errorText } = props;
    const { t } = useTranslation();
    return (
        <div id="500Page" className="flex h-full items-center justify-center text-center">
            <div className="flex h-64 flex-col items-center justify-between">
                <h1 className="w-3/4 text-4xl xxs:w-full xxs:text-5xl">{t("500Error")}</h1>
                <div className="text-sm xxs:text-base">{errorText ? errorText : t("500ErrorText")}</div>
                <a className="text-sm underline xxs:text-base" href={Config.web_portal_url}>
                    {t("goStartPage")}
                </a>
            </div>
        </div>
    );
};
