import { useState, useEffect, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { postMessageToPrincess } from "utils/postToPrincess";
import { IAbsenceReportEmploymentExtended } from "interfaces/IAbsenceReportEmployment";
import { IAbsenceReportExtended } from "interfaces/IAbsenceReportExtended";
import ModalCommonFooter from "@medhelp/ui/Modal/ModalCommonFooter";
import { DateTimepicker, ModalFrame, SingleDatepicker } from "@medhelp/ui";
import { selectors as absenceReportsSelectors, thunkActions } from "../../redux";
import * as UserSelectors from "store/userSelectors";
import AbsenceReportFieldWrapper from "../AbsenceReportFieldWrapper";
import { getDateStringForwardOrBack, getStartOfDate, getStartOfDateString, isSameOrAfterDate } from "utils/date";
import useDateTimepickerProps from "hooks/useDateTimepickerProps";
import useSingleDatepickerProps from "hooks/useSingleDatepickerProps";

interface IAbsenceReportHealthyModal {
    id: string;
    open: boolean;
    setOpen: (open: boolean) => void;
    setOpenAbsenceNotificationModal: () => void;
    selectedEmployee: IAbsenceReportEmploymentExtended;
    absenceReport: IAbsenceReportExtended;
}

const AbsenceReportHealthyModal = (props: IAbsenceReportHealthyModal) => {
    const { id, open, setOpen, setOpenAbsenceNotificationModal, selectedEmployee, absenceReport } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const hasMedicalAdvisory = useAppSelector(UserSelectors.getHasMedicalAdvisory);
    const showReportHealthyTime = useAppSelector(
        absenceReportsSelectors.getConfigurationByName("showReportHealthyTime"),
    );
    const showAbsenceNotification = useAppSelector(absenceReportsSelectors.showAbsenceNotification);

    const [endDate, setEndDate] = useState<string | undefined>(getStartOfDateString("days"));

    const [backAtWork, setBackAtWork] = useState<string | undefined>(
        getDateStringForwardOrBack(1, "days", "forward", getStartOfDate("days")),
    );

    const [isValidEndDateTime, setIsValidEndDateTime] = useState(false);
    const [isValidBackAtWorkDateTime, setIsValidBackAtWorkDateTime] = useState(false);
    const isValid = useMemo(() => {
        const valid = backAtWork && endDate && isSameOrAfterDate(backAtWork, endDate);
        return showReportHealthyTime ? isValidEndDateTime && isValidBackAtWorkDateTime && valid : valid;
    }, [backAtWork, endDate, isValidBackAtWorkDateTime, isValidEndDateTime, showReportHealthyTime]);

    useEffect(() => {
        if (hasMedicalAdvisory) postMessageToPrincess("init", "recovery", absenceReport?.id, absenceReport?.type!);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleReportHealthy = () => {
        if (backAtWork && endDate && absenceReport?.type) {
            const finishedAbsenceReport: IAbsenceReportExtended = {
                ...absenceReport,
                backAtWork: backAtWork,
                end: endDate,
            };
            dispatch(
                thunkActions.finishAbsenceReport({
                    absenceReport: finishedAbsenceReport,
                    resetAbsenceReport: !showAbsenceNotification,
                    postToPrincess: hasMedicalAdvisory,
                }),
            );
            setOpen(false);
            if (showAbsenceNotification) {
                setOpenAbsenceNotificationModal();
            }
        }
    };

    const disabledDateAfter = useCallback(
        (noOfDays: number): string => getDateStringForwardOrBack(noOfDays, "days", "forward"),
        [],
    );

    const endDateDateTimePickerProps = useDateTimepickerProps({
        onChangeDateTime: (v) => {
            setEndDate(v), setIsValidEndDateTime(true);
        },
        givenSelectedDateTime: endDate,
        placeholder: t("lastAbsenceDay"),
        noTimeSelected: true,
    });
    const endDateDatePickerProps = useSingleDatepickerProps({
        onChangeDate: setEndDate,
        givenSelectedDate: endDate,
        placeholder: t("lastAbsenceDay"),
    });
    const backAtWorkDateTimePickerProps = useDateTimepickerProps({
        onChangeDateTime: (v) => {
            setBackAtWork(v);
            setIsValidBackAtWorkDateTime(true);
        },
        givenSelectedDateTime: backAtWork,
        placeholder: t("firstWorkDay"),
        noTimeSelected: true,
    });
    const backAtWorkDatePickerProps = useSingleDatepickerProps({
        onChangeDate: setBackAtWork,
        givenSelectedDate: backAtWork,
        placeholder: t("firstWorkDay"),
    });

    return (
        <div id="reportHealthy">
            <ModalFrame
                id={`${id}-DatePickerModal`}
                header={<h1>{`${t("reportHealthy")} ${selectedEmployee.firstName} ${selectedEmployee.lastName}`}</h1>}
                setOpen={setOpen}
                open={open}
                content={
                    <div className="flex flex-col">
                        <AbsenceReportFieldWrapper heading={`1. ${t("stateLastAbsenceDay")}`} isMandatory>
                            <div className="mt-2" />
                            {showReportHealthyTime ? (
                                <DateTimepicker
                                    {...endDateDateTimePickerProps}
                                    disableDatesBefore={absenceReport.start}
                                    disableDatesAfter={disabledDateAfter(3)}
                                    timepickerHeading={`2. ${t("timeEndDate")}`}
                                    timepickerIsMandatory
                                    heading={`1. ${t("stateLastAbsenceDay")}`}
                                    isMandatory
                                />
                            ) : (
                                <SingleDatepicker
                                    {...endDateDatePickerProps}
                                    disableDatesBefore={absenceReport.start}
                                    disableDatesAfter={disabledDateAfter(3)}
                                    mobileHeading={`1. ${t("stateLastAbsenceDay")}`}
                                    isMandatory
                                />
                            )}
                        </AbsenceReportFieldWrapper>
                        <AbsenceReportFieldWrapper
                            heading={`${showReportHealthyTime ? 3 : 2}. ${t("stateFirstWorkDay")}`}
                            isMandatory
                        >
                            <div className="mt-2" />
                            {showReportHealthyTime ? (
                                <DateTimepicker
                                    {...backAtWorkDateTimePickerProps}
                                    disableDatesBefore={endDate}
                                    disableDatesAfter={disabledDateAfter(4)}
                                    timepickerHeading={`4. ${t("timeBackAtWorkDate")}`}
                                    timepickerIsMandatory
                                    heading={`${showReportHealthyTime ? 3 : 2}. ${t("stateFirstWorkDay")}`}
                                    isMandatory
                                />
                            ) : (
                                <SingleDatepicker
                                    {...backAtWorkDatePickerProps}
                                    disableDatesBefore={endDate}
                                    disableDatesAfter={disabledDateAfter(4)}
                                    mobileHeading={`${showReportHealthyTime ? 3 : 2}. ${t("stateFirstWorkDay")}`}
                                    isMandatory
                                />
                            )}
                        </AbsenceReportFieldWrapper>
                    </div>
                }
                footer={
                    <ModalCommonFooter
                        tertiaryOnClick={() => {
                            if (hasMedicalAdvisory) {
                                postMessageToPrincess("cancel", "recovery", absenceReport?.id, absenceReport?.type!);
                            }
                            setOpen(false);
                        }}
                        tertiaryText={t("abort")}
                        primaryOnClick={handleReportHealthy}
                        primaryText={t("reportHealthy")}
                        primaryDisabled={!isValid}
                    />
                }
            />
        </div>
    );
};
export default AbsenceReportHealthyModal;
