import PrimaryButton from "components/PrimaryButton";
import BackButtonHeader from "../BackButtonHeader";
import { IUsernameAndPropsCompProps } from "pages/login/types";
import UsernameOrPwdInput from "../UsernameOrPwdInput";

const UsernameAndPass = ({
    usernameInput,
    passwordInput,
    setUsernameAndPassPropValue,
    selectedMarket,
    translations,
    handleSubmit,
    submitDisabled,
    isLoading,
    failedAt,
    navigate,
}: IUsernameAndPropsCompProps) => {
    return (
        <div className="mb-14 flex flex-col">
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                }}
            >
                <BackButtonHeader
                    heading={translations.usernameAndPass}
                    onClick={() => {
                        navigate(`/login/${selectedMarket === "sv" ? "moreOptions" : "dk"}`);
                    }}
                />
                <UsernameOrPwdInput
                    placeholder={translations.ssnOrEmail}
                    heading={translations.ssnOrEmail}
                    inputValue={usernameInput}
                    inputChanged={(v) => setUsernameAndPassPropValue(v, "usernameInput")}
                    containerClassName="mb-8 xxs:w-full"
                    className="h-12"
                    submitOnEnter={handleSubmit}
                    type="email"
                    name="userAndPass-username"
                    autoComplete="username"
                />
                <UsernameOrPwdInput
                    placeholder={translations.password}
                    heading={translations.password}
                    inputValue={passwordInput}
                    inputChanged={(v) => setUsernameAndPassPropValue(v, "passwordInput")}
                    containerClassName="mb-8 xxs:w-full"
                    className="h-12"
                    type="password"
                    name="userAndPass-password"
                    submitOnEnter={handleSubmit}
                    autoComplete="current-password"
                />
                {failedAt === "loginUsernameAndPass" && <p className="text-mhred-alert2">{translations.loginFailed}</p>}
                <button
                    type="button"
                    className="mb-8 w-max border-b border-linkBorderBottom hover:border-linkBorderBottomHover"
                    onClick={() => navigate(`/login/resetPass`)}
                >
                    {translations.forgotPass}
                </button>
                <PrimaryButton
                    id="userAndPassButton"
                    type="submit"
                    text={translations.login}
                    className="flex h-12 justify-center"
                    onClick={handleSubmit}
                    disabled={submitDisabled}
                    isLoading={isLoading}
                />
            </form>
        </div>
    );
};

export default UsernameAndPass;
