import ArrowIcon from "../ArrowIconComponent/ArrowIconComponent";
import { DepartmentListProps, IActivityButtonValues } from "./DepartmentList";
import MiniMenu from "components/MiniMenu/MiniMenu";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { ToggleSwitch } from "components/ToggleSwitch/ToggleSwitch";
import { isMiniMenuDisabled } from "pages/Administration/utils";
import { setCreatedByName } from "pages/Administration/utils";

const DepartmentListDesktopView = ({
    id,
    reason,
    createdBy,
    day,
    title,
    isActive,
    showDescription,
    toggleShow,
    handleOnOff,
    miniMenuItems,
    description,
    switchOnOff,
    type,
    partner,
    companyName,
}: DepartmentListProps & {
    switchOnOff: boolean;
    showDescription: boolean;
    toggleShow: () => void;
    handleOnOff: () => void;
    miniMenuItems: () => IActivityButtonValues[];
}) => {
    const { t } = useTranslation("administration");
    return (
        <div key={id}>
            <div className="flex w-full px-1.5">
                <div className="flex w-1/2">
                    <button
                        className={twMerge(
                            "focus:ring-0 focus:ring-offset-0",
                            !isActive && "cursor-not-allowed text-gray-500",
                        )}
                        type="button"
                        onClick={toggleShow}
                        disabled={!isActive}
                    >
                        <ArrowIcon show={showDescription} />
                    </button>
                    <div className="flex w-full pl-3">
                        <div className="w-1/2 flex-col pt-2">
                            <div className="flex">
                                <p className="pr-2 text-sm">
                                    {createdBy === "null" || createdBy === "standard" ? (
                                        <>{t("rehabChain")}</>
                                    ) : (
                                        <>
                                            {t("suggestedBy")} {setCreatedByName(createdBy, companyName, partner)}
                                        </>
                                    )}
                                </p>
                            </div>
                            <p className="text-lg">{title}</p>
                        </div>
                        <div className="flex w-1/2 items-start justify-end">
                            <p className="flex p-6 text-end text-base capitalize">{`${t("day")}${" "}${day}`}</p>
                        </div>
                    </div>
                </div>
                <div className="flex w-1/2 justify-between">
                    <div className="w-1/2 flex-col items-center p-6">
                        {reason.length > 0
                            ? Object.values(reason).map((data) => (
                                  <p key={data.id} className="block pl-6 text-sm">
                                      {data.title}
                                  </p>
                              ))
                            : t("longtermAbsence")}
                    </div>
                    <div className="flex w-1/2 items-start justify-end py-6">
                        <div className="mx-auto pl-9">
                            <ToggleSwitch
                                onChange={handleOnOff}
                                value={switchOnOff}
                                name=""
                                disabled={type === "chain"}
                            />
                        </div>
                        <div className="">
                            <MiniMenu
                                buttonValues={miniMenuItems()}
                                buttonRight
                                className="!mt-2 rounded-sm"
                                id={`miniMenu`}
                                preset="white"
                                disabled={isMiniMenuDisabled(type, createdBy)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="px-6 pb-6">
                {showDescription && description && (
                    <div className="flex w-full flex-col rounded-lg bg-grey-50 p-6">
                        <h5 className="pb-2 text-sm font-bold">{t("description")}</h5>
                        <p className="text-base font-normal">{description}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DepartmentListDesktopView;
