import { useAppSelector } from "store/hooks";
import { ToastContainer, toast } from "react-toastify";
import { getUserContext } from "store/userSelectors";
import Icon from "components/Icon";
import { twMerge } from "tailwind-merge";
import { useEffect } from "react";

interface ToastProps {
    message?: string;
    className?: string;
}

const Toast = ({ message, className }: ToastProps) => {
    const userContext = useAppSelector(getUserContext);
    useEffect(() => {
        if (message) {
            toast(message, {
                position: "top-center",
                hideProgressBar: true,
                autoClose: 10000,
                closeButton: ({ closeToast }) => (
                    <button
                        onClick={closeToast}
                        className="focus:outline-none"
                        style={{
                            margin: "auto",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Icon icon="close" />
                    </button>
                ),
                className: twMerge(
                    "flex px-2 mb-2 hover:cursor-pointer bg-grey-300 text-mhdarkgreen-original",
                    userContext.HasCustomerSupport ? "h-50px" : "h-0px",
                    className,
                ),

                icon: <Icon icon="checkCircle" className={className} />,
            });
        }
    }, [message, className, userContext.HasCustomerSupport]);

    return <ToastContainer />;
};

export default Toast;
