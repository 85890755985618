import Icon, { Icons } from "components/Icon";
import { useState } from "react";

export interface ErrorRowProps {
    text: string;
    line?: string;
}

const ErrorRow = ({ text, line }: ErrorRowProps) => {
    const [copyIcon, setCopyIcon] = useState<Icons>("copy");

    const handleClick = (value: string) => {
        navigator.clipboard.writeText(value).then(() => {
            setCopyIcon("checkMark");
            setTimeout(() => {
                setCopyIcon("copy");
            }, 1500);
        });
    };

    return (
        <div
            onClick={() => handleClick(text)}
            className="flex items-center justify-between px-2 py-1 hover:cursor-copy hover:bg-[#F4F6F3] md:px-4"
        >
            <div className="flex items-center gap-2">
                <Icon icon="arrowSwoosh" size={12} />
                <div className="break-all">{text}</div>
            </div>
            <div className="flex items-center gap-2">
                {line && <div>{line}</div>}
                <div>{<Icon icon={copyIcon} />}</div>
            </div>
        </div>
    );
};

export default ErrorRow;
