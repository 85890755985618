import React, { ReactNode, useEffect, useState } from "react";
import { useAppDispatch } from "store/hooks";
import { useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";
import { setOverflow } from "utils/setOverflow";
import * as UserSelectors from "store/userSelectors";
import WelcomeMessage from "components/WelcomeMessage";
import Page from "components/Page";
import { getWelcomeMessage } from "store/userActions";
import "react-toastify/dist/ReactToastify.css";
import Toast from "components/Toast";
import { fetchUnreadCount } from "pages/Inbox/redux/inboxAsyncActions";
import MenuNavBar from "components/MenuNavBar";
import Main from "components/Main";
import SideMenu from "components/SideMenu";
import MobileMenu from "components/MobileMenu";

type MainWrapperProps = {
    children: ReactNode;
};

const MainWrapper = ({ children }: MainWrapperProps) => {
    const dispatch = useAppDispatch();
    const [sideMenuOpen, setSideMenuOpen] = useState(false);
    const welcomeMessage = useAppSelector(UserSelectors.getWelcomeMessage);
    const { t } = useTranslation();
    const showWelcomeText = sessionStorage.getItem("hideWelcomeText") !== "true";

    useEffect(() => {
        dispatch(getWelcomeMessage());
        dispatch(fetchUnreadCount());
    }, [dispatch]);

    useEffect(() => {
        setOverflow(sideMenuOpen ? "hidden" : "auto");
    }, [sideMenuOpen]);

    return (
        <Page>
            <div
                className={`absolute h-full w-full bg-mhgrey-dark bg-opacity-50 ${
                    sideMenuOpen ? "z-10 flex" : "hidden"
                }`}
            />
            <div className="flex flex-col xl:flex-row">
                <div className="relative flex min-h-screen w-full flex-1 flex-col xl:ml-65">
                    <header className="flex flex-row bg-menuNavBg px-4 xl:px-0">
                        <MobileMenu setSideMenuOpen={setSideMenuOpen} sideMenuOpen={sideMenuOpen} />
                        <MenuNavBar />
                    </header>
                    <Toast />
                    {showWelcomeText && welcomeMessage?.value && (
                        <WelcomeMessage id="welcomeMessage" message={welcomeMessage.value} buttonText={t("okClose")} />
                    )}
                    <Main>{children}</Main>
                </div>
                <SideMenu setSideMenuOpen={setSideMenuOpen} sideMenuOpen={sideMenuOpen} />
            </div>
        </Page>
    );
};
export default MainWrapper;
