import { getDateStringForwardOrBack } from "utils/date";
import { useBreakpoints, useElementClicked } from "hooks";
import DisplayValue from "./DisplayValue";
import { ISingleDatepickerProps } from "../types";
import { twMerge } from "tailwind-merge";
import DatePickerModal from "components/DatePicker/DatePickerModal";
import SinglePickerComponent from "./SingleDatepickerComponent";

const SingleDatepicker = ({
    open,
    setOpen,
    selectedDate,
    setSelectedDate,
    month,
    setMonth,
    selectedLang,
    disableDatesBefore = getDateStringForwardOrBack(20, "years", "backwards"),
    disableDatesAfter = getDateStringForwardOrBack(1, "years", "forward"),
    placeholder,
    className = "",
    disabled,
    mobileHeading,
    isMandatory,
}: ISingleDatepickerProps) => {
    const ref = useElementClicked((open) => !isSmallscreen && setOpen(!disabled && open));
    const { isSmallscreen } = useBreakpoints();

    return (
        <>
            <div
                ref={ref}
                id="single-datepicker-container"
                className={twMerge("relative flex w-full flex-col xs:w-[308px]", className)}
            >
                <DisplayValue
                    open={open}
                    setOpen={setOpen}
                    isSmallscreen={isSmallscreen}
                    selectedLocale={selectedLang}
                    disabled={disabled}
                    selectedDate={selectedDate}
                    placeholder={placeholder}
                    clearDate={() => !disabled && setSelectedDate(undefined)}
                    className={className}
                    dataTestId="single-datepicker"
                />
                <div className={`z-10 max-w-[308px] bg-white shadow-lg ${open ? "flex" : "hidden"}`}>
                    <SinglePickerComponent
                        setSelectedDate={setSelectedDate}
                        selectedDate={selectedDate}
                        disableDatesBefore={disableDatesBefore}
                        disableDatesAfter={disableDatesAfter}
                        selectedLang={selectedLang}
                        month={month}
                        setMonth={setMonth}
                        className="m-0 inline-block w-full max-w-[308px] justify-center bg-[#FFFAF5] p-4 text-sm shadow-md max-sm:hidden"
                    />
                </div>
            </div>

            {open && (
                <DatePickerModal open={isSmallscreen && open} setOpen={setOpen}>
                    <div>
                        <div className="max-w-full px-4">
                            <div key={mobileHeading} className={`w-full pt-5`}>
                                <div className="flex pb-2">
                                    <p className={`font-bold`}>{mobileHeading}</p>
                                    {isMandatory && <div className="ml-1 text-2xl leading-6 text-mhred-alert2">*</div>}
                                </div>
                            </div>
                            <DisplayValue
                                open={open}
                                selectedLocale={selectedLang}
                                disabled={disabled}
                                selectedDate={selectedDate}
                                placeholder={placeholder}
                                clearDate={() => !disabled && setSelectedDate(undefined)}
                                className={className}
                                dataTestId="single-datepicker"
                            />
                        </div>
                        <SinglePickerComponent
                            setSelectedDate={setSelectedDate}
                            selectedDate={selectedDate}
                            disableDatesBefore={disableDatesBefore}
                            disableDatesAfter={disableDatesAfter}
                            selectedLang={selectedLang}
                            month={month}
                            setMonth={setMonth}
                            className="z-30 m-0 inline-block h-[100dvh] w-full justify-center bg-[#FFFAF5] pt-6 text-sm shadow-md md:relative md:hidden md:h-full md:w-auto md:max-w-[620px]"
                        />
                    </div>
                </DatePickerModal>
            )}
        </>
    );
};

export default SingleDatepicker;
