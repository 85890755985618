import Icon from "components/Icon";

interface InfoBoxProps {
    translatedTitle?: string;
    translatedText: string;
}

const InfoBox = ({ translatedTitle, translatedText }: InfoBoxProps) => {
    return (
        <div className="mx-0 flex gap-3 rounded-lg bg-grey-100 p-8 px-4">
            <div className="w-4">
                <Icon icon="lightBulb" size={20} />
            </div>
            <li className="flex flex-col">
                <h4 className="ml-4 w-full font-sans text-base font-semibold">{translatedTitle}</h4>

                <p className="ml-4 w-full lg:w-3/5">{translatedText}</p>
            </li>
        </div>
    );
};

export default InfoBox;
