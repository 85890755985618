import { ISearchFilter, ISearchFilterTags } from "../types";
import * as SearchApi from "../utils";
import { getInitialPeriods } from "../utils";
import { IISearchState, Status } from ".";

export const initialSearchFilterState: ISearchFilter = {
    filters: [],
    showBasedOn: null,
    showDepartments: "onlyLatestDepartment",
    sorting: "startDate",
};

export const filtersInitial: ISearchFilterTags = [
    {
        label: "sick",
        referenceKey: "sick",
        checked: true,
        id: "absenceType",
    },
    {
        label: "careOfChild",
        referenceKey: "careOfChild",
        checked: true,
        id: "absenceType",
    },
    {
        label: "flexJob",
        referenceKey: "flexJob",
        checked: false,
        id: "reimbursableType",
    },
    {
        label: "§56",
        referenceKey: "§56",
        checked: false,
        id: "reimbursableType",
    },
    {
        label: "pregnancyRelated",
        referenceKey: "pregnancyRelated",
        checked: false,
        id: "reimbursableType",
    },
    {
        label: "sicknessBenefits",
        referenceKey: "sicknessBenefits",
        checked: false,
        id: "reimbursableType",
    },
    {
        label: "all",
        checked: true,
        id: "reimbursableType",
        referenceKey: "all",
    },
    {
        label: "Started",
        referenceKey: "Started",
        checked: true,
        id: "monthStatus",
    },
    {
        label: "Created",
        referenceKey: "Created",
        checked: false,
        id: "monthStatus",
    },
    {
        label: "Ended",
        referenceKey: "Ended",
        checked: false,
        id: "monthStatus",
    },
    {
        label: "Affected",
        referenceKey: "Affected",
        checked: false,
        id: "monthStatus",
    },
    {
        label: "impactSelectedPeriod",
        referenceKey: "Affected",
        checked: false,
        id: "absenceImpact",
    },
    {
        label: "started",
        referenceKey: "Started",
        checked: true,
        id: "absenceImpact",
    },
    {
        label: "created",
        referenceKey: "Created",
        checked: false,
        id: "absenceImpact",
    },
    {
        label: "ended",
        referenceKey: "Ended",
        checked: false,
        id: "absenceImpact",
    },
    {
        label: "startedAbsence",
        referenceKey: "startedAbsence",
        checked: false,
        id: "absenceStatus",
    },
    {
        label: "endedAbsence",
        referenceKey: "endedAbsence",
        checked: false,
        id: "absenceStatus",
    },
    {
        label: "workRelatedAbsence",
        referenceKey: "workRelated",
        checked: false,
        id: "workRelated",
    },
    {
        label: "workAccidentAbsence",
        referenceKey: "workAccident",
        checked: false,
        id: "workRelated",
    },
    {
        label: "longtermAbsence",
        referenceKey: "longterm",
        checked: false,
        id: "causes",
    },
    {
        label: "recurringAbsence",
        referenceKey: "recurringperiod",
        checked: false,
        id: "causes",
    },
    {
        label: "earlySignals",
        referenceKey: "earlysignals",
        checked: false,
        id: "causes",
    },
    {
        label: "ownInitiativ",
        referenceKey: "manually",
        checked: false,
        id: "causes",
    },
    {
        label: "preliminary",
        referenceKey: "preliminary",
        checked: false,
        id: "caseStatus",
    },
    {
        label: "ongoing",
        referenceKey: "ongoing",
        checked: false,
        id: "caseStatus",
    },
    {
        label: "paused",
        referenceKey: "paused",
        checked: false,
        id: "caseStatus",
    },
    {
        label: "declined",
        referenceKey: "declined",
        checked: false,
        id: "caseStatus",
    },
    {
        label: "ended",
        referenceKey: "ended",
        checked: false,
        id: "caseStatus",
    },
    {
        label: "healthPromotionCase",
        referenceKey: "healthpromotion",
        checked: true,
        id: "caseType",
    },
    {
        label: "rehabCase",
        referenceKey: "rehab",
        checked: true,
        id: "caseType",
    },
    {
        label: "activityCompleted",
        referenceKey: "completed",
        checked: false,
        id: "activityStatus",
    },
    {
        label: "upcomingActivities",
        referenceKey: "upcoming",
        checked: false,
        id: "activityStatus",
    },
    {
        label: "activityNotCompleted",
        referenceKey: "notcompleted",
        checked: false,
        id: "activityStatus",
    },
    ...getInitialPeriods(),
];
const defaultSearchFilters: ISearchFilter = {
    ...initialSearchFilterState,
    filters: filtersInitial,
};
const defaultPageNumbers = {
    currentSearchPageNumber: 1,
    paginatorPageNumber: 1,
};
export const initialSearchState: IISearchState = {
    userGuard: null,
    search: {
        absencePeriod: {},
        ongoingAbsence: {},
        longtermAbsence: {},
        recurringAbsence: {},
        monthlyReport: {},
        medicalCertificate: {},
        reimbursable: {},
        cases: {},
        activities: {},
    },
    searchHasMore: {
        absencePeriod: {},
        ongoingAbsence: {},
        longtermAbsence: {},
        recurringAbsence: {},
        monthlyReport: {},
        medicalCertificate: {},
        reimbursable: {},
        cases: {},
        activities: {},
    },
    searchStatus: {
        absencePeriod: "no-data",
        longtermAbsence: "no-data",
        medicalCertificate: "no-data",
        monthlyReport: "no-data",
        ongoingAbsence: "no-data",
        recurringAbsence: "no-data",
        reimbursable: "no-data",
        cases: "no-data",
        activities: "no-data",
    },
    pageNumber: {
        absencePeriod: defaultPageNumbers,
        ongoingAbsence: defaultPageNumbers,
        longtermAbsence: defaultPageNumbers,
        recurringAbsence: defaultPageNumbers,
        monthlyReport: defaultPageNumbers,
        medicalCertificate: defaultPageNumbers,
        reimbursable: defaultPageNumbers,
        cases: defaultPageNumbers,
        activities: defaultPageNumbers,
    },
    currentSearch: SearchApi.ReportSearch[1],
    searchFilter: {
        absencePeriod: defaultSearchFilters,
        ongoingAbsence: defaultSearchFilters,
        longtermAbsence: defaultSearchFilters,
        recurringAbsence: defaultSearchFilters,
        monthlyReport: defaultSearchFilters,
        medicalCertificate: defaultSearchFilters,
        reimbursable: defaultSearchFilters,
        cases: defaultSearchFilters,
        activities: defaultSearchFilters,
    },
    searchCompaniesDepartments: {
        companies: null,
        departments: [],
        currentCompanySearchId: null,
        storedDepartments: [],
    },
    rehabRoutineDocuments: {
        data: [],
        status: Status.PENDING,
    },
    costCalculation: {
        data: [],
        status: Status.PENDING,
    },
    companyGroup: {
        data: [],
        status: Status.PENDING,
    },
    includeArchivedEmployees: false,
};

export const initialEmployeeCardState = {
    medicalCertificate: {
        firstDayMedicalCertificate: null,
    },
    absenceOverview: [],
    absenceList: [],
    deviant: [],
    companiesWithRegion: [],
    organizationTree: [],
    employmentGroups: [],
    subscription: {
        userSubscription: null,
        userSubscriber: null,
        missingSubscriptionAccessRightsForUser: false,
    },
    userEmployment: null,
    rehab: {
        activeChain: null,
        daysInRehabChain: null,
        allowDeleteDate: null,
        employmentId: null,
        endDate: null,
        initiationDate: null,
    },
    absenceActivityLogs: {},
    currentUser: "",
    healthCases: [],
    caseResponsibleList: [],
    partnerContactInformation: null,
    configurationFields: [],
    status: {
        absenceOverview: Status.OK,
        absenceList: Status.OK,
        activityLogs: {},
        deviant: Status.OK,
        userEmployment: Status.PENDING,
        userSubscription: Status.PENDING,
        medicalCertificate: Status.OK,
        rehab: Status.PENDING,
        companiesWithRegion: Status.PENDING,
        organizationTree: Status.PENDING,
        employmentGroups: Status.PENDING,
        noData: false,
        healthCases: Status.PENDING,
        caseResponsibleList: Status.PENDING,
        partnerContactInformation: Status.PENDING,
        configurationFields: Status.PENDING,
        partnerContactRequest: Status.OK,
    },
};
