import IconModal from "../IconModal";

interface IInputAdminProps {
    id: string;
    preset: IInputAdminPresets;
    onChange: (value: string) => void;
    value: string;
    className?: string;
    placeholder?: string;
    errorMessage?: string;
    errorClassName?: string;
    heading?: string;
    type?: string;
    infoModalHeading?: string;
    infoModalText?: string;
    isMandatory?: boolean;
    disabled?: boolean;
    children?: React.ReactNode;
}

const inputAdminPresets = {
    background: "bg-pageBg",
};

export type IInputAdminPresets = keyof typeof inputAdminPresets;

const InputAdmin = (props: IInputAdminProps) => {
    const {
        id,
        preset,
        className,
        placeholder,
        onChange,
        value,
        errorMessage,
        errorClassName,
        heading,
        type,
        infoModalHeading,
        infoModalText,
        isMandatory,
        disabled,
        children,
        ...rest
    } = props;

    return (
        <div id={id} className={`${inputAdminPresets[preset]} ${className}`}>
            <div className="flex items-center">
                <p>{heading}</p>
                {infoModalHeading && (
                    <IconModal
                        id={id}
                        heading={infoModalHeading}
                        topContent={infoModalText!}
                        isMandatory={isMandatory}
                    />
                )}
            </div>
            <input
                className={`mt-2 h-12 w-full border border-mhgrey-dark p-2 md:w-[624px] ${inputAdminPresets[preset]}`}
                placeholder={placeholder}
                value={value}
                type={type || "text"}
                onChange={(e) => onChange(e.target.value)}
                disabled={disabled}
                {...rest}
            />
            <p className={`text-base text-mhred-alert1 ${errorClassName}`}>{errorMessage}</p>
            {children}
        </div>
    );
};
export default InputAdmin;
