import { useNavigate } from "react-router-dom";
import MenuNavDropdownMenu from "./MenuNavDropdownMenu";
import { useTranslation } from "react-i18next";

export const SettingsDropdownMenu = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const items = [
        {
            value: "fileImport",
            label: t("fileImport"),
        },
    ];

    if (localStorage.getItem("messageTemplates") === "true") {
        items.push({
            value: "messageTemplates",
            label: t("messageTemplates"),
        });
    }

    const handleClick = (value: string) => {
        switch (value) {
            case "fileImport":
                navigate("fileImport");
                break;
            case "messageTemplates":
                navigate("messageTemplates");
                break;

            default:
                break;
        }
    };
    return <MenuNavDropdownMenu label={t("settings")} icon="cog" handleClick={handleClick} items={items} />;
};
