import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SecondaryButton from "components/SecondaryButton";
import { SpinnerWithOverlay } from "components/Spinner";
import PrimaryButton from "components/PrimaryButton";
import { ISelectValue } from "components/Select/Select";
import { ReactComponent as EditIcon } from "icons/edit.svg";
import { ReactComponent as CheckMarkIcon } from "icons/checkMarkBlack.svg";
import { MedHelpAbsenceReportingWebApiModelsAbsenceReportDegree } from "swagger/absencereportapi";
import { IAbsenceReportExtended } from "interfaces/IAbsenceReportExtended";
import AbsenceReportCardDetail from "../AbsenceReportCardDetail";
import * as UserSelectors from "store/userSelectors";
import { selectors as AbsenceReportsSelectors } from "../../redux";
import { useAppSelector } from "store/hooks";
import { getDateDisplayValue, isAfterDate, getUnix } from "utils/date";
import Icon, { Icons } from "components/Icon";

interface IAbsenceReportCard {
    header: string;
    absenceReport: IAbsenceReportExtended;
    onDeleteButtonClick?: (absenceReport: IAbsenceReportExtended) => void;
    onEditButtonClick: (absenceReport: IAbsenceReportExtended) => void;
    onReportHealthyButtonClick?: (absenceReport: IAbsenceReportExtended) => void;
    reportHealthyButtonText?: string;
    reportHealthyButtonId?: string;
    reportHealthyButtonDisabled?: boolean;
    complete?: unknown;
    infoTextType?: "nurseComplete" | "incomplete" | "estimatedBackPassed" | "reportedHealthy";
    isEditable: boolean;
}

const AbsenceReportCard = ({
    children,
    header,
    absenceReport,
    onDeleteButtonClick,
    onEditButtonClick,
    reportHealthyButtonText,
    reportHealthyButtonId,
    onReportHealthyButtonClick,
    reportHealthyButtonDisabled,
    complete,
    infoTextType,
    isEditable,
}: IAbsenceReportCard & { children?: React.ReactNode }) => {
    const { t } = useTranslation();
    const isIncomplete = (infoTextType === "incomplete" || infoTextType === "nurseComplete") && !complete;
    const [nextDegree, setNextDegree] = useState<MedHelpAbsenceReportingWebApiModelsAbsenceReportDegree | null>(null);
    const [showAbsenceReportDetails, setShowAbsenceReportDetails] = useState<boolean>(false);
    const isManage = useAppSelector(UserSelectors.getIsAbsenceReportAdmin);

    const deletable = useAppSelector(AbsenceReportsSelectors.getAbsenceReportDeletable(absenceReport, isManage));

    const arCardDetails: ISelectValue[] = [
        {
            description: t("startDate"),
            value: absenceReport?.start ? getDateDisplayValue(absenceReport?.start) : t("dateMissing"),
        },
        {
            description: t("endDate"),
            value: absenceReport?.end ? getDateDisplayValue(absenceReport?.end) : t("ongoingAbsence"),
        },
        {
            description: t("estimatedBack"),
            value: absenceReport?.backAtWork ? getDateDisplayValue(absenceReport?.backAtWork) : t("dateMissing"),
        },
        {
            description: t("absenceDegree"),
            value: `${absenceReport?.degree}%`,
        },
    ];

    if (nextDegree) {
        arCardDetails.push({
            description: t("comingAbsenceDegree"),
            value: `${nextDegree?.degree}% ${t("starting")} ${getDateDisplayValue(nextDegree?.timeStamp)}`,
        });
    }

    useEffect(() => {
        if (absenceReport?.degrees && absenceReport?.degrees?.length > 1) {
            const upcomingDegrees = absenceReport.degrees
                .filter((degree: MedHelpAbsenceReportingWebApiModelsAbsenceReportDegree) =>
                    isAfterDate(degree.timeStamp),
                )
                .sort((a, b) => getUnix(a.timeStamp) - getUnix(b.timeStamp));
            setNextDegree(upcomingDegrees?.length > 0 ? upcomingDegrees[0] : null);
        }
    }, [absenceReport]);

    const hasMessage = absenceReport.fields?.some((field) => field.name === "Message" && field.value !== "");

    const renderInfoText = () => {
        let icon: Icons = "checkCircle";
        let backgroundColor = "bg-mhgrey-light";
        let infoText = "";

        switch (infoTextType) {
            case "nurseComplete":
                infoText = t("nurseCompleteReport");
                break;
            case "incomplete":
                infoText = t("completeReportText");
                break;
            case "reportedHealthy":
                infoText = t("reportedHealthy");
                break;
            case "estimatedBackPassed":
                infoText = t("estimatedBackHasPassed");
                backgroundColor = "bg-mhred-alert1 bg-opacity-25";
                icon = "warning";
        }

        return (
            <div className={`flex items-center px-6 py-4 ${backgroundColor}`}>
                <div>
                    <Icon icon={icon} size={20} />
                </div>
                <p className="ml-3 font-bold">{infoText}</p>
            </div>
        );
    };

    return (
        <div className="my-6 w-full bg-primaryBg shadow-lg xs:my-8">
            {absenceReport.isLoading && <SpinnerWithOverlay />}
            {infoTextType && renderInfoText()}
            <div>
                <div className="flex flex-col">
                    <div className="flex px-6 pt-6 align-middle xxs:p-8 xxs:pb-0">
                        <h3 className={`flex w-full self-center ${isIncomplete ? "opacity-50" : ""}`}>{header}</h3>
                        <button
                            className="border p-4"
                            type="button"
                            onClick={() => setShowAbsenceReportDetails(!showAbsenceReportDetails)}
                        >
                            <Icon icon={showAbsenceReportDetails ? "caretUp" : "caretDown"} />
                        </button>
                    </div>
                    <div className="flex w-full flex-col flex-wrap gap-2 border-b border-gray-300 p-6 xxs:p-8 md:flex-row md:gap-12">
                        {arCardDetails.map((c) => (
                            <div key={c.description} className={`flex flex-col ${isIncomplete ? "opacity-50" : ""}`}>
                                <p
                                    className={`text-sm ${isIncomplete ? "opacity-100" : "opacity-75"}`}
                                >{`${c?.description}: `}</p>
                                <p className="font-bold text-grey-700">{c?.value}</p>
                            </div>
                        ))}
                        {hasMessage && (
                            <div className={`flex flex-col sm:pb-0 xl:mr-16 ${isIncomplete ? "opacity-50" : ""}`}>
                                <p className={`text-sm ${isIncomplete ? "opacity-100" : "opacity-75"}`}>
                                    {t("message")}:
                                </p>
                                <div
                                    onClick={() => setShowAbsenceReportDetails(!showAbsenceReportDetails)}
                                    className="font-bold underline hover:cursor-pointer"
                                >
                                    {t("open")}
                                </div>
                            </div>
                        )}
                    </div>
                    {showAbsenceReportDetails && <AbsenceReportCardDetail absenceReport={absenceReport} />}
                    <div className="mx-6 my-4 flex flex-wrap xxs:mx-8">
                        {onDeleteButtonClick && deletable && (
                            <SecondaryButton
                                id="deleteArButton"
                                text={t("delete")}
                                onClick={() => onDeleteButtonClick(absenceReport)}
                                className="w-full justify-center sm:mr-3 sm:w-auto"
                                leftIcon={<Icon icon="trashCan" />}
                            />
                        )}
                        <SecondaryButton
                            id="editArButton"
                            disabled={absenceReport.end || !isEditable ? true : false}
                            text={isIncomplete ? t("finishAndSendIn") : t("editExtend")}
                            onClick={() => onEditButtonClick(absenceReport)}
                            className="my-3 w-full justify-center sm:my-0 sm:mr-3 sm:w-auto"
                            leftIcon={<EditIcon className="w-4.5 text-secondaryButtonText" />}
                        />
                        {reportHealthyButtonDisabled ||
                            (reportHealthyButtonText && reportHealthyButtonId && !isIncomplete && (
                                <PrimaryButton
                                    className="flex w-full justify-center sm:w-auto"
                                    text={reportHealthyButtonText}
                                    id={reportHealthyButtonId}
                                    disabled={reportHealthyButtonDisabled}
                                    onClick={() => onReportHealthyButtonClick!(absenceReport)}
                                    leftIcon={<CheckMarkIcon stroke="currentcolor" />}
                                />
                            ))}
                    </div>
                </div>
            </div>
            {children}
        </div>
    );
};
export default AbsenceReportCard;
