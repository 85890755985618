import React from "react";
import { twMerge } from "tailwind-merge";
import { useAppSelector } from "store/hooks";
import * as Tooltip from "@radix-ui/react-tooltip";
import { getUserRetailer } from "store/userSelectors";

interface TooltipProps {
    children: React.ReactNode;
    content: React.ReactNode;
}

export default ({ children, content }: TooltipProps) => {
    const retailer = useAppSelector(getUserRetailer);
    return (
        <Tooltip.Provider>
            <Tooltip.Root>
                <Tooltip.Trigger className="cursor-default" asChild>
                    {children}
                </Tooltip.Trigger>
                {content && (
                    <Tooltip.Portal>
                        <Tooltip.Content
                            className={twMerge("rounded bg-secondaryBg p-3 text-secondaryText shadow-md", retailer)}
                            side="bottom"
                        >
                            {content}
                            <Tooltip.Arrow className="text-secondaryBg" />
                        </Tooltip.Content>
                    </Tooltip.Portal>
                )}
            </Tooltip.Root>
        </Tooltip.Provider>
    );
};
