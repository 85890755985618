import PrimaryButton from "components/PrimaryButton";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { actions, selectors, thunkActions } from "../../redux";
import SearchDropdowns from "../SearchDropdowns";

import { Checkbox } from "components/Checkbox";
import Tooltip from "components/ToolTip";
import { twMerge } from "tailwind-merge";
import { getIsAbsenceArchive } from "store/userSelectors";

const SearchQuery = () => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation("followup");
    const currentSearch = useAppSelector(selectors.getCurrentSearch);
    const hasDataToSearchFor = useAppSelector(selectors.hasDataToSearchFor);
    const [disabled, setDisabled] = useState(false);

    const [checked, setChecked] = useState(false);
    const HasIsAbsenceArchive = useAppSelector(getIsAbsenceArchive);
    const showArchive = localStorage.getItem("showArchive");

    const onSearch = useCallback(() => {
        if (currentSearch) {
            setIsLoading(true);
            dispatch(
                thunkActions.search({
                    requestType: "search",
                    type: currentSearch,
                    workBookTitle: t(`excelTitle.${currentSearch}`),
                }),
            ).then(() => {
                setIsLoading(false);
            });
        }
    }, [currentSearch, dispatch, t]);

    const onChange = () => {
        setChecked(!checked);
        dispatch(actions.updateIncludeArchivedEmployees(!checked));
    };
    useEffect(() => {
        if (showArchive === "true" || HasIsAbsenceArchive) {
            if (
                currentSearch === "ongoingAbsence" ||
                currentSearch === "longtermAbsence" ||
                currentSearch === "recurringAbsence" ||
                currentSearch === "medicalCertificate" ||
                currentSearch === "reimbursable" ||
                currentSearch === "activities"
            ) {
                setDisabled(true);
                setChecked(false);
                dispatch(actions.updateIncludeArchivedEmployees(false));
            } else {
                //Only show if absencePeriod or monthlyReport or cases
                setDisabled(false);
            }
        }
    }, [currentSearch, showArchive, dispatch, HasIsAbsenceArchive]);

    return (
        <div className="flex flex-col">
            <div
                className="mt-10 flex w-full flex-col justify-between gap-5 lg:flex-row xxl:gap-20"
                data-testid="page-searchquery"
            >
                <div className="flex flex-1 flex-col">
                    <div className="flex flex-1 flex-col gap-1">
                        <SearchDropdowns />
                    </div>
                    <div
                        className={twMerge(
                            "flex items-center justify-between gap-2 pt-6",
                            showArchive !== "true" && "hidden",
                        )}
                    >
                        <div>
                            <Tooltip content={`${t("notPossibleForSelectedReport")}`}>
                                <div className="flex items-center gap-1">
                                    <Checkbox checked={checked} disabled={disabled} onChange={onChange} />
                                    <p
                                        onClick={() => hasDataToSearchFor && setChecked(!checked)}
                                        className={twMerge(
                                            "font-bold",
                                            disabled ? "text-mhgrey-dark text-opacity-50" : "",
                                        )}
                                    >{`${t("includeArchivedEmployees")}`}</p>
                                </div>
                            </Tooltip>
                            <div className={twMerge("pl-[22px]", (disabled || !checked) && "hidden")}>
                                {t("includeArchivedEmployeesNoteIfChecked")}
                            </div>
                        </div>
                    </div>
                </div>

                {currentSearch !== "monthlyReport" && (
                    <div className="flex items-end justify-center">
                        <PrimaryButton
                            className="w-full max-w-[335px] lg:w-auto"
                            id="followup-search-button"
                            onClick={onSearch}
                            isLoading={isLoading}
                            disabled={!hasDataToSearchFor}
                            styleText="justify-center"
                            text={t("searchName")}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default SearchQuery;
