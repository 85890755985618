import { useAppDispatch, useAppSelector } from "store/hooks";
import { getSelectedCompanyId, getSelectedUserAccountId } from "pages/Administration/redux/administrationSelectors";
import { useCallback, useEffect, useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import {
    getCompanyGroupRegion,
    getCompanyUserFromAccountEdit,
} from "pages/Administration/redux/administrationAsyncActions";
import { administrationActions } from "pages/Administration/redux/administrationSlice";
import { Header } from "pages/Administration/components/Header";

export default function Index() {
    const { hash } = useLocation();
    const dispatch = useAppDispatch();
    const selectedCompanyId = useAppSelector(getSelectedCompanyId);
    const stateSelectedUserAccountId = useAppSelector(getSelectedUserAccountId);

    const getUserAccountIdFromHash = useCallback((): number | null => {
        const parsedHash = new URLSearchParams(hash);
        const paramsPOJO = Object.fromEntries(parsedHash.entries());
        const userAccountIdFromHash = paramsPOJO.userAccountId;
        if (userAccountIdFromHash && !isNaN(Number(userAccountIdFromHash))) return +userAccountIdFromHash;
        return null;
    }, [hash]);

    const userAccountId = useMemo(
        () => getUserAccountIdFromHash() ?? stateSelectedUserAccountId,
        [getUserAccountIdFromHash, stateSelectedUserAccountId],
    );
    useEffect(() => {
        dispatch(administrationActions.resetUserAccountIdStates());
        if (selectedCompanyId && userAccountId) {
            dispatch(administrationActions.setSelectedUserAccountId(userAccountId));
        }
    }, [dispatch, selectedCompanyId, userAccountId]);

    useEffect(() => {
        if (selectedCompanyId) {
            // Existing user
            if (stateSelectedUserAccountId) {
                dispatch(
                    getCompanyUserFromAccountEdit({
                        userAccountId: stateSelectedUserAccountId,
                        companyId: selectedCompanyId,
                    }),
                );
            } else {
                //New user
                dispatch(getCompanyGroupRegion(selectedCompanyId));
            }
        }
    }, [dispatch, selectedCompanyId, stateSelectedUserAccountId]);

    return (
        <>
            {selectedCompanyId && <Header />}
            <Outlet />
        </>
    );
}
