import Icon from "components/Icon";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IdNameMap, TreeViewNode } from "swagger/customercompany";
import { twMerge } from "tailwind-merge";
import Tooltip from "./Tooltip";
import { HierarchicalList } from "./HierarchicalList";

interface DepartmentListProps {
    heading: string;
    departments?: IdNameMap[] | null;
    orgTreeView: TreeViewNode[];
    departmentCount: number;
    type: "current" | "other";
}

const ITEMS_PER_PAGE = 10;

export const DepartmentList = ({ heading, departments, orgTreeView, departmentCount, type }: DepartmentListProps) => {
    const { t } = useTranslation("administration");
    const [currentPage, setCurrentPage] = useState(1);
    const [isOpen, setIsOpen] = useState(type === "current");

    const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
    const currentDepartments = departments?.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil((departments?.length || 0) / ITEMS_PER_PAGE);

    return (
        <div className="bg-background-200 p-4 pt-3">
            <div onClick={() => setIsOpen(!isOpen)} className="flex cursor-pointer justify-between py-1.5 font-bold">
                <div className="flex items-center gap-3 pl-3">
                    <Icon icon={isOpen ? "caretDown" : "caretRight"} size={9} />
                    <div>
                        {heading} ({departmentCount})
                    </div>
                </div>
                <div className="hidden md:block">{t("includeUnderlyingDepartments")}</div>
            </div>
            {isOpen && (
                <>
                    <div className="divide-y-1 border-y">
                        {currentDepartments?.map((item) => (
                            <div key={item.id} className="flex flex-col justify-between px-4 py-1.5 md:flex-row">
                                <Tooltip
                                    content={
                                        item.recursive && (
                                            <HierarchicalList
                                                orgTreeView={orgTreeView}
                                                departmentId={item.id}
                                                recursive={item.recursive || false}
                                            />
                                        )
                                    }
                                >
                                    <div className="flex items-center gap-3">{item.name}</div>
                                </Tooltip>
                                <div className="flex items-center justify-between">
                                    <div className="text-xs text-grey-700 md:hidden">
                                        {t("includeUnderlyingDepartments")}
                                    </div>
                                    <div className={item.recursive ? "text-[#11B05A]" : "text-grey-700"}>
                                        <Icon icon={item.recursive ? "checkCircle" : "close"} size={16} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {totalPages > 1 && (
                        <div className="flex items-center justify-center gap-2 pt-3">
                            {Array.from({ length: totalPages }, (_, index) => (
                                <button
                                    key={index}
                                    className={twMerge(
                                        "flex w-4 justify-center rounded-sm",
                                        currentPage === index + 1 ? "bg-grey-200" : "text-grey-600",
                                    )}
                                    onClick={() => setCurrentPage(index + 1)}
                                >
                                    {index + 1}
                                </button>
                            ))}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
