import Icon from "components/Icon/Icon";
import { Trans, useTranslation } from "react-i18next";

interface NoteItem {
    header: string;
    listItem: string;
}
function NoteSettingsComponent({ partner }: any) {
    const { t } = useTranslation("administration");
    const notes: NoteItem[] = t("notes", { returnObjects: true });

    if (!Array.isArray(notes)) {
        return null;
    }

    const processedNotes = notes.map((item: any) => ({
        header: item.header,
        listItem: item.list,
    }));

    return (
        <div className="my-12 rounded-lg border-2 border-zinc-200 px-4 py-6 lg:mb-6 lg:mt-4">
            <Trans i18nKey={`notes.Header`} values={{ Partner: partner }}>
                {processedNotes.map((item, index) => (
                    <div key={`${item.header}-${index}`}>
                        {index === 0 || processedNotes[index - 1].header !== item.header ? (
                            <div className="flex items-center">
                                <Icon icon="info" height="26" width="26" />
                                <h2 className="ml-4">{item.header}</h2>
                            </div>
                        ) : null}
                        <ul className="pr-4pt-2 w-full list-outside list-disc pl-14 lg:w-3/5">
                            {item.listItem.map((listItem: string) => (
                                <li key={`${listItem}`}>{listItem}</li>
                            ))}
                        </ul>
                    </div>
                ))}
            </Trans>
        </div>
    );
}

export default NoteSettingsComponent;
