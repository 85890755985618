import Icon, { Icons } from "components/Icon";
import { twMerge } from "tailwind-merge";

interface InfoBoxProps {
    icon?: Icons;
    text?: string;
    children?: React.ReactNode;
    className?: string;
}

export const InfoBox = ({ icon = "info", text, children, className }: InfoBoxProps) => (
    <div className={twMerge("flex gap-4 rounded-lg bg-grey-50 p-4", className)}>
        <div>
            <Icon icon={icon} size={24} />
        </div>
        {text && <div className="text-sm">{text}</div>}
        {children && children}
    </div>
);
