import EditorJS, { OutputData } from "@editorjs/editorjs";

import { EDITOR_JS_TOOLS } from "./const";
import { useEffect, useRef, useState } from "react";
import PrimaryButton from "components/PrimaryButton";
import edjsHTML from "editorjs-html";
import { decode } from "html-entities";
import SecondaryButton from "components/SecondaryButton";
import { twMerge } from "tailwind-merge";

// Parse this custom block in editorjs-html
function customBreakLineParser() {
    return `<br>`;
}

interface HtmlEditorProps {
    defaultData: OutputData;
    setOpen: (open: boolean) => void;
}

const htmlParser = (html: string[]) => {
    const temp = html.map((item: any) => {
        return decode(item); //+ "<br>";
    });
    const joinedText = temp.join("");

    return joinedText;
};

const HtmlEditor = ({ defaultData, setOpen }: HtmlEditorProps) => {
    const canLoad = useRef(true);
    const [contentData, setContentData] = useState<OutputData | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);

    const edjsParser = edjsHTML({ breakLine: customBreakLineParser });
    const htmlArray = edjsParser.parse(defaultData);
    const html = htmlParser(htmlArray);

    const [htmlPreview, setHtmlPreview] = useState(html || "");
    const [editing, setEditing] = useState(false);

    const submit = () => {
        if (!contentData) return;
        setEditing(false);
        setIsLoading(true);
        setInterval(() => {
            setIsLoading(false);
        }, 1000);
        //console.log({ contentData, htmlPreview });
    };

    useEffect(() => {
        if (!contentData) return;
        const htmlArray = edjsParser.parse(contentData);
        const html = htmlParser(htmlArray);
        setHtmlPreview(html);
    }, [contentData, setHtmlPreview, edjsParser]);

    useEffect(() => {
        if (!canLoad.current) return;
        if (canLoad.current) {
            canLoad.current = false;
            initEditor({ onContentChange: setContentData, defaultData });
        }
        return () => {
            canLoad.current = true;
            setIsLoading(false);
        };
    }, [defaultData]);

    return (
        <div className="flex flex-col items-start justify-start py-5">
            <div className={twMerge("hidden", editing && "border-gray flex border")}>
                <div className="w-8/12 bg-white" id="editorjs"></div>
            </div>
            {editing ? (
                <>
                    <PrimaryButton
                        id="email-save-button"
                        onClick={submit}
                        text="Click me"
                        className="mt-8 w-[300px]"
                        disabled={!contentData}
                        isLoading={isLoading}
                    >
                        SPARA
                    </PrimaryButton>
                    <SecondaryButton
                        id="email-close-button"
                        onClick={() => setOpen(false)}
                        text="Close"
                        className="mt-8 w-[300px]"
                    >
                        Avbryt
                    </SecondaryButton>
                </>
            ) : (
                <div>
                    <SecondaryButton
                        id="email-edit-button"
                        onClick={() => setEditing(true)}
                        text="Edit"
                        className="mt-8w-[300px] absolute right-0"
                    >
                        Redigera
                    </SecondaryButton>

                    <div className="bg-white" dangerouslySetInnerHTML={{ __html: htmlPreview }} />
                </div>
            )}
        </div>
    );
};

const initEditor = ({ onContentChange, defaultData }: any) => {
    const editor = new EditorJS({
        holder: "editorjs",
        onReady: () => {
            //console.log("Editor.js is ready to work!ssssssss");
        },
        autofocus: true,
        data: defaultData,
        onChange: async () => {
            //Find linebreaks and set them to not editable
            const containtsNewLines = window?.document?.querySelectorAll(".break-line");
            if (containtsNewLines.length > 0) {
                containtsNewLines.forEach((currentElement) => {
                    (currentElement as HTMLElement).contentEditable = "false";
                });
            }
            //Save that data
            const contentData = await editor.saver.save(); // Ensure contentData is properly declared locally
            if (!contentData) return;
            if (typeof onContentChange === "function") {
                onContentChange(contentData); // Ensure setContentData is callable before using it
            }
        },
        tools: EDITOR_JS_TOOLS,
    });
};

export default HtmlEditor;
