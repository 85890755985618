import { configureStore, combineReducers, Action, ThunkDispatch, ThunkAction } from "@reduxjs/toolkit";
import { UnknownAction } from "redux";
import { persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import { Config } from "config";
import user from "./userSlice";
import search from "./searchSlice";
import { reducer as absenceReports } from "pages/Absence/pages/absencereporting/redux";
import { reducer as dashboard } from "../pages/dashboard/redux";
import { reducer as medicalCertificate } from "@medhelp/medical-certificate/redux";
import { reducer as service } from "../pages/service/redux";
import { reducer as followUp, listener as followUpListener } from "../pages/FollowUp/redux";
import { reducer as login } from "../pages/login/redux";
import { reducer as userSettings } from "../pages/UserSettings/redux";
import modal from "./modal/modalSlice";
import { reducer as about } from "../pages/about/redux";
import { reducer as workOrder } from "../pages/WorkOrder/redux";
import { reducer as organization } from "./organization";
import { reducer as administration } from "../pages/Administration/redux";
import { reducer as inbox } from "../pages/Inbox/redux";
import { reducer as route } from "./route";
import { fileImportBaseApi } from "./rtk-apis/fileImport/fileImportBaseApi";
import { reducer as fortnoxAdministration } from "../pages/FortnoxAdministration/redux";
import { customerCompanyBaseApi } from "./rtk-apis/customerCompany/customerCompanyBaseApi";
import { businessSupportBaseApi } from "./rtk-apis/businessSupport/businessSupportBaseApi";

export const combinedReducer = combineReducers({
    user,
    route,
    absenceReports,
    dashboard,
    medicalCertificate,
    search,
    service,
    followUp,
    modal,
    login,
    userSettings,
    about,
    workOrder,
    organization,
    administration,
    inbox,
    fortnoxAdministration,
    [fileImportBaseApi.reducerPath]: fileImportBaseApi.reducer,
    [customerCompanyBaseApi.reducerPath]: customerCompanyBaseApi.reducer,
    [businessSupportBaseApi.reducerPath]: businessSupportBaseApi.reducer,
});

export const listeners = [...followUpListener.map((listener) => listener.middleware)];

export const rootReducer = (state: any, action: any) => {
    if (action.type.toLowerCase() === "user/signout") {
        state = undefined;
    }
    return combinedReducer(state, action);
};

const store = configureStore({
    reducer: rootReducer,
    devTools: Config.env !== "prod",
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })
            .prepend(listeners)
            .concat(fileImportBaseApi.middleware)
            .concat(customerCompanyBaseApi.middleware)
            .concat(businessSupportBaseApi.middleware),
});

export type Store = typeof store;
export type RootState = ReturnType<typeof store.getState>;

export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;
export type AppDispatch = ThunkAppDispatch;

export type AppThunkAction<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, UnknownAction>;

// @ts-ignore
window._globalStore = store;

const persistor = persistStore(store);

export { store, persistor };
