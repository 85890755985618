import { useTranslation } from "react-i18next";
import HtmlEditor from "components/HtmlEditor";
import HtmlEditorModal from "components/HtmlEditor/HtmlEditorModal";
import { useMemo, useState } from "react";
import { DEFAULT_INITIAL_DATA } from "./DefaultData";
import { ReactComponent as MhLogo } from "icons/menu/mhlogo.svg";
import { ReactComponent as AvonovaLogo } from "icons/avonovaLogo.svg";
import { ReactComponent as FeelgoodLogo } from "icons/feelgoodLogo.svg";
import { ReactComponent as FalckLogo } from "icons/falckLogo.svg";
import { useAppSelector } from "store/hooks";
import * as UserSelectors from "store/userSelectors";

const Email = () => {
    const { t } = useTranslation("email");
    const [open, setOpen] = useState(false);
    const [defaultData, setDefaultData] = useState(DEFAULT_INITIAL_DATA[0]);

    const typeOfSelection = [
        "Frånvaroanmälan (Customers template)",
        "Uppdaterad frånvaroanmälan (Customers template)",
        "Åter efter frånvaro (Customers template)",
        "VAB-anmälan (Customers template)",
    ];

    const [selectedType, setSelectedType] = useState(typeOfSelection[0]);

    const edit = (index: number) => {
        setOpen(true);
        setSelectedType(typeOfSelection[index]);
        setDefaultData(DEFAULT_INITIAL_DATA[index]);
    };

    const activeRetailer = useAppSelector(UserSelectors.getUserRetailer);
    const SetLogo = useMemo(() => {
        const logoMap = {
            Avonova: <AvonovaLogo className="inline" />,
            Feelgood: <FeelgoodLogo className="inline" />,
            Falck: <FalckLogo className="inline w-36" />,
            Medhelp: <MhLogo />,
        };
        return logoMap[activeRetailer] || <MhLogo />;
    }, [activeRetailer]);

    return (
        <div className="px-4 xl:px-0">
            <h2 className="pb-4 capitalize md:pb-8">{t("email")}</h2>
            <ol>
                <li className="flex justify-between pb-2">
                    <div>Frånvaroanmälan (Customers template)</div>
                    <button
                        className="rounded-md bg-mhgrey-light px-4 py-2 hover:bg-mhgrey-medium"
                        onClick={() => edit(0)}
                    >
                        {t("edit")}
                    </button>
                </li>
                <li className="flex justify-between pb-2">
                    <div>Uppdaterad frånvaroanmälan (Customers template)</div>
                    <button
                        className="rounded-md bg-mhgrey-light px-4 py-2 hover:bg-mhgrey-medium"
                        onClick={() => edit(1)}
                    >
                        {t("edit")}
                    </button>
                </li>
                <li className="flex justify-between pb-2">
                    <div>Åter efter frånvaro (Customers template)</div>
                    <button
                        className="rounded-md bg-mhgrey-light px-4 py-2 hover:bg-mhgrey-medium"
                        onClick={() => edit(2)}
                    >
                        {t("edit")}
                    </button>
                </li>
                <li className="flex justify-between pb-2">
                    <div>VAB-anmälan (Customers template)</div>
                    <button
                        className="rounded-md bg-mhgrey-light px-4 py-2 hover:bg-mhgrey-medium"
                        onClick={() => edit(3)}
                    >
                        {t("edit")}
                    </button>
                </li>
            </ol>

            <HtmlEditorModal open={open} setOpen={setOpen}>
                <div>Lägg till text för medddelandetyp {selectedType}</div>
                <div>Language selector</div>
                <div id="mailTemplate" className="">
                    <div className="flex h-[150px] w-full items-center justify-center bg-menuNavBg">{SetLogo}</div>
                    <div className="flex-col bg-white">
                        <div>
                            <h1>Långtidsfrånvaro överstiger 14 dagar</h1>
                            <p>
                                Medarbetare &nbsp;&lt;<strong>Förnamn Efternamn</strong>&gt; &nbsp;&lt;
                                <strong>ID</strong>
                                &gt; på avdelning ekonomi på Företagsnamn AB har en frånvaro som pågått längre än 14
                                dagar. Ett ärende kopplat till frånvaron finns i MedHelps plattform. <a>Logga in</a> i
                                för att följa upp ärendet. Kom ihåg att följa rutinerna i din organisations interna
                                policy.
                            </p>
                        </div>
                        <HtmlEditor defaultData={defaultData} setOpen={setOpen} />
                        <button className="rounded-md bg-mhgrey-light px-4 py-2 hover:bg-mhgrey-medium">
                            {t("login")}
                        </button>
                    </div>
                    <div className="flex h-[400px] w-full flex-col items-center justify-center bg-mhgrey-medium">
                        <h5>MedHelp Care</h5>
                        <p>
                            MedHelp Care Aktiebolag (publ)
                            <br />
                            Marieviksgatan 19, 117 43 Stockholm, Sverige
                        </p>
                    </div>
                </div>
            </HtmlEditorModal>
        </div>
    );
};

export default Email;
