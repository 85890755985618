export const DEFAULT_INITIAL_DATA = [
    {
        time: new Date().getTime(),
        blocks: [
            {
                id: "sheNwCUP5A",
                type: "header",
                data: {
                    text: "Editor.js",
                    level: 2,
                },
            },
            {
                id: "12iM3lqzcm",
                type: "paragraph",
                data: {
                    text: "Hey. Meet the new Editor. On this page you can see it in action — try to edit this text. ",
                },
            },
        ],
    },
    {
        time: new Date().getTime(),
        blocks: [
            {
                id: "sheNwCUP5A",
                type: "header",
                data: {
                    text: "Editor.js",
                    level: 2,
                },
            },
            {
                id: "12iM3lqzcm",
                type: "paragraph",
                data: {
                    text: "Hey. Meet the new Editor. On this page you can see it in action — try to edit this text. ",
                },
            },
        ],
    },
    {
        time: new Date().getTime(),
        blocks: [
            {
                id: "sheNwCUP5A",
                type: "header",
                data: {
                    text: "Editor.js",
                    level: 2,
                },
            },
            {
                id: "12iM3lqzcm",
                type: "paragraph",
                data: {
                    text: "Hey. Meet the new Editor. On this page you can see it in action — try to edit this text. ",
                },
            },
        ],
    },
    {
        time: new Date().getTime(),
        blocks: [
            {
                id: "sheNwCUP5A",
                type: "header",
                data: {
                    text: "Editor.js",
                    level: 2,
                },
            },
            {
                id: "12iM3lqzcm",
                type: "paragraph",
                data: {
                    text: "Hey. Meet the new Editor. On this page you can see it in action — try to edit this text. ",
                },
            },
        ],
    },
];
