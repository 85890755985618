import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { useGetApiCustomerCompanyGetCompanyByIdQuery } from "store/rtk-apis/customerCompany/customerCompanyApi";
import { getSelectedCompanyId, getSelectedCompanyUser } from "../redux/administrationSelectors";
import { skipToken } from "@reduxjs/toolkit/query";
import { Config } from "config";

export const Header = () => {
    const { t } = useTranslation("administration");
    const user = useAppSelector(getSelectedCompanyUser);
    const selectedCompanyId = useAppSelector(getSelectedCompanyId);

    const { data: company } = useGetApiCustomerCompanyGetCompanyByIdQuery(
        selectedCompanyId ? { companyId: selectedCompanyId } : skipToken,
    );

    if (!user || !company) return null;

    const LINK_TO_COMPANY_PAGE = `${Config.web_customerSupport_url}company/${selectedCompanyId}`;

    const { userId, name, primaryCompany } = user;
    const { name: companyName, number: orgNr } = company;
    return (
        <div className="flex gap-8 px-5 py-10 xl:px-0">
            <div>
                <div className="text-xs font-bold">{t("selectedCompany")}</div>
                <a href={LINK_TO_COMPANY_PAGE} className="text-[#389191]">
                    {companyName}
                </a>
                <div className="text-xs">
                    {t("orgNo")}: {orgNr}
                </div>
            </div>
            <div>
                <div className="text-xs font-bold">
                    {t("selectedUserId")}: {userId}
                </div>
                <div className="text-[#389191]">{name}</div>
                <div className="text-xs">
                    {t("employedAt")}: {primaryCompany?.name}
                </div>
            </div>
        </div>
    );
};
