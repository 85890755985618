import { Dropdown } from "@medhelp/ui";
import { SyntheticEvent } from "react";

interface IPropsView {
    open: boolean;
    setOpen: (event: SyntheticEvent) => void;
    items: React.ReactElement;
    heading: React.ReactElement;
    label: string;
    onClickOutside: (value: boolean) => void;
}
const MonthDropdown = ({ open, setOpen, items, heading, label, onClickOutside }: IPropsView) => (
    <div className="w-full max-w-[335px] lg:w-[260px]" data-testid="months-dropdown-reports">
        <p className="text-bold">{label}</p>
        <Dropdown
            open={open}
            setOpen={setOpen}
            preset="secondary"
            header={heading}
            items={items}
            onClickOutside={onClickOutside}
        />
    </div>
);

export default MonthDropdown;
