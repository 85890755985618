import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Config } from "config";
import { RootState } from "store";

export const businessSupportBaseApi = createApi({
    reducerPath: "businessSupportBaseApi",
    baseQuery: fetchBaseQuery({
        baseUrl: Config.api_businessSupport_url,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).user.token;
            if (token) headers.set("Authorization", `Bearer ${token}`);
            return headers;
        },
    }),
    endpoints: () => ({}),
});
