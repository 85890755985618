import ReactModal from "react-modal";
import { useAppSelector } from "store/hooks";
import { ReactComponent as CloseIcon } from "icons/login/close.svg";
import { getUserRetailer } from "store/userSelectors";
export interface HtmlEditorrModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    children?: React.ReactNode;
}
const HtmlEditorModal = ({ open, setOpen, children }: HtmlEditorrModalProps) => {
    const activeRetailer = useAppSelector(getUserRetailer);

    return (
        <ReactModal
            id="htmlEditorModal"
            isOpen={open}
            className={`${activeRetailer} relative z-30 flex h-screen w-screen justify-center overflow-auto bg-white lg:max-h-4/5 lg:min-h-xl lg:max-w-4/5`}
            onRequestClose={() => setOpen(false)}
            shouldCloseOnEsc
            style={{
                overlay: {
                    zIndex: 30,
                },
            }}
        >
            <div className="fixed right-0 top-0 lg:absolute">
                <button type="button" className="p-5 xl:p-9" onClick={() => setOpen(false)}>
                    <CloseIcon className="h-4" />
                </button>
            </div>
            <div id="htmlEditorContent" className="w-full max-w-screen-msd pt-12">
                {children}
            </div>
        </ReactModal>
    );
};

export default HtmlEditorModal;
