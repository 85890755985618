import Icon from "components/Icon";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { AccessLevel } from "../types";

interface AccordionProps {
    heading: string;
    accessLevel?: AccessLevel;
    departmentCount?: number;
    absenceArchive?: boolean;
    otherCompanies?: string[];
    children?: React.ReactNode;
}

export const Accordion = ({
    heading,
    accessLevel,
    departmentCount,
    otherCompanies,
    absenceArchive,
    children,
}: AccordionProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation("administration");

    const getAccessLevelText = (accessLevel: AccessLevel) => {
        if (accessLevel === "company") {
            return t("companyLevel");
        }
        if (accessLevel === "department") {
            return t("departmentLevel") + (departmentCount ? ` (${departmentCount})` : "");
        }
        return "";
    };

    const hasAccessRight = accessLevel !== undefined;

    return (
        <div>
            <div
                className={twMerge(
                    "flex items-center justify-between px-4 py-3 hover:cursor-pointer",
                    !isOpen && "hover:bg-grey-50",
                )}
                onClick={() => setIsOpen(!isOpen)}
            >
                <div className="flex items-center gap-3">
                    <div>
                        <Icon icon={isOpen ? "caretDown" : "caretRight"} size={9} />
                    </div>
                    <div className="text-lg">{heading}</div>
                </div>

                <div className="text-sm text-grey-700">
                    <div className="flex items-center justify-between gap-3">
                        <div className="hidden sm:block">{getAccessLevelText(accessLevel)}</div>
                        <div className={hasAccessRight ? "text-[#11B05A]" : "text-grey-700"}>
                            <Icon icon={hasAccessRight ? "checkCircle" : "close"} size={16} />
                        </div>
                    </div>
                    {absenceArchive && <div className="hidden sm:block">{t("includingAbsenceArchive")}</div>}
                    <div className="hidden sm:block">
                        {otherCompanies?.map((company) => <div key={company}>+ {company}</div>)}
                    </div>
                </div>
            </div>
            {isOpen && <div className="flex flex-col gap-4 pb-6 pt-1 md:px-4">{children}</div>}
        </div>
    );
};
