import { isEmpty } from "lodash/fp";
import { ISelectValue } from "../Select/Select";

export const showDataWithSearch = (searchParams: string, data: ISelectValue[]) => {
    if (!isEmpty(searchParams)) {
        const lowerCaseSearchParams = searchParams.toLowerCase();
        return data.filter(
            (item) =>
                item.description.toLowerCase().includes(lowerCaseSearchParams) ||
                (item.nonTranslatedDesc && item.nonTranslatedDesc.toLowerCase().includes(lowerCaseSearchParams)),
        );
    }

    return data;
};
