import {
    getHasCustomerSupport,
    getUserAccountCompanyId,
    getUserAccessRightsNoUndefined,
    getHasBaseProduct,
} from "store/userSelectors";
import { actions } from "./redux";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { SpinnerPage } from "components/Spinner";
import { Status } from "./redux/dashboardStateTypes";
import { NewsWidget } from "./components/NewsWidget";
import Draggable from "./components/Draggable/Draggable";
import { dashboardActions } from "./redux/dashboardSlice";
import WidgetContainer from "./components/WidgetContainer";
import EmployeeSummary from "./components/EmployeeSummary";
import { getActiveSearchCompanyId } from "store/searchSelectors";
import useDragAndDrop from "./components/Draggable/useDragAndDrop";
import DashboardGuide from "./components/DashboardGuide/DashboardGuide";
import { getWidgets } from "./redux/dashboardSelectors";
import { fetchDashboardInit, fetchNews, updateWidgetOrder } from "./redux/dashboardActions";
import { useTranslation } from "react-i18next";
import AddWidget from "./components/AddWidget";
import TopLocation from "components/TopLocation";

const DashboardPage = () => {
    const dispatch = useAppDispatch();
    const widgets = useAppSelector(getWidgets);
    const customerSupport = useAppSelector(getHasCustomerSupport);
    const accessRights = useAppSelector(getUserAccessRightsNoUndefined);
    const userAccountCompanyId = useAppSelector(getUserAccountCompanyId);
    const companyIdFromPowerBar = useAppSelector(getActiveSearchCompanyId);
    const hasBaseProduct = useAppSelector(getHasBaseProduct);
    const [guideModalOpen, setGuideModalOpen] = useState(false);
    const status = useAppSelector((state) => state.dashboard.DashboardInitStatus);

    const { items, handleDragStart, handleDrop, handleDragOver, handleDragLeave, hoveredIndex, draggingIndex } =
        useDragAndDrop(widgets, {
            onDragStart: () => dispatch(dashboardActions.setIsDragging(true)),
            onDrop: (index, items) => {
                dispatch(dashboardActions.setDroppedOnId(items[index].id));
                dispatch(updateWidgetOrder(items));
            },
        });

    useEffect(() => {
        if (userAccountCompanyId) {
            dispatch(actions.reset());
            const companyId = customerSupport ? companyIdFromPowerBar || userAccountCompanyId : userAccountCompanyId;
            dispatch(fetchDashboardInit({ companyId, accessRights }));
            dispatch(fetchNews());
        }
    }, [accessRights, companyIdFromPowerBar, customerSupport, dispatch, userAccountCompanyId]);
    const { t } = useTranslation("dashboard");
    if (status === Status.PENDING) return <SpinnerPage />;

    return (
        <>
            <div className="mb-0 mt-6 flex justify-between px-5 max-xl:hidden xl:mt-0 xl:px-0">
                <h1>Dashboard</h1>
                <AddWidget testId="add-widget" />
            </div>
            <div className="flex flex-col items-center justify-center xl:hidden">
                <div className="mb-8 w-full">
                    <TopLocation header={t("Dashboard")} />
                </div>
                <div className="dashboard-width flex">
                    <AddWidget testId="add-widget" />
                </div>
            </div>
            <div data-testid="dashboard" className="border-t-0 py-8">
                <DashboardGuide open={guideModalOpen} setOpen={setGuideModalOpen} />
                <div className="flex flex-col items-center">
                    {!hasBaseProduct && <EmployeeSummary />}
                    <div className="dashboard-width mt-4 grid grid-cols-1 gap-4 lg:grid-cols-2 xxxl:grid-cols-3">
                        <NewsWidget />
                        {items.map((widget, index) => (
                            <Draggable
                                key={widget.id}
                                handleDragStart={() => handleDragStart(index)}
                                handleDrop={() => handleDrop(index)}
                                handleDragOver={(e) => handleDragOver(e, index)}
                                handleDragLeave={handleDragLeave}
                                isHovered={hoveredIndex === index}
                                isDragged={draggingIndex === index}
                                isHoveredStyle="border-4 border-dashed border-gray-400 widget"
                            >
                                <WidgetContainer {...widget} />
                            </Draggable>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default DashboardPage;
