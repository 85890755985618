import { useTranslation } from "react-i18next";
import ImportContainer from "./components/ImportContainer";
import InfoBox from "components/InfoBox";

const FileImport = () => {
    const { t } = useTranslation("fileImport");
    return (
        <div className="px-4 pt-4 xl:px-0 xl:pt-0">
            <h2 className="pb-4 md:pb-8">{t("fileImport")}</h2>
            <InfoBox translatedText={t("fileImportDescription")} />
            <ImportContainer />
        </div>
    );
};

export default FileImport;
