import Icon from "components/Icon";
import PrimaryButton from "components/PrimaryButton";
import { useTranslation } from "react-i18next";
import { InfoBox } from "./components/InfoBox";
import {
    IdNameMap,
    useGetApiCustomerCompanyGetOrganizationTreeViewQuery,
    useGetApiUserGetAccessRightsForCompanyGroupQuery,
} from "store/rtk-apis/customerCompany/customerCompanyApi";
import {
    getFileImportCustomerId,
    getSelectedCompanyId,
    getSelectedCompanyUser,
    getSelectedCompanyUserUserAccountId,
} from "pages/Administration/redux/administrationSelectors";
import { useAppSelector } from "store/hooks";
import { skipToken } from "@reduxjs/toolkit/query";
import { Accordion } from "./components/Accordion";
import { AccessRightInfo } from "./components/AccessRightInfo";
import { DepartmentList } from "./components/DepartmentList";
import { AccessRightGroup, AccessRightKey } from "./types";
import { departmentCount, getAccessLevel, getOtherCompanyDepartments } from "./utils";
import { SpinnerPage } from "components/Spinner";
import { Custom500 } from "pages/500/500";
import { useGetConfigurationCustomersByCustomerIdQuery } from "store/rtk-apis/fileImport/fileImportApi";
import { useGetApiOnboardingGetServiceUtilizationInfoQuery } from "store/rtk-apis/businessSupport/businessSupportApi";
import { getHasBaseProduct, getUserContext } from "store/userSelectors";

const AccessRights = () => {
    const { t } = useTranslation("administration");
    const customerId = useAppSelector(getFileImportCustomerId);
    const companyId = useAppSelector(getSelectedCompanyId);
    const hasBaseProduct = useAppSelector(getHasBaseProduct);
    const userAccountId = useAppSelector(getSelectedCompanyUserUserAccountId);
    const selectedUser = useAppSelector(getSelectedCompanyUser);
    const { HasCustomerSupport, HasCustomerCompanyAdministration } = useAppSelector(getUserContext);

    const {
        data: allAccessRights,
        isLoading: isLoadingAccessRights,
        isError: isErrorAccessRights,
    } = useGetApiUserGetAccessRightsForCompanyGroupQuery(
        userAccountId && companyId ? { userAccountId, companyId } : skipToken,
    );

    const {
        data: orgTreeView,
        isLoading: isLoadingOrgTreeView,
        isError: isErrorOrgTreeView,
    } = useGetApiCustomerCompanyGetOrganizationTreeViewQuery(companyId ? { companyId } : skipToken);

    const { data: fileImportConfiguration } = useGetConfigurationCustomersByCustomerIdQuery(
        customerId ? { customerId } : skipToken,
    );

    const { data: serviceUtilizationInfo } = useGetApiOnboardingGetServiceUtilizationInfoQuery(
        companyId && customerId ? { companyId, customerId } : skipToken,
    );

    const currentCompanyAccessRights = allAccessRights?.find((accessRight) => accessRight.companyId === companyId);
    const topDepartment = currentCompanyAccessRights?.topDepartment;

    const otherCompanyAccessRights = allAccessRights?.filter((accessRight) => accessRight.companyId !== companyId);
    const otherCompanyDepartments = getOtherCompanyDepartments(otherCompanyAccessRights);

    const createAccessRight = (key: AccessRightKey) => {
        const accessRightValue = currentCompanyAccessRights?.[key];
        let departments: IdNameMap[] | null | undefined;

        if (Array.isArray(accessRightValue)) {
            departments = accessRightValue;
        } else {
            departments = undefined;
        }

        return {
            key,
            heading: t(`accessRightHeadings.${key}`),
            accessLevel: getAccessLevel(topDepartment, accessRightValue),
            departments,
            otherCompanyDepartments: otherCompanyDepartments[key],
        };
    };

    // company conditions
    const companyHasBaseProduct = hasBaseProduct;
    const companyHasFileImport = !!fileImportConfiguration;
    const companyHasRehab = serviceUtilizationInfo?.some((service) => service.healthService === 3) || false;
    const companyHasAbsenceBoard = serviceUtilizationInfo?.some((service) => service.healthService === 5) || false;

    const absenceReportingAccessRights: { key: AccessRightKey; condition: boolean }[] = [
        { key: "absenceReporting", condition: !companyHasBaseProduct },
        { key: "absenceAdministration", condition: !companyHasBaseProduct },
        { key: "absenceBoard", condition: companyHasAbsenceBoard },
    ];

    const absenceFollowupAccessRights: { key: AccessRightKey; condition: boolean }[] = [
        { key: "absenceView", condition: true },
        { key: "absenceFollowup", condition: true },
        { key: "rehabProcessManagement", condition: companyHasRehab },
        { key: "absenceStatistics", condition: true },
    ];

    const administrationAccessRights: { key: AccessRightKey; condition: boolean }[] = [
        { key: "departmentAdministration", condition: true },
        { key: "customerCompanyAdministration", condition: true },
        {
            key: "customerOrganizationAdministration",
            condition: HasCustomerSupport || HasCustomerCompanyAdministration,
        },
        {
            key: "fileImportAdministration",
            condition: companyHasFileImport && (HasCustomerSupport || HasCustomerCompanyAdministration),
        },
    ];

    const accessRightGroups: AccessRightGroup[] = [
        {
            heading: t("absenceReporting"),
            accessRights: absenceReportingAccessRights
                .filter(({ condition }) => condition)
                .map(({ key }) => createAccessRight(key)),
        },
        {
            heading: t("followUp"),
            accessRights: absenceFollowupAccessRights
                .filter(({ condition }) => condition)
                .map(({ key }) => createAccessRight(key)),
        },
        {
            heading: t("administration"),
            accessRights: administrationAccessRights
                .filter(({ condition }) => condition)
                .map(({ key }) => createAccessRight(key)),
        },
    ];

    const hasAbsenceArchive = currentCompanyAccessRights?.absenceArchive;

    if (isErrorAccessRights || isErrorOrgTreeView) {
        return <Custom500 />;
    }

    if (isLoadingAccessRights || isLoadingOrgTreeView) {
        return <SpinnerPage />;
    }

    return (
        <div className="flex flex-col gap-6">
            <InfoBox icon="lightBulb" className="border bg-transparent">
                <div className="max-w-xl text-sm">{t("accessRightsInfo", { name: selectedUser?.name || "" })}</div>
            </InfoBox>
            <div>
                <PrimaryButton
                    id="edit-access-rights"
                    text={t("editAccessRights")}
                    leftIcon={<Icon icon="pencil" size={20} />}
                    onClick={() => console.log("TODO")}
                />
            </div>
            <div className="flex flex-col gap-10 bg-white p-6">
                {accessRightGroups.map(({ heading, accessRights }) => (
                    <div key={heading}>
                        <div className="flex justify-between pb-3">
                            <h3>{heading}</h3>
                            <p className="font-bold">{t("status")}</p>
                        </div>
                        <div className="divide-y border-b border-t">
                            {accessRights.map((accessRight) => {
                                const currentCompanydepartmentCount = departmentCount(
                                    accessRight.departments || [],
                                    orgTreeView || [],
                                );
                                return (
                                    <Accordion
                                        key={accessRight.key}
                                        heading={accessRight.heading}
                                        accessLevel={accessRight.accessLevel}
                                        departmentCount={currentCompanydepartmentCount}
                                        otherCompanies={accessRight.otherCompanyDepartments?.map(
                                            ({ companyName }) => companyName,
                                        )}
                                        absenceArchive={accessRight.key === "absenceFollowup" && hasAbsenceArchive}
                                    >
                                        <AccessRightInfo type={accessRight.key} />
                                        {accessRight.accessLevel === "department" && (
                                            <DepartmentList
                                                heading={currentCompanyAccessRights?.companyName || ""}
                                                departments={accessRight.departments}
                                                orgTreeView={orgTreeView || []}
                                                departmentCount={currentCompanydepartmentCount}
                                                type="current"
                                            />
                                        )}
                                        {accessRight.otherCompanyDepartments?.map((item) => (
                                            <DepartmentList
                                                key={item.companyName}
                                                heading={item.companyName}
                                                departments={item.departments}
                                                orgTreeView={orgTreeView || []}
                                                departmentCount={departmentCount(
                                                    item.departments || [],
                                                    orgTreeView || [],
                                                )}
                                                type="other"
                                            />
                                        ))}
                                    </Accordion>
                                );
                            })}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AccessRights;
