import * as React from "react";
import { flow, identity } from "lodash/fp";
import ExpandableComponent from "components/ExpandableComponent";

interface IProps {
    index: number;
    headerView: React.ReactElement;
    children?: React.ReactNode | React.ReactElement;
    onExpand?: (expanded: boolean) => void;
    initiallyExpanded?: boolean;
}
const CardWithExpand = ({ index, headerView, children, onExpand: onExpandCalled, initiallyExpanded }: IProps) => {
    const [isExpanded, setIsExpandedState] = React.useState(initiallyExpanded);

    const setIsExpanded = React.useCallback((expanded: boolean) => {
        setIsExpandedState(expanded);
        return expanded;
    }, []);

    const onExpand = React.useCallback(
        (newState: boolean) => flow(setIsExpanded, onExpandCalled ? onExpandCalled : identity)(newState),
        [onExpandCalled, setIsExpanded],
    );
    return (
        <div
            className={`w-full ${isExpanded && "bg-primaryBg"} ${
                index > 0 && "border-t-2 border-solid border-mhgrey-border"
            }`}
        >
            <ExpandableComponent headerView={headerView} getExpanded={onExpand} onMount={isExpanded}>
                <div className="px-4">{children}</div>
            </ExpandableComponent>
        </div>
    );
};

export default CardWithExpand;
