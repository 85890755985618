import * as React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeft } from "components/Icon/Arrow";

export interface IProps {
    heading: string;
    onClick?: () => void;
}

const BackButtonHeader = ({ heading, onClick }: IProps) => {
    const navigate = useNavigate();
    const handleOnclick = () => {
        onClick ? onClick() : navigate(-1);
    };
    return (
        <div className="mb-14 flex items-start">
            <button type="button" className="mt-2 px-2 xxs:mt-1" onClick={handleOnclick}>
                <ArrowLeft className="hover:text-grey-350" />
            </button>
            <h1 className="ml-5">{heading}</h1>
        </div>
    );
};

export default BackButtonHeader;
