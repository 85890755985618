import React from "react";
import InputAdmin from "../InputAdmin";
import { IDynamicComponent } from "./IDynamicComponent";

const DynamicString = (
    props: IDynamicComponent<string> & {
        placeholder: string;
        children?: React.ReactNode;
    },
) => {
    const { id, value, onChange, children, placeholder, disabled } = props;

    return (
        <>
            <div>
                <InputAdmin
                    id={id}
                    placeholder={placeholder}
                    value={value ?? ""}
                    onChange={onChange}
                    preset="background"
                    disabled={disabled}
                />
            </div>
            {children}
        </>
    );
};
export default DynamicString;
