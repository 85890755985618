import ArchiveTag from "pages/FollowUp/components/ArchiveTag";

interface IProps {
    id: string;
    name: string;
    ssn?: React.ReactElement;
    companyName?: React.ReactElement;
    closestManager: React.ReactElement;
    children?: React.ReactNode;
    isArchived?: boolean;
}
const ContactInfromation = ({ id, ssn, name, companyName, closestManager, children, isArchived }: IProps) => (
    <div id={id} className="mb-10 bg-primaryBg p-5 xs:bg-pageBg xl:p-0" data-testid="absence-report-header-container">
        <div className="px-0">
            <div className="mb-1 flex items-center gap-4 xs:mb-6">
                <h2 data-dd-privacy="mask">{name}</h2>
                {isArchived && <ArchiveTag />}
            </div>
            <div className={`flex flex-col xxs:justify-center xs:justify-between lg:flex-row`}>
                <div className="w-full justify-between text-grey-700 xs:p-0 md:flex" data-dd-privacy="mask">
                    <div className="flex flex-col xs:flex-row">
                        <div className="mb-5 mr-12">{ssn}</div>

                        <div className="mb-5 mr-12">{companyName}</div>
                        {closestManager}
                    </div>
                    {children}
                </div>
            </div>
        </div>
    </div>
);

export default ContactInfromation;
