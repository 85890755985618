import { useMemo } from "react";
import { ReactComponent as MhLogoMobile } from "icons/menu/mhLogoMobile.svg";
import { ReactComponent as AvonovaLogoMobile } from "icons/menu/avonovaLogoMobile.svg";
import { ReactComponent as FeelgoodLogoMobile } from "icons/menu/feelgoodLogoMobile.svg";
import { ReactComponent as FalckLogoMobile } from "icons/menu/falckLogoMobile.svg";

import Icon from "components/Icon";
import { useBreakpoints } from "hooks";
import { useAppSelector } from "store/hooks";
import * as UserSelectors from "store/userSelectors";

interface IMobileMenuProps {
    setSideMenuOpen: (value: boolean) => void;
    sideMenuOpen: boolean;
}

const MobileMenu = ({ setSideMenuOpen, sideMenuOpen }: IMobileMenuProps) => {
    const { isXXSmallScreen } = useBreakpoints();
    const userContext = useAppSelector(UserSelectors.getUserContext);
    const activeRetailer = useAppSelector(UserSelectors.getUserRetailer);
    const SetLogoMobile = useMemo(() => {
        // Temporary solution to hide logo on small screens if user has FIA (settings icon) or CS (search icon) because the icons dont fit
        // In the future more users will have the settings / search icons so this logic will have to be updated
        const hideLogoOnMobile =
            isXXSmallScreen && (userContext.HasFileImportAdministration || userContext.HasCustomerSupport);

        if (hideLogoOnMobile) return <></>;

        const logoMap = {
            Avonova: <AvonovaLogoMobile className="inline" />,
            Feelgood: <FeelgoodLogoMobile className="inline" />,
            Falck: <FalckLogoMobile className="inline" />,
            Medhelp: <MhLogoMobile />,
        };
        return logoMap[activeRetailer] || <MhLogoMobile />;
    }, [activeRetailer, isXXSmallScreen, userContext.HasCustomerSupport, userContext.HasFileImportAdministration]);

    return (
        <div className="w-full self-center xl:hidden xl:self-start">
            <div className="flex w-full items-center justify-between py-5 xs:pr-4">
                <div className="flex items-center">
                    <button
                        type="button"
                        data-testid="mobile-hamburger-icon"
                        className="h-full rounded-full border border-menuBorder bg-menu text-menuNavColorPrimary xl:hidden"
                        onClick={() => {
                            setSideMenuOpen(!sideMenuOpen);
                        }}
                    >
                        <Icon icon="hamburgerIcon" size={52} />
                    </button>
                    <div className="flex h-12 items-center justify-between pl-4 text-menuText">{SetLogoMobile}</div>
                </div>
            </div>
        </div>
    );
};

export default MobileMenu;
