import TabMenu from "components/TabMenu";
import { Config } from "config";
import { IUsersCompProps } from "pages/Administration/types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

const AddUser = ({ tabsDisabled, companyId, userId, isCs }: IUsersCompProps) => {
    const { t } = useTranslation("administration");
    const Routes = useMemo(
        () => [
            {
                route: "administration/users/addUser",
                name: "test",
                indexRoute: "user",
                children: [
                    {
                        route: "user",
                        name: t("user"),
                        children: [],
                    },
                    {
                        route: "employment",
                        name: t("employment"),
                        children: [],
                        disabled: tabsDisabled,
                    },
                    // Remove these when pages are done in new web
                    {
                        route: `${Config.web_customerSupport_url}company/${companyId}/addemployment/${2}/${userId}`,
                        name: t("subscriptions"),
                        children: [],
                        disabled: userId < 1,
                    },
                    {
                        route: `${Config.web_customerSupport_url}company/${companyId}/addemployment/${3}/${userId}`,
                        name: t("accessRights"),
                        children: [],
                        disabled: userId < 1,
                    },
                    // Uncomment these when pages are done in new web
                    // {
                    //     route: "subscriptions",
                    //     name: t("subscriptions"),
                    //     children: [],
                    //     disabled: tabsDisabled,
                    // },
                    // {
                    //     route: "permissions",
                    //     name: t("permissions"),
                    //     children: [],
                    //     disabled: tabsDisabled,
                    // },
                    ...(isCs
                        ? [
                              {
                                  route: "logins",
                                  name: t("logins"),
                                  children: [],
                                  disabled: tabsDisabled,
                              },
                          ]
                        : []),
                ],
            },
        ],

        [companyId, isCs, t, tabsDisabled, userId],
    );

    return (
        <div className="shadow-lg">
            <TabMenu routes={Routes} subMenuTo="administration/users/addUser" className="xl:px-6">
                <div className="py-8 xl:px-6">
                    <Outlet />
                </div>
            </TabMenu>
        </div>
    );
};

export default AddUser;
