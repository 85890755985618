import CheckboxFilter, { IViewElements } from "@medhelp/ui/CheckboxFilter";
import { IDynamicData } from "@medhelp/ui/List/CheckboxList/types";
import { useCallback, useMemo, useState } from "react";
import Overflow from "../Overflow";
import { twMerge } from "tailwind-merge";

type Variants = "standard" | "fullWidth";
interface IProps {
    data: IDynamicData[];
    setData: (value: string) => void;
    header: string;
    viewElements: IViewElements;
    keepClose: boolean;
    loading: boolean;
    variants?: Variants;
    disabled?: boolean;
}

const DropdownCheckboxFilter = ({
    data,
    setData,
    header,
    loading,
    viewElements,
    keepClose,
    variants = "standard",
    disabled,
}: IProps) => {
    const [hiddenElements, setHiddenElements] = useState(0);
    const numberOfHiddenElements = useCallback((hiddenElement: number) => setHiddenElements(hiddenElement), []);
    const variantTheme = useMemo(() => {
        if (variants === "fullWidth") {
            return "flex-1";
        }
        return "w-full max-w-[335px] lg:w-[260px]";
    }, [variants]);
    return (
        <div className={variantTheme}>
            <p className={twMerge("font-bold", disabled ? "text-mhgrey-dark text-opacity-50" : "")}>{header}</p>
            <CheckboxFilter
                data={data}
                setData={setData}
                overflowText={<Overflow hiddenElements={hiddenElements} loading={loading} />}
                viewElements={viewElements}
                keepClose={keepClose}
                numberOfHiddenElements={numberOfHiddenElements}
                hideOnClickOutside
                loading={loading}
                disabled={disabled}
            />
        </div>
    );
};

export default DropdownCheckboxFilter;
