import { useTranslation } from "react-i18next";
import { Card } from "@medhelp/ui";
import TertiaryButton from "components/TertiaryButton";
import SecondaryButton from "components/SecondaryButton";
import { ReactComponent as PlusIcon } from "icons/plus.svg";
import { actions, MedicalCertificateModals } from "../../redux";
import { useAppDispatch } from "store/hooks";
import StatusMessage from "components/StatusMessage/StatusMessage";

const UploadError = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    return (
        <div className="mt-5 gap-2" data-testid="error-uploading-certificate-file">
            <StatusMessage title={t("uploadFailed")} theme="warning" icon="warning" />
            <Card testId="medical-certificate-error">
                <div className="flex gap-2">
                    <TertiaryButton
                        id="upload-doctor-certificate-abort"
                        text={t("abort")}
                        className="w-13"
                        onClick={() => {
                            dispatch(actions.hideModal(MedicalCertificateModals.uploadCertificate));
                            dispatch(actions.resetFileError());
                        }}
                    />
                    <SecondaryButton
                        leftIcon={<PlusIcon />}
                        id="medical-certificate"
                        onClick={() => dispatch(actions.resetFileError())}
                        text={t("tryAgain")}
                    />
                </div>
            </Card>
        </div>
    );
};
export default UploadError;
