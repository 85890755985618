import { IViewElements } from "@medhelp/ui/CheckboxFilter";
import { IDynamicData } from "@medhelp/ui/List/CheckboxList/types";
import DropdownCheckboxFilter from "../DropdownCheckboxFilter";

interface IProps {
    data: IDynamicData[];
    setData: (value: string) => void;
    viewElements: IViewElements;
    keepClose: boolean;
    loading: boolean;
    header: string;
    disabled?: boolean;
}

const DepartmentsDropdown = (props: IProps) => (
    <DropdownCheckboxFilter {...props} variants="fullWidth" data-testid="departments-dropdown-reports" />
);

export default DepartmentsDropdown;
