import { Navigate, Route, Routes } from "react-router-dom";
import AbsencePage from "pages/Absence";
import AbsenceReporting from "pages/Absence/pages/absencereporting/pages/AbsenceReporting";
import { MedicalCertificate } from "@medhelp/medical-certificate";
import AbsenceReportingSearchPage from "pages/Absence/pages/absencereporting/pages/AbsenceReportingSearchPage";
import AbsenceReportingMeCreatePage from "pages/Absence/pages/absencereporting/pages/AbsenceReportingMeCreatePage";
import * as FollowUpPages from "pages/FollowUp";
import DashboardPage from "pages/dashboard/DashboardPage";
import ServicePage from "pages/service/ServicePage";
import AboutPage from "pages/about/About";
import ContactSupport from "pages/Support/pages/ContactSupport";
import HelpGuide from "pages/Support/pages/HelpGuide";
import UserSettings from "pages/UserSettings";
import ProtectedRoute from "routes/ProtectedRoute";
import WorkOrder from "pages/WorkOrder";
import {
    workorderAccess,
    subcontractorAccess,
    caseAccess,
    adminAccess,
    coaAccess,
    fileImportAccess,
    dashboardAccess,
    messageTemplates,
} from "routes";
import Support from "pages/Support";
import PrivacyPolicy from "pages/about/pages/PrivacyPolicy";
import CookiePolicy from "pages/about/pages/CookiePolicy";
import Subcontractors from "pages/about/pages/Subcontractors";
import * as AdministrationPages from "pages/Administration/pages";
import Administration from "pages/Administration";
import Inbox from "pages/Inbox";
import { Custom500 } from "pages/500/500";
import CompanyConfiguration from "pages/service/pages/CompanyConfiguration";
import CustomerConfiguration from "pages/service/pages/CustomerConfiguration";
import GeneralTab from "pages/service/components/Tabs/GeneralTab";
import ServicesTab from "pages/service/components/Tabs/ServicesTab";
import RightsTab from "pages/service/components/Tabs/RightsTab";
import NotificationsTab from "pages/service/components/Tabs/NotificationsTab";
import EventMessages from "pages/Inbox/pages/EventMessages";
import SubscriptionMessages from "pages/Inbox/pages/SubscriptionMessages";
import FileImport from "pages/FileImport/FileImport";
import MessageTemplates from "pages/MessageTemplates/MessageTemplates";
import FortnoxAdministration from "pages/FortnoxAdministration";
import ProductSetting from "pages/Administration/components/ProductSettings/ProductSetting";
import { useAppSelector } from "store/hooks";
import * as UserSelectors from "store/userSelectors";

const AuthenticatedRoutes = () => {
    const userContext = useAppSelector(UserSelectors.getUserContext);
    const getStartRoute = (): string => {
        if (userContext.HasMedicalAdvisory) return "/signedin/absencereporting/search";
        else if (
            userContext.HasCustomerSupport ||
            userContext.HasAbsenceView ||
            userContext.HasAbsenceFollowup ||
            userContext.HasTopLevelAbsenceStatistics
        )
            return "/dashboard";
        else return "/absencereporting";
    };
    return (
        <Routes>
            <Route path="/" element={<Navigate replace to={getStartRoute()} />} />
            <Route path="*" element={<Navigate replace to={getStartRoute()} />} />
            <Route path="/500" element={<Custom500 />} />
            <Route
                path="/dashboard"
                element={
                    <ProtectedRoute accessRightData={dashboardAccess}>
                        <DashboardPage />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/absencereporting"
                element={
                    <ProtectedRoute protectWhenProduct="base">
                        <AbsenceReporting />
                    </ProtectedRoute>
                }
            >
                <Route index element={<AbsencePage />} />
                <Route path="absence" element={<AbsencePage />} />
                <Route path="absence/:routeEmploymentGuid" element={<AbsencePage />} />
                <Route path=":routeEmploymentGuid" element={<AbsencePage />} />
                <Route path="me/create" element={<AbsenceReportingMeCreatePage />} />
                <Route path="medical-certificate" element={<MedicalCertificate />} />
                <Route path="medical-certificate/:routeEmploymentGuid" element={<MedicalCertificate />} />
            </Route>
            <Route path="/followup" element={<FollowUpPages.default />}>
                <Route path="search" element={<FollowUpPages.SearchReports />}>
                    <Route path="reports" element={<FollowUpPages.SearchReports />} />
                </Route>
                <Route path="search/employee" element={<FollowUpPages.SearchEmployee />} />
                <Route path="search/resources" element={<FollowUpPages.Resources />}></Route>
                <Route path="search/absenceCostCalculation" element={<FollowUpPages.AbsenceCostCalculation />} />
                <Route path="employee" element={<FollowUpPages.Employee />}>
                    <Route path=":routeEmploymentGuid" element={<Navigate to="absence" />} />
                    <Route path=":routeEmploymentGuid/absence" element={<FollowUpPages.Absence />} />
                    <Route
                        path=":routeEmploymentGuid/case"
                        element={
                            <ProtectedRoute accessRightData={caseAccess} hideIfOwnPage>
                                <FollowUpPages.Case />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path=":routeEmploymentGuid/case/:routeCaseId"
                        element={
                            <ProtectedRoute accessRightData={caseAccess} hideIfOwnPage>
                                <FollowUpPages.CaseInfo />
                            </ProtectedRoute>
                        }
                    >
                        <Route
                            index
                            element={
                                <ProtectedRoute accessRightData={caseAccess} hideIfOwnPage>
                                    <FollowUpPages.CaseDetails />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="details"
                            element={
                                <ProtectedRoute accessRightData={caseAccess} hideIfOwnPage>
                                    <FollowUpPages.CaseDetails />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="activities"
                            element={
                                <ProtectedRoute accessRightData={caseAccess} hideIfOwnPage>
                                    <FollowUpPages.CaseActivities />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="notes"
                            element={
                                <ProtectedRoute accessRightData={caseAccess} hideIfOwnPage>
                                    <FollowUpPages.CaseNotes />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="documents"
                            element={
                                <ProtectedRoute accessRightData={caseAccess} hideIfOwnPage>
                                    <FollowUpPages.CaseDocuments />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="log"
                            element={
                                <ProtectedRoute accessRightData={caseAccess} hideIfOwnPage>
                                    <FollowUpPages.CaseLog />
                                </ProtectedRoute>
                            }
                        />
                    </Route>
                    <Route
                        path=":routeEmploymentGuid/medical-certificate"
                        element={<FollowUpPages.MedicalCertificate />}
                    />
                    <Route path=":routeEmploymentGuid/subscribe" element={<FollowUpPages.Subscribe />}>
                        <Route index element={<FollowUpPages.Subscriber />} />
                        <Route path="subscriber" element={<FollowUpPages.Subscriber />} />
                        <Route path="subscribes" element={<FollowUpPages.SubscribesTo />} />
                    </Route>
                    <Route path=":routeEmploymentGuid/profile" element={<FollowUpPages.Profile />} />
                </Route>
            </Route>
            <Route
                path="/administration"
                element={
                    <ProtectedRoute accessRightData={adminAccess}>
                        <Administration />
                    </ProtectedRoute>
                }
            >
                <Route path="productsettings" element={<ProductSetting />}></Route>
                <Route path="users" element={<AdministrationPages.Users />}>
                    <Route index element={<AdministrationPages.AddUser />} />
                    <Route path="addUser" element={<AdministrationPages.AddUser />}>
                        <Route index element={<AdministrationPages.User />} />
                        <Route path="user" element={<AdministrationPages.User />} />
                        <Route path="employment" element={<AdministrationPages.Employment />} />
                        <Route path="subscriptions" element={<AdministrationPages.Subscriptions />} />
                        <Route path="accessrights" element={<AdministrationPages.AccessRights />} />
                        <Route path="logins" element={<AdministrationPages.Logins />} />
                    </Route>

                    <Route path="erasureFinished" element={<AdministrationPages.ErasureFinished />}>
                        <Route path="settings" element={<AdministrationPages.ErasureFinishedSettings />} />
                    </Route>
                    <Route path="subscriptions" element={<AdministrationPages.Subscriptions />} />
                    <Route path="accessrights" element={<AdministrationPages.AccessRights />} />
                    <Route
                        path="erasureActive"
                        element={
                            <ProtectedRoute accessRightData={coaAccess}>
                                <AdministrationPages.ErasureActive />
                            </ProtectedRoute>
                        }
                    >
                        <Route index element={<AdministrationPages.ErasureActiveSettings />} />
                        <Route path="settings" element={<AdministrationPages.ErasureActiveSettings />} />
                    </Route>
                </Route>
                <Route path="settings" element={<AdministrationPages.Settings />}>
                    <Route index element={<AdministrationPages.RoleSubscriptionsSettings />} />
                    <Route path="roleSubscriptions" element={<AdministrationPages.RoleSubscriptionsSettings />} />
                </Route>
            </Route>
            <Route path="/fortnoxAdministration" element={<FortnoxAdministration />} />

            {/* Routing for SSK / Medical Advisory Web */}
            <Route path="/signedin" element={<AbsenceReporting />}>
                <Route path="absencereporting/employee/:routeEmploymentGuid" element={<AbsencePage />} />
            </Route>
            <Route path="/signedin/absencereporting/search" element={<AbsenceReportingSearchPage />} />
            <Route path="service" element={<ServicePage />}>
                <Route path="" element={<Navigate to="company/general" />} />
                <Route path="company" element={<CompanyConfiguration />}>
                    <Route index element={<GeneralTab />} />
                    <Route path="general" element={<GeneralTab />} />
                    <Route path="services" element={<ServicesTab />} />
                    <Route path="services/:customerId/:companyId" element={<ServicesTab />} />
                    <Route path="accessRights" element={<RightsTab />} />
                    <Route path="notifications" element={<NotificationsTab />} />
                </Route>

                <Route path="customer" element={<CustomerConfiguration />} />
            </Route>
            <Route path="/support" element={<Support />}>
                <Route index element={<HelpGuide />} />
                <Route path="helpGuide" element={<HelpGuide />} />
                <Route path="contactSupport" element={<ContactSupport />} />
            </Route>
            <Route path="about" element={<AboutPage />}>
                <Route index element={<PrivacyPolicy />} />
                <Route path="privacyPolicy" element={<PrivacyPolicy />} />
                <Route path="cookiePolicy" element={<CookiePolicy />} />
                <Route
                    path="subcontractors"
                    element={
                        <ProtectedRoute accessRightData={subcontractorAccess}>
                            <Subcontractors />
                        </ProtectedRoute>
                    }
                />
            </Route>
            <Route path="inbox" element={<Inbox />}>
                <Route index element={<EventMessages />} />
                <Route path="eventMessages" element={<EventMessages />} />
                <Route path="subscriptionsMessages" element={<SubscriptionMessages />} />
            </Route>
            <Route path="/userSettings" element={<UserSettings />} />
            <Route
                path="/workorder"
                element={
                    <ProtectedRoute accessRightData={workorderAccess}>
                        <WorkOrder />
                    </ProtectedRoute>
                }
            />
            <Route
                path="fileImport"
                element={
                    <ProtectedRoute accessRightData={fileImportAccess}>
                        <FileImport />
                    </ProtectedRoute>
                }
            />
            <Route
                path="messageTemplates"
                element={
                    <ProtectedRoute accessRightData={messageTemplates}>
                        <MessageTemplates />
                    </ProtectedRoute>
                }
            />
        </Routes>
    );
};

export default AuthenticatedRoutes;
