import { IAccessRightTypeEnum } from "interfaces/IAccountAccessRights";
import { IAccessRightData } from "domain/accessRights";
export * from "./helpers";
export const workorderAccess: IAccessRightData[] = [
    { value: IAccessRightTypeEnum.customerSupport, top: false },
    { value: IAccessRightTypeEnum.medicalAdvice, top: false },
    { value: IAccessRightTypeEnum.medicalAdvising, top: false },
];

export const caseAccess: IAccessRightData[] = [
    { value: IAccessRightTypeEnum.customerSupport, top: false },
    { value: IAccessRightTypeEnum.rehabDepartmentManagement, top: false },
];

export const subcontractorAccess: IAccessRightData[] = [
    { value: IAccessRightTypeEnum.customerSupport, top: false },
    { value: IAccessRightTypeEnum.customerCompanyAdministration, top: false },
];
export const adminAccess: IAccessRightData[] = [
    { value: IAccessRightTypeEnum.customerSupport, top: false },
    { value: IAccessRightTypeEnum.customerCompanyAdministration, top: false },
    { value: IAccessRightTypeEnum.departmentAdministration, top: false },
];
export const coaAccess: IAccessRightData[] = [
    { value: IAccessRightTypeEnum.customerSupport, top: false },
    {
        value: IAccessRightTypeEnum.customerOrganizationAdministration,
        top: false,
    },
];

export const fileImportAccess: IAccessRightData[] = [
    { value: IAccessRightTypeEnum.customerSupport, top: false },
    { value: IAccessRightTypeEnum.fileImportAdministration, top: false },
];

export const messageTemplates: IAccessRightData[] = [
    { value: IAccessRightTypeEnum.customerSupport, top: false },
    { value: IAccessRightTypeEnum.customerOrganizationAdministration, top: false },
    { value: IAccessRightTypeEnum.customerCompanyAdministration, top: false },
];

export const dashboardAccess: IAccessRightData[] = [
    { value: IAccessRightTypeEnum.customerSupport, top: false },
    { value: IAccessRightTypeEnum.absenceView, top: false },
    { value: IAccessRightTypeEnum.absenceFollowup, top: false },
    { value: IAccessRightTypeEnum.absenceStatistics, top: true },
];
