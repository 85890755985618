import { useCallback, useEffect, useState } from "react";
import { getSelectedCompanyId, selectLoggedInUserCompanies } from "pages/Administration/redux/administrationSelectors";
import { Card, CardWithExpand } from "@medhelp/ui";
import { ISelectValue } from "components/Select";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import NoteSettingsComponent from "../NoteSettingsComponent/NoteSettingsComponent";
import PrimaryButton from "components/PrimaryButton/PrimaryButton";
import { getCustomerId, getUserContext, getUserRetailer } from "store/userSelectors";
import { fetchHCMTemplates } from "pages/Administration/redux/administrationAsyncActions";
import { selectHCMTemplatesData } from "pages/dashboard/redux/dashboardSelectors";
import HealthCaseTable, { MyActivityTemplate } from "../HealthCaseTable/HealthCaseTable";
import Icon from "components/Icon";
import { administrationActions } from "pages/Administration/redux/administrationSlice";
import SelectWithSearchParams from "@medhelp/ui/SearchSelector/SelectWithSearchParams";
import { getCompaniesForUser } from "pages/Administration/redux/administrationAsyncActions";
import { MedHelpHCMDomainTemplate } from "swagger/healthCaseManagement/api";
import InfoBox from "components/InfoBox";

function ProductSetting() {
    const dispatch = useAppDispatch();
    const customerId = useAppSelector(getCustomerId);
    const retailerName = useAppSelector(getUserRetailer);
    const stateSelectedCompanyId = useAppSelector(getSelectedCompanyId);
    const userContext = useAppSelector(getUserContext);
    const companies = useAppSelector(selectLoggedInUserCompanies);
    const HCMTemplatesData = useAppSelector(selectHCMTemplatesData);
    const { t } = useTranslation("administration");
    const primaryCompanyId = userContext.companyEmployment?.primaryCompany?.id;

    const [selectedValue, setSelectedValue] = useState<ISelectValue>();

    // Determine company options and selected company based on fetched data or fallback
    const companyOption: ISelectValue[] =
        companies?.map((company) => ({
            description: company.name || "",
            value: company.id?.toString() || "",
        })) || [];

    useEffect(() => {
        if (stateSelectedCompanyId) {
            const selectedCompany = companyOption.find((option) => option.value === stateSelectedCompanyId.toString());
            setSelectedValue(selectedCompany);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateSelectedCompanyId]); //TODO: remove whole useEffect when fetching templateReasons.

    const companyOnChange = useCallback(
        (value: ISelectValue) => {
            const companyId = Number(value.value);
            dispatch(administrationActions.setSelectedCompanyId(companyId)); //TODO: Remove this and replace it with fetchTemplateReasons
            setSelectedValue(value);
        },
        [dispatch],
    );

    useEffect(() => {
        if (customerId && companies?.length === 0) {
            dispatch(fetchHCMTemplates({ customerId, language: "sv" }));
            dispatch(getCompaniesForUser({ companyId: primaryCompanyId }));
        }
    }, [dispatch, customerId, companies, primaryCompanyId]);

    const filteredHCMTemplatesData: MedHelpHCMDomainTemplate[] = HCMTemplatesData.filter(
        (item) => item.companyId === stateSelectedCompanyId,
    ); //TODO: replace stateSelectedCompanyId with selectedValue

    return (
        <div data-testid="productsettings">
            <div className="pt-10">
                <InfoBox translatedText={t("manageSettingsDescription")} />
            </div>
            <div className="my-16 w-full px-6 lg:w-2/5 lg:px-0">
                <h2 className="pb-2 text-base">{t("company")}</h2>
                <SelectWithSearchParams
                    options={companyOption}
                    onChange={companyOnChange}
                    placeholder={t("selectCompany")}
                    selectedValue={selectedValue}
                />
            </div>
            <div className="mx-6 bg-widgetBeige shadow lg:mx-0">
                {selectedValue ? (
                    <CardWithExpand
                        index={0}
                        headerView={
                            <div className="w-full lg:w-3/5">
                                <h1 className="text-2xl">{t("caseActivityTemplate")}</h1>
                                <p className="pt-4 text-base">{t("caseActivityTemplateInfo")}</p>
                            </div>
                        }
                        initiallyExpanded={true}
                    >
                        <>
                            <div className="flex flex-col lg:block">
                                <NoteSettingsComponent partner={retailerName} />
                                <PrimaryButton
                                    id={"add-activity-button"}
                                    text={"+ " + t("addActivityButton")}
                                    styleText="flex justify-center"
                                />
                            </div>

                            <div className="mb-6">
                                {filteredHCMTemplatesData.map((item: MedHelpHCMDomainTemplate) => (
                                    <HealthCaseTable
                                        key={item.id}
                                        heading={t(item?.healthCaseType ?? "")}
                                        cases={item as unknown as MyActivityTemplate}
                                        companyName={selectedValue.description}
                                        partner={retailerName}
                                    />
                                ))}
                            </div>
                        </>
                    </CardWithExpand>
                ) : (
                    <Card
                        className="bg-widgetBeige"
                        children={
                            <div className="flex w-full rounded-lg border border-solid border-grey-200 px-4 py-6 lg:p-6">
                                <Icon
                                    icon="info"
                                    size={24}
                                    fill="transparent"
                                    color="black"
                                    style={{ minWidth: "24px", minHeight: "24px" }}
                                />
                                <p className="px-4 text-sm">{t("selectCompanyInfoBox")}</p>
                            </div>
                        }
                    />
                )}
            </div>
        </div>
    );
}

export default ProductSetting;
