import { businessSupportBaseApi as api } from "./businessSupportBaseApi";
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getApiAccessRightConfiguration: build.query<
            GetApiAccessRightConfigurationApiResponse,
            GetApiAccessRightConfigurationApiArg
        >({
            query: (queryArg) => ({
                url: `/api/AccessRightConfiguration`,
                params: {
                    customerId: queryArg.customerId,
                    companyId: queryArg.companyId,
                },
            }),
        }),
        postApiAccessRightConfiguration: build.mutation<
            PostApiAccessRightConfigurationApiResponse,
            PostApiAccessRightConfigurationApiArg
        >({
            query: (queryArg) => ({
                url: `/api/AccessRightConfiguration`,
                method: "POST",
                body: queryArg.accessRightConfigurationPostModel,
            }),
        }),
        postApiAccessRightConfigurationDelete: build.mutation<
            PostApiAccessRightConfigurationDeleteApiResponse,
            PostApiAccessRightConfigurationDeleteApiArg
        >({
            query: (queryArg) => ({
                url: `/api/AccessRightConfiguration/Delete`,
                method: "POST",
                body: queryArg.accessRightConfigurationPostModel,
            }),
        }),
        getApiBusinessSupportPerformedServicesForCompany: build.query<
            GetApiBusinessSupportPerformedServicesForCompanyApiResponse,
            GetApiBusinessSupportPerformedServicesForCompanyApiArg
        >({
            query: (queryArg) => ({
                url: `/api/BusinessSupport/PerformedServicesForCompany`,
                params: {
                    companyId: queryArg.companyId,
                    rangeStart: queryArg.rangeStart,
                    rangeEnd: queryArg.rangeEnd,
                },
            }),
        }),
        postApiBusinessSupportSaveServiceContract: build.mutation<
            PostApiBusinessSupportSaveServiceContractApiResponse,
            PostApiBusinessSupportSaveServiceContractApiArg
        >({
            query: (queryArg) => ({
                url: `/api/BusinessSupport/SaveServiceContract`,
                method: "POST",
                body: queryArg.serviceContractModel,
            }),
        }),
        getApiBusinessSupportGetCompanyServiceContracts: build.query<
            GetApiBusinessSupportGetCompanyServiceContractsApiResponse,
            GetApiBusinessSupportGetCompanyServiceContractsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/BusinessSupport/GetCompanyServiceContracts`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiBusinessSupportGetAbsenceFollowUpConfiguration: build.query<
            GetApiBusinessSupportGetAbsenceFollowUpConfigurationApiResponse,
            GetApiBusinessSupportGetAbsenceFollowUpConfigurationApiArg
        >({
            query: (queryArg) => ({
                url: `/api/BusinessSupport/GetAbsenceFollowUpConfiguration`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiBusinessSupportGetCompanyInfo: build.query<
            GetApiBusinessSupportGetCompanyInfoApiResponse,
            GetApiBusinessSupportGetCompanyInfoApiArg
        >({
            query: (queryArg) => ({
                url: `/api/BusinessSupport/GetCompanyInfo`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiBusinessSupportGetCompanyMainDetailList: build.query<
            GetApiBusinessSupportGetCompanyMainDetailListApiResponse,
            GetApiBusinessSupportGetCompanyMainDetailListApiArg
        >({
            query: (queryArg) => ({
                url: `/api/BusinessSupport/GetCompanyMainDetailList`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiBusinessSupportGetGroupDescription: build.query<
            GetApiBusinessSupportGetGroupDescriptionApiResponse,
            GetApiBusinessSupportGetGroupDescriptionApiArg
        >({
            query: (queryArg) => ({
                url: `/api/BusinessSupport/GetGroupDescription`,
                params: {
                    companyId: queryArg.companyId,
                    groupName: queryArg.groupName,
                },
            }),
        }),
        getApiBusinessSupportGetRehabConfiguration: build.query<
            GetApiBusinessSupportGetRehabConfigurationApiResponse,
            GetApiBusinessSupportGetRehabConfigurationApiArg
        >({
            query: (queryArg) => ({
                url: `/api/BusinessSupport/GetRehabConfiguration`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiBusinessSupportGetRehabTemplatesConfiguration: build.query<
            GetApiBusinessSupportGetRehabTemplatesConfigurationApiResponse,
            GetApiBusinessSupportGetRehabTemplatesConfigurationApiArg
        >({
            query: (queryArg) => ({
                url: `/api/BusinessSupport/GetRehabTemplatesConfiguration`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        postApiBusinessSupportSaveRehabTemplateConfiguration: build.mutation<
            PostApiBusinessSupportSaveRehabTemplateConfigurationApiResponse,
            PostApiBusinessSupportSaveRehabTemplateConfigurationApiArg
        >({
            query: (queryArg) => ({
                url: `/api/BusinessSupport/SaveRehabTemplateConfiguration`,
                method: "POST",
                body: queryArg.rehabTemplatesConfiguration,
            }),
        }),
        postApiBusinessSupportSaveAbsenceFollowUpConfiguration: build.mutation<
            PostApiBusinessSupportSaveAbsenceFollowUpConfigurationApiResponse,
            PostApiBusinessSupportSaveAbsenceFollowUpConfigurationApiArg
        >({
            query: (queryArg) => ({
                url: `/api/BusinessSupport/SaveAbsenceFollowUpConfiguration`,
                method: "POST",
                body: queryArg.absenceFollowUpConfiguration,
            }),
        }),
        postApiBusinessSupportSaveRehabConfiguration: build.mutation<
            PostApiBusinessSupportSaveRehabConfigurationApiResponse,
            PostApiBusinessSupportSaveRehabConfigurationApiArg
        >({
            query: (queryArg) => ({
                url: `/api/BusinessSupport/SaveRehabConfiguration`,
                method: "POST",
                body: queryArg.rehabConfiguration,
            }),
        }),
        getApiBusinessSupportGetWorkOrderConfiguration: build.query<
            GetApiBusinessSupportGetWorkOrderConfigurationApiResponse,
            GetApiBusinessSupportGetWorkOrderConfigurationApiArg
        >({
            query: (queryArg) => ({
                url: `/api/BusinessSupport/GetWorkOrderConfiguration`,
                params: {
                    customerId: queryArg.customerId,
                    companyId: queryArg.companyId,
                },
            }),
        }),
        putApiBusinessSupportSaveWorkOrderConfiguration: build.mutation<
            PutApiBusinessSupportSaveWorkOrderConfigurationApiResponse,
            PutApiBusinessSupportSaveWorkOrderConfigurationApiArg
        >({
            query: (queryArg) => ({
                url: `/api/BusinessSupport/SaveWorkOrderConfiguration`,
                method: "PUT",
                body: queryArg.saveWorkOrderConfigurationModel,
            }),
        }),
        putApiBusinessSupportSaveFollowupConfiguration: build.mutation<
            PutApiBusinessSupportSaveFollowupConfigurationApiResponse,
            PutApiBusinessSupportSaveFollowupConfigurationApiArg
        >({
            query: (queryArg) => ({
                url: `/api/BusinessSupport/SaveFollowupConfiguration`,
                method: "PUT",
                body: queryArg.saveFollowUpAdvisoryConfigurationModel,
            }),
        }),
        postApiBusinessSupportSaveCompanyInfo: build.mutation<
            PostApiBusinessSupportSaveCompanyInfoApiResponse,
            PostApiBusinessSupportSaveCompanyInfoApiArg
        >({
            query: (queryArg) => ({
                url: `/api/BusinessSupport/SaveCompanyInfo`,
                method: "POST",
                body: queryArg.companyInfoModel,
            }),
        }),
        postApiBusinessSupportSaveCustomerCompany: build.mutation<
            PostApiBusinessSupportSaveCustomerCompanyApiResponse,
            PostApiBusinessSupportSaveCustomerCompanyApiArg
        >({
            query: (queryArg) => ({
                url: `/api/BusinessSupport/SaveCustomerCompany`,
                method: "POST",
                body: queryArg.customerCompanyInfo,
            }),
        }),
        postApiBusinessSupportSaveCompany: build.mutation<
            PostApiBusinessSupportSaveCompanyApiResponse,
            PostApiBusinessSupportSaveCompanyApiArg
        >({
            query: (queryArg) => ({
                url: `/api/BusinessSupport/SaveCompany`,
                method: "POST",
                body: queryArg.companyModel,
            }),
        }),
        postApiBusinessSupportGetBillableErpProductsByRetailIds: build.mutation<
            PostApiBusinessSupportGetBillableErpProductsByRetailIdsApiResponse,
            PostApiBusinessSupportGetBillableErpProductsByRetailIdsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/BusinessSupport/GetBillableErpProductsByRetailIds`,
                method: "POST",
                body: queryArg.retailerIdsPostModel,
            }),
        }),
        postApiBusinessSupportSaveBillableErpProduct: build.mutation<
            PostApiBusinessSupportSaveBillableErpProductApiResponse,
            PostApiBusinessSupportSaveBillableErpProductApiArg
        >({
            query: (queryArg) => ({
                url: `/api/BusinessSupport/SaveBillableErpProduct`,
                method: "POST",
                body: queryArg.billableErpProduct,
            }),
        }),
        getApiBusinessSupportGetAllHealthServices: build.query<
            GetApiBusinessSupportGetAllHealthServicesApiResponse,
            GetApiBusinessSupportGetAllHealthServicesApiArg
        >({
            query: () => ({ url: `/api/BusinessSupport/GetAllHealthServices` }),
        }),
        postApiBusinessSupportSearchCompaniesByNameOrOrgNr: build.mutation<
            PostApiBusinessSupportSearchCompaniesByNameOrOrgNrApiResponse,
            PostApiBusinessSupportSearchCompaniesByNameOrOrgNrApiArg
        >({
            query: (queryArg) => ({
                url: `/api/BusinessSupport/SearchCompaniesByNameOrOrgNr`,
                method: "POST",
                body: queryArg.searchCompanyByNameOrOrgNrModel,
            }),
        }),
        postApiBusinessSupportSearchCompany: build.mutation<
            PostApiBusinessSupportSearchCompanyApiResponse,
            PostApiBusinessSupportSearchCompanyApiArg
        >({
            query: (queryArg) => ({
                url: `/api/BusinessSupport/SearchCompany`,
                method: "POST",
                body: queryArg.searchCompanyModel,
            }),
        }),
        getApiBusinessSupportGetEmploymentGroups: build.query<
            GetApiBusinessSupportGetEmploymentGroupsApiResponse,
            GetApiBusinessSupportGetEmploymentGroupsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/BusinessSupport/GetEmploymentGroups`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        postApiBusinessSupportSaveEmploymentGroups: build.mutation<
            PostApiBusinessSupportSaveEmploymentGroupsApiResponse,
            PostApiBusinessSupportSaveEmploymentGroupsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/BusinessSupport/SaveEmploymentGroups`,
                method: "POST",
                body: queryArg.employmentGroupsRequest,
            }),
        }),
        postApiBusinessSupportDeleteEmploymentGroups: build.mutation<
            PostApiBusinessSupportDeleteEmploymentGroupsApiResponse,
            PostApiBusinessSupportDeleteEmploymentGroupsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/BusinessSupport/DeleteEmploymentGroups`,
                method: "POST",
                body: queryArg.employmentGroupsRequest,
            }),
        }),
        postApiBusinessSupportUpdateCompany: build.mutation<
            PostApiBusinessSupportUpdateCompanyApiResponse,
            PostApiBusinessSupportUpdateCompanyApiArg
        >({
            query: (queryArg) => ({
                url: `/api/BusinessSupport/UpdateCompany`,
                method: "POST",
                body: queryArg.companyModel,
            }),
        }),
        postApiBusinessSupportUpdateCustomerCompanyDetails: build.mutation<
            PostApiBusinessSupportUpdateCustomerCompanyDetailsApiResponse,
            PostApiBusinessSupportUpdateCustomerCompanyDetailsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/BusinessSupport/UpdateCustomerCompanyDetails`,
                method: "POST",
                body: queryArg.companyModel,
            }),
        }),
        getApiBusinessSupportGetLoginConfiguration: build.query<
            GetApiBusinessSupportGetLoginConfigurationApiResponse,
            GetApiBusinessSupportGetLoginConfigurationApiArg
        >({
            query: (queryArg) => ({
                url: `/api/BusinessSupport/GetLoginConfiguration`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        postApiBusinessSupportSaveLoginConfiguration: build.mutation<
            PostApiBusinessSupportSaveLoginConfigurationApiResponse,
            PostApiBusinessSupportSaveLoginConfigurationApiArg
        >({
            query: (queryArg) => ({
                url: `/api/BusinessSupport/SaveLoginConfiguration`,
                method: "POST",
                body: queryArg.loginConfiguration,
            }),
        }),
        getApiBusinessSupportGetRegions: build.query<
            GetApiBusinessSupportGetRegionsApiResponse,
            GetApiBusinessSupportGetRegionsApiArg
        >({
            query: () => ({ url: `/api/BusinessSupport/GetRegions` }),
        }),
        putApiBusinessSupportUpdateCompanyRegion: build.mutation<
            PutApiBusinessSupportUpdateCompanyRegionApiResponse,
            PutApiBusinessSupportUpdateCompanyRegionApiArg
        >({
            query: (queryArg) => ({
                url: `/api/BusinessSupport/UpdateCompanyRegion`,
                method: "PUT",
                params: {
                    companyId: queryArg.companyId,
                    countryCode: queryArg.countryCode,
                    language: queryArg.language,
                    currency: queryArg.currency,
                },
            }),
        }),
        getApiCustomerGetCompaniesByCustomerId: build.query<
            GetApiCustomerGetCompaniesByCustomerIdApiResponse,
            GetApiCustomerGetCompaniesByCustomerIdApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Customer/GetCompaniesByCustomerId`,
                params: {
                    customerId: queryArg.customerId,
                },
            }),
        }),
        postApiCustomerGetCompaniesByCustomerIds: build.mutation<
            PostApiCustomerGetCompaniesByCustomerIdsApiResponse,
            PostApiCustomerGetCompaniesByCustomerIdsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Customer/GetCompaniesByCustomerIds`,
                method: "POST",
                body: queryArg.customersModel,
            }),
        }),
        getApiCustomerCustomers: build.query<GetApiCustomerCustomersApiResponse, GetApiCustomerCustomersApiArg>({
            query: () => ({ url: `/api/Customer/Customers` }),
        }),
        getApiCustomerGetCustomers: build.query<
            GetApiCustomerGetCustomersApiResponse,
            GetApiCustomerGetCustomersApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Customer/GetCustomers`,
                params: {
                    dateFrom: queryArg.dateFrom,
                    dateTo: queryArg.dateTo,
                    retailerId: queryArg.retailerId,
                },
            }),
        }),
        getApiCustomerCustomersByRetailer: build.query<
            GetApiCustomerCustomersByRetailerApiResponse,
            GetApiCustomerCustomersByRetailerApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Customer/CustomersByRetailer`,
                params: {
                    retailerId: queryArg.retailerId,
                },
            }),
        }),
        postApiCustomerSaveBillingPostConfiguration: build.mutation<
            PostApiCustomerSaveBillingPostConfigurationApiResponse,
            PostApiCustomerSaveBillingPostConfigurationApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Customer/SaveBillingPostConfiguration`,
                method: "POST",
                body: queryArg.billingPostConfigurationPostModel,
            }),
        }),
        postApiCustomerSaveCustomerBilling: build.mutation<
            PostApiCustomerSaveCustomerBillingApiResponse,
            PostApiCustomerSaveCustomerBillingApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Customer/SaveCustomerBilling`,
                method: "POST",
                body: queryArg.billingConfiguration,
            }),
        }),
        postApiCustomerSaveBillingPostConfigurations: build.mutation<
            PostApiCustomerSaveBillingPostConfigurationsApiResponse,
            PostApiCustomerSaveBillingPostConfigurationsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Customer/SaveBillingPostConfigurations`,
                method: "POST",
                body: queryArg.billingPosts,
            }),
        }),
        postApiCustomerSaveBillingConfiguration: build.mutation<
            PostApiCustomerSaveBillingConfigurationApiResponse,
            PostApiCustomerSaveBillingConfigurationApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Customer/SaveBillingConfiguration`,
                method: "POST",
                body: queryArg.inboundCustomerBillingConfiguration,
            }),
        }),
        postApiCustomerSaveCustomerInfo: build.mutation<
            PostApiCustomerSaveCustomerInfoApiResponse,
            PostApiCustomerSaveCustomerInfoApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Customer/SaveCustomerInfo`,
                method: "POST",
                body: queryArg.customerInfo,
            }),
        }),
        postApiCustomerDeactivateCompany: build.mutation<
            PostApiCustomerDeactivateCompanyApiResponse,
            PostApiCustomerDeactivateCompanyApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Customer/DeactivateCompany`,
                method: "POST",
                body: queryArg.companyDeactivationContract,
            }),
        }),
        getApiOnboardingSearchCustomersAndCompanies: build.query<
            GetApiOnboardingSearchCustomersAndCompaniesApiResponse,
            GetApiOnboardingSearchCustomersAndCompaniesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Onboarding/SearchCustomersAndCompanies`,
                params: {
                    searchString: queryArg.searchString,
                },
            }),
        }),
        getApiOnboardingGetCustomerInfo: build.query<
            GetApiOnboardingGetCustomerInfoApiResponse,
            GetApiOnboardingGetCustomerInfoApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Onboarding/GetCustomerInfo`,
                params: {
                    companyId: queryArg.companyId,
                    customerId: queryArg.customerId,
                },
            }),
        }),
        getApiOnboardingGetOrganizationInfo: build.query<
            GetApiOnboardingGetOrganizationInfoApiResponse,
            GetApiOnboardingGetOrganizationInfoApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Onboarding/GetOrganizationInfo`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiOnboardingGetEmploymentAbsenceInfo: build.query<
            GetApiOnboardingGetEmploymentAbsenceInfoApiResponse,
            GetApiOnboardingGetEmploymentAbsenceInfoApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Onboarding/GetEmploymentAbsenceInfo`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiOnboardingGetUserInfo: build.query<
            GetApiOnboardingGetUserInfoApiResponse,
            GetApiOnboardingGetUserInfoApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Onboarding/GetUserInfo`,
                params: {
                    companyId: queryArg.companyId,
                    customerId: queryArg.customerId,
                },
            }),
        }),
        getApiOnboardingGetWorkOrderInfo: build.query<
            GetApiOnboardingGetWorkOrderInfoApiResponse,
            GetApiOnboardingGetWorkOrderInfoApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Onboarding/GetWorkOrderInfo`,
                params: {
                    companyId: queryArg.companyId,
                    customerId: queryArg.customerId,
                },
            }),
        }),
        getApiOnboardingGetServiceUtilizationInfo: build.query<
            GetApiOnboardingGetServiceUtilizationInfoApiResponse,
            GetApiOnboardingGetServiceUtilizationInfoApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Onboarding/GetServiceUtilizationInfo`,
                params: {
                    companyId: queryArg.companyId,
                    customerId: queryArg.customerId,
                },
            }),
        }),
        getApiOnboardingGetRehabConfig: build.query<
            GetApiOnboardingGetRehabConfigApiResponse,
            GetApiOnboardingGetRehabConfigApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Onboarding/GetRehabConfig`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiOnboardingGetRehabTemplates: build.query<
            GetApiOnboardingGetRehabTemplatesApiResponse,
            GetApiOnboardingGetRehabTemplatesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Onboarding/GetRehabTemplates`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiOnboardingGetFileImportConfig: build.query<
            GetApiOnboardingGetFileImportConfigApiResponse,
            GetApiOnboardingGetFileImportConfigApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Onboarding/GetFileImportConfig`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiOnboardingGetServiceContracts: build.query<
            GetApiOnboardingGetServiceContractsApiResponse,
            GetApiOnboardingGetServiceContractsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Onboarding/GetServiceContracts`,
                params: {
                    customerId: queryArg.customerId,
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiOnboardingGetNotificationConfiguration: build.query<
            GetApiOnboardingGetNotificationConfigurationApiResponse,
            GetApiOnboardingGetNotificationConfigurationApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Onboarding/GetNotificationConfiguration`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        postApiOnboardingSaveNotificationConfiguration: build.mutation<
            PostApiOnboardingSaveNotificationConfigurationApiResponse,
            PostApiOnboardingSaveNotificationConfigurationApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Onboarding/SaveNotificationConfiguration`,
                method: "POST",
                body: queryArg.notificationConfigurationModel,
            }),
        }),
        getApiOnboardingGetCompanyConfiguration: build.query<
            GetApiOnboardingGetCompanyConfigurationApiResponse,
            GetApiOnboardingGetCompanyConfigurationApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Onboarding/GetCompanyConfiguration`,
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        postApiOnboardingSaveCompanyConfiguration: build.mutation<
            PostApiOnboardingSaveCompanyConfigurationApiResponse,
            PostApiOnboardingSaveCompanyConfigurationApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Onboarding/SaveCompanyConfiguration`,
                method: "POST",
                body: queryArg.companyConfigurationModel,
            }),
        }),
        deleteApiOnboardingDeleteCompanyConfiguration: build.mutation<
            DeleteApiOnboardingDeleteCompanyConfigurationApiResponse,
            DeleteApiOnboardingDeleteCompanyConfigurationApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Onboarding/DeleteCompanyConfiguration`,
                method: "DELETE",
                params: {
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiOnboardingGetCustomerCompanyAdvisoryServices: build.query<
            GetApiOnboardingGetCustomerCompanyAdvisoryServicesApiResponse,
            GetApiOnboardingGetCustomerCompanyAdvisoryServicesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Onboarding/GetCustomerCompanyAdvisoryServices`,
                params: {
                    customerId: queryArg.customerId,
                    companyId: queryArg.companyId,
                },
            }),
        }),
        getApiOnboardingGetAdvisoryServices: build.query<
            GetApiOnboardingGetAdvisoryServicesApiResponse,
            GetApiOnboardingGetAdvisoryServicesApiArg
        >({
            query: () => ({ url: `/api/Onboarding/GetAdvisoryServices` }),
        }),
        postApiOnboardingSaveCustomerCompanyAdvisoryService: build.mutation<
            PostApiOnboardingSaveCustomerCompanyAdvisoryServiceApiResponse,
            PostApiOnboardingSaveCustomerCompanyAdvisoryServiceApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Onboarding/SaveCustomerCompanyAdvisoryService`,
                method: "POST",
                body: queryArg.customerCompanyAdvisoryService,
            }),
        }),
        postApiOnboardingDeleteEmploymentGroups: build.mutation<
            PostApiOnboardingDeleteEmploymentGroupsApiResponse,
            PostApiOnboardingDeleteEmploymentGroupsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Onboarding/DeleteEmploymentGroups`,
                method: "POST",
                body: queryArg.deleteEmploymentGroupsModel,
            }),
        }),
        postApiOnboardingSaveEmploymentGroups: build.mutation<
            PostApiOnboardingSaveEmploymentGroupsApiResponse,
            PostApiOnboardingSaveEmploymentGroupsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Onboarding/SaveEmploymentGroups`,
                method: "POST",
                body: queryArg.saveEmploymentGroupsModel,
            }),
        }),
        postApiOnboardingUpdateCompany: build.mutation<
            PostApiOnboardingUpdateCompanyApiResponse,
            PostApiOnboardingUpdateCompanyApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Onboarding/UpdateCompany`,
                method: "POST",
                body: queryArg.updateCompanyModel,
            }),
        }),
        getApiOnboardingGetCustomer: build.query<
            GetApiOnboardingGetCustomerApiResponse,
            GetApiOnboardingGetCustomerApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Onboarding/GetCustomer`,
                params: {
                    customerId: queryArg.customerId,
                },
            }),
        }),
        postApiOnboardingUpdateCustomerProduct: build.mutation<
            PostApiOnboardingUpdateCustomerProductApiResponse,
            PostApiOnboardingUpdateCustomerProductApiArg
        >({
            query: (queryArg) => ({
                url: `/api/Onboarding/UpdateCustomerProduct`,
                method: "POST",
                body: queryArg.customerProductModel,
            }),
        }),
        getApiStatus: build.query<GetApiStatusApiResponse, GetApiStatusApiArg>({
            query: () => ({ url: `/api/Status` }),
        }),
        getApiStatusInformation: build.query<GetApiStatusInformationApiResponse, GetApiStatusInformationApiArg>({
            query: () => ({ url: `/api/Status/Information` }),
        }),
        getApiSupportToolGetPerformedByInfos: build.query<
            GetApiSupportToolGetPerformedByInfosApiResponse,
            GetApiSupportToolGetPerformedByInfosApiArg
        >({
            query: (queryArg) => ({
                url: `/api/SupportTool/GetPerformedByInfos`,
                params: {
                    userAccountIds: queryArg.userAccountIds,
                },
            }),
        }),
        postApiSupportToolGetEmploymentInfos: build.mutation<
            PostApiSupportToolGetEmploymentInfosApiResponse,
            PostApiSupportToolGetEmploymentInfosApiArg
        >({
            query: (queryArg) => ({
                url: `/api/SupportTool/GetEmploymentInfos`,
                method: "POST",
                body: queryArg.employmentInfosModel,
            }),
        }),
        getApiSupportToolGetAllCustomers: build.query<
            GetApiSupportToolGetAllCustomersApiResponse,
            GetApiSupportToolGetAllCustomersApiArg
        >({
            query: () => ({ url: `/api/SupportTool/GetAllCustomers` }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as businessSupportApi };
export type GetApiAccessRightConfigurationApiResponse = unknown;
export type GetApiAccessRightConfigurationApiArg = {
    customerId?: number;
    companyId?: number;
};
export type PostApiAccessRightConfigurationApiResponse = unknown;
export type PostApiAccessRightConfigurationApiArg = {
    accessRightConfigurationPostModel: AccessRightConfigurationPostModel;
};
export type PostApiAccessRightConfigurationDeleteApiResponse = unknown;
export type PostApiAccessRightConfigurationDeleteApiArg = {
    accessRightConfigurationPostModel: AccessRightConfigurationPostModel;
};
export type GetApiBusinessSupportPerformedServicesForCompanyApiResponse =
    /** status 200 Success */ PerformedServiceModel[];
export type GetApiBusinessSupportPerformedServicesForCompanyApiArg = {
    companyId?: number;
    rangeStart?: string;
    rangeEnd?: string;
};
export type PostApiBusinessSupportSaveServiceContractApiResponse = /** status 200 Success */ number;
export type PostApiBusinessSupportSaveServiceContractApiArg = {
    serviceContractModel: ServiceContractModel;
};
export type GetApiBusinessSupportGetCompanyServiceContractsApiResponse =
    /** status 200 Success */ ServiceContractModel[];
export type GetApiBusinessSupportGetCompanyServiceContractsApiArg = {
    companyId?: number;
};
export type GetApiBusinessSupportGetAbsenceFollowUpConfigurationApiResponse =
    /** status 200 Success */ AbsenceFollowUpConfiguration;
export type GetApiBusinessSupportGetAbsenceFollowUpConfigurationApiArg = {
    companyId?: number;
};
export type GetApiBusinessSupportGetCompanyInfoApiResponse = /** status 200 Success */ CompanyInfoModel;
export type GetApiBusinessSupportGetCompanyInfoApiArg = {
    companyId?: number;
};
export type GetApiBusinessSupportGetCompanyMainDetailListApiResponse = /** status 200 Success */ CompanyMainDetailList;
export type GetApiBusinessSupportGetCompanyMainDetailListApiArg = {
    companyId?: number;
};
export type GetApiBusinessSupportGetGroupDescriptionApiResponse = /** status 200 Success */ ClassDecription;
export type GetApiBusinessSupportGetGroupDescriptionApiArg = {
    companyId?: number;
    groupName?: string;
};
export type GetApiBusinessSupportGetRehabConfigurationApiResponse = /** status 200 Success */ RehabConfiguration;
export type GetApiBusinessSupportGetRehabConfigurationApiArg = {
    companyId?: number;
};
export type GetApiBusinessSupportGetRehabTemplatesConfigurationApiResponse =
    /** status 200 Success */ RehabTemplatesConfiguration;
export type GetApiBusinessSupportGetRehabTemplatesConfigurationApiArg = {
    companyId?: number;
};
export type PostApiBusinessSupportSaveRehabTemplateConfigurationApiResponse = /** status 200 Success */ ObjectResult;
export type PostApiBusinessSupportSaveRehabTemplateConfigurationApiArg = {
    rehabTemplatesConfiguration: RehabTemplatesConfiguration;
};
export type PostApiBusinessSupportSaveAbsenceFollowUpConfigurationApiResponse = /** status 200 Success */ ObjectResult;
export type PostApiBusinessSupportSaveAbsenceFollowUpConfigurationApiArg = {
    absenceFollowUpConfiguration: AbsenceFollowUpConfiguration;
};
export type PostApiBusinessSupportSaveRehabConfigurationApiResponse = /** status 200 Success */ ObjectResult;
export type PostApiBusinessSupportSaveRehabConfigurationApiArg = {
    rehabConfiguration: RehabConfiguration;
};
export type GetApiBusinessSupportGetWorkOrderConfigurationApiResponse =
    /** status 200 Success */ WorkOrderConfiguration;
export type GetApiBusinessSupportGetWorkOrderConfigurationApiArg = {
    customerId?: number;
    companyId?: number;
};
export type PutApiBusinessSupportSaveWorkOrderConfigurationApiResponse = /** status 200 Success */ string;
export type PutApiBusinessSupportSaveWorkOrderConfigurationApiArg = {
    saveWorkOrderConfigurationModel: SaveWorkOrderConfigurationModel;
};
export type PutApiBusinessSupportSaveFollowupConfigurationApiResponse =
    /** status 200 Success */ WorkOrderConfiguration;
export type PutApiBusinessSupportSaveFollowupConfigurationApiArg = {
    saveFollowUpAdvisoryConfigurationModel: SaveFollowUpAdvisoryConfigurationModel;
};
export type PostApiBusinessSupportSaveCompanyInfoApiResponse = /** status 200 Success */ ObjectResult;
export type PostApiBusinessSupportSaveCompanyInfoApiArg = {
    companyInfoModel: CompanyInfoModel;
};
export type PostApiBusinessSupportSaveCustomerCompanyApiResponse = unknown;
export type PostApiBusinessSupportSaveCustomerCompanyApiArg = {
    customerCompanyInfo: CustomerCompanyInfo;
};
export type PostApiBusinessSupportSaveCompanyApiResponse = /** status 200 Success */ number;
export type PostApiBusinessSupportSaveCompanyApiArg = {
    companyModel: CompanyModel;
};
export type PostApiBusinessSupportGetBillableErpProductsByRetailIdsApiResponse =
    /** status 200 Success */ BillableErpProduct[];
export type PostApiBusinessSupportGetBillableErpProductsByRetailIdsApiArg = {
    retailerIdsPostModel: RetailerIdsPostModel;
};
export type PostApiBusinessSupportSaveBillableErpProductApiResponse = unknown;
export type PostApiBusinessSupportSaveBillableErpProductApiArg = {
    billableErpProduct: BillableErpProduct;
};
export type GetApiBusinessSupportGetAllHealthServicesApiResponse = /** status 200 Success */ IdNameMap[];
export type GetApiBusinessSupportGetAllHealthServicesApiArg = void;
export type PostApiBusinessSupportSearchCompaniesByNameOrOrgNrApiResponse = /** status 200 Success */ Company[];
export type PostApiBusinessSupportSearchCompaniesByNameOrOrgNrApiArg = {
    searchCompanyByNameOrOrgNrModel: SearchCompanyByNameOrOrgNrModel;
};
export type PostApiBusinessSupportSearchCompanyApiResponse = /** status 200 Success */ Company[];
export type PostApiBusinessSupportSearchCompanyApiArg = {
    searchCompanyModel: SearchCompanyModel;
};
export type GetApiBusinessSupportGetEmploymentGroupsApiResponse = /** status 200 Success */ EmploymentGroup[];
export type GetApiBusinessSupportGetEmploymentGroupsApiArg = {
    companyId?: number;
};
export type PostApiBusinessSupportSaveEmploymentGroupsApiResponse = unknown;
export type PostApiBusinessSupportSaveEmploymentGroupsApiArg = {
    employmentGroupsRequest: EmploymentGroupsRequest;
};
export type PostApiBusinessSupportDeleteEmploymentGroupsApiResponse = /** status 200 Success */ ObjectResult;
export type PostApiBusinessSupportDeleteEmploymentGroupsApiArg = {
    employmentGroupsRequest: EmploymentGroupsRequest;
};
export type PostApiBusinessSupportUpdateCompanyApiResponse = /** status 200 Success */ ObjectResult;
export type PostApiBusinessSupportUpdateCompanyApiArg = {
    companyModel: CompanyModel;
};
export type PostApiBusinessSupportUpdateCustomerCompanyDetailsApiResponse = /** status 200 Success */ ObjectResult;
export type PostApiBusinessSupportUpdateCustomerCompanyDetailsApiArg = {
    companyModel: CompanyModel;
};
export type GetApiBusinessSupportGetLoginConfigurationApiResponse = /** status 200 Success */ LoginConfiguration;
export type GetApiBusinessSupportGetLoginConfigurationApiArg = {
    companyId?: number;
};
export type PostApiBusinessSupportSaveLoginConfigurationApiResponse = /** status 200 Success */ ObjectResult;
export type PostApiBusinessSupportSaveLoginConfigurationApiArg = {
    loginConfiguration: LoginConfiguration;
};
export type GetApiBusinessSupportGetRegionsApiResponse = /** status 200 Success */ Region[];
export type GetApiBusinessSupportGetRegionsApiArg = void;
export type PutApiBusinessSupportUpdateCompanyRegionApiResponse = /** status 200 Success */ boolean;
export type PutApiBusinessSupportUpdateCompanyRegionApiArg = {
    companyId?: number;
    countryCode?: string;
    language?: string;
    currency?: string;
};
export type GetApiCustomerGetCompaniesByCustomerIdApiResponse = /** status 200 Success */ CustomerCompany[];
export type GetApiCustomerGetCompaniesByCustomerIdApiArg = {
    customerId?: number;
};
export type PostApiCustomerGetCompaniesByCustomerIdsApiResponse = /** status 200 Success */ CustomerCompany[];
export type PostApiCustomerGetCompaniesByCustomerIdsApiArg = {
    customersModel: CustomersModel;
};
export type GetApiCustomerCustomersApiResponse = /** status 200 Success */ CustomerModel[];
export type GetApiCustomerCustomersApiArg = void;
export type GetApiCustomerGetCustomersApiResponse = /** status 200 Success */ CustomerModel[];
export type GetApiCustomerGetCustomersApiArg = {
    dateFrom?: string;
    dateTo?: string;
    retailerId?: number;
};
export type GetApiCustomerCustomersByRetailerApiResponse = /** status 200 Success */ CustomerModel[];
export type GetApiCustomerCustomersByRetailerApiArg = {
    retailerId?: number;
};
export type PostApiCustomerSaveBillingPostConfigurationApiResponse = unknown;
export type PostApiCustomerSaveBillingPostConfigurationApiArg = {
    billingPostConfigurationPostModel: BillingPostConfigurationPostModel;
};
export type PostApiCustomerSaveCustomerBillingApiResponse = unknown;
export type PostApiCustomerSaveCustomerBillingApiArg = {
    billingConfiguration: BillingConfiguration;
};
export type PostApiCustomerSaveBillingPostConfigurationsApiResponse = unknown;
export type PostApiCustomerSaveBillingPostConfigurationsApiArg = {
    billingPosts: BillingPosts;
};
export type PostApiCustomerSaveBillingConfigurationApiResponse = /** status 200 Success */ number;
export type PostApiCustomerSaveBillingConfigurationApiArg = {
    inboundCustomerBillingConfiguration: InboundCustomerBillingConfiguration;
};
export type PostApiCustomerSaveCustomerInfoApiResponse = /** status 200 Success */ boolean;
export type PostApiCustomerSaveCustomerInfoApiArg = {
    customerInfo: CustomerInfo;
};
export type PostApiCustomerDeactivateCompanyApiResponse = unknown;
export type PostApiCustomerDeactivateCompanyApiArg = {
    companyDeactivationContract: CompanyDeactivationContract;
};
export type GetApiOnboardingSearchCustomersAndCompaniesApiResponse =
    /** status 200 Success */ CustomerCompanyOnboardingRead[];
export type GetApiOnboardingSearchCustomersAndCompaniesApiArg = {
    searchString?: string;
};
export type GetApiOnboardingGetCustomerInfoApiResponse = /** status 200 Success */ CompanySelectCustomer;
export type GetApiOnboardingGetCustomerInfoApiArg = {
    companyId?: number;
    customerId?: number;
};
export type GetApiOnboardingGetOrganizationInfoApiResponse = /** status 200 Success */ CompanySelectOrganization;
export type GetApiOnboardingGetOrganizationInfoApiArg = {
    companyId?: number;
};
export type GetApiOnboardingGetEmploymentAbsenceInfoApiResponse = /** status 200 Success */ CompanySelectedEmpAbsence;
export type GetApiOnboardingGetEmploymentAbsenceInfoApiArg = {
    companyId?: number;
};
export type GetApiOnboardingGetUserInfoApiResponse = /** status 200 Success */ CompanySelectedUser;
export type GetApiOnboardingGetUserInfoApiArg = {
    companyId?: number;
    customerId?: number;
};
export type GetApiOnboardingGetWorkOrderInfoApiResponse = /** status 200 Success */ WorkOrderConfiguration;
export type GetApiOnboardingGetWorkOrderInfoApiArg = {
    companyId?: number;
    customerId?: number;
};
export type GetApiOnboardingGetServiceUtilizationInfoApiResponse = /** status 200 Success */ ServiceContractModel[];
export type GetApiOnboardingGetServiceUtilizationInfoApiArg = {
    companyId?: number;
    customerId?: number;
};
export type GetApiOnboardingGetRehabConfigApiResponse = /** status 200 Success */ RehabConfiguration;
export type GetApiOnboardingGetRehabConfigApiArg = {
    companyId?: number;
};
export type GetApiOnboardingGetRehabTemplatesApiResponse = /** status 200 Success */ RehabTemplate;
export type GetApiOnboardingGetRehabTemplatesApiArg = {
    companyId?: number;
};
export type GetApiOnboardingGetFileImportConfigApiResponse = /** status 200 Success */ boolean;
export type GetApiOnboardingGetFileImportConfigApiArg = {
    companyId?: number;
};
export type GetApiOnboardingGetServiceContractsApiResponse = /** status 200 Success */ CompanySelectedServiceUtil;
export type GetApiOnboardingGetServiceContractsApiArg = {
    customerId?: number;
    companyId?: number;
};
export type GetApiOnboardingGetNotificationConfigurationApiResponse =
    /** status 200 Success */ NotificationConfigurationModel;
export type GetApiOnboardingGetNotificationConfigurationApiArg = {
    companyId?: number;
};
export type PostApiOnboardingSaveNotificationConfigurationApiResponse = unknown;
export type PostApiOnboardingSaveNotificationConfigurationApiArg = {
    notificationConfigurationModel: NotificationConfigurationModel;
};
export type GetApiOnboardingGetCompanyConfigurationApiResponse = /** status 200 Success */ CompanyConfigurationModel;
export type GetApiOnboardingGetCompanyConfigurationApiArg = {
    companyId?: number;
};
export type PostApiOnboardingSaveCompanyConfigurationApiResponse = unknown;
export type PostApiOnboardingSaveCompanyConfigurationApiArg = {
    companyConfigurationModel: CompanyConfigurationModel;
};
export type DeleteApiOnboardingDeleteCompanyConfigurationApiResponse = unknown;
export type DeleteApiOnboardingDeleteCompanyConfigurationApiArg = {
    companyId?: number;
};
export type GetApiOnboardingGetCustomerCompanyAdvisoryServicesApiResponse =
    /** status 200 Success */ AdvisoryServiceResultRead;
export type GetApiOnboardingGetCustomerCompanyAdvisoryServicesApiArg = {
    customerId?: number;
    companyId?: number;
};
export type GetApiOnboardingGetAdvisoryServicesApiResponse = /** status 200 Success */ AdvisoryServiceRead[];
export type GetApiOnboardingGetAdvisoryServicesApiArg = void;
export type PostApiOnboardingSaveCustomerCompanyAdvisoryServiceApiResponse = /** status 200 Success */ boolean;
export type PostApiOnboardingSaveCustomerCompanyAdvisoryServiceApiArg = {
    customerCompanyAdvisoryService: CustomerCompanyAdvisoryService;
};
export type PostApiOnboardingDeleteEmploymentGroupsApiResponse = /** status 200 Success */ boolean;
export type PostApiOnboardingDeleteEmploymentGroupsApiArg = {
    deleteEmploymentGroupsModel: DeleteEmploymentGroupsModel;
};
export type PostApiOnboardingSaveEmploymentGroupsApiResponse = /** status 200 Success */ EmploymentGroup[];
export type PostApiOnboardingSaveEmploymentGroupsApiArg = {
    saveEmploymentGroupsModel: SaveEmploymentGroupsModel;
};
export type PostApiOnboardingUpdateCompanyApiResponse = /** status 200 Success */ number;
export type PostApiOnboardingUpdateCompanyApiArg = {
    updateCompanyModel: UpdateCompanyModel;
};
export type GetApiOnboardingGetCustomerApiResponse = /** status 200 Success */ CustomerOnboarding;
export type GetApiOnboardingGetCustomerApiArg = {
    customerId?: number;
};
export type PostApiOnboardingUpdateCustomerProductApiResponse = /** status 200 Success */ boolean;
export type PostApiOnboardingUpdateCustomerProductApiArg = {
    customerProductModel: CustomerProductModel;
};
export type GetApiStatusApiResponse = unknown;
export type GetApiStatusApiArg = void;
export type GetApiStatusInformationApiResponse = unknown;
export type GetApiStatusInformationApiArg = void;
export type GetApiSupportToolGetPerformedByInfosApiResponse = /** status 200 Success */ PerformedByInfo[];
export type GetApiSupportToolGetPerformedByInfosApiArg = {
    userAccountIds?: number[];
};
export type PostApiSupportToolGetEmploymentInfosApiResponse = /** status 200 Success */ EmploymentInfoRead[];
export type PostApiSupportToolGetEmploymentInfosApiArg = {
    employmentInfosModel: EmploymentInfosModel;
};
export type GetApiSupportToolGetAllCustomersApiResponse = /** status 200 Success */ CustomerModel[];
export type GetApiSupportToolGetAllCustomersApiArg = void;
export type AccessRightType =
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21
    | 22;
export type AccessRightConfigurationPostModel = {
    customerId?: number;
    companyId?: number | null;
    accessRightType?: AccessRightType;
    hierarchical?: boolean;
    type?: string | null;
};
export type PerformedServiceDetail = {
    user?: string | null;
    registrationDate?: string;
    referencePerformedDate?: string;
};
export type PerformedServiceModel = {
    service?: string | null;
    usage?: number;
    details?: PerformedServiceDetail[] | null;
};
export type HealthServiceModel = 0 | 1 | 2 | 3 | 4 | 5;
export type ServiceContractModel = {
    id?: number;
    activationDate?: string;
    deactivationDate?: string | null;
    description?: string | null;
    customerId?: number;
    companyId?: number | null;
    phoneNumber?: string | null;
    ivrPhoneNumber?: string | null;
    ivrCustomerReferenceKey?: number | null;
    healthService?: HealthServiceModel;
};
export type AbsenceFollowUpConfiguration = {
    companyId?: number;
    socialInsuranceReporting?: boolean;
    medicalCertificateMonitoring?: boolean;
    mandatoryAdvisoryCallback?: boolean;
    id?: number;
    isConfigured?: boolean;
};
export type CompanyInfoModel = {
    industryCode?: string | null;
    companyId?: number;
    id?: string;
    newReporting?: boolean;
};
export type CompanyMainDetailList = {
    id?: number;
    name?: string | null;
    number?: string | null;
    customerId?: number;
    customerName?: string | null;
};
export type PropertyType = 0 | 1 | 2 | 3 | 4;
export type PropertyDescription = {
    name?: string | null;
    type?: PropertyType;
    values?: string[] | null;
    isReadOnly?: boolean;
};
export type ClassDecription = {
    name?: string | null;
    propertyDescriptions?: PropertyDescription[] | null;
};
export type RehabBaseActivity = {
    carryOutDay?: number;
    partOfRehabChain?: boolean;
};
export type RehabConfiguration = {
    companyId?: number;
    rehabBaseActivities?: RehabBaseActivity[] | null;
    isConfigured?: boolean;
};
export type RehabFormDocumentTemplateReferenceType = 0 | 1 | 2 | 3;
export type RehabTemplate = {
    id?: number;
    title?: string | null;
    ownerReferenceType?: RehabFormDocumentTemplateReferenceType;
    pdfDocumentReferenceKey?: string | null;
    companyId?: number | null;
};
export type RehabTemplatesConfiguration = {
    companyId?: number;
    rehabTemplates?: RehabTemplate[] | null;
    isConfigured?: boolean;
};
export type IOutputFormatter = object;
export type ObjectResult = {
    value?: any | null;
    formatters?: IOutputFormatter[] | null;
    contentTypes?: string[] | null;
    declaredType?: string | null;
    statusCode?: number | null;
};
export type FollowUpAdvisoryConfiguration = {
    id?: string;
    followUpAdvisorySickDay?: number;
    absenceReportType?: string | null;
};
export type WorkOrderConfiguration = {
    id?: string;
    customerId?: number;
    companyId?: number | null;
    allowDirectAdvisoryCallback?: boolean;
    mandatoryAdvisoryCallback?: boolean;
    localePreference?: string | null;
    followUpAdvisoryConfigurations?: FollowUpAdvisoryConfiguration[] | null;
};
export type SaveWorkOrderConfigurationModel = {
    id?: string;
    customerId?: number;
    companyId?: number | null;
    allowDirectAdvisoryCallback?: boolean;
    mandatoryAdvisoryCallback?: boolean;
    localePreference?: string | null;
};
export type SaveFollowUpAdvisoryConfigurationModel = {
    workOrderConfigurationId?: string;
    days?: number[] | null;
};
export type CustomerCompanyInfo = {
    companyId?: number;
    companyName?: string | null;
    organizationNumber?: string | null;
    customerId?: number;
    customerName?: string | null;
    customerNumber?: string | null;
    customerDescription?: string | null;
    retailerId?: number;
    erp?: string | null;
    regionId?: string | null;
};
export type Region = {
    id?: string;
    country?: string | null;
    language?: string | null;
    currency?: string | null;
    isDefault?: boolean;
};
export type CompanyModel = {
    id?: number;
    name?: string | null;
    number?: string | null;
    retailerId?: number;
    customerId?: number;
    customerErpNumber?: string | null;
    description?: string | null;
    sizeClass?: string | null;
    industryCode?: string | null;
    newReporting?: boolean;
    region?: Region;
    product?: string | null;
    subcategory?: string | null;
};
export type BillingPostType =
    | 0
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21
    | 22
    | 23
    | 24
    | 25
    | 26;
export type BillableErpProduct = {
    id?: number;
    name?: string | null;
    billingPostType?: BillingPostType;
    healthServiceId?: number | null;
    retailerId?: number | null;
    brandedServiceId?: number;
    erpNumber?: string | null;
    note?: string | null;
    standardPrice?: number;
};
export type RetailerIdsPostModel = {
    retailIds?: number[] | null;
};
export type IdNameMap = {
    id?: number;
    name?: string | null;
};
export type Company = {
    id?: number;
    name?: string | null;
    number?: string | null;
    customerId?: number;
    regionId?: string | null;
};
export type SearchCompanyByNameOrOrgNrModel = {
    searchString?: string | null;
    retailerId?: number;
};
export type SearchCompanyModel = {
    name?: string | null;
    organizationNumber?: string | null;
    retailerId?: number;
};
export type EmploymentGroup = {
    id?: number;
    nameIdentifier?: string | null;
    nameString?: string | null;
    companyId?: number | null;
    company?: Company;
    region?: Region;
    isReadOnly?: boolean;
};
export type EmploymentGroupsRequest = {
    companyId?: number;
    employmentGroups?: EmploymentGroup[] | null;
};
export type LoginConfiguration = {
    usernameFromEmail?: boolean;
    usernameFromSsn?: boolean;
    office365?: boolean;
    companyId?: number;
    isConfigured?: boolean;
};
export type CustomerCompany = {
    id?: number;
    customerId?: number;
    companyId?: number;
    companyName?: string | null;
    deactivationDate?: string | null;
};
export type CustomersModel = {
    customerIds?: number[] | null;
};
export type BillingPeriod = 0 | 1 | 2;
export type CustomerCompanyContract = {
    customerId?: number;
    companyId?: number;
    companyName?: string | null;
};
export type BillingBasisPostModel = {
    departmentId?: number;
    cost?: number;
    unitPrice?: number;
    manuallyAdded?: boolean;
};
export type BillingBasisModel = {
    id?: number;
    effectiveDate?: string;
    fromDate?: string;
    toDate?: string;
    creationDate?: string;
    billingBasisPosts?: BillingBasisPostModel[] | null;
};
export type ExportedContentLevel = 0 | 1 | 2 | 3 | 4;
export type CustomerModel = {
    id?: number;
    retailerId?: number;
    name?: string | null;
    description?: string | null;
    customerNumber?: string | null;
    customerErpNumber?: string | null;
    billingPeriodKey?: BillingPeriod;
    billingDayInMonth?: number;
    customerCompanyKeys?: CustomerCompanyContract[] | null;
    customerGroupType?: number;
    billingBasises?: BillingBasisModel[] | null;
    useCostCenter?: boolean;
    groupedOnTopLevelDepartments?: boolean;
    exportedContentLevel?: ExportedContentLevel;
};
export type BillingPostConfigurationPostModel = {
    customerId?: number;
    retailerId?: number;
    activationDate?: string;
    deactivationDate?: string | null;
    billingPostType: string;
    overridingPrice?: string | null;
    level?: number;
};
export type GroupType = 0 | 1 | 2;
export type BillingConfiguration = {
    customerId?: number;
    retailerId?: number;
    billingPeriodType?: BillingPeriod;
    billingDayInMonth?: number;
    groupType?: GroupType;
    groupedOnTopLevelDepartments?: boolean;
    exportedContentLevel?: ExportedContentLevel;
};
export type BillingPostConfigurationModel = {
    id?: number;
    deactivationDate?: string | null;
    activationDate?: string;
    overridingPrice?: number | null;
    billableErpProductId?: number;
    billingPostType?: number;
};
export type BillingPosts = {
    customerId?: number;
    retailerId?: number;
    configurations?: BillingPostConfigurationModel[] | null;
};
export type InboundCustomerBillingConfiguration = {
    id?: number;
    customerId?: number;
    retailerId?: number | null;
    billableErpProductId?: number;
    billingDayInMonth?: number | null;
    billingPeriodType?: BillingPeriod;
    groupType?: GroupType;
    groupedOnTopLevelDepartments?: boolean;
    overridingPrice?: string | null;
    billingPostType?: BillingPostType;
    activationDate?: string;
    deactivationDate?: string | null;
    exportedContentLevel?: ExportedContentLevel;
};
export type CustomerInfo = {
    customerId?: number;
    customerName?: string | null;
    description?: string | null;
    customerNumber?: string | null;
    customerErpNumber?: string | null;
};
export type CompanyDeactivations = {
    companyId?: number;
    deactivationDate?: string | null;
};
export type CompanyDeactivationContract = {
    customerId?: number;
    companyDeactivations?: CompanyDeactivations[] | null;
};
export type CustomerCompanyOnboarding = {
    customerId?: number;
    customerName?: string | null;
    customerNumber?: string | null;
    retailerId?: number;
    retailerName?: string | null;
    companyId?: number;
    companyName?: string | null;
    companyNumber?: string | null;
    region?: Region;
};
export type CustomerCompanyOnboardingRead = {
    customerId?: number;
    customerName?: string | null;
    customerNumber?: string | null;
    retailerId?: number;
    retailerName?: string | null;
    companyId?: number;
    companyName?: string | null;
    companyNumber?: string | null;
    type?: string | null;
    region?: Region;
};
export type BillingConfigurationModel = {
    billingPeriodType?: number | null;
    billingDayInMonth?: number;
    groupType?: number | null;
    groupedOnTopLevelDepartments?: boolean;
    exportedContentLevel?: number | null;
};
export type InvoicePost = {
    id?: number;
    erpNumber?: string | null;
    name?: string | null;
    standardPrice?: number;
    customerPrice?: number | null;
    activationDate?: string | null;
    deactivationDate?: string | null;
    billingPostType?: number;
};
export type BillingErpOption = {
    value?: string | null;
    nonTranslatedDescription?: string | null;
};
export type CompanySelectCustomer = {
    companyId?: number;
    customerId?: number;
    customerName?: string | null;
    selectedCompanyName?: string | null;
    retailerId?: number;
    partner?: string | null;
    billingConfiguration?: BillingConfigurationModel;
    invoicePosts?: InvoicePost[] | null;
    activationDate?: string | null;
    deactivationDate?: string | null;
    billingErpOptions?: BillingErpOption[] | null;
};
export type CompanySelectOrganization = {
    companyName?: string | null;
    organizationNumber?: string | null;
    region?: Region;
    employmentGroups?: EmploymentGroup[] | null;
};
export type CompanySelectedEmpAbsence = {
    industry?: string | null;
    industryCode?: string | null;
};
export type HierarchyAccessRight = {
    customerConfigurationId?: number;
    accessRightType?: string | null;
};
export type OnBoardingManagerAccessRight = {
    managerType?: string | null;
    customerConfigurationId?: number;
    accessRightType?: string | null;
    hierarchical?: boolean;
};
export type CompanySelectedUser = {
    loginConfiguration?: LoginConfiguration;
    hierarchyAccessRights?: HierarchyAccessRight[] | null;
    managerAccessRights?: OnBoardingManagerAccessRight[] | null;
};
export type CompanySelectedServiceUtil = {
    serviceContracts?: ServiceContractModel[] | null;
};
export type NotificationConfigurationModel = {
    id?: string | null;
    companyId: number;
    customerId: number;
    handleMedicalReminder?: boolean;
    handleEstimatedBackAtWork?: boolean;
};
export type CompanyConfigurationModel = {
    id?: string;
    companyId: number;
    country: string;
    maskSsn?: boolean;
};
export type AdvisoryService = {
    id?: string;
    name?: string | null;
    nameIdentifier?: string | null;
};
export type AdvisoryServiceRead = {
    id?: string;
    name?: string | null;
    nameIdentifier?: string | null;
    type?: string | null;
};
export type CustomerCompanyAdvisoryService = {
    id?: string;
    customerId: number;
    companyId?: number | null;
    activationDate: string;
    deactivationDate?: string | null;
    advisoryServiceId: string;
};
export type CustomerCompanyAdvisoryServiceRead = {
    id?: string;
    customerId: number;
    companyId?: number | null;
    activationDate: string;
    deactivationDate?: string | null;
    advisoryServiceId: string;
    type?: string | null;
};
export type AdvisoryServiceResult = {
    advisoryServices?: AdvisoryService[] | null;
    customerCompanyAdvisoryServices?: CustomerCompanyAdvisoryService[] | null;
};
export type AdvisoryServiceResultRead = {
    advisoryServices?: AdvisoryServiceRead[] | null;
    customerCompanyAdvisoryServices?: CustomerCompanyAdvisoryServiceRead[] | null;
};
export type DeleteEmploymentGroupsModel = {
    companyId?: number;
    employmentGroupIds?: number[] | null;
};
export type EmploymentGroupModel = {
    nameString?: string | null;
    regionId?: string | null;
};
export type SaveEmploymentGroupsModel = {
    companyId?: number;
    employmentGroups?: EmploymentGroupModel[] | null;
};
export type UpdateCompanyModel = {
    companyId?: number;
    companyName?: string | null;
    number?: string | null;
    newReporting?: boolean;
    region?: Region;
};
export type CustomerOnboarding = {
    customerId?: number;
    customerName?: string | null;
    customerNumber?: string | null;
    retailerId?: number;
    product?: string | null;
    subcategory?: string | null;
    billingConfiguration?: BillingConfigurationModel;
    invoicePosts?: InvoicePost[] | null;
    retailerName?: string | null;
};
export type CustomerProductModel = {
    customerId?: number;
    product?: string | null;
    subcategory?: string | null;
};
export type PerformedByInfo = {
    userAccountId?: number;
    employmentId?: string;
    firstName?: string | null;
    lastName?: string | null;
};
export type EmploymentInfo = {
    employmentId?: string;
    employmentNumber?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
};
export type EmploymentInfoRead = {
    employmentId?: string;
    employmentNumber?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    fullName?: string | null;
    email?: string | null;
};
export type EmploymentInfosModel = {
    referenceKeys?: string[] | null;
};
export const {
    useGetApiAccessRightConfigurationQuery,
    usePostApiAccessRightConfigurationMutation,
    usePostApiAccessRightConfigurationDeleteMutation,
    useGetApiBusinessSupportPerformedServicesForCompanyQuery,
    usePostApiBusinessSupportSaveServiceContractMutation,
    useGetApiBusinessSupportGetCompanyServiceContractsQuery,
    useGetApiBusinessSupportGetAbsenceFollowUpConfigurationQuery,
    useGetApiBusinessSupportGetCompanyInfoQuery,
    useGetApiBusinessSupportGetCompanyMainDetailListQuery,
    useGetApiBusinessSupportGetGroupDescriptionQuery,
    useGetApiBusinessSupportGetRehabConfigurationQuery,
    useGetApiBusinessSupportGetRehabTemplatesConfigurationQuery,
    usePostApiBusinessSupportSaveRehabTemplateConfigurationMutation,
    usePostApiBusinessSupportSaveAbsenceFollowUpConfigurationMutation,
    usePostApiBusinessSupportSaveRehabConfigurationMutation,
    useGetApiBusinessSupportGetWorkOrderConfigurationQuery,
    usePutApiBusinessSupportSaveWorkOrderConfigurationMutation,
    usePutApiBusinessSupportSaveFollowupConfigurationMutation,
    usePostApiBusinessSupportSaveCompanyInfoMutation,
    usePostApiBusinessSupportSaveCustomerCompanyMutation,
    usePostApiBusinessSupportSaveCompanyMutation,
    usePostApiBusinessSupportGetBillableErpProductsByRetailIdsMutation,
    usePostApiBusinessSupportSaveBillableErpProductMutation,
    useGetApiBusinessSupportGetAllHealthServicesQuery,
    usePostApiBusinessSupportSearchCompaniesByNameOrOrgNrMutation,
    usePostApiBusinessSupportSearchCompanyMutation,
    useGetApiBusinessSupportGetEmploymentGroupsQuery,
    usePostApiBusinessSupportSaveEmploymentGroupsMutation,
    usePostApiBusinessSupportDeleteEmploymentGroupsMutation,
    usePostApiBusinessSupportUpdateCompanyMutation,
    usePostApiBusinessSupportUpdateCustomerCompanyDetailsMutation,
    useGetApiBusinessSupportGetLoginConfigurationQuery,
    usePostApiBusinessSupportSaveLoginConfigurationMutation,
    useGetApiBusinessSupportGetRegionsQuery,
    usePutApiBusinessSupportUpdateCompanyRegionMutation,
    useGetApiCustomerGetCompaniesByCustomerIdQuery,
    usePostApiCustomerGetCompaniesByCustomerIdsMutation,
    useGetApiCustomerCustomersQuery,
    useGetApiCustomerGetCustomersQuery,
    useGetApiCustomerCustomersByRetailerQuery,
    usePostApiCustomerSaveBillingPostConfigurationMutation,
    usePostApiCustomerSaveCustomerBillingMutation,
    usePostApiCustomerSaveBillingPostConfigurationsMutation,
    usePostApiCustomerSaveBillingConfigurationMutation,
    usePostApiCustomerSaveCustomerInfoMutation,
    usePostApiCustomerDeactivateCompanyMutation,
    useGetApiOnboardingSearchCustomersAndCompaniesQuery,
    useGetApiOnboardingGetCustomerInfoQuery,
    useGetApiOnboardingGetOrganizationInfoQuery,
    useGetApiOnboardingGetEmploymentAbsenceInfoQuery,
    useGetApiOnboardingGetUserInfoQuery,
    useGetApiOnboardingGetWorkOrderInfoQuery,
    useGetApiOnboardingGetServiceUtilizationInfoQuery,
    useGetApiOnboardingGetRehabConfigQuery,
    useGetApiOnboardingGetRehabTemplatesQuery,
    useGetApiOnboardingGetFileImportConfigQuery,
    useGetApiOnboardingGetServiceContractsQuery,
    useGetApiOnboardingGetNotificationConfigurationQuery,
    usePostApiOnboardingSaveNotificationConfigurationMutation,
    useGetApiOnboardingGetCompanyConfigurationQuery,
    usePostApiOnboardingSaveCompanyConfigurationMutation,
    useDeleteApiOnboardingDeleteCompanyConfigurationMutation,
    useGetApiOnboardingGetCustomerCompanyAdvisoryServicesQuery,
    useGetApiOnboardingGetAdvisoryServicesQuery,
    usePostApiOnboardingSaveCustomerCompanyAdvisoryServiceMutation,
    usePostApiOnboardingDeleteEmploymentGroupsMutation,
    usePostApiOnboardingSaveEmploymentGroupsMutation,
    usePostApiOnboardingUpdateCompanyMutation,
    useGetApiOnboardingGetCustomerQuery,
    usePostApiOnboardingUpdateCustomerProductMutation,
    useGetApiStatusQuery,
    useGetApiStatusInformationQuery,
    useGetApiSupportToolGetPerformedByInfosQuery,
    usePostApiSupportToolGetEmploymentInfosMutation,
    useGetApiSupportToolGetAllCustomersQuery,
} = injectedRtkApi;
