import { pick, upperFirst } from "lodash/fp";
import { IFilter, ISearchFilterTags, ISortingFilter } from "../types";
import { MonthEnum, SearchTypes } from "./searchApi";
import { translation } from "./searchDummyData";
import { getStringFromDate } from "utils/date";
import { getIdFromSelected } from "domain/ogranization/dynamicData";
import { IDynamicData } from "@medhelp/ui/List/CheckboxList/types";
import { MedHelpHCMDomainTemplate } from "swagger/healthCaseManagement";
import { isEmpty } from "lodash";

const FieldNames = {
    workRelated: { name: "WorkRelated", value: "true" },
    workAccident: { name: "WorkPlaceAccident", value: "true" },
    flexJob: { name: "EmploymentGroup", value: "flexjob" },
    pregnancyRelated: { name: "PregnancyRelated", value: "true" },
    "§56": { name: "Paragraph56", value: "true" },
    employmentGroups: (value: string) => ({
        name: "EmploymentGroup",
        value,
    }),
} as const;

type FieldTypes = "flexJob" | "§56" | "pregnancyRelated" | "workAccident" | "workRelated";

interface ISearchField {
    name: string;
    value: string;
}
interface ISearchSchema {
    companyIds: number[];
    departments: number[];
    descending: boolean | null;
    historicalDepartments: boolean | null;
    page: number | null;
    fields: ISearchField[];
    from: string | null;
    to: string | null;
    groupByEmployment: boolean | null;
    maxDays: number | null;
    minDays: number | null;
    occurrences: number | null;
    showOnlyLastAffectedDepartment: boolean | null;
    startedDuringSelectedPeriod: boolean | null;
    statusEnd: boolean;
    statusStart: boolean;
    types: string[];
    sicknessBenefits: boolean;
    translation: [] | null;
    fromDate: string | null;
    startInInterval: boolean;
    codes: string[] | null;
    absenceImpact: MonthEnum | null;
    departmentIds: string[] | null;
    templateIds: string[] | null;
    causes: string[] | null;
    status: string[] | null;
    daysActivityDelayed: number | null;
    groupBy: string | null;
    includeArchived?: boolean;
}

const SearchSchema: ISearchSchema = {
    companyIds: [],
    departments: [],
    descending: true,
    historicalDepartments: false,
    fields: [],
    from: null,
    to: null,
    groupByEmployment: null,
    maxDays: null,
    minDays: null,
    occurrences: null,
    page: null,
    showOnlyLastAffectedDepartment: null,
    startedDuringSelectedPeriod: null,
    statusEnd: false,
    statusStart: false,
    types: [],
    sicknessBenefits: false,
    translation: null,
    fromDate: null,
    startInInterval: false,
    codes: null,
    absenceImpact: null,
    departmentIds: [],
    templateIds: [],
    causes: [],
    status: [],
    daysActivityDelayed: null,
    groupBy: null,
    includeArchived: false,
};
type SearchSchemaType = keyof typeof SearchSchema;

const AbsencePeriodSchema: Readonly<SearchSchemaType[]> = [
    "fields",
    "from",
    "to",
    "historicalDepartments",
    "maxDays",
    "minDays",
    "occurrences",
    "types",
    "groupByEmployment",
    "absenceImpact",
    "showOnlyLastAffectedDepartment",
    "descending",
    "absenceImpact",
    "includeArchived",
] as const;

const OngoingSchema: Readonly<SearchSchemaType[]> = [
    "historicalDepartments",
    "maxDays",
    "minDays",
    "types",
    "page",
    "showOnlyLastAffectedDepartment",
    "groupByEmployment",
    "descending",
    "fields",
    "includeArchived",
] as const;

const LongtermSchema: Readonly<SearchSchemaType[]> = [
    "from",
    "to",
    "page",
    "absenceImpact",
    "types",
    "descending",
    "codes",
    "fields",
    "includeArchived",
] as const;

const RecurringSchema: Readonly<SearchSchemaType[]> = [
    "from",
    "to",
    "page",
    "types",
    "absenceImpact",
    "descending",
    "fields",
    "includeArchived",
];

const MedicalSchema: Readonly<SearchSchemaType[]> = [
    "from",
    "page",
    "to",
    "groupByEmployment",
    "showOnlyLastAffectedDepartment",
    "descending",
    "includeArchived",
];

const ReimbursibleSchema: Readonly<SearchSchemaType[]> = [
    "types",
    "sicknessBenefits",
    "fields",
    "from",
    "to",
    "groupByEmployment",
    "showOnlyLastAffectedDepartment",
    "page",
    "descending",
    "includeArchived",
];

const MonthlySchema: Readonly<SearchSchemaType[]> = [
    "fromDate",
    "startInInterval",
    "types",
    "absenceImpact",
    "includeArchived",
];

const CasesSchema: Readonly<SearchSchemaType[]> = [
    "departmentIds",
    "templateIds",
    "descending",
    "groupBy",
    "page",
    "from",
    "to",
    "causes",
    "status",
    "includeArchived",
];

const ActivitiesSchema: Readonly<SearchSchemaType[]> = [
    "departmentIds",
    "templateIds",
    "descending",
    "groupBy",
    "page",
    "from",
    "to",
    "status",
    "daysActivityDelayed",
    "includeArchived",
];

const Schemas = {
    absencePeriod: AbsencePeriodSchema,
    ongoingAbsence: OngoingSchema,
    longtermAbsence: LongtermSchema,
    recurringAbsence: RecurringSchema,
    medicalCertificate: MedicalSchema,
    reimbursable: ReimbursibleSchema,
    monthlyReport: MonthlySchema,
    cases: CasesSchema,
    activities: ActivitiesSchema,
};

const transformFilterToSearchRequest = (prev: typeof SearchSchema, item: IFilter) => {
    switch (item.id) {
        case "absenceStatus": {
            if (item.referenceKey === "startedAbsence") {
                return {
                    ...prev,
                    statusStart: item.checked,
                };
            }
            if (item.referenceKey === "endedAbsence") {
                return {
                    ...prev,
                    statusEnd: item.checked,
                };
            }
            return prev;
        }
        case "absenceType": {
            return {
                ...prev,
                types: [...prev.types, upperFirst(item.referenceKey)],
            };
        }
        case "workRelated": {
            if (item.checked) {
                return {
                    ...prev,
                    fields: [...prev.fields, FieldNames[item.referenceKey as FieldTypes]],
                };
            }
            return prev;
        }
        case "absenceImpact": {
            if (item.checked) {
                return {
                    ...prev,
                    absenceImpact: MonthEnum[item.referenceKey as "Started" | "Created" | "Ended" | "Affected"],
                };
            }
            return prev;
        }
        case "reimbursableType": {
            if (item.referenceKey === "all") return prev;
            if (item.referenceKey === "sicknessBenefits") {
                return {
                    ...prev,
                    sicknessBenefits: item.checked,
                };
            }
            return {
                ...prev,
                fields: [...prev.fields, FieldNames[item.referenceKey as FieldTypes]],
            };
        }
        case "employmentGroups": {
            return {
                ...prev,
                fields: [...prev.fields, FieldNames.employmentGroups(item.label)],
            };
        }
        case "from": {
            return {
                ...prev,
                from: item.date,
            };
        }
        case "to": {
            return {
                ...prev,
                to: item.date,
            };
        }
        case "occurrences": {
            return {
                ...prev,
                occurrences: item.value,
            };
        }
        case "minDays": {
            return {
                ...prev,
                minDays: item.value,
            };
        }
        case "maxDays": {
            return {
                ...prev,
                maxDays: item.value,
            };
        }
        case "codes": {
            const rest = prev.codes ?? [];
            return {
                ...prev,
                codes: [...rest, item.label],
            };
        }
        case "month": {
            const { monthIndex, year } = item;
            if (monthIndex !== null && year) {
                return {
                    ...prev,
                    fromDate: getStringFromDate(new Date(Number(year), monthIndex, 1)),
                };
            }
            return prev;
        }
        case "causes": {
            const rest = prev.causes ?? [];
            return {
                ...prev,
                causes: [...rest, item.referenceKey],
            };
        }
        case "caseStatus": {
            const rest = prev.status ?? [];
            return {
                ...prev,
                status: [...rest, item.referenceKey],
            };
        }
        case "activityStatus": {
            const rest = prev.status ?? [];
            return {
                ...prev,
                status: [...rest, item.referenceKey],
            };
        }
        case "daysActivityDelayed": {
            return {
                ...prev,
                daysActivityDelayed: item.value,
            };
        }
        default: {
            return prev;
        }
    }
};
export const createSearchRequestSchema = (
    searchType: SearchTypes,
    item: IFilter[],
    sort: ISortingFilter,
    requestType: "excel" | "search",
    workbookTitle: string,
    country?: string,
    includeArchived?: boolean,
) => {
    const filter = item.reduce(transformFilterToSearchRequest, SearchSchema);
    const request = pick(Schemas[searchType], {
        ...filter,
        groupByEmployment: Boolean(sort.showBasedOn === "employee"),
        groupBy: sort.showBasedOn === "employee" ? "employment" : "",
        showOnlyLastAffectedDepartment: Boolean(sort.showDepartments === "onlyLatestDepartment"),
        descending: true,
        includeArchived,
    });
    const addTranslation = requestType === "excel";

    return addTranslation
        ? {
              ...request,
              translation: translation(workbookTitle, country, searchType),
          }
        : request;
};

export const getTemplateIdsFilteredByCaseType = (
    companies: IDynamicData[],
    templates: MedHelpHCMDomainTemplate[],
    tags: ISearchFilterTags,
) => {
    const filteredTemplates = templates.filter((x) =>
        getIdFromSelected(companies)
            .map(Number)
            .includes(x.companyId ?? 0),
    );
    const filteredTags = tags.filter((x) => x.id === "caseType");
    const templateIdsFilteredByCaseType = filteredTemplates
        .filter((x) => filteredTags.some((y) => x.healthCaseType?.includes(y.referenceKey)))
        .map((x) => x.id);

    if (isEmpty(templateIdsFilteredByCaseType)) {
        return filteredTemplates.map(({ id }) => id);
    } else {
        return templateIdsFilteredByCaseType;
    }
};
