import { MedHelpHCMDomainActivityTemplate } from "swagger/healthCaseManagement/api";
import DepartmentList from "../DepartmentList/DepartmentList";
import { CaseTableHeader } from "../ProductSettings/CaseTableHeader";
import { TableCell, TableRow } from "@medhelp/ui";
import { useTranslation } from "react-i18next";

export interface MyActivityTemplate extends MedHelpHCMDomainActivityTemplate {
    source: string;
    containsChain: boolean;
    healthCaseType: string;
    activityTemplates: MyActivityTemplate[];
}
interface HealthCaseTableProps {
    cases?: MyActivityTemplate;
    heading: string;
    containsChain?: boolean;
    partner: string;
    companyName: string;
}
function HealthCaseTable({ cases, heading, partner, companyName }: HealthCaseTableProps) {
    const { t } = useTranslation("administration");
    return (
        <div>
            <CaseTableHeader heading={heading}>
                {cases && (
                    <div key={cases.id}>
                        <TableRow className="hidden w-full lg:flex">
                            <div className="flex w-1/2 justify-between">
                                <TableCell className="w-1/2 py-2 text-sm font-bold" children={t("activityTitle")} />
                                <TableCell
                                    className="w-1/2 py-2 text-end text-sm font-bold"
                                    children={t("daysAfterCaseStart")}
                                />
                            </div>
                            <div className="flex w-1/2 justify-between">
                                <TableCell className="w-1/2 py-2 text-sm font-bold" children={t("occurReason")} />
                                <TableCell className="w-1/2 py-2 text-center text-sm font-bold" children={t("onOff")} />
                            </div>
                        </TableRow>
                        {cases.activityTemplates &&
                            cases.activityTemplates.length > 0 &&
                            cases.activityTemplates
                                .slice()
                                .sort((a, b) => (a?.day ?? 0) - (b?.day ?? 0))
                                .map((activity: MyActivityTemplate) => (
                                    <DepartmentList
                                        key={activity.id}
                                        id={activity.id!}
                                        healthCaseType={cases.healthCaseType}
                                        reason={activity.templateReasons || []}
                                        createdBy={activity.source}
                                        day={activity.day ?? 0}
                                        isActive={activity.isActive ?? false}
                                        name={activity.name ?? ""}
                                        description={activity.description || ""}
                                        title={activity.title || ""}
                                        containsChain={cases.containsChain}
                                        type={activity.type ?? ""}
                                        partner={partner}
                                        companyName={companyName}
                                    />
                                ))}
                    </div>
                )}
            </CaseTableHeader>
        </div>
    );
}

export default HealthCaseTable;
