import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Countdown from "../Countdown";
import { useNavigate } from "react-router-dom";
import { Spinner } from "components/Spinner";
import { ILoginPageData } from "pages/login/types";

const Microsoft = ({ countdownTimer, userAccounts }: ILoginPageData) => {
    const { t } = useTranslation("login");
    const navigate = useNavigate();

    const [timeToNavigate, setTimeToNavigate] = useState<number>(-1);

    useEffect(() => {
        if (userAccounts && userAccounts.length > 1) navigate(`/login/selectCompany`);
    }, [navigate, userAccounts]);

    useEffect(() => {
        if (countdownTimer) setTimeToNavigate(countdownTimer);
    }, [countdownTimer]);

    return (
        <div className="mb-3">
            {timeToNavigate === -1 && (
                <div id="spinner" className="mb-7">
                    <Spinner />
                </div>
            )}
            {timeToNavigate !== -1 && (
                <Countdown
                    message={t("authFailedRedirectToLoginPageIn", {
                        time: timeToNavigate,
                    })}
                    time={timeToNavigate}
                    setTime={setTimeToNavigate}
                    navigateTo="/login/userAndPass"
                    isError
                />
            )}
        </div>
    );
};

export default Microsoft;
