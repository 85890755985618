import TopLinks from "components/TopLinks";
import { Config } from "config";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IRoute } from "routes/hooks";

export interface IAdminTopLinksProps {
    companyId: string | undefined;
    isCoaOrCs: boolean;
}

const AdministrationTopLinks = ({ companyId, isCoaOrCs }: IAdminTopLinksProps) => {
    const { t } = useTranslation("administration");
    const Routes: IRoute[] = useMemo(
        () => [
            {
                route: `${Config.web_customerSupport_url}Organization`,
                name: t("organization"),
                children: [],
            },
            {
                route: `${Config.web_customerSupport_url}company/${companyId}`,
                name: t("company"),
                children: [],
            },
            {
                route: Config.web_customerSupport_url,
                name: t("users"),
                hidden: !companyId,
                subMenu: true,
                children: [
                    {
                        route: `${Config.web_administrationWeb_url}users/addUser#companyId=${companyId}`,
                        name: t("addUser"),
                        children: [],
                    },
                    {
                        route: `${Config.web_customerSupport_url}importemployees/${companyId}`,
                        name: t("importEmployees"),
                        children: [],
                    },
                    {
                        route: `${Config.web_customerSupport_url}unstartedemployments/${companyId}`,
                        name: t("nonActivatedUsers"),
                        children: [],
                    },
                    {
                        route: `${Config.web_customerSupport_url}removedusers/${companyId}`,
                        name: t("removedUsers"),
                        children: [],
                    },
                    {
                        route: `${Config.web_customerSupport_url}removedemployments/${companyId}`,
                        name: t("removeDeletedUsers"),
                        children: [],
                    },
                    {
                        route: `${Config.web_administrationWeb_url}users/erasureActive/settings`,
                        name: t("removeActiveUsers"),
                        children: [],
                        hidden: !isCoaOrCs,
                    },
                ],
            },
            {
                route: Config.web_customerSupport_url,
                name: t("eventLogs"),
                subMenu: true,
                children: [
                    {
                        route: `${Config.web_customerSupport_url}subscriptionevents/${companyId}`,
                        name: t("subscriptions"),
                        children: [],
                    },
                    {
                        route: `${Config.web_customerSupport_url}organizationevents/${companyId}`,
                        name: t("organization"),
                        children: [],
                    },
                    {
                        route: `${Config.web_customerSupport_url}accessrightsevents/${companyId}`,
                        name: t("accessRights"),
                        children: [],
                    },
                ],
            },
            {
                route: `productsettings`,
                name: t("productSettings"),
                children: [],
            },
            {
                route: Config.web_customerSupport_url,
                name: t("settings"),
                subMenu: true,
                children: [
                    {
                        route: `${Config.web_administrationWeb_url}settings/roleSubscriptions`,
                        name: t("roleSubscriptionSettings"),
                        children: [],
                    },
                    {
                        route: `${Config.web_customerSupport_url}subscriptionssettings`,
                        name: t("subscriptions"),
                        children: [],
                    },
                ],
            },
        ],
        [companyId, isCoaOrCs, t],
    );
    return <TopLinks routes={Routes} headRoute="administration" header={t("administration")} />;
};

export default AdministrationTopLinks;
