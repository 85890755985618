import axios from "axios";
import { jwtDecode } from "jwt-decode";
import {
    DashboardMetricApiFactory as FollowupMetricFactory,
    AbsenceApiFactory,
    DeviantApiFactory,
    ExcelApiFactory,
    SearchAbsenceApiFactory,
    SearchDeviantApiFactory,
    SocialInsuranceApiFactory,
} from "../swagger/absencefollowup";
import { FileImportApiFactory, FilesApiFactory } from "swagger/storage";
import {
    AbsenceReportDegreesApiFactory,
    AbsenceReportFieldsApiFactory,
    AbsenceReportsApiFactory,
    FieldTemplateApiFactory,
    OptionFieldDescriptionsApiFactory,
    PingApiFactory,
    TemplatesApiFactory,
    Configuration,
    MedicalCertificatesApiFactory as FirstDayCertificateRequiredFactory,
    ConfigurationFieldsApiFactory,
    HistoricalAbsenceReportApiFactory,
    TemplateFieldDescriptionsApiFactory,
} from "../swagger/absencereportapi";
import {
    ContentApiFactory,
    NewsApiFactory,
    WidgetApiFactory,
    WelcomeMessageApiFactory,
    LocalSearchApiFactory,
} from "../swagger/people";
import { PasswordResetApiFactory, UserApiFactory } from "../swagger/usercontextservice";
import {
    OrganizationApiFactory,
    EmploymentApiFactory,
    SubscribersApiFactory,
    AbsenceApiFactory as EAAbsenceApiFactory,
    UserApiFactory as UserRehabApiFactory,
    CostCalculationBasisApiFactory,
} from "../swagger/employeeattendance";
import {
    DashboardMetricApiFactory as RehabMetricFactory,
    RehabApiFactory,
    ServiceContractApiFactory,
} from "../swagger/rehab";
import {
    CustomerSupportApiFactory,
    UserApiFactory as CustomerUserApiFactory,
    CustomerCompanyApiFactory,
    DataAnonymizationApiFactory,
} from "../swagger/customercompany";
import {
    AbsenceWorkRatioApiFactory,
    ForecastApiFactory,
    DashboardMetricApiFactory as StatisticsDashboardMetricApiFactory,
} from "../swagger/statistics";
import { Config } from "../config";
import { SupportApiFactory } from "swagger/support";
import {
    AccessRightConfigurationApiFactory,
    BusinessSupportApiFactory,
    CustomerApiFactory,
    OnboardingApiFactory,
} from "swagger/businessupport/api";
import { AuthApiFactory } from "swagger/authentication";
import { JwtApiFactory } from "swagger/authorization";
import {
    ConfigurationApiFactory,
    ConfigurationFieldApiFactory,
    ContactInformationApiFactory,
    HealthCaseApiFactory as HealthCaseMessengerApiFactory,
    InboxApiFactory,
    PartnerContactApiFactory,
    TemporarySubscriptionApiFactory,
    UserRoleApiFactory,
} from "swagger/messenger";
import { MedicalAdvisoryApiFactory } from "swagger/medicaladvisory";
import { EmploymentApiFactory as RiskApiFactory } from "swagger/risk";
import { handleSessionStorage } from "pages/login/utils";
import {
    ExcelApiFactory as ExcelHealthCaseApiFactory,
    HealthCaseActivityApiFactory,
    HealthCaseApiFactory,
    HealthCaseDocumentApiFactory,
    ResponsibleApiFactory,
    SearchHealthCaseApiFactory,
    TemplateApiFactory,
    DashboardApiFactory,
    TemplateFormDocumentApiFactory,
} from "swagger/healthCaseManagement";

/**
 * The environment is a place where services and shared dependencies between
 * models live.  They are made available to every model via dependency injection.
 */
export class Services {
    token: string;

    absenceReportApi: string;

    userContextService: string;

    absenceFollowup: string;

    people: string;

    employeeAttendance: string;

    rehab: string;

    customerCompany: string;

    statistics: string;

    businessSupport: string;

    storage: string;

    support: string;

    accessRights: string;

    authentication: string;

    authorization: string;

    messenger: string;

    healthCaseManagement: string;

    medicaladvisory: string;

    risk: string;

    /**
     *
     */
    constructor(
        token: string,
        absenceReportApi: string,
        userContextService: string,
        absenceFollowup: string,
        people: string,
        employeeAttendance: string,
        rehab: string,
        customerCompany: string,
        statistics: string,
        businessSupport: string,
        storage: string,
        support: string,
        accessRights: string,
        authentication: string,
        authorization: string,
        messenger: string,
        healthCaseManagement: string,
        medicaladvisory: string,
        risk: string,
    ) {
        this.token = token;
        this.absenceReportApi = absenceReportApi;
        this.userContextService = userContextService;
        this.absenceFollowup = absenceFollowup;
        this.people = people;
        this.employeeAttendance = employeeAttendance;
        this.rehab = rehab;
        this.customerCompany = customerCompany;
        this.statistics = statistics;
        this.businessSupport = businessSupport;
        this.storage = storage;
        this.support = support;
        this.accessRights = accessRights;
        this.authentication = authentication;
        this.authorization = authorization;
        this.messenger = messenger;
        this.healthCaseManagement = healthCaseManagement;
        this.medicaladvisory = medicaladvisory;
        this.risk = risk;
    }

    get clients() {
        // TODO READ FROM ENV VAR
        // https://github.com/vercel/next.js/issues/6888#issuecomment-479867225
        const {
            absenceReportApi,
            userContextService,
            absenceFollowup,
            people,
            employeeAttendance,
            rehab,
            customerCompany,
            statistics,
            businessSupport,
            storage,
            support,
            accessRights,
            authentication,
            authorization,
            messenger,
            healthCaseManagement,
            medicaladvisory,
            risk,
        } = this;

        // TODO2 New basePath For new API
        const config: Configuration = new Configuration({
            accessToken: `Bearer ${this.token}`,
            apiKey: `Bearer ${this.token}`,
        });

        const roles = jwtDecode<any>(this.token).role as string[];
        let isMedicalAdvisory = false;

        if (Array.isArray(roles)) {
            isMedicalAdvisory = roles.some(
                (role: string) => role.toLowerCase() === "ma" || role.toLowerCase() === "maaa",
            );
        } else
            isMedicalAdvisory = (roles as string).toLowerCase() === "ma" || (roles as string).toLowerCase() === "maaa";

        axios.interceptors.request.use((config) => {
            handleSessionStorage();

            config.headers["x-reported-via"] = isMedicalAdvisory ? "MedicalAdvisoryWeb" : "web";
            config.headers["Timing-Allow-Origin"] = "*";
            config.headers.Authorization = `Bearer ${this.token}`;

            return config;
        });

        return {
            historicalAbsenceReport: HistoricalAbsenceReportApiFactory(config, absenceReportApi, axios),
            absenceReportDegrees: AbsenceReportDegreesApiFactory(config, absenceReportApi, axios),
            absenceReportFields: AbsenceReportFieldsApiFactory(config, absenceReportApi, axios),
            absenceReports: AbsenceReportsApiFactory(config, absenceReportApi, axios),
            fieldTemplate: FieldTemplateApiFactory(config, absenceReportApi, axios),
            optionFieldDescriptions: OptionFieldDescriptionsApiFactory(config, absenceReportApi, axios),
            configurationFields: ConfigurationFieldsApiFactory(config, absenceReportApi, axios),
            ping: PingApiFactory(config, absenceReportApi, axios),
            templates: TemplatesApiFactory(config, absenceReportApi, axios),
            firstDayCertificateRequired: FirstDayCertificateRequiredFactory(config, absenceReportApi, axios),
            passwordReset: PasswordResetApiFactory(config, userContextService, axios),
            user: UserApiFactory(config, userContextService, axios),
            dashboardMetric: FollowupMetricFactory(config, absenceFollowup, axios),
            absence: AbsenceApiFactory(config, absenceFollowup, axios),
            deviant: DeviantApiFactory(config, absenceFollowup, axios),
            excel: ExcelApiFactory(config, absenceFollowup, axios),
            searchAbsence: SearchAbsenceApiFactory(config, absenceFollowup, axios),
            searchDeviant: SearchDeviantApiFactory(config, absenceFollowup, axios),
            widget: WidgetApiFactory(config, people, axios),
            organization: OrganizationApiFactory(config, employeeAttendance, axios),
            employment: EmploymentApiFactory(config, employeeAttendance, axios),
            absenceAttendance: EAAbsenceApiFactory(config, employeeAttendance, axios),
            subscribeEmployment: SubscribersApiFactory(config, employeeAttendance, axios),
            rehabMetric: RehabMetricFactory(config, rehab, axios),
            rehab: RehabApiFactory(config, rehab, axios),
            serviceContract: ServiceContractApiFactory(config, rehab, axios),
            news: NewsApiFactory(config, people, axios),
            search: LocalSearchApiFactory(config, people, axios),
            customerSupport: CustomerSupportApiFactory(config, customerCompany, axios),
            customerUserSupport: CustomerUserApiFactory(config, customerCompany, axios),
            customerCompany: CustomerCompanyApiFactory(config, customerCompany, axios),
            dataAnonymization: DataAnonymizationApiFactory(config, customerCompany, axios),
            absenceRatio: AbsenceWorkRatioApiFactory(config, statistics, axios),
            content: ContentApiFactory(config, people, axios),
            welcomeMessage: WelcomeMessageApiFactory(config, people, axios),
            businessSupport: BusinessSupportApiFactory(config, businessSupport, axios),
            storage: FilesApiFactory(config, storage, axios),
            customer: CustomerApiFactory(config, businessSupport, axios),
            support: SupportApiFactory(config, support, axios),
            accessRights: AccessRightConfigurationApiFactory(config, accessRights, axios),
            onBoarding: OnboardingApiFactory(config, businessSupport, axios),
            forecast: ForecastApiFactory(config, statistics, axios),
            authentication: AuthApiFactory(config, authentication, axios),
            jwt: JwtApiFactory(config, authorization, axios),
            temporarySubscription: TemporarySubscriptionApiFactory(config, messenger, axios),
            healthCase: HealthCaseApiFactory(config, healthCaseManagement, axios),
            healthCaseActivity: HealthCaseActivityApiFactory(config, healthCaseManagement, axios),
            healthCaseDocument: HealthCaseDocumentApiFactory(config, healthCaseManagement, axios),
            healthCaseDashboard: DashboardApiFactory(config, healthCaseManagement, axios),
            template: TemplateApiFactory(config, healthCaseManagement, axios),
            medicaladvisory: MedicalAdvisoryApiFactory(config, medicaladvisory, axios),
            risk: RiskApiFactory(config, risk, axios),
            templateFieldDescriptions: TemplateFieldDescriptionsApiFactory(config, absenceReportApi, axios),
            healthCaseMessenger: HealthCaseMessengerApiFactory(config, messenger, axios),
            responsible: ResponsibleApiFactory(config, healthCaseManagement, axios),
            searchHealthCase: SearchHealthCaseApiFactory(config, healthCaseManagement, axios),
            excelHealthCase: ExcelHealthCaseApiFactory(config, healthCaseManagement, axios),
            userRehab: UserRehabApiFactory(config, employeeAttendance, axios),
            costCalculation: CostCalculationBasisApiFactory(config, employeeAttendance, axios),
            statisticsDashboardMetric: StatisticsDashboardMetricApiFactory(config, statistics, axios),
            healthCaseTemplates: TemplateApiFactory(config, healthCaseManagement, axios),
            templateFormDocument: TemplateFormDocumentApiFactory(config, healthCaseManagement, axios),
            inbox: InboxApiFactory(config, messenger, axios),
            configuration: ConfigurationApiFactory(config, messenger, axios),
            socialInsurance: SocialInsuranceApiFactory(config, absenceFollowup, axios),
            configurationField: ConfigurationFieldApiFactory(config, messenger, axios),
            partnerContact: PartnerContactApiFactory(config, messenger, axios),
            contactInformation: ContactInformationApiFactory(config, messenger, axios),
            userRole: UserRoleApiFactory(config, messenger, axios),
            fileImportStorage: FileImportApiFactory(config, storage, axios),
        };
    }

    setServicesJwtToken(token: string) {
        this.token = token;
    }
}

let services: Services;

export const createServicesUsingToken = (token: string): Services => {
    services = new Services(
        token,
        Config.api_absenceReport_url,
        Config.api_userContextService_url,
        Config.api_absenceFollowup_url,
        Config.api_people_url,
        Config.api_employeeAttendance_url,
        Config.api_rehab_url,
        Config.api_customerCompany_url,
        Config.api_statistics_url,
        Config.api_businessSupport_url,
        Config.api_storage_url,
        Config.api_support_url,
        Config.api_accessRights_url,
        Config.api_authService_url,
        Config.api_authorization_url,
        Config.api_messenger_url,
        Config.api_healthCaseManagement_url,
        Config.api_medicaladvisory_url,
        Config.api_risk_url,
    );
    services.setServicesJwtToken(token);
    return services;
};

export const getServices = (): Services => {
    return services;
};

export const resetService = () => {
    //@ts-ignore
    services = undefined;
};
